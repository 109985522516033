import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import { invoiceUses } from '../../helpers/invoiceUses';
import { useAuth } from '../../hooks/useAuth';
import { useClientsSlice } from '../../hooks/redux/useClientsSlice';
import { useUsersSlice } from '../../hooks/redux/useUsersSlice';

export const PaymentResumenOptions = ({ PaymentResumeProps }) => {

    const { user, userInfo } = useAuth();
    const { clients } = useClientsSlice();
    const { users } = useUsersSlice();

    const [ clientsOptions, setClientsOptions ] = useState({});
    const [ sellersOptions, setSellersOptions ] = useState({});
    
    useEffect(() => {
        generateClients( );
        generateSellers( );
    }, []);

    const {
        creditPayment,
        handleClientChange,
        handleCreditPaymentChange,
        handleDiscountChange,
        handleInvoiceTypeChange,
        handlePaymentTypeChange,
        handlePurchaseTypeChange, 
        handleRequiresInvoiceChange,
        handleSellerChange,
        paymentType,
        purchaseType, 
        requiresInvoice,
        selectedClient,
    } = PaymentResumeProps;

    const generateClients = ( ) => {
        let options = [{ label: 'Público en general', value: 'public' }];
        clients.forEach(client => {
            options.push({ label: client.name, value: client.id })
        })
        setClientsOptions( options );
    }

    const generateSellers = ( ) => {
        let options = [];
         users.forEach(seller => {
            options.push({ label: seller.name, value: seller.id })
        })
        setSellersOptions( options );
    }


    return (
        <div className = ' align-items-center py-4 '>
            <div className=' row ps-sm-3 pe-sm-1 ps-2 '>

                <div className = ' col-12 mb-2 '>
                    { sellersOptions.length > 0 && (
                        <>
                            <label className = ' text_instructions '>Selecciona tu nombre (de vendedor).</label>
                            <Select
                                defaultValue = { () => {
                                    const loggedUser = sellersOptions.find( seller => seller.value === user.uid );
                                    return loggedUser || { label: userInfo.name, value: userInfo.name };
                                }}
                                onChange = { handleSellerChange }
                                options = { sellersOptions }
                                placeholder='Seleccionar vendedor'
                            />
                        </>
                    )}
                </div>

                <div className = ' col-12 mb-2'>
                    { clientsOptions.length > 0 && (
                        <>
                            <label className = ' text_instructions '>Selecciona el cliente.</label>
                            <Select
                                defaultValue = { () => {
                                    const publicUser = clientsOptions.find( client => client.value === 'public' );
                                    return publicUser || { label: 'Público en General', value: 'public' }
                                }}
                                onChange = { handleClientChange }
                                options = { clientsOptions }
                                placeholder='Seleccionar cliente'
                            />
                        </>
                    )}
                </div>


                { selectedClient !== 'public' && selectedClient?.creditAuthorized && (
                    <div className = ' col-6 mt-1 '>
                        <div className = "form-check">
                            <input 
                                className = "form-check-input" type="checkbox" value="" id="creditPurchase" 
                                checked = { purchaseType === 'cash' ? false : true }
                                onChange = { handlePurchaseTypeChange }        
                            />
                            <label className = " text_data " htmlFor="creditPurchase">
                                Compra a crédito
                            </label>
                        </div>
                    </div>
                )}

                { selectedClient.id !== 'public' && (
                    <div className = ' col-6 mt-1 '>
                        <div className = " form-check ">
                            <input 
                                className = "form-check-input" type="checkbox" value="" id = "requiresInvoice" 
                                onChange = { handleRequiresInvoiceChange }
                            />
                            <label className = " text_data " htmlFor = "requiresInvoice">
                                Requiere factura
                            </label>
                        </div>
                    </div>
                )}

                { purchaseType === 'credit' && selectedClient?.creditAuthorized && (
                <div className = 'col-6 mt-1 '>
                        <div className = "form-check">
                            <input 
                                className = "form-check-input" type="checkbox" value = '' id="chargeCredit" 
                                checked = { creditPayment }
                                onChange = { handleCreditPaymentChange }
                            />
                            <label className = " text_data " htmlFor="chargeCredit">
                                Cobrar a precio de crédito
                            </label>
                        </div>
                </div>
                )}
            </div>

            { requiresInvoice && selectedClient.id !== 'public' && (
                <div className = ' mt-1 '>
                    <label className = ' text_instructions '>Selecciona el uso de la factura</label>
                    <Select 
                        options = { invoiceUses }
                        onChange = { handleInvoiceTypeChange }
                    />
                </div>
            )}

            <div className = ' mt-3 '>
                <div className = ' d-flex align-items-center '>
                    <label>Descuento </label>
                    <input 
                        className = 'form-control ms-2 '
                        min = '0'
                        onChange = { handleDiscountChange }
                        placeholder = 'Descuento' 
                        type = 'number'
                    />
                </div>
            </div>

            <div className = ' row mx-0 mt-3 '>
                <div className = ' col-6 mt-1 '>
                    <div className = "form-check">
                        <input 
                            className = "form-check-input" type="checkbox" value="" id="cardPayment" 
                            checked = { paymentType === 'card' ? true : false } 
                            onChange = { (e) => handlePaymentTypeChange(e, 'card') }
                        />
                        <label className = " text_data " htmlFor="cardPayment">
                            Pago con tarjeta
                        </label>
                    </div>
                </div>
                <div className = ' col-6 mt-1 '>
                    <div className = "form-check">
                        <input 
                            className = "form-check-input" type="checkbox" value="" id="cashPayment" 
                            checked = { paymentType === 'cash' ? true : false } 
                            onChange = { (e) => handlePaymentTypeChange(e, 'cash') }
                        />
                        <label 
                            className = " text_data " 
                            htmlFor="cashPayment" >
                            Pago con efectivo
                        </label>
                    </div>
                </div>
            </div>
                
        </div>
    )
}
