import React, { useEffect } from 'react';
import Select from 'react-select';
import DatePicker, { registerLocale } from 'react-datepicker';

import { formatDate } from '../../helpers/formatDate';
import { useAccounting } from '../../hooks/useAccounting';

/* Date Pickers config  */
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
registerLocale('es', es);

const today = new Date();
today.setHours( 0, 0, 0 ,0 );

export const InvoicesList = ({ setSelectedInvoice, modifiedInvoice, setModifiedInvoice }) => {

    const { state, stateUpdaters } = useAccounting();
    const { 
        filter, filterOptions, filterOptionsStatus, 
        fromDate, toDate,
        shownInvoices, 
        disableMoreInvoices } = 
    state;
    const {
        getInvoices, 
        handleFilterChange, handleStatusChange, 
        loadInvoices,
        updateInvoices, getMoreInvoices,
        setFromDate, setToDate,
    } = stateUpdaters;

    useEffect(() => {
        if (!modifiedInvoice) return;

        updateInvoices();
        setSelectedInvoice('');
        setModifiedInvoice(false);
    }, [modifiedInvoice]);

    return (
        <div className = ' accounting_list '>
            <div className=' d-flex align-items-center px-3 '>
                <div className=' flex-grow-1 '>
                    <p className=' section-title ps-2 pt-4 pb-2 '>Facturación</p>
                </div>
                <div className=' px-2 '>
                    <div
                        className = ' header-button  me-2 '
                        onClick={getInvoices}
                    >
                        <ion-icon name="refresh-outline"></ion-icon>
                    </div>
                </div>
            </div>
            <div className=' row mx-0 px-3 '>
                <div className = ' col-6 pe-2'>
                    <p className = ' mb-2 text_bold-label '>Fecha</p>
                    <Select
                        defaultValue={filterOptions[1]}
                        onChange={handleFilterChange}
                        options={filterOptions}
                    />
                </div>
                <div className=' col-6 px-2'>
                    <p className='mb-2 text_bold-label '>Estatus</p>
                    <Select
                        defaultValue={filterOptionsStatus[0]}
                        onChange={handleStatusChange}
                        options={filterOptionsStatus}
                    />
                </div>

                {/* If filter is to custom dates, show pickers. */}
                { filter === 'custom' && (
                    <div className = ' mt-2 '>
                        <div className = ' row d-flex align-items-end '>
                            <div className = ' col-5 '>
                                <label className = ' text_instructions ms-1 '>Desde</label>
                                <DatePicker 
                                    dateFormat = "dd/MM/yyyy"
                                    locale = 'es'
                                    maxDate = { today }
                                    onChange = { setFromDate } 
                                    selected = { fromDate } 
                                    wrapperClassName = 'datepicker_white'
                                />

                            </div>
                            <div className = ' col-5 '>
                                <label className = ' text_instructions ms-1 '>Hasta</label>
                                <DatePicker 
                                    dateFormat = "dd/MM/yyyy"
                                    locale = 'es'
                                    maxDate = { today }
                                    minDate = { fromDate }
                                    onChange = { setToDate } 
                                    selected = { toDate } 
                                    wrapperClassName = 'datepicker_white'
                                />
                            </div>
                            <div className = ' col-2 '>
                                <button 
                                    className = ' button purple-button '
                                    onClick = { loadInvoices }>Buscar</button>
                            </div>
                        </div>
                    </div>
                )}


                { shownInvoices.length > 0 &&  (
                    <div className=' row mt-4 text-start px-3 pb-1 mx-0 '>
                        <div className=' offset-2 col-3 px-2 '>
                            <p className = ' text_bold-label '>Folio</p>
                        </div>
                        <div className=' col-4 px-2 '>
                            <p className = ' text_bold-label '>Fecha</p>
                        </div>
                        <div className=' col-1 px-2 '>
                            <p className = ' text_bold-label '>Estatus</p>
                        </div>
                    </div>
                )}


            </div>
            <div className=' mt-1 '>
                {(shownInvoices.length > 0) &&
                    shownInvoices.map((invoice, i) => (
                        <div
                            className=' accounting_item mb-2 p-3 bg-white rounded d-flex me-3 row mx-0 '
                            key={i}
                            onClick={() => setSelectedInvoice(invoice)}
                        >
                            <div className=' col-2 mx-1 '>
                                <div 
                                    className = {` 
                                        ${(invoice.paymentType === 'cash' ? 'icons_purple-icon' : 'icons_blue-icon')}
                                    `}
                                >
                                    <ion-icon 
                                        name={(invoice.paymentType === 'cash' ? 'cash-outline' : 'card-outline')}
                                    >
                                    </ion-icon>
                                </div>
                            </div>
                            <div className=' col-3 mx-1 overflow-hidden '>
                                <div className=''>
                                    <p>{invoice.folio}</p>
                                </div>
                            </div>
                            <div className=' col-4 mx-1'>
                                <div className=''>
                                    <p>{ formatDate(invoice.date) }</p>
                                </div>
                            </div>
                            <div className=' col-1 mx-auto '>
                                <div 
                                    className = {`
                                        accounting_icon 
                                        ${ invoice.invoiceFulfilled === true ? 'accounting_green-icon' : 'accounting_red-icon' }
                                    `}
                                >
                                    <ion-icon 
                                        size="large" 
                                        name={(invoice.invoiceFulfilled === true ? 'checkmark-circle-outline' : 'close-circle-outline')}
                                    >
                                    </ion-icon>
                                </div>
                            </div>
                        </div>
                    ))
                }
                {(shownInvoices.length > 0 && !disableMoreInvoices ) && (
                    <div className = ' mb-2 p-3 rounded d-flex me-3'>
                        <div className = ' flex-grow-1 '>
                            <div
                                className='button purple-button mx-auto'
                                onClick={getMoreInvoices}
                            >
                                Mostrar más facturas
                            </div>
                        </div>
                    </div>
                )}
                {(shownInvoices.length > 0 && disableMoreInvoices ) && (
                    <div className=' mb-2 p-3 bg-white rounded d-flex me-3 align-items-center '>
                        <div className={` icons_purple-icon `}>
                            <ion-icon name='close-circle-outline'></ion-icon>
                        </div>
                        <div className = ' flex-grow-1 '>
                            <p>No hay más facturas en estas fechas para mostrar.</p>
                        </div>
                    </div>
                )}
                {(shownInvoices.length === 0) && (
                    <div className=' my-4 p-3 bg-white rounded d-flex me-3 '>
                        <div className = ' flex-grow-1 ms-2 '>
                            <p>Aún no hay ventas registradas para facturacion en esta fecha.</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}