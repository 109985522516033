import React from 'react';
import { UnselectedItemPlaceholder } from '../../UnselectedItemPlaceholder/UnselectedItemPlaceholder';
import { UploadImageComponent } from '../../UploadImageComponent/UploadImageComponent';
import { SupplierDirectoryEdit } from './SupplierDirectoyEdit';


export const SupplierDirectoryDetail = ({ editable, editForm, user, setEditable, uploadImage, removeUser, setUploadImage }) => {

    return (
        <>
            { user !== '' && (
                <div className = ' detail_bordered-container '>
                    <>
                        <div className = {` detail_branding-container `}>
                            { editable ? 
                                <UploadImageComponent 
                                    defaultImg = { user.image } 
                                    userType = { 'proveedor' } 
                                    uploadImage = { uploadImage } 
                                    setUploadImage = { setUploadImage } 
                                />
                            :
                                <img
                                    className = {` detail_branding `}
                                    src = { user.image }
                                    alt = 'directorio'
                                />
                            }
                        </div>
                        <div className = {` container px-3 pt-5 pb-1 `}>
                            <div className = ' d-flex align-items-center '>
                                <p className = ' flex-grow-1 '>{ user.name }</p>
                                <p className='button purple-button' onClick = {() => setEditable(!editable)}>
                                    { (editable) ? 'Cancelar' : 'Editar' }
                                </p>
                            </div>
                        </div>
                    </>
                    <hr className = ' ui_grey-separator ' />
                    <div className = ' px-3 row mx-0 '>
                        { !editable && (
                            <>
                                <div className = ' col-lg-6 mb-4 '>
                                    <p className = ' text_bold-label '>Encargado</p>
                                    <p className = ' text_data '>{ user.contactName }</p>
                                </div>
                                <div className = ' col-lg-6 mb-4 '>
                                    <p className = '  text_bold-label '>Correo Electrónico</p>
                                    <p className = ' text_data '>{ user.email }</p>
                                </div>
                                <div className = ' col-lg-6 mb-4 '>
                                    <p className = ' text_bold-label '>Dirección</p>
                                    <p className = ' text_data '>{ user.address }</p>
                                </div>
                                <div className = ' col-lg-6 mb-4 '>
                                    <p className = ' text_bold-label '>Teléfono</p>
                                    <p className = ' text_data '>{ user.phoneNumber }</p>
                                </div>
                            </>
                        )}
                        { !!editable && (
                            <SupplierDirectoryEdit
                                user = { user } 
                                editSupplier = { editForm } 
                                removeSupplier = { removeUser } 
                                uploadImage = { uploadImage } 
                            />
                        ) }
                    </div>
                </div>
            )}
            { user === '' && (
                <UnselectedItemPlaceholder />
            ) }
        </>
    )

}
