import React, { useEffect, useState } from 'react';
// import firebase from 'firebase/compat/app';
import { Line } from 'react-chartjs-2';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { demoSalesStats } from '../../helpers/sales/demoSalesStats';
import { useSalesSlice } from '../../hooks/redux/useSalesSlice';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);
  

export const ChartComponent = () => {

    const { salesStats } = useSalesSlice();

    const [ chartData, setChartData ] = useState([]);
    const [ labels, setLabels ] = useState([]);

    useEffect(() => {
        setChartLabels();
        prepareData();
    }, []);
    
    const setChartLabels = () => {
        let currentDay = new Date().getDate();
        let labelsTemp = [];
        for(let i = 1; i <= currentDay; i++){
            labelsTemp.push(`${i}`);
        }
        setLabels([...labelsTemp]);
    }

    const prepareData = () => {
        let tempData = [];
        let date = new Date();
        let today = date.getDate();
        let month = date.getMonth()+1;
        let year = date.getFullYear();

        let salesArray = []
        for(let day = 0; day < 30; day++) {
            salesArray.push( salesStats[ day ].total );
        }
        setChartData([...salesArray]);
    }

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
                display: false,
            },
            title: {
                display: false,
                text: 'Venta del mes',
            },
        },
    };


    const data = {
        labels,
        datasets: [
            {
                fill: true,
                label: 'Venta del día',
                data: [...chartData],
                backgroundColor: 'rgba(119, 20, 219, 0.9)',
                borderColor: 'rgba(119, 20, 219, 0.5)',
                tension: 0.4,
            },
        ],
      };

    return (
        <div className = '  mt-2 '>
            <Line data = { data } options = { options }/>
        </div>
    )
}
