import React, { useState } from 'react'
import { AccountingDetail } from '../../Components/AccountingComponents/AccountingDetail';
import { InvoicesList } from '../../Components/AccountingComponents/InvoicesList';

export const AccountingScreen = () => {

    const [ modifiedInvoice, setModifiedInvoice ] = useState(false);
    const [ selectedInvoice, setSelectedInvoice ] = useState('');  

    return (
        <div className = ' px-4 '>

            <div className = ' row '>
                <div className = 'col-6'>
                    <InvoicesList 
                        setSelectedInvoice = { setSelectedInvoice } 
                        setModifiedInvoice = { setModifiedInvoice }
                        modifiedInvoice = { modifiedInvoice }
                    />
                </div>            
                <div className = 'col-6'>
                    <AccountingDetail 
                        selectedInvoice = { selectedInvoice } 
                        setModifiedInvoice = { setModifiedInvoice }
                    />
                </div>            
            </div>
            
            
        </div>
    )
}
