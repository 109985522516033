import React, { useContext, useEffect, useState } from 'react'
import { DetalleProductoItemEditable } from './DetalleProductoItemEditable'
import { SelectAplicacion } from './../SelectAplicacion/SelectAplicacion'
import { AplicacionCarroItem } from '../AplicacionCarroItem/AplicacionCarroItem';
import { useProducts } from '../../../hooks/useProducts';
import Select from 'react-select';
import { ProductContext } from '../../../Context/ProductContext';
import { UploadImageComponent } from '../../UploadImageComponent/UploadImageComponent';
import { productFamily } from '../../../helpers/ProductFamilies';
import { useDetalleInfoEditable } from '../../../hooks/useDetalleInfoEditable';

export const DetalleProductoInfoEditable = ({ producto, productoEditado, setProductoEditado, uploadImage, setUploadImage }) => {
  
    const [ states, stateUpdaters ] = useDetalleInfoEditable(producto, productoEditado, setProductoEditado, uploadImage, setUploadImage);
    const {
        aplications,
        allSuppliers,
        costoFS,
        descripcion, 
        existencia,
        familyFS,
        filter,
        highRotationState,
        image,
        nombre,
        pieza, 
        precio_contado,
        precio_credito,
        proveedorData,
    } = states;
    const {
        addNewAppExistingProduct,
        borrarAplicacion,
        deleteAplication,
        handleCostChange,
        handleFamilyChange,
        handleProveedorChange,
        setFilter,
        setHighRotationState,
    } = stateUpdaters;

    return (
        <div className = ' '>
            <div className = ' row mx-0 ps-3 pe-2 '>
                <div className=' col-6 pe-3 '>
                    <DetalleProductoItemEditable 
                        label = 'Número de pieza' value = { pieza } state = 'pieza'
                        productoEditado = { productoEditado } setProductoEditado = { setProductoEditado } />
                </div>
                <div className = ' col-6 pe-3 '>
                    <DetalleProductoItemEditable 
                        label = 'Nombre del producto' value = { nombre } state = 'nombre'
                        productoEditado = { productoEditado } setProductoEditado = { setProductoEditado } />
                </div>
                <div className = ' col-9 pe-3 '>
                    <DetalleProductoItemEditable 
                        label = 'Descripción' value = { descripcion } state = 'descripcion'
                        productoEditado = { productoEditado } setProductoEditado = { setProductoEditado } />
                </div>
                <div className = ' col-3 pe-3 '>
                    <DetalleProductoItemEditable 
                        label = 'Existencia' value = { existencia } state = 'existencia'
                        productoEditado = { productoEditado } setProductoEditado = { setProductoEditado } />
                </div>
                <div className = 'col-lg-6 pe-3 mb-3 '>
                    <p className = 'mb-2'>Grupo del Producto</p>
                    <Select 
                        id = 'family'
                        onChange = { handleFamilyChange }
                        options = { productFamily }
                        defaultValue = { () => {
                            const family = productFamily.filter( family => family.value === productoEditado.family );
                            if ( family[0] !== undefined ) {
                                return { label: family[0].label, value: familyFS };
                            }
                        }}
                    />
                </div>

                <div className = ' col-6 pe-3 '>
                    { (!allSuppliers.isLoading && proveedorData !== '') && (
                        <>
                            <p className = 'mb-2'>Proveedor</p>
                            <Select 
                                onChange = { handleProveedorChange }
                                options = { 
                                    allSuppliers.map(proveedor => 
                                        ({ value: proveedor.id, label: proveedor.name })
                                    ) 
                                }
                            />
                        </>
                    )}
                </div>
                
                <div className = ' col-4 pe-3 '>
                    {/* <DetalleProductoItemEditable 
                        label = 'Costo' value = { costoFS } state = 'costo'
                        productoEditado = { productoEditado } setProductoEditado = { setProductoEditado } /> */}
                    <div className = ' mb-3 '>
                        <p className = ' text_bold-label '>Costo</p>
                        <input 
                            className = 'form-control mt-2'
                            onChange = { handleCostChange }
                            value = { Number(productoEditado.costo) }
                        />
                    </div>
                </div>

                <div className = ' col-4 pe-3 '>
                    <DetalleProductoItemEditable 
                        label = 'Precio Contado' value = { productoEditado.precio_contado } state = 'precio_contado'
                        productoEditado = { productoEditado } setProductoEditado = { setProductoEditado } />
                </div>

                <div className = ' col-4 pe-3 '>
                    <DetalleProductoItemEditable 
                        label = 'Precio Crédito' value = { productoEditado.precio_credito } state = 'precio_credito'
                        productoEditado = { productoEditado } setProductoEditado = { setProductoEditado } />
                </div>

                <div className = ' col-12 mt-2 '>
                    <p className = ' text_instructions '>Activa este botón para poner el producto en lista de alta rotación.</p>
                    <div 
                        className = {` inventario_rotation-button ${ (!!highRotationState) && 'active' } `}
                        onClick = { () => setHighRotationState(state => !state) }
                    >
                        <ion-icon name = "checkmark-outline"></ion-icon>
                        <p>Este es un producto de alta rotación</p>
                    </div>
                </div>

                <div className = ' col-12 mt-2 d-flex align-items-center ' >
                    <UploadImageComponent 
                        defaultImg = { image }
                        userType = 'product'
                        uploadImage = { uploadImage }
                        setUploadImage = { setUploadImage }
                    />
                </div>
                
                {
                    productoEditado.aplicaciones.map(aplicacion => 
                        <div key = { aplicacion.id } className='col-6 pe-3 mt-3'>
                            <AplicacionCarroItem 
                                borrarAplicacion = { borrarAplicacion }
                                item = { aplicacion }
                            />
                        </div>
                    )
                }
            </div>

            <div className = 'mt-3 ps-3 pe-2 '>
                <SelectAplicacion 
                    aplications = { aplications }
                    addNewAplication = { addNewAppExistingProduct }
                    deleteAplication = { deleteAplication }
                    filter = { filter }
                    setFilter = { setFilter }
                />
            </div>
        </div>
    )

}
