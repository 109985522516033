import { useEffect, useState } from 'react';
// import firebase from 'firebase/compat/app';
import { useSalesSlice } from './redux/useSalesSlice';

export const useIncomeStats = () => {

    const { salesStats } = useSalesSlice();

    const year = new Date().getFullYear();
    const month = Number(new Date().getMonth()) + 1;

    const [ sales, setSales ] = useState([]);
    const [ selectedSale, setSelectedSale ] = useState('');
    const [ date, setDate ] = useState({
        year, month,
    });

    useEffect(() => {
        queryMonthSales(date);
    }, [ date ]);

    const queryMonthSales = ( date ) => {
        setSales([ ...salesStats ]);
    }

    const states = {
        date,
        sales,
        selectedSale,
    }

    const stateUpdaters = {
        setDate,
        setSelectedSale,
    }

    return {
        states,
        stateUpdaters
    }

}
