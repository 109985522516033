import React, { useState } from 'react';
import Select from 'react-select';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useSalesHistory } from '../../hooks/useSalesHistory';
import { HistorySaleItem } from './HistorySaleItem';

/* Date Pickers config  */
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
registerLocale('es', es);

const today = new Date();
today.setHours( 0, 0, 0, 0 );

export const HistorySalesList = ({ setSelectedSale }) => {

    const [ activeSale, setActiveSale ] = useState('');

    const { state, stateUpdaters } = useSalesHistory();
    const { disableMoreSales, filter, filterOptions, filterPaymentOptions, fromDate, shownSales, salesActive, toDate } = state;
    const { getSales, handleFilterChange, handleFilterPaymentChange, searchSales, setFromDate, setToDate, getMoreSales } = stateUpdaters;

    return (
        <div className=' screen_section '>
            <div className=' d-flex align-items-center px-3 '>
                <div className=' flex-grow-1 '>
                    <p className=' section-title ps-2 pt-4 pb-2 '>Historial de Ventas</p>
                </div>
                <div className=' px-2 '>
                    <div
                        className=' header-button me-2 '
                        onClick={getSales}
                    >
                        <ion-icon name="refresh-outline"></ion-icon>
                    </div>
                </div>
            </div>
            <div className=' mb-3 me-3 px-3 '>
                <input
                    className=' search-bar '
                    onChange={searchSales}
                    placeholder="Busca con el número de folio..."
                    type='number'
                />
            </div>
            <div className=' row px-4 '>
                <div className = ' col-6 pe-3'>
                    <p className = 'mb-2 history_filter-name '>Fecha</p>
                    <Select
                        defaultValue={filterOptions[1]}
                        onChange={handleFilterChange}
                        options={filterOptions}
                    />
                </div>
                <div className=' col-6 pe-3 '>
                    <p className = ' mb-2 history_filter-name '>Tipo de pago</p>
                    <Select
                        defaultValue={filterPaymentOptions[0]}
                        onChange={handleFilterPaymentChange}
                        options={filterPaymentOptions}
                    />
                </div>
            </div>

            {/* If filter is 'custom', show the date pickers  */}
            { filter === 'custom' && (
                <div className = ' p-2 mt-1 px-2 '>
                    <div className = ' row mx-0 '>
                        <div className = ' col-6 '>
                            <label className = ' text_instructions ms-1 '>Desde</label>
                            <DatePicker 
                                dateFormat = "dd/MM/yyyy"
                                locale = 'es'
                                maxDate = { today }
                                onChange = { setFromDate } 
                                selected = { fromDate } 
                                wrapperClassName = 'datepicker_white'
                            />

                        </div>
                        <div className = ' col-6 '>
                            <label className = ' text_instructions ms-1 '>Hasta</label>
                            <DatePicker 
                                dateFormat = "dd/MM/yyyy"
                                locale = 'es'
                                maxDate = { today }
                                minDate = { fromDate }
                                onChange = { setToDate } 
                                selected = { toDate } 
                                wrapperClassName = 'datepicker_white'
                            />
                        </div>

                    </div>
                </div>
            )}

            <div className=' mt-4 '>

                {(salesActive === false) && (
                    <div className = ' mb-2 p-3 rounded d-flex me-3'>
                        <div className = ' flex-grow-1 '>
                            <div
                                className = 'button purple-button mx-auto'
                                onClick={getSales}
                            >
                                Mostrar historial de ventas
                            </div>
                        </div>
                    </div>
                )}

                {(salesActive === true && shownSales.length > 0) && 
                    shownSales.map((sale, i) => (
                        <HistorySaleItem 
                            activeSale = { activeSale } 
                            i = { i } 
                            sale = { sale } 
                            setActiveSale = { setActiveSale }
                            setSelectedSale = { setSelectedSale }
                            key = { i }
                        />
                    ))
                }
                {( !disableMoreSales && salesActive && shownSales.length > 0) && (
                    <div className=' mb-2 p-3 rounded d-flex me-3'>
                        <div className = ' flex-grow-1 '>
                            <div
                            className='button purple-button mx-auto'
                            onClick = { getMoreSales }
                            >
                                Mostrar mas ventas
                            </div>
                        </div>
                    </div>
                )}
                {(salesActive === true && shownSales.length === 0) && (
                    <div className=' mb-2 p-3 bg-white rounded d-flex me-3 '>
                        <div className={` icons_purple-icon `}>
                            <ion-icon name='close-circle-outline'></ion-icon>
                        </div>
                        <div className = ' flex-grow-1 '>
                            <p>Aún no hay ventas registradas para esta fecha.</p>
                        </div>
                    </div>
                )}
                { disableMoreSales && salesActive && (
                    <div className=' mb-2 p-3 bg-white rounded d-flex me-3 '>
                        <div className={` icons_purple-icon `}>
                            <ion-icon name='close-circle-outline'></ion-icon>
                        </div>
                        <div className = ' flex-grow-1 '>
                            <p>No hay más ventas en estas fechas para mostrar.</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}