import React, { useContext, useState } from 'react';
import { ProductContext } from '../../Context/ProductContext';
import { Modal } from '../Modal/Modal';
import { CarNewBrand } from './CarNewBrand';

export const CarModelsList = ({ cars, deleteBrand, selectedCar, setSelectedCar, handleBrandSearch }) => {
    
    const [ addNewModal, setAddNewModal ] = useState(false);
    const { showCars, getCars } = useContext(ProductContext);

    return (
        <div className = ' screen_section '>
            <div className = ' d-flex align-items-center '>
                <div className = ' flex-grow-1 '>
                    <p className = ' section-title ps-2 pt-4 pb-2 '>Marcas</p>
                </div>
                <div className = ' ps-2 '>
                    <div 
                        className=' header-button me-2 '
                        onClick = { getCars }
                    >
                        <ion-icon name = "refresh-outline"></ion-icon>
                    </div>
                </div>
                <div className = ' pe-2 '>
                    <div 
                        className=' header-button me-2 '
                        onClick = { () => setAddNewModal(true) } 
                    >
                        <ion-icon name = "add-outline"></ion-icon>
                    </div>
                </div>
            </div>
            <div className = ' w-100 pe-3 '>
                <input 
                    className = ' search-bar '
                    placeholder = 'Busca una marca...'
                    onChange = { handleBrandSearch }
                />
            </div>
            <div className = ' mt-2 '>

                { showCars.length > 0 && (
                    showCars.map((car, index) => (car.brand !== 'Todas las marcas') && (
                        <div
                            key = { index }
                            className = ' d-flex align-items-center pointer mb-2 p-3 bg-white rounded d-flex me-3 '
                            onClick = { () => {
                                setSelectedCar({ ...selectedCar, brandName: car.brand, model: '', engines: [], brandIndex: index })
                            }}
                        >
                            <div className = ' icons_purple-icon '>
                                <ion-icon name = 'car-sport-outline'></ion-icon>
                            </div>
                            <div className = ' flex-grow-1 '>
                                <p>{ car.brand }</p>
                            </div>
                            <div className = ' icons_delete-list-icon '>
                                <ion-icon 
                                    name = "close-circle-outline"
                                    onClick = { () => deleteBrand( index, car.brand ) }
                                ></ion-icon>
                            </div>
                        </div>
                    ))
                )}
            </div>

            { addNewModal && (
                <Modal>
                    <CarNewBrand closeModal = { () => setAddNewModal(false) } />
                </Modal>
            ) }

        </div>
    )
}