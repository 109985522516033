/*

    HOOK NAME: useCart.
    HOOK DESCRIPTION: Hook that stores and returns all 'cart' data (current order).
    HOOK CONTENT:

    # HOOK CONFIG:
        - State Inizialization.
        - Effect Listeners.

    # FIRESTORE PRODUCT FUNCTIONS.
        - Add to cart.
        - Add one piece.
        - Substract one piece.
        - Calculate cart total.
        - Cancel cart (cancel current order).
        - Delete cart product.

    # CAR APLICATION HANDLE PRODUCTS.
        - Add new aplication.
        - Delete aplication.

    HOOKS NOTES:
        - This hook is added on ProductContext. Every function added here with the intention to be
        consumed on the Context must be called on App.js and added to the function object there.

*/

import React, { useState, useEffect } from 'react'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export const useCart = () => {

    const MySwal = withReactContent(Swal)

    const [ addSpecialProduct, setAddSpecialProduct ] = useState({ active: false, product: '' });
    const [cart, setCart] = useState([]);
    const [cartTotal, setCartTotal] = useState({ contado: 0, credito: 0 });

    useEffect(() => {
        if(cart.length === 0) {
            setCartTotal({ credito: 0, contado: 0 });
        } else {
            calculateCartTotal();
        }
    }, [cart])
  
    /* 
     *  Add a product to the cart.
     *  @param { object } producto Firestore product object (with id and data()).
     */
    const addToCart = ( product ) => {
        if( !product.id.includes('generic-stock') ) {
            let found = cart.find(cartProduct => cartProduct.id === product.id);
            if( !found ) {
                const { descripcion, existencia, family, nombre, pieza, precio_contado, precio_credito } = product;
                const productData = { 
                    amount: 1,
                    code: pieza,
                    description: descripcion,
                    discount: 0,
                    family: family,
                    id: product.id,
                    name: nombre,
                    price_cash: precio_contado,
                    price_credit: precio_credito,
                    currentExistence: existencia,
                }
                setCart([...cart, productData]);
            } else {
                let filteredCart = cart.filter(cartProduct => cartProduct.id !== found.id);
                console.log( filteredCart );
                filteredCart.push({
                    amount: found.amount + 1,
                    code: found.code,
                    description: found.description,
                    discount: found.discount,
                    family: found.family,
                    id: found.id,
                    name: found.name,
                    price_cash: found.price_cash,
                    price_credit: found.price_credit,
                    currentExistence: 0,
                })
                console.log(filteredCart);
                setCart([...filteredCart]);
            }
        } else {
            setCart(carrito => [...carrito, product]);
        }
    }

    /* 
     *  Update product quantity on cart to one more.
     *  @param { object } producto Firestore product object (with id and data()).
     */
    const addOnePiece = ( product ) => {
        let found = cart.find( cartProduct => cartProduct.id === product.id );
        let index = cart.findIndex( cartProduct => cartProduct.id === found.id );
        let tempCart = [...cart];
        const currentProduct = tempCart[index];
        tempCart[index] = {
            amount: currentProduct.amount + 1,
            code: currentProduct.code,
            description: currentProduct.description,
            discount: currentProduct.discount,
            family: currentProduct.family,
            id: currentProduct.id,
            name: currentProduct.name,
            price_cash: currentProduct.price_cash,
            price_credit: currentProduct.price_credit,
        }
        setCart([...tempCart]);
    }

    /* 
     *  Calculate the current order total cost.
     */
    const calculateCartTotal = ( ) => {
        let total = { contado: 0, credito: 0 };
        cart.forEach(product => {
            const { amount, discount, price_cash, price_credit } = product;
            total.contado += (price_cash * amount * ( 1 - ( discount / 100 ) ));
            total.credito += (price_credit * amount * ( 1 - ( discount / 100 ) ));
        })
        setCartTotal(total);
    }

    /* 
     *  Cancel current purchase.
     */
    const cancelCart = ( ) => {
        MySwal.fire({
            title: <strong>¿Seguro que deseas cancelar esta venta?</strong>,
            html: <i>Tendrás que comenzar de cero otra venta.</i>,
            confirmButtonText: 'Confirmar',
            showCancelButton: true,
            reverseButtons: true,
            cancelButtonText: 'Regresar',
            icon: 'info'
        }).then(response => {
            if(response.isConfirmed) {
                setCart([])
            }
        })
    }

    /* 
     *  Called when a purchase is completed and cart has to be restarted.
     *  @param { object } producto Firestore product object (with id and data()).
     */
    const cleanCart = ( ) => {
        return new Promise((resolve, reject) => {
            MySwal.fire({
                title: <strong>¡Venta Registrada Exitosamente!</strong>,
                confirmButtonText: 'Continuar',
                icon: 'success'
            }).then(res => {
                setCart([]);
                resolve('cleaned')
            }).catch(e => reject(e))
        })
    }

    /* 
     *  Update product quantity on cart to one more.
     *  @param { object } producto Firestore product object (with id and data()).
     */
    const deleteOnePiece = ( product ) => {
        let found = cart.find( cartProduct => cartProduct.id === product.id );
        let index = cart.findIndex( cartProduct => cartProduct.id === found.id );
        let tempCart = [...cart];
        const currentProduct = tempCart[index];
        tempCart[index] = {
            amount: currentProduct.amount - 1,
            code: currentProduct.code,
            description: currentProduct.description,
            discount: currentProduct.discount,
            family: currentProduct.family,
            id: currentProduct.id,
            name: currentProduct.name,
            price_cash: currentProduct.price_cash,
            price_credit: currentProduct.price_credit,
        }
        setCart([...tempCart]);
    }

    /*
     *  Delete product from cart.
     *  @param { object } producto Firestore product object (with id and data()).
     */
    const deleteCartProduct = ( product ) => {
        let found = cart.find(cartProduct => cartProduct.id === product.id);
        let filteredCarrito = cart.filter(cartProduct => cartProduct.id !== found.id);
        setCart([...filteredCarrito]);
    }
    
    return {
        addSpecialProduct,
        cart, 
        cartTotal,
        addToCart,
        addOnePiece,
        cancelCart,
        calculateCartTotal,
        cleanCart,
        deleteCartProduct,
        deleteOnePiece,
        setAddSpecialProduct,
        setCart,
    }

}
