import firebase from 'firebase/compat/app';

export const updateFolio = ( newFolio ) => {
    return new Promise( async (resolve, reject) => {
        await firebase.firestore().collection('datos').doc('folio').update({ folio: newFolio })
            .then(res => {
                resolve('success');
            })
            .catch( reject );
    })
}