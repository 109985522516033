import React, { useEffect } from 'react';
import { InputComponent } from '../InputComponent/InputComponent';
import camera from '../../cameraPP.png';
import { UploadImageComponent } from '../UploadImageComponent/UploadImageComponent';

export const NewClientModal = ({ closeModal, formik, savedData, uploadImage, setUploadImage }) => {

    useEffect(() => {
        (!!savedData) && closeModal();
    }, [savedData]);
    
    return (
        <div className = {` modal `}>
            <div className = {` modal-content `}>

                <div className = {` modal-header `}>
                    <p className='section-title'>Registrar nuevo cliente.</p>
                </div>
                <div className = {` modal-body `}>

                    <form
                        onSubmit = { formik.handleSubmit }
                    >   
                        <div className = {` row `}>

                            <div className = {` col-lg-6 `}>

                                <InputComponent 
                                    id = "clientName"
                                    onChange = { formik.handleChange }
                                    value = { formik.values.clientName }
                                    label = 'Nombre'
                                    placeholder = 'Ingresa el nombre del cliente'
                                />
                                { formik.touched.clientName && formik.errors.clientName ? (
                                    <div className = 'error-message'>
                                        <p>{ formik.errors.nombre }</p>
                                    </div>
                                ) : null}

                                <InputComponent 
                                    id = "clientContactName"
                                    onChange = { formik.handleChange }
                                    value = { formik.values.clientContactName }
                                    label = 'Nombre del Encargado'
                                    placeholder = 'Ingresa el nombre del encargado'
                                />
                                { formik.touched.clientContactName && formik.errors.clientContactName ? (
                                    <div className = 'error-message'>
                                        <p>{ formik.errors.clientContactName }</p>
                                    </div>
                                ) : null}

                                <InputComponent 
                                    id = "clientAddress"
                                    onChange = { formik.handleChange }
                                    value = { formik.values.clientAddress }
                                    label = 'Dirección del cliente'
                                    placeholder = 'Ingresa la direccion del cliente'
                                />
                                { formik.touched.clientAddress && formik.errors.clientAddress ? (
                                    <div className = 'error-message'>
                                        <p>{ formik.errors.clientAddress }</p>
                                    </div>
                                ) : null}
                                
                                <InputComponent 
                                    id = "clientCP"
                                    onChange = { formik.handleChange }
                                    value = { formik.values.clientCP }
                                    label = 'Código Postal del cliente'
                                    placeholder = 'Ingresa el CP del cliente'
                                />
                                { formik.touched.clientCP && formik.errors.clientCP ? (
                                    <div className = 'error-message'>
                                        <p>{ formik.errors.clientCP }</p>
                                    </div>
                                ) : null}

                                <UploadImageComponent defaultImg = { camera } userType = 'newUser' uploadImage = { uploadImage } setUploadImage = { setUploadImage }  />

                                <p className = 'input-label mt-4' >Autorizar Crédito a Cliente</p>
                                <div className = "form-check form-switch">
                                    <input 
                                        className = "form-check-input" 
                                        type = "checkbox" 
                                        id = "creditAuthorized" 
                                        onChange = { formik.handleChange } 
                                        value = { formik.values.creditAuthorized }
                                        checked = { formik.values.creditAuthorized }
                                    />
                                    <label className = "form-check-label" htmlFor = "creditAuthorized">Crédito Autorizado</label>
                                </div>

                            </div>
                            <div className = {` offset-lg-1 col-lg-5 `}>
                                <InputComponent 
                                    id = "clientPhoneNumber"
                                    onChange = { formik.handleChange }
                                    value = { formik.values.clientPhoneNumber }
                                    label = 'Teléfono'
                                    placeholder = 'Ingresa el telefono del cliente'
                                />
                                { formik.touched.clientPhoneNumber && formik.errors.clientPhoneNumber ? (
                                    <div className = 'error-message'>
                                        <p>{ formik.errors.clientPhoneNumber }</p>
                                    </div>
                                ) : null}

                                <InputComponent 
                                    id = "clientRFC"
                                    onChange = { formik.handleChange }
                                    value = { formik.values.clientRFC }
                                    label = 'RFC'
                                    placeholder = 'Ingresa el RFC del cliente'
                                />
                                { formik.touched.clientRFC && formik.errors.clientRFC ? (
                                    <div className = 'error-message'>
                                        <p>{ formik.errors.clientRFC }</p>
                                    </div>
                                ) : null}

                                <InputComponent 
                                    id = "clientEmail"
                                    onChange = { formik.handleChange }
                                    value = { formik.values.clientEmail }
                                    label = 'Correo'
                                    placeholder = 'Ingresa el correo del cliente'
                                />
                                { formik.touched.clientEmail && formik.errors.clientEmail ? (
                                    <div className = 'error-message'>
                                        <p>{ formik.errors.clientEmail }</p>
                                    </div>
                                ) : null}

                                <InputComponent 
                                    id = "discount"
                                    onChange = { formik.handleChange }
                                    value = { formik.values.discount }
                                    label = 'Descuento'
                                    placeholder = 'Ingresa el descuento del cliente'
                                />
                                { formik.touched.discount && formik.errors.discount ? (
                                    <div className = 'error-message'>
                                        <p>{ formik.errors.discount }</p>
                                    </div>
                                ) : null}

                            </div>
                        </div>
                        <div className = {` modal-footer `}>
                            <button 
                                className = {` button cancel-button shadow px-5 `}
                                onClick = { closeModal }
                                type = 'reset'
                            >
                                Cancelar
                            </button>
                            <button 
                                className = {` button purple-button shadow px-5 ms-3 `}
                                type = 'submit'
                            >
                                Registrar
                            </button>
                        </div>
                    </form>

                </div>
            </div>    
        </div>
    )

}
