import firebase from 'firebase/compat/app';

export const getProductMoresNotifications = ( existence, limit, lastDocument ) => {
    return new Promise(async (resolve, reject) => {
        // await firebase.firestore().collection('inventario')
        //     .orderBy('existencia', 'asc')
        //     .where('highRotation', '==', true)
        //     .where('existencia', '<=', existence)
        //     .startAfter( lastDocument ).
        //     limit( limit )
        //     .get()
        //     .then( resolve )
        //     .catch( reject );
        resolve( [] );
    })
}