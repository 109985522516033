import React from 'react';
import pageIcon from './404-error.png'
import { Link } from 'react-router-dom';

export const PageNotFoundScreen = () => {
	return (
		<>
			<div className = ' notfound_container '>
				<div className = ' notfound_info-container shadow'>
					<img className = ' notfound_info-icon ' src = { pageIcon } alt = 'error-404' />
					<p className = 'h5 info-container-title'>Error 404: No se ha encontrado la página</p>
					<p className = 'py-3'>No hay nada por aquí..</p>
					<Link className = 'btn purple-button back-button' to = '/'>Regresar a Inicio</Link>
				</div>
			</div>
		</>
	)
}
