import React, { useState } from 'react';
import { CSVLink } from 'react-csv';
import Select from 'react-select';
import { families } from '../../../helpers/ProductFamilies';
import { useInventory } from '../../../hooks/useInventory';

export const InventorySoldProductsReport = ({ closeModal }) => {
 
    const { 
        activeDateButtons, activateDownload, activateFileGeneration,
        getSalesByDate, getProductsData, handleFamilySelection, setActivateFileGeneration, setActivateDownload
    } = useInventory();
    const [ fromDate, setFromDate ] = useState('');
    const [ toDate, setToDate ] = useState('');
    const [ products, setProducts ] = useState([]);

    const generateFile = () => {
        getSalesByDate( fromDate, toDate ).then(async res => {
            await getProductsData(res).then(products => {
                console.log(products);
                setProducts([ ...products ]);
                setActivateDownload(true);
            }).catch(e => console.error(new Error(e)));
        }).catch(e => console.error(new Error(e)));
    }

    const headers = [
        { label: 'Pieza', key: 'pieza' },
        { label: 'Nombre', key: 'nombre' },
        { label: 'Vendidos', key: 'vendidos' },
        { label: 'Existencia', key: 'existencia' },
    ];


    const setDates = ( duration ) => {
        const today = new Date();
        const from = new Date();

        switch( duration ) {
            case ' day ': 
                from.setDate( today.getDate() - 1 );
                break;
            case 'week': 
                from.setDate( today.getDate() - 7 );
                break;
            case 'month': 
                from.setDate( today.getDate() - 30 );
                break;
            default:
                from.setDate( today.getDate() );
                break;
        }
        
        from.setHours(0);
        from.setMinutes(0);
        from.setMilliseconds(0);
        setFromDate( from );
        setToDate( today );
        setActivateFileGeneration( true );
    }

    const setProductsData = () => products;

    return (
        <div className = ' modal '>
            <div className = ' modal-content '>

                <div className = ' modal-header '>
                    <p className = 'section-title'>Descargar reporte de inventario por grupo.</p>
                </div>
                <div className = ' modal-body '>

                    <div className = ' mb-2 '>
                        <p className = 'section-subtitle'>Selecciona una familia</p>
                        <Select 
                            options = { families.map(family => ({ value: family, label: family }) ) }
                            onChange = { handleFamilySelection }
                        />
                    </div>

                    { (activeDateButtons && !activateFileGeneration) && (
                        <div className = ' d-flex mb-3 '>
                            <div className = ' button purple-button me-2 ' onClick = { () => setDates('day') } >
                                Reporte de ayer
                            </div> 
                            <div className = ' button purple-button me-2 ' onClick = { () => setDates('week') } >
                                Reporte de los últimos 7 días
                            </div> 
                            <div className = ' button purple-button me-2 ' onClick = { () => setDates('month') } >
                                Reporte de los últimos 30 días
                            </div> 
                        </div>
                    )}


                    <div className = ' d-flex '>
                        { (!activateDownload && !!activateFileGeneration) && (
                            <div className = ' button purple-button ' onClick = { generateFile } >
                                Generar Reporte
                            </div> 
                        )} 

                        { !!activateDownload  && products.length !== 0 && (
                            <CSVLink 
                                data = { setProductsData() } headers = { headers }
                                filename = { `ReporteVentas.csv` }
                                className = " button purple-button "
                                onClick = { generateFile }
                                separator = ";"
                                enclosingCharacter={`"`}
                            >
                                { activateDownload && 'Descargar' }
                            </CSVLink>
                        )}
                        { !!activateDownload && products.length === 0 && (
                            <div>No se vendieron productos de esta familia en ese periodo.</div>
                        )}
                    </div>



                </div>
                <div className = ' modal-footer '>
                    <button
                        className = ' button cancel-button shadow px-5 '
                        onClick = { closeModal }
                    >
                        Cerrar Ventana
                    </button>
                </div>
            </div>
        </div>
    )

}
