import firebase from 'firebase/compat/app';

/* 
 *      Functions that gets the continues x invoices matching a minimum date, and limit, after the last gotten result.
 *      @params { date } fromDate. 
 *      @params { date } toDate. 
 *      @params { number } limit. How many results do we wanna get.
 *      @params { array } clients. Existing clients already gotten by another function.
 */
export const getMoreInvoicesByPeriod = ( fromDate, toDate, limit, lastVisible ) => {
    return new Promise( async (resolve, reject) => {
        const toDateWithTime = new Date( toDate );
        toDateWithTime.setHours( 23, 59, 59, 999);

        const fromDateQuery = firebase.firestore.Timestamp.fromDate( new Date( fromDate ) );
        const toDateQuery = firebase.firestore.Timestamp.fromDate( new Date( toDateWithTime ) );
        let tempInvoices = [];

        await firebase.firestore().collection('invoices')
            .orderBy("date", "desc")
            .where('date', ">=", fromDateQuery)
            .where('date', "<=", toDateQuery)
            .startAfter(lastVisible)
            .limit( limit )
            .get()
            .then(response => {
                response.forEach(doc => {
                    const invoiceDoc = {
                        id: doc.id,
                        data: doc.data(),
                    }
                    tempInvoices.push( invoiceDoc );
                })
                resolve( tempInvoices );
            })
            .catch( reject )
    })
}
