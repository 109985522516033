import React, { useState } from 'react'
import { CancelPurchaseHeader } from '../../Components/CancelPurchaseComponents/CancelPurchaseHeader';
import { CancelSaleDetail } from '../../Components/CancelPurchaseComponents/CancelSaleDetail';
import { CancelStats } from '../../Components/CancelPurchaseComponents/CancelStats';
import { UnselectedItemPlaceholder } from '../../Components/UnselectedItemPlaceholder/UnselectedItemPlaceholder';
import { formatDate } from '../../helpers/formatDate';
import { formatMoney } from '../../helpers/formatMoney';
import { useCancelPurchase } from '../../hooks/useCancelPurchase'

export const CancelPurchases = () => {

    const [active, setActive] = useState(0);

    const { states, stateUpdaters } = useCancelPurchase();
    const { availableDays, date, sales, selectedSale, total } = states;
    const { getSales, handleSaleSelection, setDate, setSelectedSale } = stateUpdaters;

    const elementSelected = (sale, index) => {
        setActive(index);
        setSelectedSale( sale );
    }

    return (
        <div>
            <div className = {` row mx-0 px-0 `}>
                <div className = {` col-12 col-sm-4 col-lg-5 white-background screen_section pt-4 `}>
                    <CancelPurchaseHeader 
                        availableDays = { availableDays }
                        date = { date }
                        setDate = { setDate }
                        reloadSales = { getSales }
                    />

                    <CancelStats total = { total } />

                    <div className = ' mt-4 '>
                        { sales.map((sale, index) => {
                            const { folio, total, date } = sale.data.data();
                            
                            return (
                                <div 
                                    className = {` directory_list-container ${ active === index && 'active' } px-4 `}
                                    key = { index }
                                    onClick = { () => elementSelected(sale, index) }
                                >
                                    <div className = ' icons_purple-icon '>
                                        <ion-icon name="cash-outline"></ion-icon>
                                    </div>
                                    <div className = ' flex-grow-1 '>
                                        <p>Folio { folio }</p>
                                        <div className = ' d-flex align-center-center flex-grow-1 '>
                                            <p className = ' text_instructions '>
                                                Fecha { formatDate( date ) } - { formatMoney( total ) }
                                            </p>
                                        </div>
                                    </div>
                                    <div className = ' d-flex align-items-center me-3 '>
                                        <input 
                                            checked = { !sale.completed }
                                            className = "form-check-input m-0 " 
                                            type="checkbox" 
                                            id="flexCheckDefault" 
                                            value = ''
                                            onChange = { (e) => handleSaleSelection(sale, e) }
                                        />
                                    </div>
                                </div>
                            )
                        })}
                        { sales.length <= 0 && (
                            <div className = ' p-4 text-center '>
                                <p>No hay ventas registradas en esta fecha.</p>
                            </div>
                        )}
                    </div>
                </div>
                <div className = {` col-12 col-sm-8 col-lg-7 `}>
                    { selectedSale === '' && ( <UnselectedItemPlaceholder /> )}
                    { selectedSale !== '' && (
                        <CancelSaleDetail selectedSale = { selectedSale.data } />
                    )}
                </div>
            </div>
    </div>
    )
}
