
// export const productFamily = [
//     { label: 'Afinación', value: '001' },
//     { label: 'Clutch', value: '002' },
//     { label: 'Eléctrico', value: '003' },
//     { label: 'Enfriamiento', value: '004' },
//     { label: 'Frenos', value: '005' },
//     { label: 'Motor', value: '006' },
//     { label: 'Interno Motor', value: '007' },
//     { label: 'Suspensión, dirección y tracción', value: '008' },
//     { label: 'Varios', value: '009' },
// ]

export const families = ['Varios', 'Aceite', 'Acumulador', 'Eléctrico', 'Suspensión y Dirección', 'Motor', 'Frenos', 'Enfriamiento', 'Clutch / Embrague / Transmisión', 'Servicio / Afinación'];

export const productFamily = [
    { label: 'ABRAZADERAS', value: '001' },
    { label: 'ACEITE', value: '002' },
    { label: 'ACUMULADOR', value: '003' },
    { label: 'ADITIVOS', value: '004' },
    { label: 'ALTERNADOR', value: '005' },
    { label: 'AMORTIGUADOR', value: '006' },
    { label: 'ANILLOS', value: '007' },
    { label: 'ARBOL DE LEVAS', value: '008' },
    { label: 'ARO DENTADO', value: '009' },
    { label: 'BALANCIN', value: '010' },
    { label: 'BALATAS', value: '011' },
    { label: 'BALEROS', value: '012' },
    { label: 'BALERO DOBLE', value: '013' },
    { label: 'BANDAS', value: '014' },
    { label: 'BANDA DE TIEMPO', value: '015' },
    { label: 'BARRA CENTRAL', value: '016' },
    { label: 'BASE DE AMORTIGUADOR', value: '017' },
    { label: 'BASE CARBURADOR', value: '018' },
    { label: 'BUJE PILOTO BIRLO DE BALANCIN', value: '019' },
    { label: 'BOBINA', value: '020' },
    { label: 'BOMBA DE ACEITE', value: '021' },
    { label: 'BOMBA DE AGUA', value: '022' },
    { label: 'BOMBA DE CLUTCH', value: '023' },
    { label: 'BOMBA DE DIRECCION (POWER)', value: '024' },
    { label: 'BOMBA DE GASOLINA', value: '025' },
    { label: 'BOMBA DE FRENOS', value: '026' },
    { label: 'BOTADORES', value: '027' },
    { label: 'BRAZO AUXILIAR Y PITMAN', value: '028' },
    { label: 'BUJES SUSPENSIÓN', value: '029' },
    { label: 'BUJÍAS', value: '030' },
    { label: 'BULBO DE ACEITE', value: '031' },
    { label: 'BULBO DE TEMPERATURA', value: '032' },
    { label: 'CABLE ACELERADOR Y CLUTCH', value: '033' },
    { label: 'CABLES BUJIAS', value: '034' },
    { label: 'CADENA', value: '035' },
    { label: 'CAMISAS MOTOR', value: '036' },
    { label: 'CANDELERO', value: '037' },
    { label: 'CILINDRO LLAVE', value: '038' },
    { label: 'CILINDRO RUEDA', value: '039' },
    { label: 'COLLARÍN', value: '040' },
    { label: 'COPLE DIRECCIÓN', value: '041' },
    { label: 'CORONA Y PIÑÓN', value: '042' },
    { label: 'CREMALLERA', value: '043' },
    { label: 'CRUCETA', value: '044' },
    { label: 'CUBRE POLVO', value: '045' },
    { label: 'DAMPER POLEA CIGÜEÑAL', value: '046' },
    { label: 'DEPÓSITOS', value: '047' },
    { label: 'DISTRIBUIDOR', value: '048' },
    { label: 'EMPAQUE CABEZA', value: '049' },
    { label: 'EMPAQUES CARBURADOR', value: '050' },
    { label: 'EMPAQUE CARTER', value: '051' },
    { label: 'EMPAQUE DISTRIBUCIÓN', value: '052' },
    { label: 'EMPAQUE MÚLTIPLE', value: '053' },
    { label: 'EMPAQUE PUNTERÍAS', value: '054' },
    { label: 'EMPAQUE Y FILTRO TRANSMISIÓN', value: '055' },
    { label: 'CARTERA EMPAQUES', value: '056' },
    { label: 'ENGRANES', value: '057' },
    { label: 'ESPIGAS', value: '058' },
    { label: 'FAN CLUCH', value: '059' },
    { label: 'FILTRO ACEITE', value: '060' },
    { label: 'FILTRO AIRE', value: '061' },
    { label: 'FILTRO COMBUSTIBLE', value: '062' },
    { label: 'FLECHAS', value: '063' },
    { label: 'FOCOS', value: '064' },
    { label: 'GOMA TIRANTE', value: '065' },
    { label: 'GUÍAS VÁLVULA', value: '066' },
    { label: 'HORQUILLAS CLUTCH', value: '067' },
    { label: 'HORQUILLA SUSPENSIÓN', value: '068' },
    { label: 'GOMA BARRA ESTABILIZADORA', value: '069' },
    { label: 'KIT CLUTCH', value: '070' },
    { label: 'KIT DISTRIBUCIÓN', value: '071' },
    { label: 'PLUMA LIMPIAPARABRISAS', value: '072' },
    { label: 'MANGUERA FRENOS', value: '073' },
    { label: 'MANGUERA FÍSICO', value: '074' },
    { label: 'MAZA', value: '075' },
    { label: 'BUJES DE ÁRBOL', value: '076' },
    { label: 'METALES DE BIELA', value: '077' },
    { label: 'METALES DE CENTRO', value: '078' },
    { label: 'MÓDULOS MAGNETOS', value: '079' },
    { label: 'PERKINS', value: '080' },
    { label: 'PERNOS DE MANGO', value: '081' },
    { label: 'PERNO DE PISTÓN', value: '082' },
    { label: 'KIT CALIPER PISTÓN', value: '083' },
    { label: 'PLATINO Y CONDENSADOR', value: '084' },
    { label: 'POLEAS', value: '085' },
    { label: 'TENSOR DE BANDA', value: '086' },
    { label: 'REGULADOR VOLTAJE', value: '087' },
    { label: 'PISTONES MOTOR', value: '088' },
    { label: 'RESORTES SUSPENSIÓN', value: '089' },
    { label: 'RETENES', value: '090' },
    { label: 'ROTOR DISTRIBUIDOR', value: '091' },
    { label: 'RÓTULA', value: '092' },
    { label: 'SELLOS VÁLVULA', value: '093' },
    { label: 'SOPORTE MOTOR TRANSMISIÓN', value: '094' },
    { label: 'SENSORES', value: '095' },
    { label: 'SOPORTE FLECHA CARDAN', value: '096' },
    { label: 'DISCO TAMBOR FRENOS', value: '097' },
    { label: 'TAPA DISTRIBUIDOR', value: '098' },
    { label: 'TAPÓN GASOLINA ACEITE', value: '099' },
    { label: 'TAPÓN RADIADOR', value: '100' },
    { label: 'TENSOR', value: '101' },
    { label: 'TERMOSTÁTO', value: '102' },
    { label: 'TOMA DE AGUA', value: '103' },
    { label: 'TORNILLO ESTABILIZADOR', value: '104' },
    { label: 'TRIPOIDE', value: '105' },
    { label: 'VÁLVULA PCV', value: '106' },
    { label: 'VÁLVULA MOTOR', value: '107' },
    { label: 'VARIOS', value: '108' },
    { label: 'VIELETA Y TERMINAL', value: '109' },
    { label: 'YUGOS', value: '110' },
    { label: 'VENTILADOR Y MOTOVENTILADOR', value: '111' },
]

export const searchFamilies = [
    { label: 'ABRAZADERAS', value: '001' },
    { label: 'ACEITE', value: '002' },
    { label: 'ACUMULADOR', value: '003' },
    { label: 'ADITIVOS', value: '004' },
    { label: 'ALTERNADOR', value: '005' },
    { label: 'AMORTIGUADOR', value: '006' },
    { label: 'ANILLOS', value: '007' },
    { label: 'ARBOL DE LEVAS', value: '008' },
    { label: 'ARO DENTADO', value: '009' },
    { label: 'BALANCIN', value: '010' },
    { label: 'BALATAS', value: '011' },
    { label: 'BALEROS', value: '012' },
    { label: 'BALERO DOBLE', value: '013' },
    { label: 'BANDAS', value: '014' },
    { label: 'BANDA DE TIEMPO', value: '015' },
    { label: 'BARRA CENTRAL', value: '016' },
    { label: 'BASE DE AMORTIGUADOR', value: '017' },
    { label: 'BASE CARBURADOR', value: '018' },
    { label: 'BUJE PILOTO BIRLO DE BALANCIN', value: '019' },
    { label: 'BOBINA', value: '020' },
    { label: 'BOMBA DE ACEITE', value: '021' },
    { label: 'BOMBA DE AGUA', value: '022' },
    { label: 'BOMBA DE CLUTCH', value: '023' },
    { label: 'BOMBA DE DIRECCION (POWER)', value: '024' },
    { label: 'BOMBA DE GASOLINA', value: '025' },
    { label: 'BOMBA DE FRENOS', value: '026' },
    { label: 'BOTADORES', value: '027' },
    { label: 'BRAZO AUXILIAR Y PITMAN', value: '028' },
    { label: 'BUJES SUSPENSIÓN', value: '029' },
    { label: 'BUJÍAS', value: '030' },
    { label: 'BULBO DE ACEITE', value: '031' },
    { label: 'BULBO DE TEMPERATURA', value: '032' },
    { label: 'CABLE ACELERADOR Y CLUTCH', value: '033' },
    { label: 'CABLES BUJIAS', value: '034' },
    { label: 'CADENA', value: '035' },
    { label: 'CAMISAS MOTOR', value: '036' },
    { label: 'CANDELERO', value: '037' },
    { label: 'CILINDRO LLAVE', value: '038' },
    { label: 'CILINDRO RUEDA', value: '039' },
    { label: 'COLLARÍN', value: '040' },
    { label: 'COPLE DIRECCIÓN', value: '041' },
    { label: 'CORONA Y PIÑÓN', value: '042' },
    { label: 'CREMALLERA', value: '043' },
    { label: 'CRUCETA', value: '044' },
    { label: 'CUBRE POLVO', value: '045' },
    { label: 'DAMPER POLEA CIGÜEÑAL', value: '046' },
    { label: 'DEPÓSITOS', value: '047' },
    { label: 'DISTRIBUIDOR', value: '048' },
    { label: 'EMPAQUE CABEZA', value: '049' },
    { label: 'EMPAQUES CARBURADOR', value: '050' },
    { label: 'EMPAQUE CARTER', value: '051' },
    { label: 'EMPAQUE DISTRIBUCIÓN', value: '052' },
    { label: 'EMPAQUE MÚLTIPLE', value: '053' },
    { label: 'EMPAQUE PUNTERÍAS', value: '054' },
    { label: 'EMPAQUE Y FILTRO TRANSMISIÓN', value: '055' },
    { label: 'CARTERA EMPAQUES', value: '056' },
    { label: 'ENGRANES', value: '057' },
    { label: 'ESPIGAS', value: '058' },
    { label: 'FAN CLUCH', value: '059' },
    { label: 'FILTRO ACEITE', value: '060' },
    { label: 'FILTRO AIRE', value: '061' },
    { label: 'FILTRO COMBUSTIBLE', value: '062' },
    { label: 'FLECHAS', value: '063' },
    { label: 'FOCOS', value: '064' },
    { label: 'GOMA TIRANTE', value: '065' },
    { label: 'GUÍAS VÁLVULA', value: '066' },
    { label: 'HORQUILLAS CLUTCH', value: '067' },
    { label: 'HORQUILLA SUSPENSIÓN', value: '068' },
    { label: 'GOMA BARRA ESTABILIZADORA', value: '069' },
    { label: 'KIT CLUTCH', value: '070' },
    { label: 'KIT DISTRIBUCIÓN', value: '071' },
    { label: 'PLUMA LIMPIAPARABRISAS', value: '072' },
    { label: 'MANGUERA FRENOS', value: '073' },
    { label: 'MANGUERA FÍSICO', value: '074' },
    { label: 'MAZA', value: '075' },
    { label: 'BUJES DE ÁRBOL', value: '076' },
    { label: 'METALES DE BIELA', value: '077' },
    { label: 'METALES DE CENTRO', value: '078' },
    { label: 'MÓDULOS MAGNETOS', value: '079' },
    { label: 'PERKINS', value: '080' },
    { label: 'PERNOS DE MANGO', value: '081' },
    { label: 'PERNO DE PISTÓN', value: '082' },
    { label: 'KIT CALIPER PISTÓN', value: '083' },
    { label: 'PLATINO Y CONDENSADOR', value: '084' },
    { label: 'POLEAS', value: '085' },
    { label: 'TENSOR DE BANDA', value: '086' },
    { label: 'REGULADOR VOLTAJE', value: '087' },
    { label: 'PISTONES MOTOR', value: '088' },
    { label: 'RESORTES SUSPENSIÓN', value: '089' },
    { label: 'RETENES', value: '090' },
    { label: 'ROTOR DISTRIBUIDOR', value: '091' },
    { label: 'RÓTULA', value: '092' },
    { label: 'SELLOS VÁLVULA', value: '093' },
    { label: 'SOPORTE MOTOR TRANSMISIÓN', value: '094' },
    { label: 'SENSORES', value: '095' },
    { label: 'SOPORTE FLECHA CARDAN', value: '096' },
    { label: 'DISCO TAMBOR FRENOS', value: '097' },
    { label: 'TAPA DISTRIBUIDOR', value: '098' },
    { label: 'TAPÓN GASOLINA ACEITE', value: '099' },
    { label: 'TAPÓN RADIADOR', value: '100' },
    { label: 'TENSOR', value: '101' },
    { label: 'TERMOSTÁTO', value: '102' },
    { label: 'TOMA DE AGUA', value: '103' },
    { label: 'TORNILLO ESTABILIZADOR', value: '104' },
    { label: 'TRIPOIDE', value: '105' },
    { label: 'VÁLVULA PCV', value: '106' },
    { label: 'VÁLVULA MOTOR', value: '107' },
    { label: 'VARIOS', value: '108' },
    { label: 'VIELETA Y TERMINAL', value: '109' },
    { label: 'YUGOS', value: '110' },
    { label: 'VENTILADOR Y MOTOVENTILADOR', value: '111' },
]