import React, { useEffect, useState } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker';

/*  Date picker config.  */
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
import { getSpecialProductsSold } from '../../../helpers/db/getSpecialProductsSold';
import { formatDate } from '../../../helpers/formatDate';
import { getClients } from '../../../helpers/db/getClients';
import { setSpecialProductChecked } from '../../../helpers/db/setSpecialProductChecked';
import { CSVLink } from 'react-csv';
registerLocale('es', es)

const today = new Date();
const reportHeaders = [
	{ label: 'Pieza', key: 'code' },
	{ label: 'Fecha', key: 'dateSold' },
	{ label: 'Cliente', key: 'client' },
	{ label: 'Cantidad', key: 'amount' },
	{ label: 'Producto', key: 'name' },
	{ label: 'Precio Unitario', key: 'price' },
	{ label: 'Revisado', key: 'checked' },
];

export const InventorySpecialProductsSoldReport = ({ closeModal }) => {
	
	const [ fromDate, setFromDate ] = useState( today );
	const [ toDate, setToDate ] = useState( today );
	const [ products, setProducts ] = useState( [] );
	const [ clients, setClients ] = useState( [] );
	
	const [ activateDownload, setActivateDownload] = useState( false );
	const [ reportProducts, setReportProducts ] = useState( [] );

	useEffect(() => {
		getClients()
			.then( clients => setClients( clients ))
			.catch( err => console.error( err));
	}, []);

	useEffect(() => {
		if( fromDate <= toDate ) return; 
		setToDate( fromDate );
	}, [ fromDate ]);

	useEffect(() => {
		generateReportData();
	}, [ products ]);

	const checkProduct = ( event, productID ) => {
		const { checked } = event.target;
		setSpecialProductChecked( productID, checked )
			.then(async () => {
				const response = await getSpecialProductsSold( fromDate.setHours(0, 0, 0, 0), toDate.setHours( 23, 59, 59, 999 ));
				setProducts( response );
			})
	}

	const findClient = ( clientID ) => {
		if( clientID !== 'public' ) {
			const found = clients.find(client => client.id === clientID );
			return found.data.name;
		} else {
			return 'Público en General';
		}
	}

	const generateReportData = () => {
		if( products.length > 0 ) {
			setReportProducts([]);
			products.forEach(product => {
				const { amount, checked, client, code, dateSold, name, price } = product.data();
				const productInfo = {
					code, 
					dateSold: formatDate( dateSold ), 
					client: findClient(client),
					amount,
					name, 
					price, 
					checked: checked ? 'Sí' : 'No',
				}
				console.log(productInfo);
				setReportProducts( reportProducts => [...reportProducts, productInfo] );		
			})
			setActivateDownload( true );
		}
	}

	const handleSearch = async () => {
		try {
			const response = await getSpecialProductsSold( fromDate.setHours(0, 0, 0, 0), toDate.setHours( 23, 59, 59, 999 ));
			setProducts( response );
		} catch(e) {
			console.error( new Error(e) );
		}
	}
	
	return (
		<div className = ' modal '>
			<div className = ' modal-content '>
				<div className = ' modal-header d-block '>
					<p className = ' section-title mb-1 '>Productos especiales vendidos.</p>
					<p className = ' text_instructions '>
						Aquí podrás consultar los productos vendidos que no están dados de alta 
						en el inventario.
					</p>
				</div>
				<div className = ' modal-body pt-0 '>
					<p className = ' text_instructions '>
						Selecciona las fechas en las que quieres consultar tu reporte.
					</p>
					<div className = ' d-flex align-items-center '>

						<div className = ' me-1 '>
							<DatePicker 
								dateFormat = "dd/MM/yyyy"
								locale = 'es'
								maxDate = { today }
								onChange = { setFromDate } 
								selected = { fromDate } 
							/>
						</div>
						<div className = ' mx-1 '>
							<DatePicker 
								dateFormat = "dd/MM/yyyy"
								locale = 'es'
								minDate = { fromDate }
								maxDate = { today }
								onChange = { setToDate } 
								selected = { toDate } 
							/>
						</div>
						<div className = ' ms-2 '>
							<button 
								className = ' button purple-button px-3 '
								onClick = { handleSearch }
							>
								Buscar
							</button>
						</div>
					</div>
				</div>

				<div>
					{ products.length > 0 && (
						<table className = " table table-hover text_data  " >
							<thead>
								<tr>
									<th scope="col">#</th>
									<th scope="col">Fecha</th>
									<th scope="col">Cliente</th>
									<th  className = ' text-center ' scope="col">Cantidad</th>
									<th scope="col">Producto</th>
									<th className = ' text-center ' scope="col">Precio Unitario</th>
									<th scope="col">Revisado</th>
								</tr>
							</thead>
							<tbody>
								{ products.map( (product, index) => {
									const { amount, checked, client, code, dateSold, name, price } = product.data();
									return (
										<tr key = { index }>
											<th scope = 'row'>{ code }</th>
											<td>{ formatDate( dateSold ) }</td>
											<td>{ findClient( client ) || 'Cargando....' }</td>
											<td  className = ' text-center '>{ amount }</td>
											<td>{ name }</td>
											<td className = ' text-center '>{ price }</td>
											<td  className = ' d-flex justify-content-center '>
												<div className = "form-check">
													<input 
														checked = { checked }
														onChange = { (e) => checkProduct( e, product.id ) }
														class="form-check-input" type="checkbox" value="" id = {`${ index }`}  />
												</div>
											</td>
										</tr>
									)
								})}
							</tbody>
						</table>
					)}
					{ products.length == 0 && (
						<div className = ' px-3 py-2 '>
							<p>
								Lo sentimos, no hay productos que mostrar en este periodo.
							</p>
						</div>
					)}
				</div>

				<div className = ' modal-footer '>
					<button className = 'button cancel-button' onClick = { closeModal }>
						Cerrar
					</button>
					{ reportProducts.length > 0 && (
						<>
							{ !!activateDownload  && products.length !== 0 && (
								<CSVLink 
									data = { reportProducts } headers = { reportHeaders }
									filename = { `ReporteProductosEspeciales.csv` }
									className = " button purple-button "
									separator = ";"
									enclosingCharacter={`"`}
								>
									{ activateDownload && 'Descargar' }
								</CSVLink>
							)}
						</>
					)}
				</div>
			</div>
		</div>
	)
}
