/*

    HOOK NAME: useUsuarios.
    HOOK DESCRIPTION: Hook to edit, read and update users.
    HOOK CONTENT:

    # HOOK CONFIG:
        - State Initialization.

    # FIRESTORE PRODUCT FUNCTIONS.
        - 

*/

import React, { useState } from 'react';

import firebase from 'firebase/compat/app';
import { useAuth } from './useAuth';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { useUploadImage } from './useUploadImage';
import { useUsersSlice } from './redux/useUsersSlice';
import imgPlaceholder from '../assets/placeholders/user.png';


const userTypes = [
    { value: 'Administrador', label: 'Administrador' },
    { value: 'Almacen', label: 'Almacen' },
    { value: 'Ventas', label: 'Ventas' },
];

export const useUsuarios = () => {

    const { users, handleAddNewUser, handleDeleteUser, handleEditUser, } = useUsersSlice();

    const { registerUser, deleteUser } = useAuth();
    const { uploadToFirestore, uploadToStorage, deleteImage } = useUploadImage();
    const storageRef = firebase.storage().ref("usuarios");
    const MySwal = withReactContent(Swal)

    const [ editable, setEditable ] = useState(false);
    const [ uploadImage, setUploadImage ] = useState('');

    const [ loading, setLoading ] = useState(false);
    const [ shownUsers, setShownUsers ] = useState([])
    const [ savedData, setSavedData ] = useState(false);
    const [ usuarios, setUsuarios ] = useState([])
    const [ usuarioSelected, setUsuarioSelected ] = useState('');


    /*   Handle State Functions   */

    /* 
     *  Change the user position state.
     *  @param { event } e Input event.
     */
    const handleChangeSelect = (e) => {
        const datoSeleccionado = e.value;
        formik.setFieldValue('userPosition', datoSeleccionado)
    }

    /* 
     *  Get text written into input.
     *  @param { event } e Input event.
     */
    const handleUserSearch = ({ target: { value }}) => {
        if(!loading) {
            if(value !== '') {
                let searchArray = [];
                users.forEach(usuario => {
                    const name = (usuario.name).toLowerCase();
                    const searchTerm = value.toLowerCase();
                    (name).includes(searchTerm) && searchArray.push(usuario);
                })
                setShownUsers([...searchArray]);
            } else {
                setShownUsers([...users])
            }
        }
    }

    /* 
     *  Get the filter select value.
     *  @param { event } e Input event.
     */
    const handleSearchFilterChange = (event) => {
        const selection = event;
        if ( selection.length > 0 ) {
            const tempUsers = [];
            selection.forEach(position => {
                usuarios.forEach(user => {
                    if( user.position === position.value ) {
                        tempUsers.push(user);
                    } 
                })
            })
            setShownUsers([...tempUsers]);
        } else {
            setShownUsers([...usuarios]);
        }
    }


    /*   Firestore Functions.   */


    /* 
     *  Formik listener to upload new user.
     */
    const formik = useFormik({
        initialValues: {
            userName: '',
            userPhoneNumber: '',
            userPosition: '',
            userEmail: '',
            userAddress: '',
            userPassword: '',
            userPasswordConfirm: '',
            userImage: imgPlaceholder,
        },
        validationSchema: Yup.object({
            userName: Yup.string()
                        .min(3, 'Los nombres de usuario deben tener al menos 3 caracteres')
                        .required('El nombre de usuario es obligatorio'),
            userPhoneNumber: Yup.string()
                        .min(10, 'El telefono debe tener al menos 10 numeros')
                        .required('El telefono del usuario es obligatorio'),
            userPosition: Yup.string()
                        .required('El puesto del usuario es obligatorio'),
            userEmail: Yup.string()
                        .email('ingrese un correo valido')
                        .required('El correo del usuario es obligatorio'),
            userAddress: Yup.string()
                        .required('El nombre de usuario es obligatorio'),
            userPassword: Yup.string()
                        .required('La contraseña es un campo obligatorio')
                        .min(6, 'La contraseña debe tener mínimo 6 caracteres.'),
            userPasswordConfirm: Yup.string()
                        .when("userPassword", {
                            is: val => (val && val.length > 0 ? true : false),
                            then: Yup.string().oneOf(
                              [Yup.ref("userPassword")],
                              "Las contraseñas deben ser iguales"
                            )
                        })
                        .required('Confirmar la contraseña es obligatorio')
        }),
        onSubmit: user => {
            uploadNewUser( user );
        }
    });


    /* 
     *  Formik listener to edit user.
     */
    const formikEdit = useFormik({
        initialValues: {
            userName: '',
            userPhoneNumber: '',
            userPosition: '',
            userAddress: '',
            docID: '',
        },
        validationSchema: Yup.object({
            userName: Yup.string()
                        .min(3, 'Los nombres de usuario deben tener al menos 3 caracteres')
                        .required('El nombre de usuario es obligatorio'),
            userPhoneNumber: Yup.string()
                        .min(10, 'El telefono debe tener al menos 10 numeros')
                        .required('El telefono del usuario es obligatorio'),
            userPosition: Yup.string()
                        .required('El puesto del usuario es obligatorio'),
            userAddress: Yup.string()
                        .required('El nombre de usuario es obligatorio'),
        }),
        onSubmit: user => {
            const userData = {
                name: user.userName,
                phoneNumber: user.userPhoneNumber,
                position: user.userPosition,
                address: user.userAddress,
            }
            try {
                MySwal.fire({
                    title: <strong>¿Seguro que deseas registrar?</strong>,
                    html: <i>Se guardará { user.nombre }</i>,
                    confirmButtonText: 'Guardar',
                    showCancelButton: true,
                    reverseButtons: true,
                    cancelButtonText: 'Regresar',
                    icon: 'info'
                }).then(response => {
                    if(response.isConfirmed) {
                        handleEditUser( user.docID, userData );
                        return MySwal.fire({
                            title: <p>Actualización con éxito</p>,
                            html: <i>Actualiza tus usuarios para consultarlo.</i>,
                            icon: 'success',
                        }).then(() => {
                            setEditable(false);
                            setSavedData(true);
                            getUsuarios();
                        });
                    }                                              
                })
            } catch (error) {
                console.log(error)
            }
        }
    });

    /* 
     *  Load users from Firebase database.
     */
    const getUsuarios = async () => {
        setUsuarios(users);
        setShownUsers(users);
        setLoading(false);
        if( users.length > 0  ) {
            setUsuarioSelected(users[0]);
        }

    }

    /*
     *  Set users from Firebase database.
     */
    const setUsers = ( user ) => {
        getUsuarios();
    }


    /* 
     *  Get text written into input.
     *  @param { object } user Formik user object.
     */
    const NewUrl = ( user ) => {
        return new Promise((resolve, reject) => {
        //     const inicial = (user.userName).trim().split('', 1).toString().toLowerCase()
        //     console.log(inicial)
        //     firebase.storage().ref('placeholders').child(`${inicial}.png`).getDownloadURL().then(data => {
        //         console.log('Dentro del response');
        //         resolve(data)
        //     }).catch(e => {
        //         reject('https://firebasestorage.googleapis.com/v0/b/pos-web-a98f9.appspot.com/o/placeholders%2FPerfil.jpeg?alt=media&token=a152dbaa-4d16-4bb3-aacd-ccecbfe317de')
        //     })
            resolve(imgPlaceholder)
        })
    }

    /* 
     *  Upload new user to Firebase database, used inside formik.
     *  @param { user } object Formik created object.
     */
    const uploadNewUser = ( user ) => {
        const userData = {
            id: users.length + 1,
            name: user.userName,
            phoneNumber: user.userPhoneNumber,
            position: user.userPosition,
            email: user.userEmail,
            address: user.userAddress,
            password: user.userPassword,
            passwordConfirm: user.userPasswordConfirm,
            image: imgPlaceholder,
        }
        try {
            MySwal.fire({
                title: <strong>¿Seguro que deseas registrar?</strong>,
                html: <i>Se guardará a { user.userName }</i>,
                confirmButtonText: 'Guardar',
                showCancelButton: true,
                reverseButtons: true,
                cancelButtonText: 'Regresar',
                icon: 'info'
            }).then(async response => {
                if(response.isConfirmed) { 
                    handleAddNewUser( userData );

                    return MySwal.fire({
                        title: <p>Registrado con éxito</p>,
                        html: <i>Actualiza tus usuarios para consultarlo</i>,
                        icon: 'success',
                    }).then(() => {
                        setSavedData(true);
                        getUsuarios()
                        formik.resetForm();
                    })
                }                                              
            })
        } catch (error) {
            console.log(error)
        }
    } 

    
    const removeUser = ( id ) => {
        MySwal.fire({
            title: <strong>¿Seguro que deseas eliminar?</strong>,
            html: <i>No podrás recuperar a este usuario.</i>,
            confirmButtonText: 'Eliminar',
            showCancelButton: true,
            reverseButtons: true,
            cancelButtonText: 'Regresar',
            icon: 'info'
        }).then(response => {
            if(response.isConfirmed) {
                handleDeleteUser( id );
                return MySwal.fire({
                    title: <p>Eliminado con éxito</p>,
                    html: <i>Has eliminado un usuario de tu lista.</i>,
                    icon: 'success',
                }).then(() => {
                    setEditable(false);
                    getUsuarios()
                })
            }
        });
    }

    const stateUpdaters = {
        getUsuarios,
        handleChangeSelect,
        handleUserSearch,
        handleSearchFilterChange,
        removeUser,
        setEditable,
        setUploadImage,
        setUsers,
        setUsuarios,
        setUsuarioSelected,
        setSavedData,
    }

    const state = {
        editable,
        formik,
        formikEdit,
        loading,
        savedData,
        shownUsers,
        storageRef,
        uploadImage,
        userTypes,
        usuarioSelected,
    }
    
    return {
        state,
        stateUpdaters,
    }

}
