import React, { useEffect, useState } from 'react'

export const SupplierDirectoryEdit = ({ user, editSupplier, removeSupplier, uploadImage }) => {

    const [editableUser, setEditableUser] = useState({
        supplierName: user.name,
        supplierAddress: user.address,
        supplierContactName: user.contactName,
        supplierEmail: user.email,
        supplierPhoneNumber: user.phoneNumber,
        supplierUtility:   user.utility,
    })

    const { supplierName, supplierAddress, supplierContactName, supplierEmail, supplierPhoneNumber, supplierUtility }  = editableUser; 

    useEffect(() => {
        setDefaultFormikValues();
    }, []);

    const setDefaultFormikValues = () => {
        editSupplier.setFieldValue(`supplierName`, supplierName);
        editSupplier.setFieldValue(`supplierAddress`, supplierAddress);
        editSupplier.setFieldValue(`supplierContactName`, supplierContactName);
        editSupplier.setFieldValue(`supplierEmail`, supplierEmail);
        editSupplier.setFieldValue(`supplierPhoneNumber`, supplierPhoneNumber);
        editSupplier.setFieldValue(`supplierUtility`, supplierUtility);
        editSupplier.setFieldValue(`docID`, user.id);
        editSupplier.setFieldValue(`profileImage`, uploadImage )

    }

    const handleInputChange = ( e, state ) => {
        setEditableUser({
            ...editableUser,
            [state]: e.target.value,
        })
        editSupplier.setFieldValue(`${state}`, e.target.value);
    }

    return (
       <>
            <form
                className = ' mb-3 '
                onSubmit = { editSupplier.handleSubmit }
            >

                <div className = ' input_input-container '>
                    <p>Nombre del proveedor</p>
                    <input 
                        onChange = { (e) => handleInputChange(e, 'supplierName') }
                        placeholder = 'Nombre del Proveedor'
                        value = { supplierName }
                    />
                </div>
                
                <div className = ' input_input-container '>
                    <p>Dirección</p>
                    <input 
                        onChange = { (e) => handleInputChange(e, 'supplierAddress') }
                        placeholder = 'Dirección'
                        value = { supplierAddress }
                    />
                </div>

                <div className = ' input_input-container '>
                    <p>Encargado</p>
                    <input 
                        onChange = { (e) => handleInputChange(e, 'supplierContactName') }
                        placeholder = 'Encargado'
                        value = { supplierContactName }
                    />
                </div>
                
                <div className = ' input_input-container '>
                    <p>Correo</p>
                    <input 
                        onChange = { (e) => handleInputChange(e, 'supplierEmail') }
                        placeholder = 'Correo'
                        value = { supplierEmail }
                    />
                </div>

                <div className = ' input_input-container '>
                    <p>Teléfono</p>
                    <input 
                        onChange = { (e) => handleInputChange(e, 'supplierPhoneNumber') }
                        placeholder = 'Teléfono'
                        value = { supplierPhoneNumber }
                    />
                </div>

                <div className = 'd-flex mt-4'>
                    <p 
                        className = 'button cancel-button'
                        onClick = { () => removeSupplier(user.id) }
                    >
                        Eliminar    
                    </p> 

                    <button 
                        className = 'button purple-button ms-3'
                        type = 'submit'
                    >
                        Guardar
                    </button>
                </div>
            </form>
        </>
    )

}
