import React from 'react'

export const CalculateDiscount = ({ handleDiscountChange }) => {
 
    return (
        <div className = ' mt-3 '>
            <p className='h6 mb-0'>Descuento.</p>
            <p className = ' ChargeModal-discount-description mb-2 '>
                Si existe un descuento adicional en la compra, indícalo aquí en porcentaje.
            </p>
            <input 
                className = ' ChargeModal-change-input ' 
                onChange = { handleDiscountChange }
                type = 'number' 
            />
        </div>
    )

}
