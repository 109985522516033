import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import { formatMoney } from './../../../helpers/formatMoney';
import { formatDate } from './../../../helpers/formatDate';
import avluet from './../../../assets/avluetsoftware.png';

import './TicketPrint.css';

export const TicketPrint = React.forwardRef(( props, ref ) => {

    const {
        change,
        date = '',
        discount = 0,
        folio,
        paymentType = '',
        products = [],
        purchaseType,
        time = '',
        total = 0,
        totalLetter,
        user = '',
        ticketType,
        pagareName,
    } = props;
    
    return (
        <div ref={ ref } className = 'TicketPrint-ticket-content'>
            <div className = "TicketPrint-container">
        
                <div id = "TicketPrint-ticket">
                    <div className = ' row mb-2 mx-auto '>
                        <div className = ' col-8 offset-2 '>
                            <img className = ' TicketPrint-logo ' src = { avluet } />
                        </div>
                    </div>
            
                    <div className = 'TicketPrint-datos-fiscales'>
                        <p>AVLUET SOFTWARE</p>
                        <p>REGIMEN DE INCORPORACIÓN FISCAL</p>
                        <p>DURANGO, DGO. MÉXICO</p>
                        <p>Tel: 618 116 9096</p>
                        <p>ALEJANDRO VAZQUEZ</p>
                        <p>R.F.C. XXXX000000XXX</p>
                        <p>DURANGO, DGO A { date }</p>
                    </div>
            
                    <div className = 'TicketPrint-nota-venta-container'>
                        <p className = "TicketPrint-nota-venta-titulo">NOTA DE VENTA</p>
                        <p className = 'TicketPrint-nota-resume-data'><br />Folio: { folio }</p>
                        <p className = 'TicketPrint-nota-resume-data'>Atendió: { user.toUpperCase() }</p>
                        <p className = 'TicketPrint-nota-resume-data'>Hora: { time }</p>
                        <p className = 'TicketPrint-nota-resume-data'>
                            Método de Pago: { paymentType === 'cash' ? 'EFECTIVO' : 'TARJETA BANCARIA' }
                        </p>

                        <div>
                            { purchaseType === 'credit' && ( 
                                <>
                                    <p className = ' text-center small pt-2 pb-1 '>COMPRA A CRÉDITO</p> 
                                    <p className = ' text-center small text-uppercase pb-1  '>{ pagareName }</p>
                                </>
                            )}
                        </div>
            
                        <div>
                            <table className = 'TicketPrint-nota-venta-table'>
                                <thead>
                                    <tr>
                                        <th>Cant.</th>
                                        <th>Producto</th>
                                        <th>Precio Unit.</th>
                                        <th>Importe</th>
                                    </tr>
                                </thead>
                            </table>  
                        </div>
                        <div>
                            { products.map((product, i) => {
                                const { productCode, productDiscount, productName, productPrice, productTotalPrice, productQuantity } = product;
                                return (
                                    <div key = { i }>
                                        { productQuantity > 0 && (
                                            <>
                                                <table className = 'TicketPrint-nota-venta-table text-center'>
                                                    <tbody>
                                                        <tr>
                                                            <td>{ productQuantity }</td>
                                                            <td>{ productCode }</td>
                                                            <td>{ formatMoney( productPrice * ( 1 - ( productDiscount / 100 )) ) }</td>
                                                            <td>{ formatMoney( productTotalPrice * ( 1 - ( productDiscount / 100 )) ) }</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <p className = ' TicketPrint-product-name '>{ productName }</p>   
                                                { productDiscount > 0 && (
                                                    <p className = ' TicketPrint-product-name '>
                                                        DESCUENTO: { productDiscount }% PRECIO NORMAL { formatMoney( productPrice ) }
                                                    </p>
                                                )} 
                                            </>
                                        ) }
                                    </div>
                                )
                            })}
                        </div>
                        <div>
                        </div>
                        
            
            
                        <hr className = 'TicketPrint-separator' />

                        {(discount > 0) && (
                            <div className = 'TicketPrint-discount'>
                                <p>MONTO PREVIO AL DESCUENTO: { formatMoney( total + discount ) }</p>
                                <p>DESCUENTO APLICADO: { formatMoney( discount ) }</p>
                            </div>
                        )}
            
                        <div className = ' TicketPrint-nota-iva-container mt-2 '>
                            <p>IVA  </p>
                            <p className = ' ms-1 '> { formatMoney( total - (total / 1.16) ) }</p>
                        </div>
                        <div className = ' TicketPrint-nota-iva-container '>
                            <p>SUBTOTAL  </p>
                            <p className = ' ms-1 '> { formatMoney( total / 1.16 ) }</p>
                        </div>
                        <div className = 'TicketPrint-nota-total-container'>
                            <p className = ' TicketPrint-nota-cantidad-articulos '>{ products.length } { products.length === 1 ? 'Artículo' : 'Artículos'}</p>
                            <div className = ' TicketPrint-nota-total-precio '>
                                <p>TOTAL  </p>
                                <p> { formatMoney( total ) }</p>
                            </div>
                        </div>
                        <p className = "TicketPrint-total-letter" >{ totalLetter } 00/100 M.N.</p>

                        { purchaseType === 'credit' && (
                            <div className = ' TicketPrint-credit-message text-center '>
                                <p>
                                    Por este pagaré debo y pagaré incondicionalmente a la orden de ___________________________ 
                                    en DURANGO, DGO. El día ________________ la cantidad de { formatMoney( total ) }.
                                </p>
                                <p>
                                    Valor recibido a mi entera satisfacción, si no fuera cubierta a su vencimiento la suma que este 
                                    pagaré expresa, cubriré además de ella el _____ de intereses mensuales de la fecha de su vencimiento 
                                    hasta ser totalmente cubierto y me someto a los tribunales que el tenedor señale y renuncio al efecto al 
                                    fuero de mi domicilio.
                                </p>
                                <br /><br /><br />
                                <p className = ' text-center '>___________________________</p>
                                <p className = ' text-center '>{ pagareName }</p>
                                <div className = ' my-4 '>
                                    <p>{ ticketType === 'original' ? 'ORIGINAL' : 'COPIA' }</p>
                                </div>
                            </div>
                        ) }
                        
                        <div className = 'TicketPrint-footer'>
                            {/* <p className = 'TicketPrint-italic-bold'>EN PARTES ELÉCTRICAS NO HAY GARANTÍA.</p>
                            <p className = 'TicketPrint-italic-bold'>
                                AL CANCELAR PEDIDOS ESPECIALES SE COBRARÁ UN 20% SOBRE EL TOTAL DE LA NOTA.
                            </p> */}
                            <p className = 'TicketPrint-invoice-disclaimer'> <br />
                                ¡AGRADECEMOS TU COMPRA!
                            </p>
                        </div>
            
                    </div>
                </div>
            </div>
        </div>
    )
    
})
