import React, { useEffect, useState } from 'react';
import { NewClientModal } from '../../Components/ClientComponents/NewClientModal';
import { useClients } from '../../hooks/useClients';
import { Modal } from '../../Components/Modal/Modal';
import { ClientDirectoryList } from '../../Components/ClientComponents/ClientDirectoryList';
import { DirectoryHeader } from '../../Components/DirectoryComponents/DirectoryHeader';
import { ClientDirectoryDetail } from '../../Components/ClientComponents/ClientDirectoryDetail';

export const ClientsScreen = () => {

	const [ openModal, setOpenModal ] = useState(false)

    const { 
        editable, loading, clientSelected, showClients, uploadImage, formik, formikEdit, savedData,
        getClients, handleClientSearch, removeClient, setClientSelected, setEditable, setUploadImage,
    } = useClients();

	useEffect(() => {
        getClients();
    }, [])
	

	return (
		<div>

            <div className = {` row mx-0 px-0 `}>
                <div className = {` col-lg-4 pt-4 white-background `}>
                    <div className = ' px-3 '>
                        <DirectoryHeader 
                            content = {{ title: 'Clientes', subtitle: 'Busca a tus clientes en el directorio' }}
                            actions = {[
                                { action: () => getClients(), icon: 'refresh-outline' },
                                { action: () => setOpenModal(true), icon: 'add-outline' },
                            ]}
                            input = {{ 
                                show: true, 
                                placeholder: 'Ingresa el usuario que quieres buscar',
                                action: (e) => handleClientSearch(e),
                            }}
                        />
                    </div>
                    <div>
                        { !loading && (
                            <ClientDirectoryList
                                setSelectedClient = { setClientSelected }
                                clients = { showClients }
                            />
                        )}
                    </div>
                </div>
                <div className = {` col-lg-8 `}>
                    <ClientDirectoryDetail 
                        user = { clientSelected }
                        editForm = { formikEdit }
                        removeUser = { removeClient }
                        editable = { editable }
                        setEditable = { setEditable }
                        uploadImage = { uploadImage }
                        setUploadImage = { setUploadImage }
                    />
                </div>
            </div>


            { !!openModal && (
                <Modal>
                    <NewClientModal 
                        closeModal = {() => setOpenModal(false)}
                        formik = { formik }
                        savedData = { savedData }
                        uploadImage = { uploadImage }
                        setUploadImage = { setUploadImage }
                    />
                </Modal>
            )}
        </div>
	)
}
