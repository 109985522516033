// import firebase from 'firebase/compat/app';

/* 
 *      Get products from an specific model. 
 *      @params { object } filter. Object containing brand, model and year.
 *      @params { string } group. Group the user wants to search.
 */
export const getProductsByModel = ( filter, group, products, searchTerm ) => {
    return new Promise( async (resolve, reject) => {
        const productsResolve = [];
        const search = searchTerm.toUpperCase();
        console.log( search );
        const { brand, model, fromYear } = filter;

        products.forEach((product, index, array) => {
            if( product.aplicaciones.length > 0 ) {
                if( product.family === group ) {
                    product.aplicaciones.forEach( aplication => {
                        console.log( aplication.fromYear, fromYear, aplication.toYear );
                        if( aplication.brand === brand && aplication.model === model ) {
                            if( aplication.fromYear <= fromYear && aplication.toYear >= fromYear ) {
                                console.log( product );
                                productsResolve.push( product );
                            }
                        }
                    })
                }
            }
            if( index === array.length - 1 ) {
                resolve( productsResolve );
            }
        });

        // await firebase.firestore().collectionGroup('aplicaciones')
        //     .where('family', '==', group)
        //     .where('brand', '==', filter.brand)
        //     .where('model', '==', filter.model)
        //     .where('fromYear', '<=', Number(filter.fromYear))
        //     .get()
        //     .then(res => {
        //         res.docs.forEach(async (doc, index, array) => {
        //             if (doc.data().toYear >= filter.fromYear) {
        //                 await doc.ref.parent.parent.get().then(res => {
        //                     console.log( res.data() );
        //                     products.push( res );
        //                     if( index === array.length - 1 ) {
        //                         resolve( products )
        //                     }
        //                 }).catch( reject );
        //             }
        //         });
        //     })
        //     .catch( reject );
    })
}