import { useEffect, useState } from 'react'
// import firebase from 'firebase/compat/app';
import { getInvoicesByOneDate } from '../helpers/db/getInvoicesByOneDate';
import { getMoreInvoicesByOneDate } from '../helpers/db/getMoreInvoicesByOneDate';
import { getInvoicesByPeriod } from '../helpers/db/getInvoicesByPeriod';
import { getMoreInvoicesByPeriod } from '../helpers/db/getMoreInvoicesByPeriod';
import { useInvoicesSlice } from './redux/useInvoicesSlice';

const today = new Date();
today.setHours( 0, 0, 0, 0 );

const filterOptions = [
    { label: 'Fechas Personalizdas', value: 'custom' },
    { label: 'Hoy', value: 'today' },
    { label: 'Última Semana', value: 'week' },
    { label: 'Última Quincena', value: 'two-weeks' },
    { label: 'Último Mes', value: 'month' },
    { label: 'Último Bimestre', value: 'two-months' },
    { label: 'Últimos 6 Meses', value: 'six-months' },
    { label: 'Último Año', value: 'year' },
    { label: 'Todas las ventas', value: 'year' },
]

const filterOptionsStatus = [
    { label: 'Todas', value: 'all' },
    { label: 'Pendientes', value: 'pending' },
    { label: 'Completadas', value: 'completed' },
];

const firestoreLimit = 6;

export const useAccounting = () => {

    const { invoices } = useInvoicesSlice();

    const [filter, setFilter] = useState('today');
    const [statusFilter, setStatusFilter] = useState('all');
    const [invoicesState, setInvoices] = useState([]);
    const [shownInvoices, setShownInvoices] = useState([]);
    const [lastVisible, setlastVisible] = useState('');
    const [disableMoreInvoices, setDisableMoreInvoices] = useState(false);
    const [ fromDate, setFromDate ] = useState( today );
    const [ toDate, setToDate ] = useState( today );

    useEffect(() => {
        loadInvoices();
    }, [])

    useEffect(() => {
        if (invoices.length > 0) {
            let last = invoices.length - 1;
            setlastVisible(invoices[last]);
        }
    }, [invoices])

    useEffect(() => {
        if( filter !== 'custom' ) {
            loadInvoices();
        }
    }, [ filter ]);

    useEffect(() => {
        filterInvoices();
    }, [ statusFilter ]);

    useEffect(() => {
        setDisableMoreInvoices(false)
    }, [ filter ]);

    /*  
     *  If fromDate changes, and is greater than toDate, update toDate.  
     */
    useEffect(() => {
        if( fromDate <= toDate ) return;
        setToDate( fromDate );
    }, [ fromDate ]);

    /*
     *     Filter the saved invoices depending on invoice status. 
     */
    const filterInvoices = () => {
        let statusFiltered = [];
        [...invoices].forEach(sale => {
            if (statusFilter === 'all') {
                statusFiltered.push(sale);
            } else if (statusFilter === 'pending' && sale.data().invoiceFulfilled === false) {
                statusFiltered.push(sale);
            } else if (statusFilter === 'completed' && sale.data().invoiceFulfilled === true) {
                statusFiltered.push(sale);
            }
        })
        setShownInvoices([...statusFiltered]);
    }

    /*
     *     Get the filter date depending on what user selected as a filter.
     */
    const getDateWhere = () => {
        if( filter !== 'custom' ) {
            var date = new Date();
            switch (filter) {
                case 'today':
                    date.setHours(0, 0, 0, 0);
                    break;
                case 'week':
                    date.setDate(date.getDate() - 7);
                    break;
                case 'two-weeks':
                    date.setDate(date.getDate() - 14);
                    break;
                case 'month':
                    const month = date.getMonth();
                    const year = date.getFullYear();
                    const daysInMonth = new Date(year, month, 0).getDate();
                    date.setDate(date.getDate() - daysInMonth)
                    break;
                case 'two-months':
                    date.setMonth(date.getMonth() - 2);
                    break;
                case 'six-months':
                    date.setMonth(date.getMonth() - 6);
                    break;
                case 'year':
                    date.setYear(date.getFullYear() - 1);
                    break;
                default:
                    date.setDate(date.getDate() - 1);
                    break;
            }
            date.setHours( 0, 0, 0, 0 );
            return date;
        }
    }

    /*
     *  Load more invoices based on type of filter selected, and last visible item.
     */
    const getMoreInvoices = async () => {
        const dateWhereLocal = getDateWhere();
        if( filter !== 'custom' ) {
            const tempInvoices = await getMoreInvoicesByOneDate( dateWhereLocal, firestoreLimit, lastVisible );
            if (tempInvoices.length >= firestoreLimit - 1) {
                setDisableMoreInvoices(false)
            } else {
                setDisableMoreInvoices(true)
            }
            setShownInvoices(state => [...state, ...tempInvoices]);
            setInvoices(state => [...state, ...tempInvoices]);
        } else {
            const tempInvoices = await getMoreInvoicesByPeriod( fromDate, toDate, firestoreLimit, lastVisible );
            if (tempInvoices.length >= firestoreLimit - 1) {
                setDisableMoreInvoices(false)
            } else {
                setDisableMoreInvoices(true)
            }
            setShownInvoices(state => [...state, ...tempInvoices]);
            setInvoices(state => [...state, ...tempInvoices]);
        }
    }

    /*
     *    Handle when the date select is changed.
     */
    const handleFilterChange = (e) => {
        setFilter(e.value);
    }

    /*
     *    Handle when the status select is changed.
     */
    const handleStatusChange = (e) => {
        setStatusFilter(e.value)
    }

    /*
     *    Load the invoices in an starting state (from 0).
     */
    const loadInvoices = () => {
        setShownInvoices([...invoices]);
        setInvoices([...invoices]);
    }

    const state = {
        disableMoreInvoices,
        filter,
        filterOptions,
        filterOptionsStatus,
        fromDate, 
        shownInvoices,
        toDate,
    }

    const stateUpdaters = {
        handleFilterChange,
        handleStatusChange,
        getMoreInvoices,
        loadInvoices,
        setFromDate,
        setToDate,
        updateInvoices: loadInvoices,
    }

    return {
        state,
        stateUpdaters
    }

}
