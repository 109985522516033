import { useEffect, useState } from 'react'
import { NumberToText } from '../helpers/NumberToText';
import { getSalesByOneDate } from '../helpers/db/getSalesByOneDate';
import { getMoreSalesByOneDate } from '../helpers/db/getMoreSalesByOneDate';
import { getSalesByPeriod } from '../helpers/db/getSalesByPeriod';
import { getMoreSalesByPeriod } from '../helpers/db/getMoreSalesByPeriod';
import { getProductById } from '../helpers/db/ProductsDB/getProductById';
import { useSalesSlice } from './redux/useSalesSlice';

const today = new Date();
today.setHours( 0, 0, 0, 0 );

const firestoreLimit = 6;

export const useSalesHistory = () => {

    const { sales: salesSlice } = useSalesSlice();

    const [ clients, setClients ] = useState([]);
    const [ disableMoreSales, setDisableMoreSales ] = useState( false );
    const [ filter, setFilter ] = useState('today');
    const [ paymentFilter, setPaymentFilter ] = useState('all');
    const [ sales, setSales ] = useState([]);
    const [ shownSales, setShownSales ] = useState([]);
    const [ preSearchSales, setPreSearchSales ] = useState([]);
    const [ salesActive, setSalesActive ] = useState(true);
    const [ lastVisible, setLastVisible ] = useState('');
    const [ dateWhere, setDateWhere ] = useState(0);
    const [ fromDate, setFromDate ] = useState( today );
    const [ toDate, setToDate ] = useState( today );

    const filterOptions = [
        { label: 'Personalizada', value: 'custom' },
        { label: 'Hoy', value: 'today' },
        { label: 'Última Semana', value: 'week' },
        { label: 'Última Quincena', value: 'two-weeks' },
        { label: 'Último Mes', value: 'month' },
        { label: 'Último Bimestre', value: 'two-months' },
        { label: 'Últimos 6 Meses', value: 'six-months' },
        { label: 'Último Año', value: 'year' },
        { label: 'Todas las ventas', value: 'year' },
    ];

    const filterPaymentOptions = [
        { label: 'Todas las ventas', value: 'all' },
        { label: 'Efectivo', value: 'cash' },
        { label: 'Tarjeta', value: 'card' },
    ];

    useEffect(() => getSales(), []);

    useEffect(() => {
        if (preSearchSales.length > 0) {
            let last = preSearchSales.length - 1;
            setLastVisible(preSearchSales[last]);
        }
    }, [preSearchSales])

    useEffect(() => {
        filterSales();
    }, [sales, salesSlice, filter, paymentFilter, lastVisible]);

    useEffect(() => {
        setSales([]);
        setPreSearchSales([])
        setSalesActive(false);
    }, [ filter, fromDate, toDate ]);

    /*  If fromDate changes, and is greater than toDate, update toDate.  */
    useEffect(() => {
        if( fromDate <= toDate ) return;
        setToDate( fromDate );
    }, [ fromDate ]);

    /*
     *     Filter the sales provided by the database, based on the payment type selected.
     */
    const filterSales = () => {
        let paymentFilteredSales = [];
        [...sales].forEach(sale => {
            if (paymentFilter === 'all') paymentFilteredSales.push(sale);
            if (paymentFilter === 'cash' && sale.paymentType === 'cash') paymentFilteredSales.push(sale);
            if (paymentFilter === 'card' && sale.paymentType === 'card') paymentFilteredSales.push(sale)
        })
        setShownSales([...paymentFilteredSales]);
        setPreSearchSales([...paymentFilteredSales]);
    }

    /*
     *     Generate the minimum date base on their selection on date select.
     */
    const getDateWhere = () => { 
        var date = new Date();
        if( filter !== 'custom' ) {
            switch (filter) {
                case 'today':
                    date.setHours(0, 0, 0, 0);
                    break;
                case 'week':
                    date.setDate(date.getDate() - 7);
                    break;
                case 'two-weeks':
                    date.setDate(date.getDate() - 14);
                    break;
                case 'month':
                    const month = date.getMonth();
                    const year = date.getFullYear();
                    const daysInMonth = new Date(year, month, 0).getDate();
                    date.setDate(date.getDate() - daysInMonth)
                    break;
                case 'two-months':
                    date.setMonth(date.getMonth() - 2);
                    break;
                case 'six-months':
                    date.setMonth(date.getMonth() - 6);
                    break;
                case 'year':
                    date.setYear(date.getFullYear() - 1);
                    break;
                default:
                    date.setDate(date.getDate() - 1);
            }
            date.setHours(0, 0, 0, 0);
            setDateWhere(date)
            return date;
        } 
    }

    /*
     *      Get the sales registered, dependind if with minimun date o two period date. 
     */
    const getSales = async () => {
        if( filter !== 'custom' ) {
            try {
                const dateWhereLocal = getDateWhere();
                const salesArray = await getSalesByOneDate( dateWhereLocal, salesSlice );
                setSales([...salesArray]);
                setPreSearchSales([...salesArray])
                setSalesActive(true);
            } catch(e) {
                console.error(new Error(e));
            }
        } else if( filter === 'custom' ) {
            try {
                const salesArray = await getSalesByPeriod( fromDate, toDate, firestoreLimit, clients );
                setSales([...salesArray]);
                setPreSearchSales([...salesArray])
                setSalesActive(true);
            } catch(e) {
                console.error(new Error(e));
            }
        } 
    }

    /*
     *      Get the next sales registered, dependind if with minimun date o two period date, and with its last item. 
     */
    const getMoreSales = async () => {
        setDisableMoreSales( true );
    }

    /*
     *      Handle when the date filter select is changed.
     *      @params { event } e. React Select Event.
     */
    const handleFilterChange = (e) => {
        setFilter(e.value);
    }

    /*
     *      Handle when the date payment type select is changed.
     *      @params { event } e. React Select Event.
     */
    const handleFilterPaymentChange = (e) => {
        setPaymentFilter(e.value);
    }

    /* 
     *      Get ticket products when ticket created from history detail.
     *      @params { array } product.
     *      @params { string } purchaseType.
     *      @params { boolean } creditPayment.
     */
    const getTicketProduct = ( product, purchaseType, creditPayment ) => {
        return new Promise(async (resolve, reject) => {
            if( !product.product.includes('generic') ) {
                await getProductById( product.product )
                    .then(res => {
                        if( res.exists ) {
                            const { pieza, precio_contado, precio_credito, nombre } = res;
                            const productPrice = (purchaseType === 'credit' && !!creditPayment) ? precio_credito : precio_contado;
                            const productData = {
                                productQuantity: product.quantity,
                                productCode: pieza,
                                productPrice: productPrice, 
                                productTotalPrice: productPrice * product.quantity,
                                productName: nombre,
                            }
                            resolve(productData);
                        } else {
                            const productData = {
                                productQuantity: product.quantity,
                                productCode: 'No Encontrado',
                                productPrice: 'N/A',
                                productTotalPrice: 'N/A',
                                productName: 'Producto no encontrado.',
                            }
                            resolve(productData);
                        }
                    })
                    .catch( reject );
            } else if( product.product.includes('generic') ) {
                if( product.productData !== undefined ) {
                    const { code, price_credit, price, name } = product.productData.producto;
                    const productPrice = ( purchaseType === 'credit' && !!creditPayment ) ? price_credit : price;
                    const productData = {
                        productQuantity: product.quantity,
                        productCode: code,
                        productPrice: productPrice,
                        productTotalPrice: productPrice * product.quantity,
                        productName: name,
                    }
                    resolve(productData);
                } else {
                    const productData = {
                        productQuantity: product.quantity,
                        productCode: 'No Encontrado',
                        productPrice: 'N/A',
                        productTotalPrice: 'N/A',
                        productName: 'Producto no encontrado.',
                    }
                    resolve(productData);
                }
            }
        })
    }

    /* 
     *      Print the ticket, from sales history.
     *      @params { firestore objetct } sale .
     *      @params { string } clientName .
     *      @params { string } seller .
     */
    const printTicket = ( sale, clientName, seller ) => {
        return new Promise(async (resolve, reject) => {

            const { creditPayment, products, purchaseType } = sale;

            if( creditPayment !== undefined ) {
                let productsData = [];
                products.forEach(product => {
                    const { code, discount, name, price_cash, price_credit, } = product.productData;
                    const productPrice =  purchaseType === 'credit' ? price_credit : price_cash;
                    const productData = {
                        productQuantity: product.quantity,
                        productCode: code,
                        productPrice: productPrice,
                        productTotalPrice: productPrice * product.quantity,
                        productName: name,
                        productDiscount: discount,
                    }
                    productsData.push( productData );
                })

                // setTimeout(() => {
                    const date = new Date( sale.date ) ;
                    const baseHours = date.getHours();
                    const hours = ( baseHours < 10) ? `0${ baseHours }` : `${ baseHours }`;
                    const baseMinutes = date.getMinutes();
                    const minutes = ( baseMinutes < 10 ) ? `0${ baseMinutes }` : `${ baseMinutes }`;
                    const ticketData = {
                        client: clientName,
                        user: seller,
                        date: `${ date.getDate().toString().padStart(2, '0') }/${ (date.getMonth() + 1).toString().padStart(2, '0') }/${ date.getFullYear() }`,
                        discount: sale.discount,
                        paymentType: sale.paymentType,
                        products: [...productsData] || [],
                        total: sale.total,
                        totalLetter: NumberToText( sale.total ),
                        time: `${ hours }:${ minutes }`,
                        folio: sale.folio,
                        purchaseType: sale.purchaseType,
                    }
                    console.log(ticketData);
                    resolve(ticketData);
                // }, 1000);

            } else {
                alert('No puedes imprimir el ticket de esta venta.');
            }
        })
    }

    /*
     *      Handle the search with folio.
     *      @params { event } e. Event from input.
     */
    const searchSales = ({ target: { value } }) => {
        if (value !== '') {
            const foundSales = preSearchSales.filter(sale => String(sale.folio).includes(value));
            setShownSales([...foundSales]);
        } else {
            setShownSales([...preSearchSales]);
        }
    }

    const state = {
        disableMoreSales,
        filter, 
        filterOptions,
        filterPaymentOptions,
        fromDate,
        shownSales,
        salesActive,
        toDate,
    }

    const stateUpdaters = {
        getSales,
        handleFilterChange,
        handleFilterPaymentChange,
        searchSales,
        setFromDate,
        setToDate,
        getMoreSales,
        printTicket,
    }

    return {
        state,
        stateUpdaters
    }

}
