import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { generateSelectableMonths } from '../../helpers/generateSelectableMonths';

export const CancelPurchaseHeader = ({ availableDays, date, setDate, reloadSales }) => {
 
    let currentDates = generateSelectableMonths();
    const [ optionalDates, setOptionalDates ] = useState( currentDates );

    const [ selectedYear, setSelectedYear ] = useState( currentDates.years[0] | '' );
    const [ selectedMonth, setSelectedMonth ] = useState( '' );
    const [ selectedDay, setSelectedDay ] = useState( '' );
    
    useEffect(() => {
        if( selectedMonth === '' ) return;
        setDate({ ...date, month: selectedMonth, year: selectedYear })
    }, [ selectedMonth ]);

    useEffect(() => {
        if( selectedDay === '') return;

        setDate({ ...date, day: selectedDay });
    }, [ selectedDay ]);

    const handleYearChange = ( e ) => {
        setOptionalDates( generateSelectableMonths(e.value) );
        setSelectedYear( e.value );
        setSelectedMonth( '' );
    }

    const handleMonthChange = ( e ) => setSelectedMonth( e.value );
    const handleDayChange = ( e ) => setSelectedDay( e.value );

    return (
        <div className = ' IncomeStatiticsHeader-container d-flex flex-column px-3 py-1'>
            <div className = ' d-flex align-items-center w-100 pb-2 '>
                <div className = ' flex-grow-1 '>
                    <div className = 'DirectorioHeader-title '>
                        <h2 className = ' section-title mb-0 '>Prueba de Cotizaciones</h2>
                        <p className = ' text_instructions '>Página de prueba para control de cotizaciones.</p>
                    </div>
                </div>
                <div>
                    <div className = ' header-button ' onClick = { reloadSales }>
                        <ion-icon name = 'refresh-outline'></ion-icon>
                    </div>
                </div>
            </div>
            <div className = ' mt-3 w-100 row mx-0 '>
                <div className = ' col-4 pe-1 '>
                    <Select 
                        defaultValue={{ value: optionalDates.years[0], label: optionalDates.years[0] }}
                        onChange = { handleYearChange }
                        options = { optionalDates.years.map(y => ({ value: y, label: y })) }
                    />
                </div>
                <div className = ' col-4 ps-1 pe-1 '>
                    <Select 
                        defaultValue = {{ label: optionalDates.months.slice(-1), value: optionalDates.months.slice(-1) }}
                        onChange = { handleMonthChange }
                        options = { optionalDates.months.map((m, index) => ({ value: index + 1, label: m }))}
                    />
                </div>
                <div className = ' col-4 ps-1 '>
                    <Select 
                        defaultValue = {{ label: availableDays.slice(-1), value: availableDays.slice(-1) }}
                        onChange = { handleDayChange }
                        options = { availableDays.map(day => ({ label: day, value: day }))}
                    />
                </div>
            </div>
        </div>
    )
}
