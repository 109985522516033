import firebase from 'firebase/compat/app';

/* 
 *    Function that registers on the data base the sale.
 *    @param { object } invoiceData - previously prepared invoice data. 
 *    @param { string } purchaseID - purchaseID generated with date timestamp.
 */

export const registerInvoice = ( invoiceData, purchaseID ) => {
    return new Promise(async (resolve, reject) => {
        await firebase.firestore().collection('invoices')
            .doc(purchaseID)
            .set({...invoiceData})
            .then(resolve)
            .catch(error => { 
                console.error(new Error(error))
                reject( error );
            })
    })
}