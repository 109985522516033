import { useDispatch, useSelector } from "react-redux";
import { onInvoiceStatusChange } from './../../store/index';

export const useInvoicesSlice = () => {

    const dispatch = useDispatch();
    const { invoices } = useSelector( state => state.invoices);

    const handleUpdate = ( id, state ) => {
        console.log('Ejecutandoooo ', !state);
        const temp = [...invoices];
        const index = temp.findIndex(invoice => invoice.id === id);
        temp[index] = {
            ...temp[index],
            invoiceFulfilled: !state,
        }
        dispatch(onInvoiceStatusChange( temp ));
    }

    return {
        //* Properties.
        invoices,

        //* Methods.
        handleUpdate,
    }
}
