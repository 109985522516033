import React, { useEffect, useState } from 'react'
// import firebase from 'firebase/compat/app';
import { HistorySaleDetail } from '../../Components/HistoryScreenComponents/HistorySaleDetail';
import { HistorySalesList } from '../../Components/HistoryScreenComponents/HistorySalesList';
import { useClientsSlice } from '../../hooks/redux/useClientsSlice';
import { useUsersSlice } from '../../hooks/redux/useUsersSlice';

export const HistoryScreen = () => {

    const { clients } = useClientsSlice();
    const { users } = useUsersSlice();

    const [ usersState, setUsers ] = useState([]);
    const [ clientsState, setClients ] = useState([]);

    useEffect(() => {
        getClients();
        getSellers();
    }, []);

    const getClients = () => {
        setClients( clients )
    }
    
    const getSellers = () => {
       setUsers( users )
    }

    const [ selectedSale, setSelectedSale ] = useState('');  
    
    return (
        <div className = ' px-4 '>

            <div className = ' row '>
                <div className = 'col-5'>
                    <HistorySalesList setSelectedSale = { setSelectedSale } />
                </div>            
                <div className = 'col-7'>
                    <HistorySaleDetail selectedSale = { selectedSale } setSelectedSale = { setSelectedSale } clients = { clientsState } users = { usersState } />
                </div>            
            </div>
            
            
        </div>
    )
}
