import firebase from 'firebase/compat/app';

export const getClients = () => {

    return new Promise(async (resolve, reject) => {
        const clientes = await firebase.firestore().collection('clients')
            .get()
            .then(res => {
                if( res.docs.length > 0 ) {
                    let clients = [];
                    res.docs.forEach(doc => {
                        clients.push({
                            id: doc.id,
                            data: doc.data(),
                        })
                    })
                    resolve( clients );
                }
            }).catch(err => reject(new Error(err)))
    });

}