
const generateIncomeStats = () => {
    const stats = [];

    for( let i = 0; i < 30; i++ ) {
        const saleTotal = Math.floor(Math.random() * 9700) + 300;
        stats.push({
            day: 1,
            sales: 8,
            salesCash: saleTotal * 0.75, 
            salesCredit: saleTotal * 0.25, 
            salesInvoiceCard:  saleTotal * 0.15,
            salesInvoiceCash:  saleTotal * 0.5,
            salesNoInvoiceCard: saleTotal * 0.60,
            salesNoInvoiceCash:  saleTotal * 0.20,
            total: saleTotal,
            totalCash: saleTotal * 0.75,
            totalCredit: saleTotal * 0.25,
        })
    }


    return stats;
}

export const saleStatsDB = generateIncomeStats();