// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// TESTING 👇🏻
const firebaseConfig = {
    apiKey: "AIzaSyAgApxDbUPvH4A-jz1DNJAeNv9b95uttfU",
    authDomain: "testingrefaccionariaogando.firebaseapp.com",
    projectId: "testingrefaccionariaogando",
    storageBucket: "testingrefaccionariaogando.appspot.com",
    messagingSenderId: "228490523491",
    appId: "1:228490523491:web:5267858e4bbc1055d7d956",
    measurementId: "G-B4GYKZLFYG"
};
    

// PRODUCTION 👇🏻
// const firebaseConfig = {
//     apiKey: "AIzaSyATvtuQQ1GXEl8e4DgXNpZT5177dZYKaRU",
//     authDomain: "pos-web-a98f9.firebaseapp.com",
//     projectId: "pos-web-a98f9",
//     storageBucket: "pos-web-a98f9.appspot.com",
//     messagingSenderId: "549354818381",
//     appId: "1:549354818381:web:40f9802c32bbffd2198504",
//     measurementId: "G-NEW4GHQFF4"
// };

export default firebaseConfig;