
const image = 'https://firebasestorage.googleapis.com/v0/b/testingrefaccionariaogando.appspot.com/o/placeholders%2Fa.png?alt=media&token=380df832-e633-42a4-8e45-34627c98664b';

export const clientsDB = [
    {
        id: 1,
        address: 'Durango, Dgo. México',
        contactName: 'Alejandro Vázquez',
        cp: 34000,
        creditAuthorized: true,
        discount: '10',
        email: 'avluetsoftware@gmail.com',
        image: image,
        name: 'Avluet Software',
        phoneNumber: '6181169096',
        rfc: 'ABCD980926FG7',
    },
    {
        id: 2,
        address: 'Durango, Dgo. México',
        contactName: 'Sofía Verónica Ro',
        cp: 34000,
        creditAuthorized: false,
        discount: '10',
        email: 'avluetsoftware@gmail.com',
        image: image,
        name: 'Piedra Pura',
        phoneNumber: '6181169096',
        rfc: 'ABCD980926FG7',
    },
];