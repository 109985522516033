import React, { useContext, useEffect, useState } from 'react'
import Select from 'react-select'
import { ProductContext } from '../../../Context/ProductContext';
import { cars } from '../../../database/carsDB';

import './SelectAplicacionFilter.css';

export const SelectAplicacionFilter = React.memo(({ forSearch = false }) => {

	const { addNewAplication, cars, filter, setFilter} = useContext(ProductContext)

	const [selectedBrand, setSelectedBrand] = useState('');
	const [selectedModel, setSelectedModel] = useState('')
	const [selectedFromYear, setSelectedFromYear] = useState('')
	const [selectedToYear, setSelectedToYear] = useState('')
	const [selectedEngine, setSelectedEngine] = useState('')

	const [brands, setBrands] = useState([]);
	const [models, setModels] = useState([]);
	const [fromYears, setFromYears] = useState([]);
	const [toYears, setToYears] = useState([]);
	const [engines, setEngines] = useState(['Todos los motores']);

	useEffect(() => {
		generateBrands();
	}, []);

	useEffect(() => {
		if(selectedBrand === '') return;

		generateModels();
	}, [selectedBrand]);

	useEffect(() => {
		if(selectedModel === '') return;
		
		generateFromYears();
	}, [selectedModel]);

	useEffect(() => {
		if(selectedFromYear === '') return;
		
		generateToYears();
		generateEngines();
	}, [selectedFromYear])

	useEffect(() => {
		if( selectedToYear === '') return;

		generateEngines();
	}, [ selectedToYear ])

	const handleBrandChange = (e) => {
		if(selectedBrand !== '') {
			setModels([]);
			setFromYears([]);
			setToYears([]);
			setEngines([]);
		}
		setSelectedBrand(e.value);

		if(forSearch) {
			setFilter({
				...filter,
				brand: e.value,
			})
		}
	}	

	const handleModelChange = (e) => {
		if(selectedModel !== '') {
			setFromYears([]);
			setToYears([]);
			setEngines([]);
			setSelectedEngine('');
		}
		setSelectedModel(e.value);

		if(forSearch) {
			setFilter({
				...filter,
				model: e.value,
			})
		}
	} 

	const handleFromYearChange = (e) => {
		if(fromYears !== '') setToYears([]);
		setSelectedFromYear(e.value);
		setSelectedToYear('');
		setSelectedEngine('');
		if(forSearch) {
			setFilter({
				...filter,
				fromYear: e.value,
				engine: '',
			})
		}
	}

	const handleToYearChange = (e) => {
		setSelectedToYear(e.value);
		setEngines([]);

		if(forSearch) {
			setFilter({
				...filter,
				toYear: e.value,
			})
		}
	}

	const handleEngineChange = ( e ) => {
		setSelectedEngine( e.value );
		if(forSearch) {
			setFilter({
				...filter, 
				engine: e.value,
			})
		}
	}

	const generateBrands = () => {
		const brands = cars.map(car => ({ value: car.brand, label: car.brand}))
		setBrands([{ value: 'Todas las marcas', label: 'Todas las marcas' }, ...brands]);
	}

	const generateEngines = () => {
		const foundEngines = [{ label: 'Todos los motores', value: 'Todos los motores' }];
		models.filter(model => { 
			if( model.model === selectedModel ) {
				model.engines.forEach(engine => {
					if( selectedToYear === '' ) {
						if( selectedFromYear <= engine.fromYear || 
							( selectedFromYear >= engine.fromYear && selectedFromYear <= engine.toYear )) {
							foundEngines.push({ value: engine.engine, label: engine.engine });
						}
					} else {
						if( selectedFromYear >= engine.fromYear && selectedFromYear <= engine.toYear ) {
							if( selectedToYear <= engine.toYear ) {
								foundEngines.push({ value: engine.engine, label: engine.engine });
							}
						}
					}
				})
			}
		})
		setEngines(foundEngines);
	}

	const generateModels = () => {
		const brandCars = 
			cars.filter(
				car => car.brand.toLocaleLowerCase() === selectedBrand.toLocaleLowerCase()
			)
		const models = brandCars[0]?.models || []
		setModels(models) // .map(model => ({ value: model.model, label: model.model })));
	}

	const generateFromYears = () => {
		const model = models.filter(
			model => model.model === selectedModel
		)
		let years = [];
		for(let i = model[0].fromYear; i <= model[0].toYear; i++) {
			years.push(i);
		}
		setFromYears([...years]);
	}

	const generateToYears = () => {
		const model = models.filter(
			model => model.model === selectedModel
		)
		let years = [];
		for(let i = model[0].fromYear; i <= model[0].toYear; i++) {
			years.push(i);
		}
		setToYears(years.filter(year => year >= selectedFromYear));
	}


	const clearFields = () => {
		setModels([]);
		setFromYears([]);
		setToYears([]);
		setSelectedBrand('');
		setSelectedModel('');
		setSelectedFromYear('');
		setSelectedToYear('');
		setSelectedEngine('');
	}

	const guardarAplicacion = () => {
		addNewAplication(selectedBrand, selectedModel, selectedFromYear, selectedToYear);
		clearFields();
	}

	return (
		<div className = ' px-0 mx-0 mb-3 '>

			{ (!forSearch) && (
				<p className = 'title font-weight-bold '>
					Agregar Aplicación
				</p>
			) }

			<div className = 'row px-0 mx-0 mt-2'>

				<div className={` col-lg-6 pe-2 `}>
					<Select 
						onChange = { handleBrandChange }
						options = { brands }
					/>
				</div>

				{
					(models.length > 0) && (
						<div className={` col-lg-6 ps-1 `}>
							<Select 
								className = ''
								onChange = { handleModelChange }
								options = { models.map(model => ({ value: model.model, label: model.model })) }
							/>
						</div>
					)
				}

			</div>
			<div className = 'row px-0 mx-0 mt-3'>

				{
					(fromYears.length > 0) && (
						<div className={` col-lg-6 pe-2 `}>
							<Select 
								onChange = { handleFromYearChange }
								options = { fromYears.map(year => ({ value: year, label: year }))  }
							/>
						</div>
					)
				}

				{/* {
					(toYears.length > 0) && (
						<div className={` col-lg-4 pe-3 `}>
							<Select 
								className = ''
								onChange = { handleToYearChange }
								options = { toYears.map(year => ({ value: year, label: year }))  }
							/>
						</div>
					)
				} */}
				
				{/* {
					(fromYears.length > 0) && (
						<div className={` col-lg-6 ps-1 `}>
							<Select
								defaultValue = {{ label: 'Todos los motores', value: 'all' }}
								onChange = { handleEngineChange }
								options = { engines }
								value = {{ label: selectedEngine, value: selectedEngine }}
							/>
						</div>
					)
				} */}

				{

					(selectedBrand !== '' && selectedModel !== '' 
						&& selectedFromYear !== '' && selectedToYear !== '' && !forSearch) && (
							<p
								className = 'btn btn-outline-success mt-3 '
								onClick = { guardarAplicacion }
							>
								Agregar Aplicación
							</p>
					)

				}
			</div>

		</div>
	)
})