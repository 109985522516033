import { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';

export const useUserSalesHistory = ( userID ) => {

    const filterOptions = [
        { label: 'Hoy', value: 'today' },
        { label: 'Mes Actual', value: 'month' },
        { label: 'Mes Pasado', value: 'two-month' },
        { label: 'Mes Antepasado', value: 'three-month' },
        { label: 'Todas del Año', value: 'year' },
    ];

    const [ filter, setFilter ] = useState('today')
    const [ sales, setSales ] = useState([]);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ lastDocument, setLastDocument ] = useState([]);
    const [ seeMore, setSeeMore ] = useState(true);
    const [ seeTotal, setSeeTotal ] = useState(true);
    const [ salesTotal, setSalesTotal ] = useState(0);
    
    useEffect(() => {

        let unsubscribe = true

        filterSelection().then(res => {
            getSalesTotal(userID, res.date, res.lastDate)
            getUserSales(userID, res.date, res.lastDate).then(response => {
                if(unsubscribe) {
                    setUserSales(response)
                }
            })
        })

        return () => unsubscribe = false

    }, [filter, userID]);

    const handleFilterChange = (e) => {
        setFilter(e.value);
    }

    const getUserSales = ( userID, date, lastDate ) => {
        setSales([]);
        setIsLoading( true );
        setSeeMore(true)
        return firebase.firestore()
            .collection('sales')
            .orderBy('date', 'desc')
            .where('user', '==', userID)
            .where('creditPayed', '==', true)
            .where('date', '<=', date)
            .where('date', '>=', lastDate)
            .limit(10)
            .get()
    }

    const setUserSales = ( sales ) => {
        sales.docs.forEach( doc => {
            const sale = {
                creditPayment: doc.data().creditPayment,
                date: doc.data().date,
                discount: doc.data().discount,
                folio: doc.data().folio,
                id: doc.id,
                invoice: doc.data().invoiceRequired,
                products: doc.data().products.length,
                purchaseType: doc.data().purchaseType,
                paymentType: doc.data().paymentType,
                total: doc.data().total,
            }
            setSales(state => [...state, sale]);
        })
        setLastDocument(sales.docs[sales.docs.length - 1]);
        setIsLoading(false)
    }

    const getSeeMoreSales = ( userID, date, lastDate ) => {

        firebase.firestore()
            .collection('sales')
            .orderBy('date', 'desc')
            .where('user', '==', userID)
            .where('date', '<=', date)
            .where('date', '>=', lastDate)
            .startAfter( lastDocument )
            .limit(10)
            .get().then(moreSales => {
                let tempSales = [];
                moreSales.docs.forEach(doc => {
                    const sale = {
                        id: doc.id,
                        date: doc.data().date,
                        total: doc.data().total,
                        discount: doc.data().discount,
                        creditPayment: doc.data().creditPayment,
                        products: doc.data().products.length,
                        invoice: doc.data().invoiceRequired,
                        purchaseType: doc.data().purchaseType,
                        paymentType: doc.data().paymentType,
                    }
                    tempSales.push(sale);
                })
                setSales(state => [ ...state, ...tempSales ]);
                setLastDocument(moreSales.docs[moreSales.docs.length - 1]);
                if(moreSales.docs.length < 2) {
                    setSeeMore(false);
                }
            }).catch(e => {
                console.log(e)
            })

    }

    const getSalesTotal = ( userID, date, lastDate ) => {

        setSeeTotal(true)
        setSalesTotal(0);
        const month = date.getMonth()+1;
        const year = date.getFullYear();
        const lastMonth = lastDate.getMonth()+1;
        let salesDocID = '';

        if( filter === 'today' || filter === 'month' ) {
            salesDocID = `${year}-${month}`
        } else if ( filter === 'two-month' ) {
            salesDocID = `${year}-${lastMonth}`
        } else if ( filter === 'three-month') {
            salesDocID = `${year}-${lastMonth}`
        } else if ( filter === 'year' ) {
            return setSeeTotal(false)
        }

        firebase.firestore().collection('usuarios').doc(userID).collection('sales').doc(salesDocID).get().then(res => {
            if(res.exists){
                let salesArray = []
                salesArray = res.data().data;
                if(res.data()) {
                    let total = 0;
                    if(filter === 'today') {
                        date = date.getDate();
                        let requiredSales = salesArray.filter(sale => sale.day === date);
                        //console.log(requiredSales)
                        if(requiredSales !== undefined) {
                            requiredSales.forEach(sale => {
                                total = total + sale.total;
                            })
                            setSalesTotal(total);
                        }
                    } else {
                        salesArray.forEach(sale => {
                            total = total + sale.total;
                        })
                        setSalesTotal(total);
                    }
                }

            } else {
                console.log('No se ha encontrado ningún dato.')
            }
        }).catch(e => console.log(e))
    }

    const filterSelection = () => {
        let date = new Date();
        const month = date.getMonth();
        const year = date.getFullYear();
        let lastDate;

        return new Promise((resolve, reject) => {
            if(filter === 'today') {
                lastDate = new Date();
                lastDate.setHours(0)
                lastDate.setMinutes(0)
                lastDate.setSeconds(0)
                lastDate.setMilliseconds(0)
                resolve({date, lastDate})
            } else if(filter === 'month') {
                date = new Date();
                lastDate = new Date(year, month, 1)
                resolve({date, lastDate})
            } else if (filter === 'two-month') {
                date = new Date(year, month, 0)
                lastDate = new Date(year, month-1, 1);
                resolve({date, lastDate})
            } else if (filter === 'three-month') {
                date = new Date(year, month-1, 0)
                lastDate = new Date(year, month-2, 1);
                resolve({date, lastDate})
            } else if (filter === 'year') {
                date = new Date();
                lastDate = new Date(year, 0, 1);
                resolve({date, lastDate})
            } else {
                reject('No existe al opción')
            }

        })
    }

    const states = {
        filterOptions,
        isLoading,
        sales,
        salesTotal,
        seeMore,
        seeTotal,
    }
    
    const stateUpdaters = {
        handleFilterChange,
        filterSelection,
        getSeeMoreSales,
    }

    return [ states, stateUpdaters ];

}
