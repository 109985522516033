import moment from 'moment';

/* 
 *  Convert Firebase Timestamp to formated string.
 *  @param { seconds timestamp } date Firestore Timestamp second based.
 * 
 */
export const formatDate = ( date ) => {
    let modifiedDate = new Date( date ); // new Date( date.seconds * 1000 );
    return `${ modifiedDate.getDate() }/${ modifiedDate.getMonth() + 1 }/${ modifiedDate.getFullYear() }`;
}