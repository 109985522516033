import React, { useContext, useState } from 'react';
import { AddProductOrderItem } from './AddProductOrderItem';
import { ProductContext } from './../../../Context/ProductContext';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { updateDocument } from '../../../helpers/db/ProductsDB/updateDocument';


export const AddProductOrder = ({ closeModal }) => {

    const { allProducts } = useContext(ProductContext);
    const MySwal = withReactContent(Swal)

    const [ addCounter, setAddCounter ] = useState(1);
    const [ productsToAdd, setProductsToAdd ] = useState([ { id: '', product: [], quantity: 0 } ]);

    const addNewProductToList = () => {
        setProductsToAdd(state => [...state, { id: '', product: [], quantity: 0 }]);
        setAddCounter(state => state + 1);
    }
    
    const updateProducts = () => {
        let error = false;
        productsToAdd.map( async product => {
            if((product.product.id !== '' || product.id !== undefined) && product.quantity !== 0) {
                const modifiedExistence =  Number(product.product.data().existencia) + Number(product.quantity);
                try {
                    await updateDocument('inventario', product.product.id, { existencia: modifiedExistence })
                } catch(e) {
                    console.error( e );
                }
            }
        });
        if( !error ) {
            MySwal.fire({
                title: <strong>Productos actualizados exitosamente.</strong>,
                html: <i>Actualiza tu inventario para ver los cambios reflejados.</i>,
                confirmButtonText: 'Aceptar',
                icon: 'success'
            }).then(() => { 
                closeModal();
            });
        } else {
            MySwal.fire({
                title: <strong>Ocurrió un error!</strong>,
                html: <i>Uno o más de los productos pueden no haber sido actualizados exitosamente.</i>,
                confirmButtonText: 'Aceptar',
                icon: 'error'
            }).then(() => { 
                closeModal();
            })
        }
        closeModal();
    }

    const removeProduct = ( index ) => {
        let tempProds = [...productsToAdd];
        tempProds.splice(index, 1);
        setAddCounter(state => state - 1);
        setProductsToAdd([...tempProds]);
    }

    const ProductSelection = () => {
       
        return (
            <div className = ' inventario_product-selection-container row p-1 p-lg-3 pb-2 pb-3 mb-3'>
                <p className='my-3'>Ingresa el número de pieza para buscar</p>
                { [...Array(addCounter)].map((e, i) => (
                    <AddProductOrderItem  
                        key = { i }
                        allProducts = { allProducts }
                        index = { i }
                        productsToAdd = { productsToAdd }
                        removeProduct = { removeProduct }
                        setProductsToAdd = { setProductsToAdd }
                    />
                ))}
                <div 
                    className = " inventario_add-product-order-one-more button purple-button mt-2"
                    onClick = { addNewProductToList }
                >
                    <ion-icon name="add-outline"></ion-icon>
                    Agregar pieza
                </div>
            </div>
        )
    }

    return (
		<div className={` modal NuevoProductoModal-container `}>
			<div className={` modal-content `}>
				<div className={` modal-header `}>
					<p className='section-title'>Registrar entrada de mercancía.</p>
				</div>

				<div className={` modal-body container `}>
					<ProductSelection />
				</div>

				<div>
					<div className={` modal-footer `}>
						<button
							className={` button cancel-button shadow px-5 `}
							onClick = { closeModal }
						>
							Cancelar
						</button>
						<button
							className={` button purple-button shadow px-5 ms-3 `}
                            onClick = { updateProducts }
						>
							REGISTRAR
						</button>
					</div>

				</div>
			</div>


		</div>
	)

}
