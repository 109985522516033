import React from 'react'
import { CarModelsList } from '../../Components/CarModelsComponents/CarModelsList';
import { CarBrandDetail } from '../../Components/CarModelsComponents/CarBrandDetail';
import { CarModelDetail } from '../../Components/CarModelsComponents/CarModelDetail';

import { useCarModelsScreen } from '../../hooks/CarComponentHooks/useCarModelsScreen';

export const CarModelsScreen = () => {

    const [ states, stateUpdaters ] = useCarModelsScreen();
    const { cars, selectedCar } = states;
    const { deleteBrand, deleteCar, handleBrandSearch, setSelectedCar } = stateUpdaters;

    return (
        <div className = ' px-4 '>
            <div className = ' row '>
                <div className = 'col-4'>
                    <CarModelsList 
                        cars = { cars } 
                        deleteBrand = { deleteBrand } 
                        handleBrandSearch = { handleBrandSearch }
                        selectedCar = { selectedCar } setSelectedCar = { setSelectedCar } 
                    />
                </div>            
                <div className = 'col-4'>
                    <CarBrandDetail 
                        cars = { cars }
                        deleteCar = { deleteCar }
                        selectedCar = { selectedCar } setSelectedCar = { setSelectedCar } />
                </div>            
                <div className = 'col-4'>
                    <CarModelDetail selectedCar = { selectedCar } setSelectedCar = { setSelectedCar } />
                </div>            
            </div>
        </div>
    )
}
