
export const adminLinks = [
    {
        icon: 'home-outline',
        text: 'Dashboard',
        link: '/',
    },
    // {
    //     icon: 'card-outline',
    //     text: 'Ventas',
    //     link: '/ventas',
    // },
    {
        icon: 'card-outline',
        text: 'Ventas',
        link: '/payment',
    },
    {
        icon: 'cube-outline',
        text: 'Inventario',
        link: '/inventario',
    },
    {
        icon: 'car-sport-outline',
        text: 'Carros',
        link: '/carros',
    },
    {
        icon: 'calculator-outline',
        text: 'Facturas',
        link: '/contabilidad',
    },
    {
        icon: 'calendar-outline',
        text: 'Historial Ventas',
        link: '/historial',
    },
    {
        icon: 'wallet-outline',
        text: 'Reporte Ingresos',
        link: '/ingresos',
    },
    {
        icon: 'people-outline',
        text: 'Usuarios',
        link: '/usuarios',
    },
    {
        icon: 'business-outline',
        text: 'Proveedores',
        link: '/proveedores',
    },
    {
        icon: 'happy-outline',
        text: 'Clientes',
        link: '/clientes',
    },
    {
        icon: 'help-buoy-outline',
        text: 'Soporte',
        link: '/soporte',
    },
    
];

export const ventasLinks = [
    {
        icon: 'card-outline',
        text: 'Ventas',
        link: '/',
    },
    // {
    //     icon: 'card-outline',
    //     text: 'Ventas',
    //     link: '/ventas',
    // },
    {
        icon: 'cube-outline',
        text: 'Inventario',
        link: '/inventario',
    },
    {
        icon: 'help-buoy-outline',
        text: 'Soporte',
        link: '/soporte',
    },
    
];

export const almacenLinks = [
    {
        icon: 'cube-outline',
        text: 'Inventario',
        link: '/',
    },
    // {
    //     icon: 'cube-outline',
    //     text: 'Inventario',
    //     link: '/inventario',
    // },
    {
        icon: 'help-buoy-outline',
        text: 'Soporte',
        link: '/soporte',
    },
    
];