import React, { useContext, useEffect, useState } from 'react'
import Select from 'react-select';
import { InputComponent } from '../../InputComponent/InputComponent';
import { AplicacionCarroItem } from '../AplicacionCarroItem/AplicacionCarroItem';
import { SelectAplicacion } from '../SelectAplicacion/SelectAplicacion';
import { ProductContext } from '../../../Context/ProductContext';

import camera from '../../../cameraPP.png';
import { UploadImageComponent } from '../../UploadImageComponent/UploadImageComponent';
import { calculatePrices } from '../../../helpers/CalculatePrices';
import { productFamily } from '../../../helpers/ProductFamilies';
import { useNuevoProductoModal } from '../../../hooks/useNuevoProductoModal';

export const NuevoProductoModal = ({ closeModal, formik, firestoreSaved, uploadImage, setUploadImage, aplications, addNewAplication, deleteAplication, filter, setFilter }) => { 
    
    const params = { 
        closeModal, 
        firestoreSaved, 
        formik, 
        uploadImage, 
    };

    const [ states, stateUpdaters ] = useNuevoProductoModal(params);

    const {
        allSuppliers,
        costEdited,
        existentPiece,
        family,
        highRotation,
    } = states;
    const { 
        findExistingPieceNumber,
        handleCostChange,
        handleFamilyChange,
        handleProveedorSelect,
        setHighRotation 
    } = stateUpdaters;

    return (
        <div className={` modal NuevoProductoModal-container `}>
            <div className={` modal-content `}>

                <div className = ' modal-header '>
                    <p className = ' section-title text-center text-sm-start '>Registrar un nuevo producto</p>
                </div>
                <div className={` modal-body `}>

                    <form
                        onSubmit = { formik.handleSubmit }
                    >

                        <div className={` row `}>

                            <div className={` col-lg-6 pe-3 `}>
                                <InputComponent
                                    id = "nombre"
                                    label = 'Nombre de Pieza'
                                    placeholder = 'Ingresa el nombre de pieza'
                                    value = { formik.values.nombre}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />

                                { formik.touched.nombre && formik.errors.nombre ? (
                                    <div className = 'error-message'>
                                        <p>{ formik.errors.nombre }</p>
                                    </div>
                                ) : null}
                            </div>
                            <div className={` col-lg-6 pe-3 `}>
                                <InputComponent
                                    id = "pieza"
                                    label = 'Número de Pieza'
                                    placeholder = 'Ingresa el número de pieza'
                                    value = { formik.values.pieza}
                                    onChange = { (e) => {
                                        formik.handleChange(e);
                                        findExistingPieceNumber(e);
                                    }}
                                    onBlur={formik.handleBlur}
                                />

                                { formik.touched.pieza && formik.errors.pieza ? (
                                    <div className = 'error-message'>
                                        <p>{ formik.errors.pieza }</p>
                                    </div>
                                ) : null}

                                { existentPiece && (
                                    <div className = 'error-message'>
                                        <p>Este número de pieza ya está registrado.</p>
                                    </div>
                                )}

                            </div>

                            <div className = 'col-lg-6 pe-3 mb-3 '>
                                <p className = 'mb-2'>Familia del Producto</p>
                                <Select 
                                    id = 'family'
                                    onChange = { handleFamilyChange }
                                    options = { productFamily }
                                />
                                { formik.touched.family && formik.errors.family ? (
                                    <div className = 'error-message'>
                                        <p>{ formik.errors.family }</p>
                                    </div>
                                ) : null}
                            </div>
                            
                            <div className = 'col-lg-6 pe-3 mb-3 '>
                                <p className = 'mb-2'>Selecciona un Proveedor</p>                                
                                <Select 
                                    onChange = { handleProveedorSelect }
                                    options = { 
                                        allSuppliers.map(proveedor => ({ value: proveedor.id, label: proveedor.name })) 
                                    }
                                />
                            </div>

                            <div className={` col-lg-9 pe-3 `}>

                                <InputComponent
                                    id = "descripcion"
                                    label = 'Descripción'
                                    placeholder = 'Ingresa la descripción'
                                    value = { formik.values.descripcion }
                                    onChange = { formik.handleChange }
                                    onBlur = { formik.handleBlur }
                                />

                                { formik.touched.descripcion && formik.errors.descripcion ? (
                                    <div className = 'error-message'>
                                        <p>{ formik.errors.descripcion }</p>
                                    </div>
                                ) : null}
                            </div>
                            <div className={` col-lg-3 pe-3 `}>

                                <InputComponent
                                    id = "existencia"
                                    label = 'Existencia Inicial'
                                    placeholder = 'Existencia'
                                    value = { formik.values.existencia }
                                    onChange = { formik.handleChange }
                                    onBlur = { formik.handleBlur }
                                />

                                { formik.touched.existencia && formik.errors.existencia ? (
                                    <div className = 'error-message'>
                                        <p>{ formik.errors.existencia }</p>
                                    </div>
                                ) : null}
                            </div>
                            <div className={` col-lg-4 pe-3 `}>

                                <InputComponent
                                    id = "costo"
                                    label = 'Costo'
                                    placeholder = 'Ingresa el costo'
                                    value = { formik.values.costo }
                                    onChange = { handleCostChange }
                                    onBlur = { formik.handleBlur }
                                    // number = { true }
                                />

                                { formik.touched.costo && formik.errors.costo ? (
                                    <div className = 'error-message'>
                                        <p>{ formik.errors.costo }</p>
                                    </div>
                                ) : null}
                                { costEdited && family === 0 ? (
                                    <div className = 'error-message'>
                                        <p>Para calcular los precios debes seleccionar una familia primero.</p>
                                    </div>
                                ) : null}
                            </div>

                            <div className={` col-lg-4 pe-3 `}>
                                <InputComponent
                                    id = "precio_contado"
                                    label = 'Precio de Contado'
                                    placeholder = 'Ingresa el precio de contado'
                                    value = { formik.values.precio_contado }
                                    onChange = { formik.handleChange }
                                    onBlur = { formik.handleBlur }
                                    // number = { true }
                                />

                                { formik.touched.precio_contado && formik.errors.precio_contado ? (
                                    <div className = 'error-message'>
                                        <p>{ formik.errors.precio_contado }</p>
                                    </div>
                                ) : null}
                            </div>
                            <div className={` col-lg-4 pe-3 `}>
                                 <InputComponent
                                    id = "precio_credito"
                                    label = 'Precio a Crédito'
                                    placeholder = 'Ingresa el precio a crédito'
                                    value = { formik.values.precio_credito}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    // number = { true }
                                />

                                { formik.touched.precio_credito && formik.errors.precio_credito ? (
                                    <div className = 'error-message'>
                                        <p>{ formik.errors.precio_credito }</p>
                                    </div>
                                ) : null}
                            </div>

                            <div className = ' col-12 col-sm-6 mt-2 '>
                                <p className = ' text_instructions mb-1 '>
                                    Activa este botón para poner el producto en lista de alta rotación.
                                </p>
                                <div 
                                    className = {` newproduct_high-rotation ${ (!!highRotation) && 'active' } `}
                                    onClick = { () => setHighRotation(state => !state) }
                                >
                                    <ion-icon name = "checkmark-outline"></ion-icon>
                                    <p>Este es un producto de alta rotación</p>
                                </div>
                            </div>

                            <div className = ' col-12 col-sm-6 mt-2 mb-3 mb-sm-0 d-flex align-items-center  '>
                                <UploadImageComponent 
                                    defaultImg = { camera }
                                    userType = 'product'
                                    uploadImage = { uploadImage }
                                    setUploadImage = { setUploadImage }
                                />
                            </div>


                            

                            <div className={` col-lg-12 pe-3 `}>

                                {
                                    (aplications.length > 0 ) && (
                                        aplications.map(aplicacion => {
                                            return (
                                                <AplicacionCarroItem 
                                                    key = { aplicacion.id }
                                                    borrarAplicacion = { deleteAplication }
                                                    item = { aplicacion } 
                                                />
                                            )
                                        })
                                    )
                                }
                            </div>

                            <div className={` col-lg-12 `}>
                                
                                <SelectAplicacion 
                                    aplications = { aplications }
                                    addNewAplication = { addNewAplication }
                                    deleteAplication = { deleteAplication }
                                    filter = { filter }
                                    setFilter = { setFilter }
                                />
                                
                                <div className={` modal-footer `}>
                                    <button
                                        type='reset'

                                        className={` button cancel-button px-5 `}
                                        onClick = { closeModal }
                                    >
                                        Cancelar
                                    </button>
                                    <button
                                        className={` button purple-button shadow px-5 ms-3 `}
                                        type = "submit"
                                    >
                                        Guardar
                                    </button>
                                </div>

                            </div>

                        </div>

                        

                    </form>

                </div>
            </div>
        </div>
    )

}
