

export const invoiceUses = [
    { label: 'Por definir', value: 'Por definir' },
    { label: 'Gastos en general', value: 'Gastos en general' },
    { label: 'Adquisición de mercancías', value: 'Adquisición de mercancías' },
    { label: 'Devoluciones, descuentos o bonificaciones', value: 'Devoluciones, descuentos o bonificaciones' },
    { label: 'Construcciones', value: 'Construcciones' },
    { label: 'Mobiliario y equipo de oficina por inversiones', value: 'Mobiliario y equipo de oficina por inversiones' },
    { label: 'Equipo de transporte', value: 'Equipo de transporte' },
    { label: 'Equipo de cómputo y accesorios', value: 'Equipo de cómputo y accesorios' },
    { label: 'Dados, troqueles, moldes, matrices y herramental', value: 'Dados, troqueles, moldes, matrices y herramental' },
    { label: 'Comunicaciones telefónicas', value: 'Comunicaciones telefónicas' },
    { label: 'Comunicaciones satelitales', value: 'Comunicaciones satelitales' },
    { label: 'Otra maquinaria y equipo', value: 'Otra maquinaria y equipo' },
    { label: 'Honorarios médicos, dentales y gastos hospitalarios', value: 'Honorarios médicos, dentales y gastos hospitalarios.' },
    { label: 'Gastos médicos por incapacidad o discapacidad', value: 'Gastos médicos por incapacidad o discapacidad' },
    { label: 'Gastos funerales', value: 'Gastos funerales' },
    { label: 'Donativos', value: 'Donativos' },
    { label: 'Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)', value: 'Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)' },
    { label: 'Aportaciones voluntarias al SAR', value: 'Aportaciones voluntarias al SAR' },
    { label: 'Primas por seguros de gastos médicos', value: 'Primas por seguros de gastos médicos' },
    { label: 'Gastos de transportación escolar obligatoria', value: 'Gastos de transportación escolar obligatoria' },
    { label: 'Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones', value: 'Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones' },
    { label: 'Pagos por servicios educativos (colegiaturas)', value: 'Pagos por servicios educativos (colegiaturas)' },
];