import React from 'react'

import { AuthRoutes } from '../AuthRouter/AuthRoutes';
import { AppRoutes } from '../AppRouter/AppRoutes';

import firebase, { FirebaseContext } from '../../firebase';
import { useAuth } from '../../hooks/useAuth';

import { useIdleTimer } from 'react-idle-timer';

export const AppContainer = () => {

	const { user, userType, timerSignout } = useAuth();

	// const onIdle = () => {
	// 	timerSignout();
	// }

	// const { logoutTimer } = useIdleTimer({
	// 	onIdle,
	// 	timeout: 1000 * 60 * 5,
	// 	promptTimeout: 0,
	// 	events: [
	// 		'mousemove',
	// 		'keydown',
	// 		'wheel',
	// 		'DOMMouseScroll',
	// 		'mousewheel',
	// 		'touchstart',
	// 		'touchmove',
	// 		'MSPointerDown',
	// 		'MSPointerMove',
	// 		'visibilitychange',
	// 	],
	// 	immediateEvents: [],
	// 	debounce: 0,
	// 	throttle: 0,
	// 	eventsThrottle: 200,
	// 	element: document,
	// 	startOnMount: true,
	// 	startManually: false,
	// 	stopOnIdle: false,
	// 	crossTab: false,
	// 	syncTimers: 0,
	// })

	return (
		<>
			{ (user && userType !== '')
			?	
                <FirebaseContext.Provider value={{ firebase }}>
                    <AppRoutes userType = {userType} />
                </FirebaseContext.Provider>	
			:
				<AuthRoutes/>
			}
		</>
	)
}
