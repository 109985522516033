import firebase from 'firebase/compat/app';

/*
 *   Get the special products registered as sold.
 *   @param { fromDate } date.
 *   @param { toDate } date.
 */
export const getSpecialProductsSold = (fromDate, toDate) => {
    return new Promise(async (resolve, reject) => {
        const queryFromDate = firebase.firestore.Timestamp.fromDate( new Date( fromDate ) );
        const queryToDate = firebase.firestore.Timestamp.fromDate( new Date( toDate ) );

        try {
            let products = [];
            const response = await firebase.firestore().collection('specialProductsSold')
                .orderBy('dateSold', 'desc')
                .where('dateSold', '>=', queryFromDate)
                .where('dateSold', '<=', queryToDate).get();
            if( response.docs.length > 0 ) {
                response.docs.forEach(doc => {
                    console.log(doc.data());
                    products.push(doc);
                })
                resolve(products);
            } else {
                console.log('No se encontraron resultados.');
                resolve([]);
            }
        } catch(e) {
            reject(e);
        }

        

    });
}