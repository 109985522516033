import React, { useEffect } from 'react';
import camera from '../../cameraPP.png';

import { UploadImageComponent } from '../UploadImageComponent/UploadImageComponent';
import { InputComponent } from '../InputComponent/InputComponent';
import { useUsuarios } from './../../hooks/useUsuarios';


import Select from 'react-select';
import makeAnimated from 'react-select/animated';

export const NuevoUsuarioModal = ({ closeModal, formik,  handleChangeSelect, savedData, setUploadImage, uploadImage, setSavedData }) => {

    const { state } = useUsuarios();
    const { userTypes } = state;

    const animatedComponents = makeAnimated();

    useEffect(() => {
        if(!!savedData) {
            closeModal();
            setSavedData( false );
        }
    }, [savedData]);

    return (
        <div className={` modal `}>
            <div className={` modal-content `}>

                <div className={` modal-header `}>
                    <p className='section-title'>Registrar un nuevo Usuario.</p>
                </div>
                <div className={` modal-body `}>

                    <form
                        onSubmit = { formik.handleSubmit }
                    >
                        <div className = {` row `}>

                            <div className = {` col-lg-6 `}>
                                <InputComponent
                                    className = " input-container-error "
                                    id="userName"
                                    label='Nombre'
                                    placeholder='Ingresa el nombre del usuario'
                                    value={formik.values.userName}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />

                                {formik.touched.userName && formik.errors.userName ? (
                                    <div>
                                        <p className={` text_error  `}>{formik.errors.userName }</p>
                                    </div>
                                ) : null}

                                <div className={` mb-3 input-container `}>
                                    <p className='input-label'>Puesto</p>
                                    <Select
                                        id="userPosition"
                                        placeholder='Ingresa el puesto del Usuario'
                                        closeMenuOnSelect={true}
                                        components={animatedComponents}
                                        options={userTypes}
                                        onChange={handleChangeSelect}
                                    />
                                </div>

                                {formik.touched.userPosition && formik.errors.userPosition ? (
                                    <div>
                                        <p className={` text_error  `}>{formik.errors.userPosition}</p>
                                    </div>
                                ) : null}

                                <InputComponent
                                    id="userAddress"
                                    label='Dirección del usuario'
                                    placeholder='Ingresa la direccion del usuario'
                                    value={formik.values.userAddress}
                                    onChange={formik.handleChange}
                                />

                                {formik.touched.userAddress && formik.errors.userAddress ? (
                                    <div>
                                        <p className={` text_error  `}>{formik.errors.userAddress}</p>
                                    </div>
                                ) : null}

                                <InputComponent
                                    id="userPassword"
                                    label='Contraseña'
                                    placeholder='Ingresa la contraseña del usuario'
                                    value={formik.values.userPassword}
                                    onChange={formik.handleChange}
                                    password = { true }
                                />

                                {formik.touched.userPassword && formik.errors.userPassword ? (
                                    <div>
                                        <p className={` text_error  `}>{formik.errors.userPassword}</p>
                                    </div>
                                ) : null}

                            </div>
                            <div className={` offset-lg-1 col-lg-5 `}>
                                <div className = ' uploadimage_selector  '>
                                    <UploadImageComponent defaultImg = { camera } userType = 'newUser' uploadImage = { uploadImage } setUploadImage = { setUploadImage }  />
                                </div>

                                <InputComponent
                                    id="userPhoneNumber"
                                    label='Teléfono'
                                    placeholder='Ingresa el telefono del usuario'
                                    value={formik.values.userPhoneNumber}
                                    onChange={formik.handleChange}
                                />

                                {formik.touched.userPhoneNumber && formik.errors.userPhoneNumber ? (
                                    <div>
                                        <p className={` text_error  `}>{formik.errors.userPhoneNumber}</p>
                                    </div>
                                ) : null}

                                <InputComponent
                                    id="userEmail"
                                    label='Correo'
                                    placeholder='Ingresa el correo del Usuario'
                                    value = { formik.values.userEmail }
                                    onChange={formik.handleChange}
                                />

                                {formik.touched.userEmail && formik.errors.userEmail ? (
                                    <div>
                                        <p className={` text_error  `}>{formik.errors.userEmail}</p>
                                    </div>
                                ) : null}

                                <InputComponent
                                    id="userPasswordConfirm"
                                    label='Confirmar contraseña'
                                    placeholder='Confirma la contraseña del usuario'
                                    value={formik.values.userPasswordConfirm}
                                    onChange={formik.handleChange}
                                    password = { true }
                                />

                                {formik.touched.userPasswordConfirm && formik.errors.userPasswordConfirm ? (
                                    <div>
                                        <p className={` text_error  `}>{formik.errors.userPasswordConfirm}</p>
                                    </div>
                                ) : null}

                            </div>
                        </div>

                        <div className={` modal-footer `}>
                            <button
                                type='reset'

                                className={` button cancel-button shadow px-5 `}
                                onClick={closeModal}
                            >
                                Cancelar
                            </button>
                            <button
                                className={` button purple-button shadow px-5 ms-3 `}
                                type="submit"
                            >
                                Guardar
                            </button>
                        </div>


                    </form>

                </div>
            </div>
        </div>
    )
}