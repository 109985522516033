import React, { useContext, useEffect, useState } from 'react';
import { NuevoProductoModal } from '../../Components/InventarioComponents/NuevoProductoModal/NuevoProductoModal';
import { Modal } from '../../Components/Modal/Modal';
import { InventarioHeader } from './../../Components/InventarioComponents/InventarioHeader/InventarioHeader';
import { ProductsListContainer } from '../../Components/ProductsList/ProductsListContainer';
import { InventarioSearch } from '../../Components/InventarioComponents/InventarioSearch/InventarioSearch';
import { ProductContext } from '../../Context/ProductContext';
import { AddProductOrder } from '../../Components/InventarioComponents/AddProductOrder/AddProductOrder';
import { useProducts } from '../../hooks/useProducts';
import { DetallesProducto } from '../../Components/InventarioComponents/DetallesProducto/DetallesProducto';

import { UnselectedItemPlaceholder } from '../../Components/UnselectedItemPlaceholder/UnselectedItemPlaceholder';

export const InventarioScreen = () => {

    const { formik, firestoreSaved, selectedProduct, uploadImage, setUploadImage, aplications, addNewAplication, deleteAplication, setSelectedProduct } = useProducts();

    const { filter, setFilter, } = useContext(ProductContext);
    const [ openModal, setOpenModal ] = useState(false);
    const [ openProductOrder, setOpenProductOrder ] = useState(false);
    const [ active, setActive ] = useState('');

    return (
        <div className = ' px-0 mx-0 '>

            <div  className = ' row mx-0 '>
                <div className = ' col-12 col-sm-6 px-4 screens_section '>
                    <div>
                        <InventarioHeader 
                            openModal = { () => setOpenModal(true) }
                            openProductOrder = { () => setOpenProductOrder(true) }
                        />
                        <InventarioSearch />
                    </div>
                    <div className = ' inventario_product-list '>
                        <ProductsListContainer 
                            active = { active }
                            setActive = { setActive }
                            setSelectedProduct = { setSelectedProduct } 
                        />
                    </div>
                </div>
                <div className = ' d-none d-sm-block col-12 col-sm-6  '>
                    <div className = ' position-fixed top-0 end-0 col-md-6 col-lg-5 '>
                        { selectedProduct !== '' && (
                            <DetallesProducto 
                                closeModal = { () => setOpenModal(false) }
                                producto = { selectedProduct }
                                setSelectedProduct = { setSelectedProduct }
                                selectedProduct = { selectedProduct }
                            />
                        )}
                        { selectedProduct === '' && <UnselectedItemPlaceholder /> }

                    </div>
                </div>
            </div>

           { !!openModal && (
                <Modal>
                    <NuevoProductoModal 
                        closeModal = {() => setOpenModal(false)} 
                        formik = { formik }
                        firestoreSaved = { firestoreSaved }
                        uploadImage = { uploadImage }
                        setUploadImage = { setUploadImage }
                        aplications = { aplications }
                        addNewAplication = { addNewAplication }
                        deleteAplication = { deleteAplication }
                        filter = { filter }
                        setFilter = { setFilter }
                    />
                </Modal>
            )}

            { !!openProductOrder && (
                    <Modal>
                        <AddProductOrder
                            closeModal = {() => setOpenProductOrder(false)}
                        />
                    </Modal>
                )
            }

        </div>
    );

};
