import React from 'react';
import logoicon from './logoicon.png'
import { Link } from 'react-router-dom';
import { LoginForm } from '../../../Components/AuthComponents/LoginForm';

export const LoginScreen = () => {

	return (
		<div className = {` row mt-3 m-md-0 `} >
			<div className = {` login_image-container min-vh-100 col-sm-12 col-md-4 d-none d-md-flex `}></div>
			<div className = {` col-md-8 col-sm-12 `}>
				<div 
					className = 
						'  d-flex p-5 align-items-center justify-content-sm-center justify-content-md-start mx-4 mx-md-2 '
				>
					<img
						src = { logoicon }
						alt = 'icono-logo'
						className = ' login_logo '
					/>
					{/* <h1 className = ' m-0 ms-3 '>Punto de Venta</h1> */}
				</div>
				<div className = ' mx-5 '>
					<p className = ' section-title text-center text-md-start '>
						Inicia Sesión.
					</p>
					<p className = ' login_subtitle text-center text-md-start '>Ingresa a la plataforma con tu correo y contraseña registrados.</p>
					<LoginForm />
					<div className = ' text-center text-md-start '>
						<Link className = ' text-button-purple ' to = '/recuperar-cuenta' >¿Olvidaste tu contraseña?</Link>
					</div>
				</div>

			</div>
		</div>
	)
}
