import React, { useContext, useEffect, useState } from 'react';
import { InputComponent } from '../../InputComponent/InputComponent';
import { ProductContext } from './../../../Context/ProductContext';

export const AddSpecialProduct = () => {

    const { addSpecialProduct, addToCart, setAddSpecialProduct } = useContext(ProductContext);   
    const { active, product } = addSpecialProduct; 
    const { producto, cantidad } = product; 

    // const [ specialProduct, setSpecialProduct ] = useState({ producto: '', cantidad: 0 });
    // const [ specialQuantity, setSpecialQuantity ] = useState([]);

    useEffect(() => {
        // if(product !== undefined) {
        //     console.log(product);
        //     const productData = {
        //         id: 'generic-special', 
        //         producto: {
        //             name: `${ producto.data().nombre } - POR PEDIDO`,
        //             description: producto.data().descripcion,
        //             price: producto.data().precio_contado,
        //                 code: producto.data().pieza,
        //         }
        //     }
        //     setSpecialProduct({
        //         ...specialProduct,
        //         producto: productData,
        //     })
        // }
    }, []);

    // const handlePieceQuantity = ({ target: { value }}) => setSpecialProduct({ ...specialProduct, cantidad: Number(value)});

    const addSpecialToCart = () => {
        const productData = {
            id: `generic-special-${ producto.data().pieza }`, 
            producto: {
                idOriginal: producto.id,
                name: `${ producto.data().nombre } - POR PEDIDO`,
                description: producto.data().descripcion,
                price: producto.data().precio_contado,
                price_credit: producto.data().precio_credito,
                code: producto.data().pieza,
            }
        }
        const productCart = { ...productData };
        console.log(productCart);
        addToCart(productCart);
        setAddSpecialProduct({ active: false, product: '' });
        // closeModal();
    }

    return (
        <div className = ' modal  '>
            <div className={` modal-content w-50 `}>
                <div className={` modal-header d-flex align-items-center `}>
                    <p className='section-title h5 mb-0 flex-grow-1'>Agregar Producto Especial.</p>
                </div>
                <div className={` modal-body `}>
                    <div className = ' mb-3 '>
                        <p>{ producto.data().nombre }</p>
                        <p>{ producto.data().pieza }</p>
                    </div>
                    {/* <InputComponent 
                        label = 'Cantidad a pedir'
                        placeholder = 'Cantidad a agregar por pedido...'
                        onChange = { handlePieceQuantity }
                    /> */}
                </div>
                <div className={` modal-footer `}>
                    <div className = {` ResumenVenta-cta `}>
                        <p className = 'button purple-button text-center h5 me-3' onClick = { addSpecialToCart }>AGREGAR PEDIDO</p>
                        <p className = 'button cancel-button h5' >CANCELAR</p>
                    </div> 
                </div>
            </div>
        </div>
    )
}
