import React from 'react'
import { UnselectedItemPlaceholder } from '../../UnselectedItemPlaceholder/UnselectedItemPlaceholder'

// import '../SupportComponent.css'

export const SupportDetail = ({module, index}) => {
	return (
		<div className = ' supper_detail-container '>
			{module === 'inventory' && (
				<div className = ' p-4 '>
					<div>
						{index === 1 && (
							<div>
								<p className = ' h5 '>Agregar un nuevo producto al inventario.</p>
								<hr />
								<p className = ' mb-3 '>
									Para agregar un nuevo producto al Inventario, dirigete a la página 'Inventario'
									en el menú lateral izquierdo, después has click en el botón de más en la parte superior.
								</p>
								<p className = ' mb-3 '>
									Esto abrirá una ventana flotante, en donde podrás rellenar todos tus datos. Recuerda que el precio del producto 
									es calculado gracias a tu configuración de familias.
								</p>
								<iframe 
									width = "100%" height = "300px" 
									src="https://www.youtube.com/embed/fo0PtHD404A" 
									title="YouTube video player" 
									frameBorder="0"
									 allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
									 allowFullScreen
								></iframe>
							</div>
						)}
						{index === 2 && (
							<div>
								<p className = ' h5 '>Editar la información de un producto.</p>
								<hr />
								<p className = ' mb-3 '>
									Para editar la información de un producto ya existente hay que estar en la pantalla de Inventario, 
									seleccionar un producto, lo que permitirá ver toda la información del producto en el lado derecho de la pantalla.
								</p>
								<p className = ' mb-3 '>
									Al hacer clic en el botón de editar, se pueden modificar todos los campos disponibles que sean necesarios; 
									ya sea el número de pieza, nombre, existencias, la imagen del producto, el proveedor y aplicaciones.
								</p>
							</div>
						)}
						{index === 3 && (
							<div>
								<p className = ' h5 '>Eliminar un producto.</p>
								<hr />
								<p className = ' mb-3 '>
									Si es necesario eliminar un producto permanentemente del inventario, hay que seleccionar el producto y hacer click en el 
									botón de editar.
								</p>
								<p className = ' mb-3 '>
									Una vez que la información del producto se despliegue, haciendo scroll hasta la última parte de la información se encuentra un 
									botón de eliminar. Al hacer click en el mismo se abrira una ventana flotante para confirmar la eliminación del producto.
								</p>
							</div>
						)}
						{index === 4 && (
							<div>
								<p className = ' h5 '>Buscar un producto en específico.</p>
								<hr />
								<p className = ' mb-3 '>
									Los productos se pueden buscar con el código o número de pieza que tengan, el nombre y hasta con la ayuda de filtros que
									muestran los productos de acuerdo a su marca, modelo y años de aplicación.
								</p>
							</div>
						)}
						{index === 5 && (
							<div>
								<p className = ' h5 '>¿Cómo agregar una aplicación al producto?</p>
								<hr />
								<p className = ' mb-3 '>
									La aplicación de una pieza se puede agregar desde que se registra un nuevo producto, o se puede agregar a un producto 
									existente al seleccionar el producto y editarlo.
								</p>
								<p className = ' mb-3 '>
									Una vez seleccionado el producto, en la parte de abajo se encuentra la opción para agregar aplicación. Haciendo click, 
									nos desplegará toda la información de marcas y modelos existentes para la pieza.
								</p>
							</div>
						)}
						{index === 6 && (
							<div>
								<p className = ' h5 '>Entrada de mercancia de maryoreo.</p>
								<hr />
								<p className = ' mb-3 '>
									Cuando se requiere registrar la entrada de mercancia, hay que dirigirse a la pantalla de Inventario y en la parte de 
									arriba de la lista de productos se puede encontrar un botón para subir productos justo en el lado izquierdo del botón 
									de actualizar. 
								</p>
								<p className = ' mb-3 '>
									Haciendo click en el botón se abrirá una ventana flotante que contiene un campo vacío para el código o número de pieza 
									del producto para buscarlo en el inventario actual y se ingresa la cantidad que haya llegado del producto.
								</p>
							</div>
						)}
					</div>
				</div>
			)}
			{module === 'sales' && (
				<div className = ' p-4 '>
					<div>
						{index === 1 && (
							<div>
								<p className = ' h5 '>Agregar producto al carrito de ventas.</p>
								<hr />
								<p className = ' mb-3 '>
									En la pantalla de ventas se puede buscar el producto con los filtros, seleccionar un producto para ver toda su 
									información y agregarlo al carrito de ventas. 
								</p>
								<p className = ' mb-3 '>
									Una vez en el carrito se puede modificar la cantidad al hacer click 
									en el botón del carrito que se encuentra en la esquina superior derecha.
								</p>
							</div>
						)}
						{index === 2 && (
							<div>
								<p className = ' h5 '>Imprimir el ticket de venta.</p>
								<hr />
								<p className = ' mb-3 '>
									La impresión del ticket se puede realizar antes de finalizar la venta, solamente hay que hacer click en 
									"Imprimir Ticket" para mandarlo a la impresora por defecto o seleccionar la indicada para la impresión.
								</p>
								<p className = ' mb-3 '>
									En el ticket viene la información de los productos, la fecha y hora de venta, el nombre de la persona que 
									realizó la venta
								</p>
							</div>
						)}
						{index === 3 && (
							<div>
								<p className = ' h5 '>Imprimir el vale del producto.</p>
								<hr />
								<p className = ' mb-3 '>
									El voucher del producto se puede imprimir antes de realizar la compra, es el vale que permite sacar los 
									productos de la bodega sin modificar la existencia del inventario.
								</p>
								<p className = ' mb-3 '>
									En el voucher se encuentra la pieza con su nombre y cantidad, junto con la persona que solicitó y dió permiso
									para extraer la pieza de la bodega.
								</p>
							</div>
						)}
						{index === 4 && (
							<div>
								<p className = ' h5 '>Facturación de venta.</p>
								<hr />
								<p className = ' mb-3 '>
									En caso de que una venta requiera facturación, en la ventana de ventas se puede seleccionar el botón de facturación. 
									Esto abrira otra ventana flotante donde se podrán almacenar todos datos de facturación que luego podrán ser visualizados
									en la pantalla de Contabilidad.
								</p>
							</div>
						)}
						{index === 5 && (
							<div>
								<p className = ' h5 '>Descuentos a venta.</p>
								<hr />
								<p className = ' mb-3 '>
									En la ventana de ventas, hay un campo para ingresar el descuento de la venta. Se pueden realizar descuentos seleccionando 
									clientes registrados con su porcentaje de descuento designado o se puede aplicar un descuento específico. 
								</p>
								<p className = ' mb-3 '>
									Solamente hay que ingresar el porcentaje de la manera; (ejem. 10 = 10% o 50 = 50%). 
								</p>
							</div>
						)}
						{index === 6 && (
							<div>
								<p className = ' h5 '>Pedidos especiales.</p>
								<hr />
								<p className = ' mb-3 '>
									Para agregar un pedido especial de un producto del que no haya existencia en el usuario, hay que hacer click en el botón que
									se encuentra en la esquina superior derecha a lado del botón del carrito en la pantalla de Ventas. 
								</p>
								<p className = ' mb-3 '>
									Una vez hecho click en el botón, se deben de llenar los datos del pedido especial como el nombre del producto, descripción, 
									precio y número de pieza, finalmente hacer click en el botón de agregar producto y se va a mandar al carrito. 
								</p>
							</div>
						)}
					</div>
				</div>
			)}
			{module === 'dashboard' && (
				<div className = ' p-4 '>
					<div>
						{index === 1 && (
							<div>
								<p className = ' h5 '>Estadísticas que se encuentran en el dashboard.</p>
								<hr />
								<p className = ' mb-3 '>
									En nuestra pantalla principal se encuentran estádisticas de ventas que se han realizado a lo largo del día. 
									Arriba se encuentran tres tarjetas con; la venta monetaria del día, seguido por la venta monetaria de la semana 
									y por último la cantidad de ventas del día. 
								</p>
								<p className = ' mb-3 '>
									Abajo de las tarjetas se puede apreciar una gráfica con las ventas del mes, con una gráfica es más 
									fácil comparar y analizar la cantidad de ventas que se realizan de acuerdo al día y utilizarla en 
									alguna estrategía de marketing.
								</p>
								<p className = ' mb-3 '>
									En el lado derecho de la pantalla están las notificaciones de los productos con baja existencia para quitar 
									las notificaciones es necesario ingresar mercancia o existencias de los productos mencionados.
								</p>
							</div>
						)}
					</div>
				</div>
			)}
			{module === 'users' && (
				<div className = ' p-4 '>
					<div>
						{index === 1 && (
							<div>
								<p className = ' h5 '>Registrar un nuevo usuario.</p>
								<hr />
								<p className = ' mb-3 '>
									Cuando se requiera un nuevo usuario en el punto de venta, solamente un usuario con cuenta de administrador 
									puede crearla en la pantalla de Usuarios. Haciendo click en el botón de "más" en la parte superior de la 
									lista de usuarios, abrirá una nueva ventana flotante.
								</p>
								<p className = ' mb-3 '>
									Una vez abierta la ventana flotante se deben de llenar los datos del usuario y el puesto. También se le 
									crea una contraseña para el usuario que luego usará para acceder de acuerdo al puesto que tenga.
								</p>
							</div>
						)}
						{index === 2 && (
							<div>
								<p className = ' h5 '>Editar o cambiar el puesto a un usuario.</p>
								<hr />
								<p className = ' mb-3 '>
									Si es necesario cambiar la información de un usuario; ya sea su nombre, dirección, número teléfonico o 
									puesto. Hay que seleccionar al usuario que se quiera modificar dentro de la lista y eso desplegará toda 
									su información en la parte derecha de la pantalla de Usuarios.
								</p>
								<p className = ' mb-3 '>
									Con el usuario seleccionado, se hace click en el botón de "editar" y permitirá mmodificar todos los campos 
									necesarios del usuario. Una vez que se llené toda la información, guardamos los cambios haciendo click en el 
									botón de "Guardar".
								</p>
							</div>
						)}
						{index === 3 && (
							<div>
								<p className = ' h5 '>Eliminar usuario.</p>
								<hr />
								<p className = ' mb-3 '>
									Para eliminar un usuario de manera permanente del punto de venta, hay que seleccionar al usuario de la lista de 
									usuarios, hacer click en el botón de "editar". 
								</p>
								<p className = ' mb-3 '>
									Después abajo de los campos de la información del usuario, se encuentra 
									el botón de "eliminar", hacemos click en el mismo botón y confirmamos la eliminación del usuario.
									Es importante avisar al soporte de Avluet Software, cada vez que se elimine un usuario.
								</p>
							</div>
						)}
						{index === 4 && (
							<div>
								<p className = ' h5 '>Recuperar la contraseña de un usuario.</p>
								<hr />
								<p className = ' mb-3 '>
									Cuando se pierda la contraseña de algún usuario, se puede recuperar desde la pantalla de inicio de sesión haciendo 
									click en la pregunta que dice "¿Olvidaste tu contraseña?", eso lo llevará a la pantalla de Recuperación de contraseña.
								</p>
								<p className = ' mb-3 '>
									En esa pantalla hay que ingresar el correo electrónico con el que el usuario está registrado y recibirá un correo para 
									reestablecer una nueva contraseña.
								</p>
							</div>
						)}
					</div>
				</div>
			)}
			{module === 'suppliers' && (
				<div className = ' p-4 '>
					<div>
						{index === 1 && (
							<div>
								<p className = ' h5 '>Registrar un nuevo proveedor.</p>
								<hr />
								<p className = ' mb-3 '>
									Cuando se requiera registrar un nuevo proveedor, solamente un usuario con cuenta de administrador 
									puede crearla en la pantalla de Proveedores. 
								</p>
								<p className = ' mb-3 '>
									Haciendo click en el botón de "más" en la parte superior de la lista de proveedores, abrirá una nueva 
									ventana flotante. Una vez abierta la ventana flotante se deben de llenar los datos del proveedor.
								</p>
							</div>
						)}
						{index === 2 && (
							<div>
								<p className = ' h5 '>Editar un proveedor.</p>
								<hr />
								<p className = ' mb-3 '>
									Si es necesario cambiar la información de un proveedor, hay que seleccionar al proveedor que se quiera modificar 
									dentro de la lista y eso desplegará toda su información en la parte derecha de la pantalla de Proveedores.
								</p>
								<p className = ' mb-3 '>
									Con el proveedor seleccionado, se hace click en el botón de "editar" y permitirá mmodificar todos los campos 
									necesarios del proveedor. Una vez que se llené toda la información, guardamos los cambios haciendo click en el 
									botón de "Guardar".
								</p>
							</div>
						)}
						{index === 3 && (
							<div>
								<p className = ' h5 '>Eliminar proveedor.</p>
								<hr />
								<p className = ' mb-3 '>
									Para eliminar un proveedor de manera permanente del punto de venta, hay que seleccionar al proveedor de la lista de 
									proveedores, hacer click en el botón de "editar". 
								</p>
								<p className = ' mb-3 '>
									Después abajo de los campos de la información del proveedor, se encuentra 
									el botón de "eliminar", hacemos click en el mismo botón y confirmamos eliminar el proveedor.
								</p>
							</div>
						)}

					</div>
				</div>
			)}
			{module === 'clients' && (
				<div className = ' p-4 '>
					<div>
						{index === 1 && (
							<div>
								<p className = ' h5 '>Registrar un nuevo cliente.</p>
								<hr />
								<p className = ' mb-3 '>
									Cuando se requiera registrar un nuevo cliente, solamente un usuario con cuenta de administrador 
									puede crearla en la pantalla de Clientes. 
								</p>
								<p className = ' mb-3 '>
									Haciendo click en el botón de "más" en la parte superior de la lista de clientes, abrirá una nueva ventana flotante.
									Una vez abierta la ventana flotante se deben de llenar los datos del cliente y su descuento asignado.
								</p>
							</div>
						)}
						{index === 2 && (
							<div>
								<p className = ' h5 '>Editar un cliente y su descuento.</p>
								<hr />
								<p className = ' mb-3 '>
									Si es necesario cambiar la información de un cliente o su descuento actual, hay que seleccionar al cliente 
									que se quiera modificar dentro de la lista y eso desplegará toda su información en la parte derecha 
									de la pantalla de Clientes.
								</p>
								<p className = ' mb-3 '>
									Con el cliente seleccionado, se hace click en el botón de "editar" y permitirá mmodificar todos los campos 
									necesarios y el descuento del cliente. Una vez que se llené toda la información, guardamos los cambios haciendo click en el 
									botón de "Guardar".
								</p>
							</div>
						)}
						{index === 3 && (
							<div>
								<p className = ' h5 '>Eliminar cliente.</p>
								<hr />
								<p className = ' mb-3 '>
									Para eliminar un cliente de manera permanente del punto de venta, hay que seleccionar al cliente de la lista de 
									clientes, hacer click en el botón de "editar". 
								</p>
								<p className = ' mb-3 '>
									Después abajo de los campos de la información del cliente, se encuentra 
									el botón de "eliminar", hacemos click en el mismo botón y confirmamos eliminar el cliente.
								</p>
							</div>
						)}
					</div>
				</div>
			)}
			{module === 'profile' && (
				<div className = ' p-4 '>
					<div>
						{index === 1 && (
							<div>
								<p className = ' h5 '>Información que se puede ver en el perfil.</p>
								<hr />
								<p className = ' mb-3 '>
									En el perfil se encuentra la información del usuario que está conectado actualmente, se pueden modificar 
									ciertos campos y la foto de perfil, haciendo click al botón de "editar" que se encuentra en la parte superior 
									derecha de la pantalla.
								</p>
								<p className = ' mb-3 '>
									También se puede cerrar la sesión en la misma pantalla con el botón rojo o con el botón de salida que se encuentra 
									en la parte más baja a lado del botón de "Ver Perfil" de la barra de navegación que está del lado izquierdo de la pantalla.
								</p>
							</div>
						)}
					</div>
				</div>
			)}
			{module === '' && (
				<>
					<UnselectedItemPlaceholder/>
				</>
			)}
		</div>
	)
}
