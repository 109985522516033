
const imagePlaceholder = 'https://firebasestorage.googleapis.com/v0/b/pos-web-a98f9.appspot.com/o/placeholders%2Ffamily%2F002.png?alt=media&token=9799d012-d3f0-4568-ada1-a365a70d2b6d';

export const products = [
    {
        id: '1',
        aplicaciones: [
            {
                id: 1,
                brand: "Acura",
                engines: ['Todos los motores'],
                fromYear: 2000,
                id: 1658431306279,
                model: "CL",
                toYear: 2001,
            },
        ],
        costo: 50,
        descripcion: 'ACEITE PARA TRANSMISIÓN AUTOMÁTICA',
        existencia: 3,
        family: '002',
        highRotation: true,
        image: imagePlaceholder,
        nombre: 'ACEITE DE TRANSMISIÓN', 
        pieza: 'SAE-80-W90-5-ROSF',
        precio_contado: 100,
        precio_credito: 120,
        proveedor: 1,
    },
    {
        id: '2',
        aplicaciones: [
            {
                id: 1,
                brand: "Acura",
                engines: ['Todos los motores'],
                fromYear: 2000,
                id: 1658431306279,
                model: "CL",
                toYear: 2001,
            },
        ],
        costo: 200,
        descripcion: 'ANTICONGELATE GALON ROSA 3.7 ROSHFRANS SIRVE PARA VW.',
        existencia: 83,
        family: '002',
        highRotation: true,
        image: imagePlaceholder,
        nombre: 'ANTICONGELATE GALON ROSA 3.7 ROSHFRANS SIRVE PARA VW', 
        pieza: '3770-LTS-ROSHFRANS',
        precio_contado: 345,
        precio_credito: 400,
        proveedor: 1,
    },
    {
        id: '3',
        aplicaciones: [
            {
                id: 1,
                brand: "Volkswagen",
                engines: ['Todos los motores'],
                fromYear: 1998,
                model: "JETTA IV",
                toYear: 2015,
            },
            {
                id: 2,
                brand: "Volkswagen",
                engines: ['Todos los motores'],
                fromYear: 1998,
                model: "BEETLE",
                toYear: 2019,
            },
            {
                id: 3,
                brand: "Volkswagen",
                engines: ['Todos los motores'],
                fromYear: 1998,
                model: "GOLF IV",
                toYear: 2007,
            },
        ],
        costo: 1000,
        descripcion: 'ACEITE PARA MOTORES VOLKSWAGEN Y AUDI.',
        existencia: 20,
        family: '002',
        highRotation: true,
        image: imagePlaceholder,
        nombre: 'ACEITE SINTÉTICO ORIGINAL VW', 
        pieza: 'SEA-5-W40-VW',
        precio_contado: 1399,
        precio_credito: 1799,
        proveedor: 1,
    },
    {
        id: '4',
        aplicaciones: [],
        costo: 1,
        descripcion: 'ABRAZADERA METÁLICA DE MEDIA PULGADA',
        existencia: 100,
        family: '001',
        highRotation: true,
        image: imagePlaceholder,
        nombre: 'ABRAZADERA DE MEDIA', 
        pieza: 'ZCC-18',
        precio_contado: 8,
        precio_credito: 10,
        proveedor: 1,
    },
    {
        id: '5',
        aplicaciones: [],
        costo: 1,
        descripcion: 'ABRAZADERA METÁLICA DE CUARTO DE PULGADA',
        existencia: 100,
        family: '001',
        highRotation: true,
        image: imagePlaceholder,
        nombre: 'ABRAZADERA DE CUARTO', 
        pieza: 'ZCC-10',
        precio_contado: 8,
        precio_credito: 10,
        proveedor: 1,
    },
    {
        id: '6',
        aplicaciones: [
            {
                id: 1,
                brand: "Nissan",
                engines: ['Todos los motores'],
                fromYear: 2013,
                model: "SENTRA",
                toYear: 2016,
            },
        ],
        costo: 2400,
        descripcion: 'AMORTIGUADORES DE GAS PARA NISSAN SENTRA',
        existencia: 2,
        family: '006',
        highRotation: true,
        image: imagePlaceholder,
        nombre: 'AMORTIGUADOR DELANTERO SENTRA', 
        pieza: '918829181',
        precio_contado: 3100,
        precio_credito: 3499,
        proveedor: 1,
    },
];