import React from 'react'
import { Link } from 'react-router-dom';

import girl from './girl.png'

export const UnselectedItemPlaceholder = () => {
  
    return (
        <div className = ' unselected_placeholder ' >
            <img className = ' unselected_image ' src = { girl } />
            <p className = ' h5 mt-4 '>¡Aún no has seleccionado nada!</p>
            <p>
                Selecciona una opción para comenzar, si tienes una duda consulta 
                nuestra guía de soporte.
            </p>
            <Link className = ' mt-2 ' to = "/soporte">
                Soporte
            </Link>
        </div>
    )

}
