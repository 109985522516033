import React from 'react'

export const InputComponent = ({ 
    label, placeholder, onChange, id, disabled = false, value,
    number = false, password = false, email = false,
}) => {

    return (
        <div className = {` input-container `}>
            <p className='input-label'>{ label }</p>
            { !number && !email && (
                <input 
                    onChange = { onChange }
                    placeholder = { placeholder }
                    id = { id }
                    type = { (!!password) ? 'password' : 'text' }
                    disabled = { !!disabled }
                    value = { value }
                />
            )}
            { number && (
                <input 
                    onChange = { onChange }
                    placeholder = { placeholder }
                    id = { id }
                    type = 'number'
                    disabled = { !!disabled }
                    value = { value }
                />
                ) }
            { email && (
                <input 
                    onChange = { onChange }
                    placeholder = { placeholder }
                    id = { id }
                    type = 'email'
                    disabled = { !!disabled }
                    value = { value }
                />
            )}
        </div>

    )
}
