/*

    HOOK NAME: usePurchase.
    HOOK DESCRIPTION: Hook that allows to consume and write the products database.
    HOOK CONTENT:

    # HOOK CONFIG:
        - Initial State Config Declarations.
        - State Inizialization.
        - Effect Listeners.

    # CALCULATION FUNCTIONS.
        - 

	# FIRESTORE FUNCTIONS.
		- 

    HOOKS NOTES:
        - This hook is used on CobrarModal to get the payment data and register 
		on Firebase Database.

*/

import { useContext, useEffect, useRef, useState } from 'react'
import firebase from 'firebase/compat/app';
import { ProductContext } from '../Context/ProductContext';
import { useAuth } from './useAuth';
import { useClients } from './useClients';
import { NumberToText } from '../helpers/NumberToText';
import { useReactToPrint } from 'react-to-print';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { getPurchaseFolio } from '../helpers/getPurchaseFolio';
import { buildPaymentData } from '../helpers/buildPaymentData';
import { registerSaleDataBase } from '../helpers/db/registerSaleDataBase';
import { registerInvoice } from '../helpers/db/registerInvoice';
import { removeProductsFromStock } from '../helpers/db/removeProductsFromStock';
import { saveSaleStatistics } from '../helpers/db/saveSalesStatistics';
import { saveSellerStatistics } from '../helpers/sales/saveSellerStatistics';
import { createTicketData } from '../helpers/sales/createTicketData';

const selectedClientInitalState = { 
	id: '',
	name: '',
	discount: 0,
	selected: false,
    cp: '',
}

const ammountsInitialState = { 
    subtotal: 0,
    total: 0,
    iva: 0,
    discount: 0,
    discountPercentage: 0,
    change: 0,
}

export const usePurchase = ( purchaseType, creditPayment, closeModal ) => {

    const MySwal = withReactContent(Swal);

    const { cart, cartTotal, loadProducts, cleanCart } = useContext(ProductContext);
	const { getClients, showClients } = useClients();
	const { user } = useAuth();

	const [ moneyType, setMoneyType ] = useState('cash');
	const [ ammounts, setAmmounts ] = useState(ammountsInitialState);
    const [ folio, setFolio ] = useState('0000');

	const [ applyFrequentDiscount, setApplyFrequentDiscount ] = useState(false);
	const [ clients, setClients ] = useState([]);
	const [ frequentClient, setFrequentClient ] = useState(false);
	const [ selectedClient, setSelectedClient ] = useState(selectedClientInitalState);
    const [ ticketPaymentData, setTicketPaymentData ] = useState({});
    const [ payedAccount, setPayedAccount ] = useState( false );
    const [ paymentData, setPaymentData ] = useState({});

    let componentRef = useRef();

	useEffect(() => {
		getClients();
	}, []);

	useEffect(( ) => {
		let clientsTemp = [{ value: 'empty', label: 'Selecciona un cliente' }];
		showClients.forEach(client => clientsTemp.push({ value: client.id, label: client.name }) )
		setClients([...clientsTemp]);
	}, [ showClients ]);


	useEffect(() => {
		if(!frequentClient) {
			setSelectedClient(selectedClientInitalState);
			setApplyFrequentDiscount(false);
		}
	}, [ frequentClient ]);

    /*
     *  Function called on 'CobrarModal' when a client is not allowed to purchase credit.
     *  @param { function } closeModal - The function to close the modal.
     */
    const creditUnauthorizedMessage = ( closeModal ) => {
        MySwal.fire({
            title: <strong>Este cliente no está autorizado para comprar a crédito.</strong>,
            html: <i>Selecciona otro cliente o pídele a un Administrador que lo autorize en la pantalla Clientes.</i>,
            confirmButtonText: 'Entendido.',
            icon: 'error'
        }).then(response => {
            //console.log('No se pudo carnal');
            closeModal();
        });
    }

    const finishPurchase = () => {
        console.log('Terminar compra.')
        cleanCart().then(res => {
            closeModal();
        }).catch(e => console.error(new Error(e)))
    }

    const print = useReactToPrint({
		content: () => componentRef.current,
	})

	const handlePrint = () => {
        const ticketParams = {
            cart,
            paymentData,
            total: ammounts, 
            user,
        }
		createTicketData( ticketParams ).then(res => {
			setTicketPaymentData(res);
			print();	
		}).catch(e => console.error(new Error(e)));
	}

	const handleChangeCalculation = (e) => {
		const cashGiven = e.target.value;
		let change = cashGiven - ammounts.total;
		setAmmounts({
            ...ammounts,
            change: change,
        });
	}
    

    /*
     *  Search for the selected client and get its updated data.
     *  @param { event } e Event from ReactSelect Input, with ID as value.
     */
    const handleClientSelection = async ( e ) => {
        if(e.value !== 'empty' && e.value !== '' && e.value !== undefined) {
            await firebase.firestore().collection('clients').doc( e.value ).get().then(res => {
                if( res ) {
                    const { name, discount, creditAuthorized, email, rfc, phoneNumber, cp } = res;
                    setSelectedClient({
                        name: name,
                        discount: discount,
                        id: e.value,
                        selected: true,
                        creditAuthorized: (creditAuthorized !== undefined) ? creditAuthorized : true,
                        email: email, 
                        rfc: rfc,
                        phoneNumber: phoneNumber,
                        cp: cp,
                    })
                    setAmmounts({ ...ammounts, change: 0 })
                } else {
                    alert('Error al traer el cliente, confirma su existencia.');
                }
            })
        } else {
            setSelectedClient(selectedClientInitalState)
        }
    }
	
    const handleDiscountChange = ({ target: { value }}) => {
		setAmmounts({
            ...ammounts,
            discountPercentage: (value !== '') ? value : 0,
        });
	}

	/* 
     *  Resume payment data and register into the database.
     *  @param { string } docID Firestore document id to be deleted.
     */
	const setAsPayed = ( invoiceData, requiresInvoice) => {
        const buildParams = {
            cart,
            creditPayment,
            paymentType: moneyType,
            purchaseType,
            requiresInvoice,
            selectedClient,
            total: ammounts,
            user,
        }
        
        buildPaymentData( buildParams ).then(res => {
            const paymentDataRes = res;
            
            getPurchaseFolio().then( async folio => {
                setPaymentData({
                    ...res,
                    folio: folio + 1,
                });
                const newFolio = folio + 1;
                const purchaseID = String(new Date().getTime());
                
                paymentDataRes.folio = newFolio;
                invoiceData.folio = newFolio;
                invoiceData.date = new Date();
                invoiceData.invoiceFulfilled = false;
                invoiceData.purchaseID = purchaseID;
                invoiceData.total = paymentDataRes.total;

                await registerSaleDataBase( paymentDataRes, purchaseID );
                if( invoiceData.name !== '' && requiresInvoice ) {
                   await registerInvoice( invoiceData, purchaseID );
                }

                await firebase.firestore().collection('datos').doc('folio').update({ folio: newFolio })
                    .then(async () => {
                        await removeProductsFromStock( cart )
                            .then(() => {
                                setPayedAccount( true );
                            })
                            .catch(e => {
                                console.error(new Error(e));
                            })

                        if(paymentDataRes.creditPayed === true) {
                            console.log('Almacenando estadísticas..');
                            saveSaleStatistics( paymentDataRes.total, paymentDataRes.purchaseType, paymentDataRes.invoiceRequired, paymentDataRes.paymentType );
                            saveSellerStatistics( purchaseID, paymentDataRes.total, user.uid );
                        }
                    }).catch(err => console.error(err));
                
            }).catch(e => console.error(e));
        });
	}

    const stateUpdater = {
        buildPaymentData,
        // buildTicketData,
        creditUnauthorizedMessage,
        finishPurchase,
        handleChangeCalculation, 
        handleClientSelection, 
        handleDiscountChange, 
        handlePrint,
        saveSaleStatistics,
        saveSellerStatistics,
        setAmmounts,
        setApplyFrequentDiscount, 
        setAsPayed, 
        setFrequentClient,
        setMoneyType, 
        setTicketPaymentData,
    }

    const state = {
        ammounts, 
        applyFrequentDiscount, 
        clients, 
        componentRef,
        moneyType, 
        frequentClient, 
        selectedClient,
        ticketPaymentData,
        payedAccount,
    }

    return {
       state,
       stateUpdater
    }

}