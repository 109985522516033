import firebase from 'firebase/compat/app';

export const getCompletedSales = (year, month) => {
    return new Promise( async (resolve, reject) => {
        await firebase.firestore().collection('completedSaleStatistics')
            .doc(`${ year }-${ month }`)
            .get()
            .then( resolve )
            .catch( reject );
    })
}