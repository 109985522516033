import React from 'react'

export const PaymentActions = ({ cancelCart, cartTotal, handlePayment, paymentStarted }) => {
    return (
        <>
            <div className = ' d-flex w-100 mt-4 text-center px-3 '>
                <button 
                    className = ' block-button purple-button w-100 text-center mx-1 '
                    onClick = { handlePayment }
                    disabled = { paymentStarted }
                >
                    Cobrar
                </button>
                <button 
                    className = ' block-button cancel-button w-100 text-center mx-1 '
                    onClick = { cancelCart }
                >
                    Cancelar Venta
                </button>
            </div>
        </>
    )
}
