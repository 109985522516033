import React from 'react'
import { formatMoney } from '../../helpers/formatMoney'

export const CancelStats = ({ total }) => {
    return (
        <div className = ' mx-3 my-2 '>
			<div>
				<p className = ' text_data '>Total de cotizaciones</p>
				<p className = ' text_instructions '>Total Cotizado del Día { formatMoney( total ) }</p>
			</div>
		</div>
    )
}
