import React, { useState } from 'react';
import { adminLinks, almacenLinks, ventasLinks } from '../../../../database/navigationLinks';
import { useAuth } from '../../../../hooks/useAuth';
import { SideNavigationItem } from '../../SideNavigationItem/SideNavigationItem';

export const SideNavigationLinks = () => {

    const { userType } = useAuth();
    const [ active, setActive ] = useState('Dashboard');

    return (

        <div>
            {
                (userType === 'Administrador') && (
                    adminLinks.map(link => (
                        <SideNavigationItem 
                            key = { link.text }
                            active = { active }
                            icon = { link.icon }
                            link = { link.link }
                            setActive = { setActive }
                            text = { link.text }
                        />
                    ))
                ) 
            }
            {
                (userType === 'Ventas') && (
                    ventasLinks.map(link => (
                        <SideNavigationItem 
                            key = { link.text }
                            active = { active }
                            icon = { link.icon }
                            link = { link.link }
                            setActive = { setActive }
                            text = { link.text }
                        />
                    ))
                ) 
            }
            {
                (userType === 'Almacen') && (
                    almacenLinks.map(link => (
                        <SideNavigationItem 
                            key = { link.text }
                            active = { active }
                            icon = { link.icon }
                            link = { link.link }
                            setActive = { setActive }
                            text = { link.text }
                        />
                    ))
                ) 
            }
        </div>
    )

};
