import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { onAddNewSale, onSaleCanceled } from '../../store';

export const useSalesSlice = () => {

    const dispatch = useDispatch();

    const {  
        folio, sales, salesStats,
    } = useSelector( state => state.sales );

    const handleCancelSale = ( saleFolio ) => {
        dispatch(onSaleCanceled( saleFolio ));
    }

    const handleNewSale = ( sale ) => {
        console.log( sale );
        dispatch(onAddNewSale( sale ));
    }

    return {
        //* Properties.
        folio,
        sales, 
        salesStats,

        //* Methods.
        handleCancelSale,
        handleNewSale,
    }
}
