import { configureStore } from "@reduxjs/toolkit";
import { 
    clientsSlice, 
    invoicesSlice, 
    productsSlice, 
    salesSlice, 
    suppliersSlice, 
    usersSlice,
} from "./";

export const store = configureStore({
    reducer: {
        products: productsSlice.reducer,
        sales: salesSlice.reducer,
        suppliers: suppliersSlice.reducer,
        clients: clientsSlice.reducer,
        users: usersSlice.reducer,
        invoices: invoicesSlice.reducer,
    }
})