import React from 'react';

import logo from './logo.png';
import { SideNavigationLinks } from '../SideNavigationsLinks/SideNavigationItems.js/SideNavigationLinks';
import { SideNavigationUser } from '../SideNavigationUser/SideNavigationUser';

export const SideNavigation = () => {

    return(
        <div className = ' d-none d-lg-block '>
            <div className = ' sidenav_logo-container '>
                <img 
                    src = { logo }
                    className = ' sidenav_logo '
                    alt = 'Logotipo Refaccionaria Ogando'
                />
            </div>
            <div className = {` sidenav_container dark-background `}>
                <SideNavigationLinks />
            </div>
            <div className = {` sidenav_user-container `} >
                
                <SideNavigationUser />

            </div>
        </div>
    )

};
