import React, { useEffect } from 'react'
import Select from 'react-select';
import { formatDate } from '../../helpers/formatDate';
import { formatMoney } from '../../helpers/formatMoney';
import { useClientHistory } from '../../hooks/useClientHistory';

export const ClientPurchaseHistory = React.memo(({ userID }) => {

    const { state, clientStateUpdater } = useClientHistory();

    const { filter, filterOptions, isLoading, sales, seeMore } = state;
    const { filterSelection, handleFilterChange, getClientSales, setClientSales, getSeeMoreSales } = clientStateUpdater;

    useEffect(() => {
        let unsubscribe = true
        filterSelection().then(res => {
            getClientSales(userID, res.date, res.lastDate).then(response => {
                if(unsubscribe) {
                    setClientSales(response)
                }
            })
        })
        return () => unsubscribe = false
    }, [filter, userID]);
 
    return (
        <>
            <div className = ' pb-4 '> 
                <Select
                    defaultValue={filterOptions[0]}
                    onChange={handleFilterChange}
                    options={filterOptions}
                />
            </div>
            { (!isLoading && sales.length > 0) && (

                <div>
                    <table className = "table text-center ">
                        <thead>
                            <tr>
                            <th scope="col">Fecha</th>
                            <th scope="col">Folio</th>
                            <th scope="col">Total</th>
                            <th scope="col">Descuento</th>
                            <th scope="col">Productos</th>
                            <th scope="col">Factura</th>
                            <th scope="col">Tipo Compra</th>
                            <th scope="col">Método Pago</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                sales.map(sale => (
                                    <tr key = { sale.date }>
                                        <th scope="row">{ formatDate( sale.date ) }</th>
                                        <td scope="row">{ sale.folio }</td>
                                        <td>{ formatMoney( sale.total ) }</td>
                                        <td>{ formatMoney( sale.discount ) }</td>
                                        <td>{ sale.products }</td>
                                        <td>{ sale.invoice ? 'Sí' : 'No' }</td>
                                        <td>{ sale.purchaseType === 'cash' ? 'Contado' : 'Crédito' }</td>
                                        <td>{ sale.paymentType === 'card' ? 'Tarjeta' : 'Efectivo' }</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    {(sales.length > 0 && !!seeMore) && (
                        <p 
                            className = ' btn purple-button '
                            onClick = {() => { 
                                filterSelection().then(res => {
                                    getSeeMoreSales( userID, res.date, res.lastDate )
                                })
                            }}
                        >Cargar Más</p>
                    )}
                </div>

            )}
            {(!isLoading && sales.length <= 0) && (
                <div className = ' text-center '>
                    <p>No hay ventas registradas en este periodo.</p>
                </div>
            )}
            {(isLoading) && (
                <div className = ' text-center '>
                    <p>Cargando...</p>
                </div>
            )}
        </>
    )
})
