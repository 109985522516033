import React, { useState } from 'react';
import { useAuth } from '../../hooks/useAuth';
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { InputComponent } from '../InputComponent/InputComponent';

export const LoginForm = () => {

	const { login } = useAuth();

	const [ loginIn, setLoginIn ] = useState(false)
	const [ error, setError ] = useState(false)
	const [ errorMessage, setErrorMessage ] = useState('')

	const formik = useFormik({
		initialValues: {
			email: '',
			password: '',
		},
		validationSchema: Yup.object({
            email: Yup.string().required('El campo de correo eléctronico no debe estar vacío.').email('El correo ingresado no es válido'),
            password: Yup.string().required('La contraseña no debe estar vacía.'),
        }),
		onSubmit: async user => {
			try {
				setError(false);
				setLoginIn(true);
				await login();
			} catch (e) {
				setError(false)
				setLoginIn(false);
				if(e.code === 'auth/user-not-found'){
					setError(true);
					setErrorMessage('No existe usuario con el correo electrónico ingresado');
				} else if(e.code === 'auth/wrong-password'){
					setError(true);
					setErrorMessage('La contraseña ingresada no es correcta.');
				} else if(e.code === 'auth/too-many-requests'){
					setError(true);
					setErrorMessage('Se ha intentado ingresar demasiadas veces. Es necesario esperar un momento.')
				} else if(e.code === 'auth/network-request-failed'){
					setError(true);
					setErrorMessage('Ha ocurrido un error causado por la conexión de internet, vuelva a intentar después de checar la red.')
				}
			}
		}
	})

	return (
		<div>

			<form
				onSubmit = { formik.handleSubmit }
			>
				<div className = {` col-12 col-md-8 col-xl-5 `}>
					<InputComponent
						id = 'email'
						label = 'Correo Electrónico'
						placeholder = 'correo@email.com'
						values = { formik.values.email }
						onChange = { formik.handleChange }
						email = { true }
						disabled = { loginIn }
					/>

					<InputComponent
						id = 'password'
						label = 'Contraseña'
						placeholder = '********'
						values = { formik.values.password }
						onChange = { formik.handleChange }
						password = { true }
						disabled = { loginIn }
					/>

					{ (formik.dirty.email && formik.errors.email) || (formik.touched.password && formik.errors.password) ? (
						<div>
							<p className = {` error-message `}>{ formik.errors.email }</p>
							<p className = {` error-message `}>{ formik.errors.password }</p>
						</div>
					) : null }

					{ error ? (
						<div>
							<p className = {` error-message `}>{ errorMessage }</p>
						</div>
					) : null }

					<button
						className = {` login_button button purple-button shadow px-5 mb-4 `}
						type = 'submit'
						disabled = { loginIn }
					>
						Iniciar Sesión
					</button>
				</div>

			</form>
			 
		</div>
		
	)
}
