import React, { useContext, useEffect, useState } from 'react';

import { InventarioSearch } from '../../Components/InventarioComponents/InventarioSearch/InventarioSearch';
import { ProductContext } from '../../Context/ProductContext';
import { PaymentResumenHeader } from '../../Components/PaymentComponents/PaymentResumenHeader';
import { PaymentResumenOptions } from '../../Components/PaymentComponents/PaymentResumenOptions';
import { PaymentResumenProducts } from '../../Components/PaymentComponents/PaymentResumenProducts';
import { PaymentSearchHeader } from '../../Components/PaymentComponents/PaymentSearchHeader';
import { TicketPrint } from '../../Components/VentasComponents/TicketPrint/TicketPrint';
import { usePayment } from '../../hooks/usePayment';
import { PaymentProductsList } from '../../Components/PaymentComponents/PaymentProductsList';
import { PaymentNoProducts } from '../../Components/PaymentComponents/PaymentNoProducts';
import { PaymentActions } from '../../Components/PaymentComponents/PaymentActions';
import { PaymentConfirmation } from '../../Components/PaymentComponents/PaymentConfirmation';
import { ProductVoucher } from '../../Components/VentasComponents/ProductVoucherComponent/ProductVoucher';
import { QuoteVoucher } from '../../Components/VentasComponents/QuoteVoucher/QuoteVoucher';
import { Modal } from '../../Components/Modal/Modal';
import { RandomProductModal } from '../../Components/VentasComponents/RandomProductModal/RandomProductModal';

export const PaymentScreen = () => {

    const [ openRandomProductModal, setOpenRandomProductModal ] = useState( false );
    const { cart, cartTotal, cancelCart, showProducts } = useContext(ProductContext);
    const [ states, stateUpdaters ] = usePayment();
    const {
        applyClientDiscount, 
        clients, 
        componentRef,
        creditPayment, 
        purchaseType, 
        requiresInvoice,
        payedAccount,
        paymentStarted,
        paymentType,
        selectedClient, 
        ticketPaymentData,
        ticketType,
        total,
        quoteRef,
        voucherRef,
    } = states;
    const { 
        finishPurchase,
        handleClientChange, 
        handleCreditPaymentChange, 
        handleDiscountChange,
        handleInvoiceTypeChange,
        handlePayment,
        handlePaymentTypeChange,
        handlePurchaseTypeChange, 
        handlePrint,
        handleRequiresInvoiceChange,
        handleSellerChange,
        printQuote,
        printVoucher,
        setTicketType,
    } = stateUpdaters;

    const PaymentResumeProps = {
        applyClientDiscount,
        clients,
        creditPayment,
        handleClientChange,
        handleCreditPaymentChange,
        handleDiscountChange,
        handleInvoiceTypeChange,
        handlePaymentTypeChange,
        handlePurchaseTypeChange, 
        handleRequiresInvoiceChange,
        handleSellerChange,
        paymentType,
        purchaseType, 
        requiresInvoice,
        selectedClient,
        total,
    }

    return (
        <div className=' px-0 mx-0 '>
            <div className=' row mx-0'>
                <div className=' col-12 col-md-8 px-4 screens_section '>
                    <div>
                        <PaymentSearchHeader setOpenRandomProductModal = { setOpenRandomProductModal } />
                    </div>
                    <div>
                        <InventarioSearch />
                    </div>

                    <div>
                        { showProducts.length > 0 && ( <PaymentProductsList /> )}
                        { showProducts.length === 0 && ( <PaymentNoProducts /> )}
                    </div>

                </div>

                <div className = ' col-12 col-md-3 screens_section background-white payment_note-container '>
                    { !payedAccount && cartTotal.contado > 0 && (
                        <>
                            <div className = ' px-4 '> 
                                <PaymentResumenHeader 
                                    printQuote = { printQuote }
                                    printVoucher = { printVoucher }
                                />
                            </div>
                            <div className = ' px-4 '>
                                <PaymentResumenOptions
                                    PaymentResumeProps = { PaymentResumeProps }
                                />
                            </div>
                            <div>
                                <PaymentResumenProducts PaymentResumeProps = { PaymentResumeProps } />
                            </div>
                            <div>
                                <PaymentActions  
                                    cancelCart = { cancelCart }
                                    cartTotal = { cartTotal }
                                    handlePayment = { handlePayment }
                                    paymentStarted = { paymentStarted }
                                />
                            </div>
                        </>
                    )}
                    { !payedAccount && cartTotal.contado <= 0 && (
                        <div className = ' d-flex align-center-center justify-content-center w-100 h-100 p-5 '>
                            <p className = ' text-center '>Selecciona un producto para comenzar con la venta.</p>
                        </div>
                    )}
                    { payedAccount && (
                        <PaymentConfirmation 
                            finishPurchase = { finishPurchase }
                            handlePrint = { handlePrint }
                            purchaseType = { purchaseType }
                            setTicketType = { setTicketType }
                        />
                    )}
                </div>
            </div>

            <div style = {{ display: 'none' }}>
                <TicketPrint 
                    ref = { componentRef } 
                    { ...ticketPaymentData }
                    ticketType = { ticketType }
                    pagareName = { selectedClient.name }
                />
            </div>
            <div style = {{ display: 'none' }}>
                <ProductVoucher 
                    ref = { voucherRef } 
                />
            </div>
            <div style = {{ display: 'none' }}>
                <QuoteVoucher
                    ref = { quoteRef }
                    products = { cart }
                /> *
            </div>

            { !!openRandomProductModal && (
                <Modal>
                    <RandomProductModal
                        closeModal = { () => setOpenRandomProductModal(false) }
                    />
                </Modal>
            )}
        </div>
    )

}
