/* 
    Function that converts the screen data to database ready data.
    @param { object } params. Object with necesary data.
*/
export const buildPaymentData = ( params ) => {

    const {
        cart,
        creditPayment,
        paymentType,
        purchaseType,
        requiresInvoice,
        selectedClient,
        total,
        user,
        seller,
    } = params;

    return new Promise((resolve, reject) => {
        const cartProducts = cart.map(product => {
            const productData = { 
                amount: product.amount,
                code: product.code,
                description: product.description,
                discount: product.discount,
                family: product.family,
                id: product.id,
                name: product.name,
                price_cash: product.price_cash,
                price_credit: product.price_credit,
            }
            return { product: product.id, productData: productData, quantity: product.amount }
        });

        const paymentData = {
            canceled: false,
            client: selectedClient.name || 'Cliente no encontrado',
            clientDiscount: (selectedClient.id !== 'public') ? selectedClient.discount : 0,
            completed: true,
            creditPayment: creditPayment,
            creditPayed: purchaseType === 'credit' ? false : true,
            date: new Date(),
            discount: total.discount,
            discountApplied: total.discount === 0 ? false : true,
            extraDiscount: total.discountPercentage,
            invoiceRequired: requiresInvoice,
            paymentType: paymentType,
            products: cartProducts,
            purchaseType: purchaseType,
            total: total.total,
            user: seller || user.name,
        }

        resolve(paymentData);
    })
}