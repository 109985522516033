import React from 'react'
import { UnselectedItemPlaceholder } from '../UnselectedItemPlaceholder/UnselectedItemPlaceholder'
import { UploadImageComponent } from '../UploadImageComponent/UploadImageComponent'
import { UserDirectoryEdit } from './UserDirectoryEdit'
import { UserSalesHistory } from './UserSalesHistory'

export const UserDirectoryDetail = ({ editable, editForm, user, setEditable, uploadImage, removeUser, setUploadImage }) => {

    React.useEffect(() => {
        const tabElement = document.getElementById('userInfo-tab');
        if( tabElement ) tabElement.click();
    }, [ user ]);

    return (
        <>
            { user !== '' && (
                <div className = ' detail_bordered-container '>
                    <>
                        <div className = {` detail_branding-container `}>
                            { editable ? 
                                <UploadImageComponent 
                                    defaultImg = { user.image } 
                                    userType = { 'proveedor' }
                                    uploadImage = { uploadImage } 
                                    setUploadImage = { setUploadImage } 
                                />
                            :
                                <img
                                    className = {` detail_branding `}
                                    src = { user.image }
                                    alt = 'directorio'
                                />
                            }
                        </div>
                        <div className = {` container px-3 pt-5 pb-1 `}>
                            <div className = ' d-flex align-items-center '>
                                <p className = ' flex-grow-1 '>{ user.name }</p>
                                <p className='button purple-button' onClick = {() => setEditable(!editable)}>
                                    { (editable) ? 'Cancelar' : 'Editar' }
                                </p>
                            </div>
                        </div>
                    </>
                    <hr className = ' ui_grey-separator ' />

                    <div className = ' px-3 row mx-0 '>
                        { !editable && (
                            <>
                                <ul className = "nav nav-tabs mb-4" id="userTab" role="tablist">
                                    <li className = "nav-item" role="presentation">
                                        <button 
                                            className = "nav-link active" 
                                            id="userInfo-tab" 
                                            data-bs-toggle="tab"
                                            data-bs-target="#userInfo" 
                                            type="button" role="tab" aria-controls="userInfo" 
                                            aria-selected="true"
                                        >
                                            Informacion
                                        </button>
                                    </li>
                                    { (user.position === 'Administrador' || user.position === 'Ventas') && (
                                    <li className = "nav-item" role="presentation">
                                        <button 
                                            className = "nav-link" 
                                            id="salesHistory-tab" 
                                            data-bs-toggle="tab" 
                                            data-bs-target="#salesHistory" 
                                            type="button" role="tab" aria-controls="salesHistory" aria-selected="false"
                                        >
                                            Historial de Ventas
                                        </button>
                                    </li>
                                    )}
                                </ul>

                                <div className = "tab-content " id="userTabContent">
                                    <div 
                                        className = "tab-pane fade show active " 
                                        id="userInfo" role="tabpanel" aria-labelledby="userInfo-tab"
                                    >
                                        <div className = 'row'>
                                            <div className = {` col-lg-6 mb-4 `}>
                                                <p className = {` text_bold-label `}>Puesto</p>
                                                <p className = {` text_data `}>{ user.position }</p>
                                            </div>
                                            <div className = {` col-lg-6 mb-4 `}>
                                                <p className = {` text_bold-label `}>Correo Electrónico</p>
                                                <p className = {` text_data `}>{ user.email }</p>
                                            </div>
                                            <div className = {` col-lg-6 mb-4 `}>
                                                <p className = {` text_bold-label `}>Teléfono</p>
                                                <p className = {` text_data `}>{ user.phoneNumber }</p>
                                            </div>
                                            <div className = {` col-lg-6 mb-4 `}>
                                                <p className = {` text_bold-label `}>Dirección</p>
                                                <p className = {` text_data `}>{ user.address }</p>
                                            </div>
                                        </div>
                                    </div>
                                    { (user.position === 'Administrador' || user.position === 'Ventas') && (
                                        <div
                                            className = " tab-pane fade mb-4 "
                                            id="salesHistory"
                                            role="tabpanel"
                                            aria-labelledby="salesHistory-tab"
                                        >
                                            <UserSalesHistory userID = { user.id } />
                                        </div>
                                    ) }
                                </div>
                            </>
                        )}
                        { !!editable && (
                            <UserDirectoryEdit 
                                user = { user } 
                                editUser = { editForm } 
                                removeUser = { removeUser } 
                                uploadImage = { uploadImage } 
                            />
                        ) }
                    </div>
                </div>
            )}
            { user === '' && (
                <UnselectedItemPlaceholder />
            ) }
        </>
    )
}
