import React from 'react'

export const PaymentSearchHeader = ({ setOpenRandomProductModal }) => {

    return (
        <div className = ' py-4 '>

            <div className=' row ps-sm-3 pe-sm-1 ps-2 d-flex align-items-center '>

                <div className=' header_title col-12 col-sm-6'>
                    <h2 className=' section-title mb-0 '>Ventas</h2>
                    <p className=' header_title-description '>Cotiza y realiza ventas de tus productos.</p>
                </div>

                <div className = ' col-12 col-sm-6 d-flex justify-content-end pe-2 '>
                    <div 
                        className=' button secondary-button '
                        onClick = { () => setOpenRandomProductModal( true ) }
                    >
                        PEDIDO ESPECIAL
                    </div>
                </div>

            </div>
        </div>
    )
}
