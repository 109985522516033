import React, { useContext } from 'react';
import { ProductContext } from '../../../Context/ProductContext';
import { formatDate } from '../../../helpers/formatDate';
import { formatMoney } from '../../../helpers/formatMoney';
import { useAuth } from '../../../hooks/useAuth';

import logoOgando from './logoOgando.png';
import './QuoteVoucher.css';

export const QuoteVoucher = React.forwardRef(( props, ref ) => {

    const { cart, cartTotal } = useContext( ProductContext );

    const {
        products,
    } = props;

    return (
        <div ref = { ref } className = 'TicketPrint-ticket-content'>
            <div className = "TicketPrint-container">
        
                <div id = "TicketPrint-ticket">
                    <div className = ' row mb-2 mx-auto '>
                        <div className = ' col-8 offset-2 '>
                            <img className = ' TicketPrint-logo ' src = { logoOgando } />
                        </div>
                    </div>
            
                    <div className = 'TicketPrint-datos-fiscales'>
                        <p>BRENDA OGANDO TALAMANTES</p>
                        <p>PROFR. EVERARDO GAMIZ #311</p>
                        <p>Tel/Fax: 818-3636 Y 818-3225</p>
                        <p>BRENDA OGANDO</p>
                        <p>R.F.C. OATB740531VAA</p>
                        <p>DURANGO, DGO A { `${ new Date().getDate() }/${ Number(new Date().getMonth()) + 1 }/${ new Date().getFullYear() }` }</p>
                    </div>
            
                    <div className = 'TicketPrint-nota-venta-container'>
                        <p className = "TicketPrint-nota-venta-titulo">C O T I Z A C I Ó N</p>
                        <div>
                            <table className = 'TicketPrint-nota-venta-table'>
                                <thead>
                                    <tr>
                                        <th>Cant.</th>
                                        <th>Producto</th>
                                        <th>Precio Unit.</th>
                                        <th>Importe</th>
                                    </tr>
                                </thead>
                            </table>  
                        </div>
                        <div>
                            { cart.map((product, i) => (
                                <div key = { i }>
                                    { product.amount > 0 && ( 
                                        <>
                                            <table className = 'TicketPrint-nota-venta-table'>
                                                <tbody>
                                                    <tr>
                                                        <td>{ product.amount }</td>
                                                        <td>{ product.code }</td>
                                                        <td>{ formatMoney( product.price_cash ) }</td>
                                                        <td>{ formatMoney( Number( product.price_cash * product.amount ) )}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <p className = ' TicketPrint-product-name '>{ product.name }</p>    
                                        </>
                                    )}
                                </div>
                            )) }
                        </div>
                        
                        <hr className = 'TicketPrint-separator' />
            
                        <div className = 'TicketPrint-nota-total-container'>
                            <p className = ' TicketPrint-nota-cantidad-articulos '>
                                { products.length } { products.length === 1 ? 'Artículo' : 'Artículos'}
                            </p>
                            <div className = ' TicketPrint-nota-total-precio '>
                                <p>TOTAL  </p>
                                <p>{  formatMoney( cartTotal.contado )}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

})