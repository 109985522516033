import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../hooks/useAuth';

import logo from './logo-v2.png';

export const SideNavigationUser = () => {

    const { user, userInfo, signout } = useAuth();

    return (
        <div className = 'sidenav_user-container pb-3 pt-1 '>
            <div className = 'sidenav_profile-container '>
                <div className = 'sidenav_profile-icon-container' >
                    <img className = ' sidenav_user-photo ' src = { logo } alt = 'profile'/>
                </div>
                <div className = 'sidenav_data-container'>
                    <p className='sidenav_user-name'>{ userInfo.name }</p>
                    <Link className='sidenav_go-to' to = "/perfil">Ver Perfil</Link>
                </div>
            </div>
            <div className = "sidenav_logout" onClick = { signout }>
                <ion-icon name = "log-out-outline"></ion-icon>
            </div>
        </div>
    );

};
