import React from 'react';
import PropTypes from 'prop-types';

export const DirectoryHeader = ({ actions = [], input, content }) => {

    return (
        <div className = ' directory_header-container mb-4 d-flex flex-column px-3 mx-0 '>
            <div className = ' d-flex align-items-center w-100 '>
                <div className = ' directory_header-title '>
                    <h2 className = ' section-title '>{ content.title }</h2>
                    <p className = ' title-description '>{ content.subtitle }</p>
                </div>

                <div className = ' directory_header-cta '>
                    { actions.map((action, index) => (
                        <div 
                            className = ' header-button me-2 '
                            onClick = { action.action }
                            key = { index }
                        >
                            <ion-icon name = { action.icon }></ion-icon>
                        </div>
                    )) }
                </div>
            </div>
            <div className = ' mt-3 w-100 '>
                { input.show && (
                    <input 
                        className = 'search-bar'
                        onChange = { input.action }
                        placeholder = { input.placeholder }
                    />
                )}
            </div>
        </div>
    );

}

DirectoryHeader.propTypes = {
    actions: PropTypes.arrayOf(PropTypes.object).isRequired, // [{ function: () => {}, icon: '' }]
    input: PropTypes.object.isRequired, // show, placeholder, action
    content: PropTypes.object.isRequired, // { title: '', subtitle: '' }
}