const image = 'https://firebasestorage.googleapis.com/v0/b/testingrefaccionariaogando.appspot.com/o/proveedores%2FZ01Cp1cOiQuS0MgMnCS3%2FSupplierPicture?alt=media&token=8b76e29e-cbb8-4a0d-9955-f09df82cc6ae';

export const suppliersDB = [
    {
        id: 1,
        address: 'Dirección de proveedor',
        contactName: 'Contacto de proveedor',
        email: 'proveedor@avluetsoftware.com',
        image: image,
        name: 'Proveedor Genérico',
        phoneNumber: '6181021239',
    },
];