import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { adminLinks, almacenLinks, ventasLinks } from '../../../database/navigationLinks';
import { useAuth } from '../../../hooks/useAuth';

export const MobileNavigationLinks = () => {

    const { userType } = useAuth();
    const [active, setActive] = useState(0);

    return (

        <div>
            {
                (userType === 'Administrador') && (
                    adminLinks.map((link, index)=> (
                        <li 
                            className = {` nav-item  `}
                            key = { index }
                            data-toggle="collapse" 
                            data-target="#navbarNavAltMarkup"
                        >
                            <Link 
                                to = { link.link }
                                className = {` nav-link ${ (index === active) ? 'text-primary' : '' } `}
                                onClick = { () => setActive(index) }
                            >
                                { link.text }
                            </Link>
                        </li>
                    ))
                ) 
            }
            {
                (userType === 'Ventas') && (
                    ventasLinks.map((link, index)=> (
                        <li 
                            className = {` nav-item  `}
                            key = { index }
                        >
                            <Link 
                                to = { link.link }
                                className = {` nav-link ${ (index === active) ? 'text-primary' : '' } `}
                                onClick = { () => setActive(index) }
                            >
                                { link.text }
                            </Link>
                        </li>
                    ))
                ) 
            }
            {
                (userType === 'Almacen') && (
                    almacenLinks.map((link, index) => (
                        <li 
                            className = {` nav-item  `}
                            key = { index }
                        >
                            <Link 
                                to = { link.link }
                                className = {` nav-link ${ (index === active) ? 'text-primary' : '' } `}
                                onClick = { () => setActive(index) }
                            >
                                { link.text }
                            </Link>
                        </li>
                    ))
                ) 
            }
        </div>
    )

}
