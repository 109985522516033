
export const demoSalesStats = {
    data: [
        { 
            day: 1,
            sales: 10,
            salesCash: 1345,
            salesCredit: 394,
            salesInvoiceCard: 100,
            salesInvoiceCash: 293,
            salesNoInvoiceCash: 1100,
            salesNoInvoiceCard: 294,
            total: 16959,
            totalCash: 1000,
            totalCredit: 10390,
        },
        { 
            day: 2,
            sales: 18,
            salesCash: 4355,
            salesCredit: 3943,
            salesInvoiceCard: 100,
            salesInvoiceCash: 293,
            salesNoInvoiceCash: 1100,
            salesNoInvoiceCard: 294,
            total: 18959,
            totalCash: 1000,
            totalCredit: 10390,
        },
        { 
            day: 3,
            sales: 18,
            salesCash: 4355,
            salesCredit: 3943,
            salesInvoiceCard: 100,
            salesInvoiceCash: 293,
            salesNoInvoiceCash: 1100,
            salesNoInvoiceCard: 294,
            total: 23278,
            totalCash: 1000,
            totalCredit: 10390,
        },
        { 
            day: 4,
            sales: 18,
            salesCash: 4355,
            salesCredit: 3943,
            salesInvoiceCard: 100,
            salesInvoiceCash: 293,
            salesNoInvoiceCash: 1100,
            salesNoInvoiceCard: 294,
            total: 9278,
            totalCash: 1000,
            totalCredit: 10390,
        },
        { 
            day: 5,
            sales: 18,
            salesCash: 4355,
            salesCredit: 3943,
            salesInvoiceCard: 100,
            salesInvoiceCash: 293,
            salesNoInvoiceCash: 1100,
            salesNoInvoiceCard: 294,
            total: 11578,
            totalCash: 1000,
            totalCredit: 10390,
        },
        { 
            day: 6,
            sales: 18,
            salesCash: 4355,
            salesCredit: 3943,
            salesInvoiceCard: 100,
            salesInvoiceCash: 293,
            salesNoInvoiceCash: 1100,
            salesNoInvoiceCard: 294,
            total: 18578,
            totalCash: 1000,
            totalCredit: 10390,
        },
        { 
            day: 6,
            sales: 18,
            salesCash: 11,
            salesCredit: 7,
            salesInvoiceCard: 100,
            salesInvoiceCash: 293,
            salesNoInvoiceCash: 1100,
            salesNoInvoiceCard: 294,
            total: 11390,
            totalCash: 10390,
            totalCredit: 1000,
        },
    ]
}