

export const generateSelectableMonths = ( selectedYear = 0 ) => {

    // Year Generation.
    const currentYear = new Date().getFullYear();
    const years = [...Array(5)].map((y, index) => currentYear - index);

    // Month Generation.
    const currentMonth = new Date().getMonth();

    let months = [];
    if( selectedYear === currentYear || selectedYear === 0 ) {
        switch ( currentMonth ) {
            case 0: 
                months = ['Enero'];                
                break; 
            case 1: 
                months = ['Enero'];                
                break; 
            case 2: 
                months = ['Enero', 'Febrero', 'Marzo'];                
                break; 
            case 3: 
                months = ['Enero', 'Febrero', 'Marzo', 'Abril'];                
                break; 
            case 4: 
                months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo'];                
                break; 
            case 5: 
                months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio'];                
                break; 
            case 6: 
                months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio'];                
                break; 
            case 7: 
                months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto'];                
                break; 
            case 8: 
                months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre'];                
                break; 
            case 9: 
                months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre'];                
                break; 
            case 10: 
                months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre'];                
                break; 
            case 11: 
                months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];                
                break; 
        }
    } else {
        months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    }

    return {
        years: years,
        months: months,
    }  

}