import React from 'react'

export const ProfileStatistics = () => {
	return (
		<div className = ' profile_statistics '>
			<div className = ' profile_statistics-header '>
				<p className = ' h5 '>Estadísticas</p>
			</div>
		</div>
	)
}
