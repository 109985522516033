import React, { useContext, useEffect, useState } from 'react'
import firebase from 'firebase/compat/app';
import { InputComponent } from '../InputComponent/InputComponent'
import { ProductContext } from '../../Context/ProductContext';
import { AlertSuccess } from '../AlertsComponents/AlertSuccess';

const newModelInitialState = {
    model: '', 
    fromYear: '', 
    toYear: '', 
    engines: [],
}

const currentEngineDataInitalState = { engine: '', fromYear: '', toYear: ''}

export const CarNewModel = ({ brandIndex, closeModal }) => {

    const { cars } = useContext(ProductContext);

    const [ newModel, setNewModel ] = useState(newModelInitialState);
    const [ currentEngineData, setCurrentEngineData ] = useState(currentEngineDataInitalState);
    const [ engines, setEngines ] = useState([]);

    useEffect(( ) => {
        setCurrentEngineData( currentEngineDataInitalState );
    }, [ engines ]);

    const handleModelChange = ( { target: { value }}, field ) => {
        setNewModel({
            ...newModel,
            [field]: value,
        })
    }
    
    const handleEngineChange = ( { target: { value }}, field) => {
        setCurrentEngineData({
            ...currentEngineData,
            [field]: value,
        })
    }

    const addEngine = () => {
        if( currentEngineData.toYear < currentEngineData.fromYear ) {
            alert('El año máximo no puede ser menor al mínimo.');
        } else if ( currentEngineData.engine === '' || currentEngineData.fromYear === '' || currentEngineData.toYear === '' ) {
            alert('Ningún campo puede quedar vacío.');
        } else {
            setEngines(state => [ ...state, currentEngineData ]);
        }
    }

    const addModel = () => {
        if( newModel.model === '' || newModel.fromYear === '' || newModel.toYear === '') {
            alert('Los campos del modelo no pueden estar vacíos.');
        } else if( newModel.toYear < newModel.fromYear ) {
            alert('El año máximo no puede ser menor que el mínimo.')
        } else {
            const modelData = {
                model: newModel.model,
                fromYear: newModel.fromYear,
                toYear: newModel.toYear,
                engines: engines,
            }
            console.log(modelData);
            let brandModels = [...cars[brandIndex].models];
            brandModels.push(modelData);
            brandModels.sort((a, b) => (a.model > b.model) ? 1 : -1);
            console.log(brandModels);
            cars[brandIndex].models = brandModels;
            firebase.firestore().collection('cars').doc('cars').set({ 
                models: [...cars],
            }).then(() => {
                AlertSuccess('Modelo Agregado', 'El modelo se ha agregado exitosamente');
                closeModal();
            }).catch(e => {
                console.log(e);
                alert('Ha habido un error agregando este modelo, intentalo de nuevo!');
            })
            
            // const brandData = cars.filter(brand => brand.brandID === brandID);
            // let brandModels = ( brandData.length > 0) ? brandData[0].models : [];
            // brandModels.push(modelData);
        }
    }

    const deleteEngine = ( index ) => {
        const currentEngines = [...engines];
        currentEngines.splice(index, 1);
        setEngines([...currentEngines]);
    }
  
    return (
        <div className={` modal NuevoProductoModal-container `}>
            <div className={` modal-content `}>

                <div className={` modal-header `}>
                    <p className='section-title'>Registrar un nuevo modelo.</p>
                </div>
                <div className={` modal-body `}>
                    <div className = ' row mx-0'>
                        <div className = ' col-6 pe-2 '>
                            <InputComponent 
                                label = 'Nombre del modelo'
                                placeholder = 'Ingresa el nombre del modelo...'
                                onChange = { e =>  handleModelChange(e, 'model') }
                            />
                        </div>
                        <div className = ' col-3 pe-2 '>
                            <InputComponent 
                                label = 'Año Mínimo'
                                placeholder = 'Año Mínimo'
                                number = { true }
                                onChange = { e =>  handleModelChange(e, 'fromYear') }
                            />
                        </div>
                        <div className = ' col-3 '>
                            <InputComponent 
                                label = 'Año Máximo'
                                placeholder = 'Año Máximo'
                                number = { true }
                                onChange = { e =>  handleModelChange(e, 'toYear') }
                            />
                        </div>
                    </div>
                    <div>
                        <div className = ' row mx-0 '>
                            <div className = ' col-3 pe-2 '>
                                <p>Nombre del motor</p>
                            </div>
                            <div className = ' col-3 pe-2 '>
                            <p>año desde</p>
                            </div>
                            <div className = ' col-3 pe-2 '>
                                <p>año hasta</p>
                            </div>
                        </div>
                        <div className = ' row mx-0 mt-2 '>
                            <div className = ' col-3 pe-2 '>
                                <input
                                    className = ' form-control  '
                                    placeholder = 'Nombre del motor'  
                                    onChange = { e => handleEngineChange(e, 'engine') }
                                    value = { currentEngineData.engine }
                                />
                            </div>
                            <div className = ' col-3 pe-2 '>
                                <input
                                    className = ' form-control  '
                                    placeholder = 'Año desde'  
                                    onChange = { e => handleEngineChange(e, 'fromYear') } 
                                    value = { currentEngineData.fromYear }   
                                />
                            </div>
                            <div className = ' col-3 pe-2 '>
                                <input
                                    className = ' form-control  '
                                    placeholder = 'Año hasta'  
                                    onChange = { e => handleEngineChange(e, 'toYear') }   
                                    value = { currentEngineData.toYear } 
                                />
                            </div>
                            <div className = ' col-3 pe-2 '>
                                <div className = ' button purple-button ' onClick = { addEngine } >Agregar Motor</div>
                            </div>
                        </div>
                    </div>
                    <div className = ' my-4 '>
                        <h6 className = ' mb-3 '>Motores añadidos <span className = ' button purple-button '>{ engines.length }</span></h6>
                        { engines.length > 0 && 
                            engines.map((engine, index) => (
                                <div className = ' row mx-0 p-2 px-4 bg-white rounded mb-2 align-items-center ' key = { index } >
                                    <div className = ' col-3 pe-2 '>
                                        <p className = ' button purple-button mb-0 '>{ engine.engine }</p>
                                    </div>
                                    <div className = ' col-3 pe-2 '>
                                        <p className = ' mb-0 '>Desde { engine.fromYear }</p>
                                    </div>
                                    <div className = ' col-3 pe-2 '>
                                        <p className = ' mb-0 '>Hasta { engine.toYear }</p>
                                    </div>
                                    <div className = ' col-3 pe-2 d-flex justify-content-end '>
                                        <ion-icon 
                                            onClick={ () => deleteEngine(index) }
                                            name = "close-circle-outline"
                                        ></ion-icon>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className={` modal-footer `}>
                    <button
                        type='reset'
                        className={` button cancel-button px-5 `}
                        onClick = { closeModal }
                    >
                        Cancelar
                    </button>
                    <button
                        className={` button purple-button shadow px-5 ms-3 `}
                        onClick = { addModel }
                    >
                        Agregar
                    </button>
                </div>
            </div>
        </div>
    )

}
