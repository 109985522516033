import { useEffect, useState } from 'react';
import { updateSaleState } from '../helpers/cancel/updateSaleState';
import { getSalesQuotes } from '../helpers/cancel/getSalesQuotes';
import { getQuotationsTotal } from '../helpers/cancel/getQuotationsTotal';
import { getCompletedSales } from '../helpers/cancel/getCompletedSales';

export const useCancelPurchase = () => {

    const year = new Date().getFullYear();
    const month = Number(new Date().getMonth()) + 1;
    const day = Number(new Date().getDate());

    const [ availableDays, setAvailableDays ] = useState( [...Array.from({length: day}, (_, i) => i + 1)] );

    const [ sales, setSales ] = useState([]);
    const [ selectedSale, setSelectedSale ] = useState('');
    const [ total, setTotal ] = useState( 0 );
    const [ date, setDate ] = useState({
        year: year, month: month, day: day
    });

    useEffect(() => getQuotationTotal(), []);
    
    useEffect(() => {
        getSales();
        generateAvailableDays( date.year, date.month );
        getQuotationTotal();
    }, [ date ]);

    const generateAvailableDays = ( year, month ) => {
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth();
        if( currentYear === year && currentMonth + 1 === month ) {
            const avDays = [...Array.from( { length: day }, (_, i) => i + 1 )];
            setAvailableDays( avDays );
        } else {
            const days =  new Date(year, month, 0).getDate(); 
            const avDays = [...Array.from( { length: days }, (_, i) => i + 1 )];
            setAvailableDays( avDays );
        }
    }
    
    const getSales = async () => {
        const dateFrom = new Date(date.year, date.month - 1, date.day);
        const dateTo = new Date(dateFrom);
        await getSalesQuotes( dateFrom, dateTo )
            .then(response => {
                let salesArray = [];
                setSales([]);
                response.docs.forEach(sale => {
                    const saleData = { 
                        data: sale, 
                        completed: ( sale.data().completed !== undefined) ? sale.data().completed : true 
                    }
                    setSales(state => [...state, saleData]);
                })
            })
            .catch(e => console.error(new Error(e)))
    }

    const generateNewStats = ( daySales, checked, saleTotal ) => {

        let { sales, salesCash, salesCredit, total, totalCash, totalCredit,
            salesNoInvoiceCash, salesNoInvoiceCard, salesInvoiceCash, salesInvoiceCard 
        } = daySales[0];

        if( !checked ) {
            return { 
                day: day, 
                sales: Number( sales + 1 ), 
                salesCash: Number( salesCash + 1 ), 
                salesCredit: salesCredit, 
                total: Number(total + saleTotal),
                totalCash: Number( totalCash + saleTotal ),
                totalCredit: totalCredit, 
                salesNoInvoiceCash: salesNoInvoiceCash + saleTotal,
                salesNoInvoiceCard: salesNoInvoiceCard,
                salesInvoiceCash: salesInvoiceCash,
                salesInvoiceCard: salesInvoiceCard,
            }
        } else {
            return { 
                day: day, 
                sales: Number( sales - 1), 
                salesCash: Number( salesCash - 1 ), 
                salesCredit: salesCredit, 
                total: Number(total - saleTotal ),
                totalCash: Number( totalCash - saleTotal ),
                totalCredit: totalCredit, 
                salesNoInvoiceCash: salesNoInvoiceCash - saleTotal,
                salesNoInvoiceCard: salesNoInvoiceCard,
                salesInvoiceCash: salesInvoiceCash,
                salesInvoiceCard: salesInvoiceCard,
            }
        }

    }

    const getQuotationTotal = async () => {
        await getQuotationsTotal(year, month)
            .then(res => {
                const data = res.data().data;
                const findDay = data.find(data => data.day === date.day);
                if( findDay !== undefined ) {
                    setTotal( findDay.total );
                }
            })
    }
    
    const handleSaleSelection = (sale, state) => {
        const newState = state.target.checked;
        const { date } = sale.data.data();
        const formatDate = new Date( date.seconds * 1000 );
        const year = formatDate.getFullYear();
        const month = Number(formatDate.getMonth()) + 1;
        const day = formatDate.getDate();

        updateSaleState( sale.data.id, !newState )
            .then(async () => {
                await getSales();
                await getCompletedSales()
                    .then(res => {
                        if( res.get ) {
                            let monthSales = [...res.data().data];
                            let daySales = monthSales.filter(sale => sale.day === day);
                            let daySalesIndex = monthSales.findIndex(sale => sale.day === day);
                            if( daySales.length > 0) {
                                const { total: saleTotal } = sale.data.data();
                                monthSales[daySalesIndex] = generateNewStats( daySales, newState, saleTotal );
                                res.ref.update({ data: [...monthSales] })
                                    .then(() => {
                                    })
                                    .catch(err => console.error(new Error(err)));
                        } else {
                            alert('Hubo un error intentando realizar esta acción.')
                        }
                    }
                })
            })
            .catch(e => console.error(new Error(e)));
    }

    const states = {
        availableDays,
        date,
        sales,
        selectedSale,
        total,
    }

    const stateUpdaters = {
        generateAvailableDays,
        getSales,
        handleSaleSelection,
        setDate,
        setSelectedSale,
    }

    return {
        states,
        stateUpdaters
    }

}
