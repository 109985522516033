import React from 'react';

export const StatsItem = ({ data, title }) => {

    return (
        <div className = ' stats_item-main '>
            <div className = ' stats_item-container '>
                <div className = ' stats_icon_container '>
                    <ion-icon name = "card-outline"></ion-icon>
                </div>
                <div className = ' stats_info-container '>
                    <p className = ' text_instructions mb-0 '>{ title }</p>
                    <p className = ' text_data text_bold '>{ data }</p>
                </div>
            </div>
            <div className = ' stats_item-border '>
            </div>
        </div>
    );

};
