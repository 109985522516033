import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../hooks/useAuth';

export const ProfileEditableContainer = ({ profileEdit, uploadImage }) => {

	const { user, userInfo } = useAuth();

	const [ editableUser, setEditableUser ] = useState({
        profileAddress: userInfo.address,
        profilePhoneNumber: userInfo.phoneNumber,
    })

	const { profileAddress, profilePhoneNumber, }  = editableUser; 

	useEffect(() => {
		setDefaultFormikValues();
	}, [])
	
	const setDefaultFormikValues = () => {
        profileEdit.setFieldValue(`profileAddress`, profileAddress);
        profileEdit.setFieldValue(`profilePhoneNumber`, profilePhoneNumber);
        profileEdit.setFieldValue(`docID`, user.uid);
		profileEdit.setFieldValue(`profileImage`, uploadImage )
    }

	const handleInputChange = ( e, state ) => {
        setEditableUser({
            ...editableUser,
            [state]: e.target.value,
        })
        profileEdit.setFieldValue(`${state}`, e.target.value);
    }


	return (
		<div className = {` profile_info col `}>
			<div className = {` profile_info-header `}>
				<p className = ' h5 '>Mi Información</p>
				<p>Editando información de la cuenta...</p>
			</div>

			<form
				onSubmit = { profileEdit.handleSubmit }
			>

				<div className = ' profile_info-data '>
					<div className = ' col '>
						<div className = ' py-3 '>
							<p className = ' text_bold-label '>Perfil</p>
							<p>{ userInfo.position }</p>
						</div>
						<div className = ' py-3 pe-4 '>
							<p className = ' text_bold-label '>Teléfono</p>
							<div className = ' input_input-container '>
								<input
									onChange = { (e) => handleInputChange(e, 'profilePhoneNumber') }
									placeholder = { userInfo.phoneNumber }
									value = { profilePhoneNumber }
								/>
							</div>
						</div>
						<button 
							className = 'button purple-button'
							type = 'submit'
						>
							Guardar
						</button>
					</div>
					<div className = {` col `}>
						<div className = {` py-3 `}>
							<p className = ' text_bold-label '>Email</p>
							<p>{ userInfo.email }</p>
						</div>
						<div className = {` py-3 `}>
							<p className = ' text_bold-label '>Dirección</p>
							<div className = ' input_input-container '>
								<input
									onChange = { (e) => handleInputChange(e, 'profileAddress') }
									placeholder = { userInfo.address }
									value = { profileAddress }
								/>
							</div>
						</div>
					</div>
				</div>
			</form>

		</div>
	)
}
