import React, { useEffect, useState } from 'react';
import { ChartComponent } from '../../Components/ChartComponents/ChartComponent';
import { Notifications } from '../../Components/NotificationComponents/Notifications/Notifications';
import { Stats } from '../../Components/StatsComponents/Stats/Stats';
import { demoSalesStats } from '../../helpers/sales/demoSalesStats';
import { useProductsSlice } from '../../hooks/redux/useProductsSlice';

const demoSalesArray = demoSalesStats;

export const HomeScreen = () => {

    const [ salesArray, setSalesArray ] = useState([]);

    useEffect(() => {
        getSales();
    }, []);

    const getSales = () => {
        setSalesArray(demoSalesArray)
    }

    const ShowChart = () => {
        try {
            return (
                <ChartComponent />
            )
        } catch(e) {
            console.error(new Error(e));
        }
    }

    return (
        <div className={` screens_container mx-p px-0 pt-3 `}>
            <div className = ' row px-3 '>
                <div className = ' col '>
                    <div className = ' mb-0 '>
                        <Stats salesArray = { salesArray }/>
                    </div>
                    <div className = ' rounded p-3 bg-white mt-0 '>
                        <p className = ' bold '>Ventas del mes</p>
                        <ShowChart />
                    </div>
                </div>

                <div className = ' col '>
                    <div>
                        <Notifications />
                    </div>
                </div>

            </div>            
        </div>
    );

};
