import React, { useContext, useState } from 'react';
import firebase from 'firebase/compat/app';

import { ProductContext } from '../../Context/ProductContext';
import { AlertSuccess } from '../../Components/AlertsComponents/AlertSuccess';

export const useCarModelDetail = ( selectedCar, setSelectedCar, setEditable ) => {

    const { cars, getCars } = useContext(ProductContext);
    const [ modelData, setModelData ] = useState({
        model: selectedCar.model,
        fromYear: selectedCar.fromYear,
        toYear: selectedCar.toYear,
        engines: [...selectedCar.engines],
    })
    const [ activateUpdate, setActivateUpdate ] = useState(false);
    const [ activateEngineUpdate, setActivateEngineUpdate ] = useState(false);
    const [ currentEngineData, setCurrentEngineData ] = useState({ engine: '', fromYear: '', toYear: '' });

    const addNewEngine = () => {
        console.log(currentEngineData);
        if( currentEngineData.engine !== '' && currentEngineData.fromYear !== '' && currentEngineData.toYear ) {
            if( Number(currentEngineData.fromYear) > Number(currentEngineData.toYear) ) {
                alert('El número máximo no puede ser mayor que el mínimo.')
            } else {
                const currentEngines = [...modelData.engines];
                currentEngines.push({ 
                    engine: currentEngineData.engine, 
                    fromYear: currentEngineData.fromYear,
                    toYear: currentEngineData.toYear
                })
                setModelData({
                    ...modelData,
                    engines: currentEngines,
                });
                setActivateEngineUpdate(true);
                setCurrentEngineData({ engine: '', fromYear: '', toYear: '' });
            }
        } else {
            alert('Ninguno de los campos puede estar vacío');
        }
    }

    const deleteEngine = ( index ) => {
        const currentEngines =[ ...modelData.engines ];
        currentEngines.splice(index, 1);
        setModelData({
            ...modelData,
            engines: currentEngines,
        })
        setActivateEngineUpdate(true);
    } 

    const handleEngineAdd = ( { target: { value }}, field ) => {
        setCurrentEngineData({
            ...currentEngineData,
            [field]: value,
        })
    }

    const handleInfoChange = ( { target: { value }}, field) => {
        if( field === 'fromYear' || field === 'toYear' ) {
            setModelData({
                ...modelData,
                [field]: Number(value),
            })
        } else {
            setModelData({
                ...modelData,
                [field]: value,
            })
        }
        setActivateUpdate(true);
    } 

    const updateEngines = () => {
        const brandData = cars[selectedCar.brandIndex];
        const brandModels = brandData.models;
        const model = brandModels.findIndex(model => model.model === selectedCar.model);
        brandModels[model] = { 
            model: modelData.model, 
            fromYear: Number(modelData.fromYear), 
            toYear: Number(modelData.toYear),
            engines: [...modelData.engines],
        };
        console.log(cars);
        firebase.firestore().collection('cars').doc('cars').update({ 
            models: cars,
        }).then(() => {
            AlertSuccess('Motores actualizados', 'Los motores se han actualizado exitosamente.');
            setEditable(false);
            getCars();
            setSelectedCar({
                ...selectedCar,
                model: '',
                yearFrom: '',
                yearTo: '',
                engines: [],
            });
        }).catch(e => {
            console.log(e);
            alert('Ha habido un error agregando este modelo, intentalo de nuevo!');
        })
    }

    const updateInfo = () => {

        const brandData = cars[ selectedCar.brandIndex ];
        console.log(brandData);
        let brandModels =  brandData.models;
        const model = brandModels.findIndex(model => model.model === selectedCar.model);
        brandModels[model] = { 
            model: modelData.model, 
            fromYear: Number(modelData.fromYear), 
            toYear: Number(modelData.toYear),
            engines: selectedCar.engines,
        };
        console.log(cars);
        firebase.firestore().collection('cars').doc('cars').update({ 
            models: cars,
        }).then(() => {
            alert('Modelo editado exitosamente!');
            setEditable(false);
            getCars();
            setSelectedCar({
                ...selectedCar,
                model: '',
                yearFrom: '',
                yearTo: '',
                engines: [],
            });
        }).catch(e => {
            console.log(e);
            alert('Ha habido un error agregando este modelo, intentalo de nuevo!');
        })                
    }

    const states = {
        addNewEngine,
        activateUpdate,
        activateEngineUpdate,
        currentEngineData,
        modelData,
    }

    const stateUpdaters = {
        deleteEngine,
        handleEngineAdd,
        handleInfoChange,
        setModelData,
        updateEngines,
        updateInfo,
    }

    return [ states, stateUpdaters ];

}
