import firebase from 'firebase/compat/app';

/* 
 *      Get the product info from database with its ID.
 *      @params { string } family.
 */
export const getProductsByFamily = ( family ) => {
    return new Promise((resolve, reject) => {
        firebase.firestore().collection('inventario').where('family', '==', family).limit(200).get()
        .then(res => {
            resolve( res );
        })
        .catch( reject );
    })
}