// export const sales = [
//     {
//         canceled: false,
//         client: 'Piedra Pura',
//         clientDiscount: '',
//         completed: true,
//         creditPayed: true,
//         creditPayment: false,
//         date: new Date('2022-07-17T10:42:00'),
//         discount: 0,
//         discountApplied: false,
//         extraDiscount: 0,
//         folio: 1000,
//         invoiceRequired: false,
//         invoiceRequired: false,
//         paymentType: 'cash',
//         products: [
//             {
//                 product: '',
//                 productData: {
//                     amount: 10,
//                     code: 'ZCC-10',
//                     descripcion: 'ABRAZADERA 1/2 1 1/8',
//                     discount: 0,
//                     family: '002',
//                     id: '', 
//                     name: 'ABRAZADERA 1/2 1 1/8',
//                     price_cash: 15,
//                     price_credit: 18,
//                 },
//                 quantity: 10,
//             },
//         ],
//         purchaseType: 'cash',
//         total: Math.floor(Math.random() * 10000) + 1,
//         user: 'Avluet Software',
//     },
//     {
//         canceled: false,
//         client: 'Piedra Pura',
//         clientDiscount: '',
//         completed: true,
//         creditPayed: true,
//         creditPayment: false,
//         date: new Date('2022-07-17T12:35:00'),
//         discount: 0,
//         discountApplied: false,
//         extraDiscount: 0,
//         folio: 1001,
//         invoiceRequired: false,
//         invoiceRequired: false,
//         paymentType: 'cash',
//         products: [
//             {
//                 product: '',
//                 productData: {
//                     amount: 10,
//                     code: 'ZCC-10',
//                     descripcion: 'ABRAZADERA 1/2 1 1/8',
//                     discount: 0,
//                     family: '002',
//                     id: '', 
//                     name: 'ABRAZADERA 1/2 1 1/8',
//                     price_cash: 15,
//                     price_credit: 18,
//                 },
//                 quantity: 10,
//             },
//         ],
//         purchaseType: 'cash',
//         total: Math.floor(Math.random() * 11) + 1,
//         user: 'Avluet Software',
//     },
//     {
//         canceled: false,
//         client: 'Piedra Pura',
//         clientDiscount: '',
//         completed: true,
//         creditPayed: true,
//         creditPayment: false,
//         date: new Date('2022-07-18T15:35:00'),
//         discount: 0,
//         discountApplied: false,
//         extraDiscount: 0,
//         folio: 1002,
//         invoiceRequired: false,
//         invoiceRequired: false,
//         paymentType: 'cash',
//         products: [
//             {
//                 product: '',
//                 productData: {
//                     amount: Math.floor(Math.random() * 20) + 1,
//                     code: 'ZCC-10',
//                     descripcion: 'ABRAZADERA 1/2 1 1/8',
//                     discount: 0,
//                     family: '002',
//                     id: '', 
//                     name: 'ABRAZADERA 1/2 1 1/8',
//                     price_cash: 15,
//                     price_credit: 18,
//                 },
//                 quantity: 10,
//             },
//         ],
//         purchaseType: 'credit',
//         total: Math.floor(Math.random() * 11) + 1,
//         user: 'Avluet Ventas',
//     },
//     {
//         canceled: false,
//         client: 'Maderera Durango S.A.',
//         clientDiscount: '',
//         completed: true,
//         creditPayed: true,
//         creditPayment: false,
//         date: new Date('2022-07-19T15:35:00'),
//         discount: 0,
//         discountApplied: false,
//         extraDiscount: 0,
//         folio: 1002,
//         invoiceRequired: false,
//         invoiceRequired: false,
//         paymentType: 'cash',
//         products: [
//             {
//                 product: '',
//                 productData: {
//                     amount: Math.floor(Math.random() * 20) + 1,
//                     code: 'ZCC-10',
//                     descripcion: 'ABRAZADERA 1/2 1 1/8',
//                     discount: 0,
//                     family: '002',
//                     id: '', 
//                     name: 'ABRAZADERA 1/2 1 1/8',
//                     price_cash: 15,
//                     price_credit: 18,
//                 },
//                 quantity: 10,
//             },
//         ],
//         purchaseType: 'credit',
//         total: Math.floor(Math.random() * 11) + 1,
//         user: 'Avluet Ventas',
//     },

// ];

const clients = [
    'Maderera Durango S.A.',
    'Piedra Pura Mx',
    'SOGO Electronics',
    'Cosmosu',
    'Avluet Software',
    'Gobierno del Estado',
];

const generateSales = () => {
    const sales = [];
    const maxDate = new Date().getDate();
    let folio = 999;
    for( let i = 1; i <= maxDate; i++) {
        folio += 1;
        sales.push(
            {
                canceled: false,
                client: clients[  Math.floor(Math.random() * clients.length) ],
                clientDiscount: '',
                completed: true,
                creditPayed: true,
                creditPayment: false,
                date: new Date(`2022-07-${ i < 10 ? `0${ i }` : i }T${ Math.floor(Math.random() * 13) + 10 }:00:00`),
                discount: 0,
                discountApplied: false,
                extraDiscount: 0,
                folio: folio,
                invoiceRequired: false,
                paymentType: 'cash',
                products: [
                    {
                        product: '',
                        productData: {
                            amount: Math.floor(Math.random() * 20) + 1,
                            code: 'ZCC-10',
                            descripcion: 'ABRAZADERA 1/2 1 1/8',
                            discount: 0,
                            family: '002',
                            id: '', 
                            name: 'ABRAZADERA 1/2 1 1/8',
                            price_cash: 15,
                            price_credit: 18,
                        },
                        quantity: 10,
                    },
                ],
                purchaseType: 'credit',
                total: Math.floor(Math.random() * 11) + 1,
                user: 'Avluet Ventas',
            },
        );
    }
    console.log(sales);
    return sales;
}

export const sales = generateSales();