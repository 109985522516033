import React, { useEffect, useState } from 'react';
import { Modal } from '../../Components/Modal/Modal';
import { NuevoUsuarioModal } from '../../Components/UsuariosComponents/NuevoUsuarioModal';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { useUsuarios } from '../../hooks/useUsuarios';
import { DirectoryHeader } from '../../Components/DirectoryComponents/DirectoryHeader';
import { UserDirectoryList } from '../../Components/UsuariosComponents/UserDirectoryList';
import { UserDirectoryDetail } from '../../Components/UsuariosComponents/UserDirectoryDetail';

const animatedComponents = makeAnimated();

export const UsuariosScreen = () => {

    const { state, stateUpdaters } = useUsuarios();
    const { loading, usuarioSelected, userTypes, savedData, shownUsers, editable, formik, formikEdit, uploadImage } = state;
    const { 
        setEditable, handleChangeSelect, handleUserSearch, handleSearchFilterChange, getUsuarios, setUsuarioSelected, 
        setUsuarios, removeUser, setUploadImage, setSavedData
    } = stateUpdaters;

    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        let unsubscribe = true;
        getUsuarios().then(() => {
            if(unsubscribe) {
                setOpenModal(false)
            }
        })
        return () => {
            setUsuarios([]);
            unsubscribe = false
        }
    }, []);

    return (
        <div className = {`  `}>
            <div className = {` row mx-0 px-0 `}>
                <div className = {` col-lg-4 pt-4 white-background Directory-list-container `}>
                    <div className = ' px-3 '>
                        <DirectoryHeader 
                            content = {{ title: 'Usuarios', subtitle: 'Busca a tus usuarios en el directorio' }}
                            actions = {[
                                { action: () => getUsuarios(), icon: 'refresh-outline' },
                                { action: () => setOpenModal(true), icon: 'add-outline' },
                            ]}
                            input = {{ 
                                show: true, 
                                placeholder: 'Ingresa el usuario que quieres buscar',
                                action: (e) => handleUserSearch(e),
                            }}
                        />
                        <p className = {` pb-2 mt-3 `}>Puesto</p>
                        <Select
                            closeMenuOnSelect = { false }
                            components = { animatedComponents }
                            isMulti
                            onChange = { handleSearchFilterChange }
                            options = { userTypes }
                            placeholder = 'Selecciona un puesto'
                        />
                    </div>

                    <div className = {` mt-4 `}>
                        {
                            (!loading) && (
                                <UserDirectoryList
                                    setSelectedUser = { setUsuarioSelected }
                                    users = { shownUsers }
                                />
                            )
                        }
                    </div>
                    
                </div>
                <div className = {` col-lg-8 `}>
                    <UserDirectoryDetail 
                        user = { usuarioSelected }
                        editForm = { formikEdit }
                        removeUser = { removeUser }
                        editable = { editable }
                        setEditable = { setEditable }
                        uploadImage = { uploadImage }
                        setUploadImage = { setUploadImage }
                    />
                </div>

            </div>

            { !!openModal && (
                <Modal>
                    <NuevoUsuarioModal
                        closeModal = {() => setOpenModal(false)}
                        formik = { formik }
                        handleChangeSelect = { handleChangeSelect }
                        savedData = { savedData }
                        setUploadImage = { setUploadImage }
                        setSavedData = { setSavedData }
                        uploadImage = { uploadImage }
                    />
                </Modal>
            )}

        </div>
    );

};
