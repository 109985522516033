import React, { useState } from 'react';

import { useFormik } from 'formik';
import { useUploadImage } from './useUploadImage';

// import firebase from 'firebase/compat/app';
import * as Yup from 'yup';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { useSuppliersSlice } from './redux/useSuppliersSlice';
import imgPlaceholder from '../assets/placeholders/supplier.png';

export const useProveedores = () => {
 
    const { suppliers, handleDeleteSupplier, handleEditSupplier, handleNewSupplier } = useSuppliersSlice();

    const { uploadToStorage, uploadToFirestore, deleteImage } = useUploadImage();
    const MySwal = withReactContent(Swal)

    const [ proveedorSelected, setProveedorSelected ] = useState('');
    const [ showSuppliers, setShowSuppliers ] = useState([]);
    const [ proveedores, setProveedores ] = useState([]);
    const [ loading, setLoading ] = useState(true);

    const [ uploadImage, setUploadImage ] = useState('');
    const [ savedData, setSavedData ] = useState(false);
    const [ editable, setEditable ] = useState(false);

    const getProveedores = () => {
        console.log('Ejecutando getProveedores, falta modificar esta función.');
        setProveedores([...suppliers]);
        setShowSuppliers([...suppliers]);
        setLoading(false);
        if( suppliers.length > 0 ) {
            setProveedorSelected(suppliers[0]);
        }
    
    }

    const setSuppliers = ( supplier ) => {
        let proveedoresTemp = [];
        supplier.docs.forEach((doc) => {
            proveedoresTemp.push(doc);
        })
        setProveedores([...proveedoresTemp]);
        setShowSuppliers([...proveedoresTemp]);
        if(proveedoresTemp.length > 0) {
            setProveedorSelected(proveedoresTemp[0]);
        }
        setLoading(false);

    }

    /*  Handle form to create new Supplier.   */
    const formik = useFormik({
        initialValues: {
            supplierName: '',
            supplierPhoneNumber: '',
            supplierContactName: '',
            supplierEmail: '',
            supplierAddress: '',
            supplierImage: imgPlaceholder,
        },
        validationSchema: Yup.object({
            supplierName: Yup.string()
                        .min(3, 'Los nombres de usuario deben tener al menos 3 caracteres')
                        .required('El nombre de usuario es obligatorio'),
            supplierPhoneNumber: Yup.string()
                        .min(10, 'El telefono debe tener al menos 10 numeros')
                        .required('El telefono del usuario es obligatorio'),
            supplierContactName: Yup.string(),
            supplierEmail: Yup.string()
                        .email('ingrese un correo valido')
                        .required('El correo del usuario es obligatorio'),
            supplierAddress: Yup.string(),
        }),
        onSubmit: supplier => {
            createSupplier( supplier );
        }
    });

    const editSupplier = useFormik({
        initialValues: {
            supplierName: '',
            supplierPhoneNumber: '',
            supplierContactName: '',
            supplierEmail: '',
            supplierAddress: '',
            supplierImage: '',
            docID: '',
        },
        validationSchema: Yup.object({
            supplierName: Yup.string()
                        .min(3, 'Los nombres de usuario deben tener al menos 3 caracteres')
                        .required('El nombre de usuario es obligatorio'),
            supplierPhoneNumber: Yup.string()
                        .min(10, 'El telefono debe tener al menos 10 numeros')
                        .required('El telefono del usuario es obligatorio'),
            supplierContactName: Yup.string(),
            supplierEmail: Yup.string()
                        .email('ingrese un correo valido')
                        .required('El correo del usuario es obligatorio'),
            supplierAddress: Yup.string(),
        }),
        onSubmit: supplier => {
            try {
                MySwal.fire({
                    title: <strong>¿Seguro que deseas actualizar?</strong>,
                    html: <i>Se guardará { supplier.supplierName }</i>,
                    confirmButtonText: 'Guardar',
                    showCancelButton: true,
                    reverseButtons: true,
                    cancelButtonText: 'Regresar',
                    icon: 'info'
                }).then(response => {
                    if(response.isConfirmed) {
                        console.log( proveedorSelected.id );
                        const editedSupplier = {
                            name: supplier.supplierName,
                            address: supplier.supplierAddress,
                            contactName: supplier.supplierContactName,
                            phoneNumber: supplier.supplierPhoneNumber,
                            email: supplier.supplierEmail,
                        }
                        handleEditSupplier( proveedorSelected.id, editedSupplier );
                        return MySwal.fire({
                            title: <p>Guardado con éxito</p>,
                            html: <i>Actualiza tus proveedores para consultarlo</i>,
                            icon: 'success',
                        }).then(() => {
                            setEditable(false);
                            getProveedores()
                        })
                    }
                })
            } catch (error) {
                console.log(error)
            }
        }
    });


    /* 
     *  Get text written into input.
     *  @param { object } user Formik user object.
     */
    const NewUrl = ( supplier ) => {
        return new Promise((resolve, reject) => {
            // const inicial = (supplier.supplierName).trim().split('', 1).toString().toLowerCase()
            // firebase.storage().ref('placeholders').child(`${inicial}.png`).getDownloadURL().then(data => {
            //     resolve(data)
            // }).catch(e => {
            //     reject('https://firebasestorage.googleapis.com/v0/b/pos-web-a98f9.appspot.com/o/placeholders%2FPerfil.jpeg?alt=media&token=a152dbaa-4d16-4bb3-aacd-ccecbfe317de')
            // })
            resolve(imgPlaceholder);
        })
    }

    /* 
    *  Upload new user to Firebase database, used inside formik.
    *  @param { supplier } object Formik created object.
    */
    const createSupplier = ( supplier ) => {
        const supplierData = {
            id: suppliers.length + 1,
            name: supplier.supplierName,
            phoneNumber: supplier.supplierPhoneNumber,
            contactName: supplier.supplierContactName,
            email: supplier.supplierEmail,
            address: supplier.supplierAddress,
            image: imgPlaceholder,
        }
    
        try {
            MySwal.fire({
                title: <strong>¿Seguro que deseas registrar?</strong>,
                html: <i>Se guardará { supplier.supplierName }</i>,
                confirmButtonText: 'Guardar',
                showCancelButton: true,
                reverseButtons: true,
                cancelButtonText: 'Regresar',
                icon: 'info'
            }).then(response => {
                if(response.isConfirmed) {
                    handleNewSupplier( supplierData );
                    return MySwal.fire({
                        title: <p>Guardado con éxito</p>,
                        html: <i>Actualiza tu inventario para consultarlo</i>,
                        icon: 'success',
                    }).then(() => {
                        getProveedores();
                        setSavedData(true);
                        setEditable(false);
                        formik.resetForm();
                    })
                }
            })
        } catch (error) {
            console.log(error)
        }
    }
   

    const removeSupplier = ( id ) => {
        MySwal.fire({
            title: <strong>¿Seguro que deseas eliminarlo?</strong>,
            html: <i>No podrás recuperar a este proveedor</i>,
            confirmButtonText: 'Eliminar',
            showCancelButton: true,
            reverseButtons: true,
            cancelButtonText: 'Regresar',
            icon: 'info'
        }).then(response => {
            if(response.isConfirmed) {
                handleDeleteSupplier( id );
                // firebase.firestore().collection('proveedores').doc( docID ).delete().then(() => {
                //     deleteImage('proveedores', docID, 'SupplierPicture').catch(e => console.log('No Suppliet Picture'))
                //     return MySwal.fire({
                //         title: <p>Guardado con éxito</p>,
                //         html: <i>Actualiza tus proveedores para consultarlo</i>,
                //         icon: 'success',
                //     }).then(() => {
                //         setEditable(false);
                //         getProveedores();
                //     })
                // }).catch(e => {
                //     return MySwal.fire({
                //         title: <p>Ha habido un error</p>,
                //         html: <i>Verifica tu conexión a internet, e intenta de nuevo.</i>,
                //         icon: 'error',
                //     })
                // })
                return MySwal.fire({
                    title: <p>Guardado con éxito</p>,
                    html: <i>Actualiza tus proveedores para consultarlo</i>,
                    icon: 'success',
                }).then(() => {
                    setEditable(false);
                    getProveedores();
                })
            }
        });
    }

    const handleSupplierSearch = ( e ) => {
        const search = (e.target.value).toLowerCase();
        if(search !== '') {
            const foundSuppliers = proveedores.filter(supplier => ((supplier.data().name).toLowerCase()).includes(search));
            setShowSuppliers([...foundSuppliers]);
        } else {
            setShowSuppliers([...proveedores]);
        }
    }

    return {
        editable,
        formik,
        editSupplier,
        loading,
        proveedores,
        proveedorSelected,
        savedData,
        showSuppliers,
        uploadImage,
        removeSupplier,
        getProveedores,
        handleSupplierSearch,
        setEditable,
        setProveedorSelected,
        setSuppliers,
        setUploadImage,
    }
    
}
