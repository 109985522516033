import React from 'react';

export const NotificacionItem = ({ notification }) => {

    const { 
        existencia,
        nombre,
        pieza,
    } = notification;

    return (
        <div className = ' notification_item-container '>
            <div className = 'notification_icon-container'>
                <ion-icon name = 'cube-outline'></ion-icon>
            </div>
            <div className = ' notification_info-container '>
                <p className = ' notification_item-category '>Inventario</p>
                { existencia < 10 && (
                    <p className = ' text_data '>
                        Necesitas { nombre } <span className = ' text_bold '>#{ pieza }</span>, hay { existencia } piezas.
                    </p>
                )}
            </div>
        </div>
    );

};
