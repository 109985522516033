import React, { useContext } from 'react';
import Swal from 'sweetalert2';
import { ProductContext } from '../../../Context/ProductContext';
import { formatMoney } from './../../../helpers/formatMoney';

export const ResumenVentaProductos = ({ }) => {
    
    const { cart, addOnePiece, deleteCartProduct, deleteOnePiece } = useContext(ProductContext);

    return (
        <table className = {` table table-hover `}>
            <thead>
                <tr>
                <th scope="col">#</th>
                <th scope="col">Nombre</th>
                <th scope="col">Cantidad</th>
                <th scope="col">Precio</th>
                <th scope="col">Crédito</th>
                <th scope="col">Total</th>
                <th scope="col">Total Crédito</th>
                <th></th>
                </tr>
            </thead>
            <tbody>
                { cart.length > 0 && (
                    cart.map((producto, index) => 
                        (
                            <tr
                                key = { index } 
                                className = 'ProductList-table-item'
                            >
                                { (!producto.producto.id.includes('generic-stock') && !producto.producto.id.includes('generic-special')) && (
                                    <>
                                        <th scope="row">{ producto.producto.data().pieza }</th>
                                        <td>{ producto.producto.data().nombre }</td>
                                        { producto.pedido && (<td>PEDIDO</td>)}
                                        <td>{ producto.cantidad }</td>
                                        <td>{ formatMoney( producto.producto.data().precio_contado ) }</td>
                                        <td>{ formatMoney( producto.producto.data().precio_credito ) }</td>
                                        <td>{ formatMoney( producto.cantidad * producto.producto.data().precio_contado ) }</td>
                                        <td>{ formatMoney( producto.cantidad * producto.producto.data().precio_credito ) }</td>
                                        <td>
                                            <div className='ResumenVentaItem-cta-counter'>
                                                <ion-icon name="remove-circle-outline" onClick = { () => deleteOnePiece( producto ) }></ion-icon>
                                                <p className = 'mx-2' >{ producto.cantidad }</p>
                                                <ion-icon name="add-circle-outline" onClick = { () => addOnePiece( producto ) }></ion-icon>
                                                <div className = 'ResumenVentaItem-cta-delete'>
                                                    <ion-icon name="trash-bin-outline" onClick = { () => deleteCartProduct( producto ) } ></ion-icon>
                                                </div>
                                            </div>
                                        </td>
                                    </>  
                                )}
                                { (producto.producto.id.includes('generic-stock') ) && (
                                    <>
                                        <th scope="row">{ producto.producto.producto.code }</th>
                                        <td>{ producto.producto.producto.name }</td>
                                        <td>{ producto.cantidad }</td>
                                        <td>{ formatMoney( producto.producto.producto.price) }</td>
                                        <td>{ formatMoney(  producto.producto.producto.price_credit ) }</td>
                                        <td>{ formatMoney (producto.cantidad * producto.producto.producto.price ) }</td>
                                        <td>{ formatMoney (producto.cantidad * producto.producto.producto.price_credit ) }</td>
                                        <td>
                                            <div className='ResumenVentaItem-cta-counter'>
                                                <ion-icon name="remove-circle-outline" onClick = { () => deleteOnePiece( producto ) }></ion-icon>
                                                <p className = 'mx-2' >{ producto.cantidad }</p>
                                                <ion-icon name="add-circle-outline" onClick = { () => addOnePiece( producto ) }></ion-icon>
                                                <div className = 'ResumenVentaItem-cta-delete'>
                                                    <ion-icon name="trash-bin-outline" onClick = { () => deleteCartProduct( producto ) } ></ion-icon>
                                                </div>
                                            </div>
                                        </td>
                                    </>  
                                )}
                                { (producto.producto.id.includes('generic-special')) && (
                                    <>
                                        <th scope="row">{ producto.producto.producto.code }</th>
                                        <td>{ producto.producto.producto.name }</td>
                                        <td>{ producto.cantidad }</td>
                                        <td>{ formatMoney( producto.producto.producto.price ) }</td>
                                        <td>{ formatMoney( producto.producto.producto.price_credit ) }</td>
                                        <td>{ formatMoney( producto.cantidad * producto.producto.producto.price ) }</td>
                                        <td>{ formatMoney( producto.cantidad *  producto.producto.producto.price_credit ) }</td>
                                        <td>
                                            <div className='ResumenVentaItem-cta-counter'>
                                                <ion-icon name="remove-circle-outline" onClick = { () => deleteOnePiece( producto ) }></ion-icon>
                                                <p className = 'mx-2' >{ producto.cantidad }</p>
                                                <ion-icon name="add-circle-outline" onClick = { () => addOnePiece( producto ) }></ion-icon>
                                                <div className = 'ResumenVentaItem-cta-delete'>
                                                    <ion-icon name="trash-bin-outline" onClick = { () => deleteCartProduct( producto ) } ></ion-icon>
                                                </div>
                                            </div>
                                        </td>
                                    </>  
                                )}
                            </tr>
                        )
                    )
                )} 
            </tbody>
        </table>
    )
}
