import React from 'react'

export const PaymentConfirmation = ({ finishPurchase, handlePrint, purchaseType, setTicketType }) => {
    
    return (
        <div className = ' d-flex align-items-center justify-content-center w-100 py-5 px-4 flex-column '>
            <div className = ' payment_successful w-100 d-flex justify-content-center '>
                <ion-icon name = 'checkmark-done-outline'></ion-icon>
            </div>
            <p className = ' h5 w-100 text-center '>Venta registrada exitosamente.</p>
            <p className = ' text-center small '>
                Puedes imprimir el ticket en este momento, o desde el panel de Historial de Ventas.
            </p>
            <div className = ' d-flex text-center align-items-center flex-column mt-3 '>
                { purchaseType !== 'credit' && (
                    <button
                        className = {` button purple-button shadow px-5 mx-auto mt-3 mt-sm-0 ms-sm-2 `}
                        onClick = { handlePrint }
                    >
                        MANDAR A CAJA
                    </button>
                )}
                { purchaseType === 'credit' && (
                    <>
                        <button
                            className = {` button purple-button shadow px-5 mx-auto mb-2 `}
                            onClick = { () => { 
                                setTicketType('original');
                                handlePrint();
                            }}
                        >
                            IMPRIMIR ORIGINAL
                        </button>
                        <button
                            className = {` button secondary-button shadow px-5 mx-auto mb-2 `}
                            onClick = { () => { 
                                setTicketType('copy');
                                handlePrint();
                            }}
                        >
                            IMPRIMIR COPIA
                        </button>
                    </>
                )}
                <button 
                    className = ' button cancel-button text-center mt-5 '
                    onClick = { finishPurchase }
                >
                    Terminar Venta
                </button>
                <>
                
                </>
            </div>
        </div>
    )
}
