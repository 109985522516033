import React, { useContext, useEffect, useState } from 'react';
import { ProductContext } from '../../Context/ProductContext';
import { Modal } from '../Modal/Modal';
import { CarNewModel } from './CarNewModel';

export const CarBrandDetail = ({ cars, deleteCar, selectedCar, setSelectedCar, handleModelSearch }) => {

    const { brandName } = selectedCar;
    const { showCars } = useContext( ProductContext )

    const [ brand, setBrand ] = useState([]);
    const [ addNewModel, setAddNewModel ] = useState(false);

    useEffect(() => {
        setBrand((brandName !== '') ? showCars.filter(car => car.brand === selectedCar.brandName) : []);  
    }, [selectedCar]);

    return (
        <div className = ' screen_section background-white p-3 '>
            <div>
                { (brand.length > 0 && brand[0].models !== undefined ) && 
                    <>
                        <div>
                            <div className = ' d-flex align-items-center '>
                                <h3 className = ' flex-grow-1 '>{ selectedCar.brandName }</h3>
                                <div className = ' pe-2 ms-1 '>
                                    <div 
                                        className = ' header-button me-2 '
                                        onClick = { () => setAddNewModel(true) } 
                                    >
                                        <ion-icon name = "add-outline"></ion-icon>
                                    </div>
                                </div>
                            </div>

                            <hr />
                        </div>
                        {(brand[0].models).map((model, index) => (
                            <div 
                                key = { index }
                                className = {` cars_model-item p-3 rounded d-flex mb-2 ${ selectedCar.model === model.model && 'active'} `}
                                onClick = { () => setSelectedCar({
                                    ...selectedCar, 
                                    model: model.model,
                                    fromYear: model.fromYear,
                                    toYear: model.toYear,
                                    engines: model.engines,
                                })}
                            >
                                <p className = ' flex-grow-1 '>{ model.model }</p>
                                <div className = ' icons_delete-list-icon mb-0 '>
                                    <ion-icon 
                                        name="close-circle-outline"
                                        onClick = { () => deleteCar( selectedCar.brandIndex, index ) }
                                    ></ion-icon>
                                </div>
                            </div>    
                        ))}
                    </>
                }
                { brandName === '' && (
                    <div className = ' px-2 pt-5 text-center '>
                        <p>Selecciona una marca para continuar.</p>
                    </div>
                )}
            </div>

            { addNewModel && (
                <Modal>
                    <CarNewModel brandIndex = { selectedCar.brandIndex } closeModal = { () => setAddNewModel(false) } />
                </Modal>
            )}

        </div>
    )
}
