import React, { useContext, useState } from 'react'
import { ProductContext } from '../../../Context/ProductContext';
import { Modal } from '../../Modal/Modal';
import { InventoryReportModal } from '../InventoryReports/InventoryReportModal';
import { InventoryReportSelection } from '../InventoryReports/InventoryReportSelection';
import { InventorySoldProductsReport } from '../InventoryReports/InventorySoldProductsReport';
import { InventorySpecialProductsSoldReport } from '../InventoryReports/InventorySpecialProductsSoldReport';

export const InventarioHeader = ({ openModal, openProductOrder }) => {

    const { loadProducts } = useContext(ProductContext);

    const [ openDownloadReport, setOpenDownloadReport ] = useState(false);
    const [ openInventoryReport, setOpenInventoryReport ] = useState( false );
    const [ openSaledProductsReport, setOpenSaledProductsReport ] = useState( false );
    const [ openSpecialProductsReport, setOpenSpecialProductsReport ] = useState( false );

    return (
        <div className = ' d-flex align-items-center py-4 '>
            
            <div className = ' row ps-sm-3 pe-sm-1 ps-2 '>
                <div className = ' header_title col-12 col-sm-6 '>
                    <h2 className = ' section-title mb-0 '>Inventario</h2>
                    <p className = ' header_title-description '>Consulta, agrega y actualiza tus productos.</p>
                </div>
                <div className = ' d-flex col-12 col-sm-6 mt-2 mt-sm-0 justify-content-end '>
                    <div 
                        className=' header-button me-2 '
                        onClick = { openProductOrder }
                    >
                        <ion-icon name="push-outline"></ion-icon>
                    </div>
                    <div 
                        className=' header-button me-2 '
                        onClick = { loadProducts }
                    >
                        <ion-icon name="refresh-outline"></ion-icon>
                    </div>
                    <div 
                        className=' header-button me-2 '
                        onClick = { openModal }
                    >
                        <ion-icon name="add-outline"></ion-icon>
                    </div>
                    <div 
                        className=' header-button'
                        onClick = { () => setOpenDownloadReport( true ) }
                    >
                        <ion-icon name="download-outline"></ion-icon>
                    </div>
                </div>

            </div>




            { !!openDownloadReport && (
                <Modal>
                    <InventoryReportSelection 
                        closeModal = { () => setOpenDownloadReport( false )  } 
                        openInventoryReport = { () => setOpenInventoryReport( true ) }
                        openSaledProductsReport = { () => setOpenSaledProductsReport( true ) }
                        openSpecialProductReport = { () => setOpenSpecialProductsReport( true )}
                    />
                </Modal>
            )}
           
            { !!openInventoryReport && (
                <Modal>
                    <InventoryReportModal closeModal = { () => setOpenInventoryReport( false )  } />
                </Modal>
            )}

            { !!openSaledProductsReport && (
                <Modal>
                    <InventorySoldProductsReport closeModal = { () => setOpenSaledProductsReport( false )  } />
                </Modal>
            )}

            { !!openSpecialProductsReport && (
                <Modal>
                    <InventorySpecialProductsSoldReport closeModal = { () => setOpenSpecialProductsReport( false )  } />
                </Modal>
            )}

        </div>
    )
}
