import React, { useContext, useState } from 'react';
import firebase from 'firebase/compat/app';

import { ProductContext } from '../../Context/ProductContext';
import { AlertSuccess } from '../../Components/AlertsComponents/AlertSuccess';

export const useCarNewBrand = ( closeModal ) => {

    const { cars, getCars } = useContext(ProductContext);
    const [ brandName, setBrandName ] = useState('');

    const handleBrandChange = ({ target: { value }}) => setBrandName( value );
    
    const addNewBrand = () => {
        const foundBrand = cars.filter(brand => brand.brand === brandName.trim());
        if(foundBrand.length > 0) {
            alert('Esta marca ya está registrada.');
        } else {
            let existingCars = [...cars];
            existingCars.push({ brand: brandName, models: [] });
            existingCars.sort((a, b) => (a.brand > b.brand) ? 1 : -1);
            firebase.firestore().collection('cars').doc('cars').set({ 
                models: [...existingCars],
            }).then(() => {
                AlertSuccess('Marca agregada', 'La marca se ha agregado exitosamente.');
                getCars();
                closeModal();
            }).catch(err => { alert(err) })
        }
    }
  
    const states = {

    };

    const stateUpdaters = {
        addNewBrand,
        handleBrandChange,
    }

    return [ states, stateUpdaters ];
}
