import React from 'react'
import { formatDate } from '../../helpers/formatDate';
import { formatMoney } from '../../helpers/formatMoney';
import Select from 'react-select';
import { useUserSalesHistory } from '../../hooks/useUserSalesHistory';

export const UserSalesHistory = React.memo(({ userID }) => {

    const [ states, stateUpdaters ] = useUserSalesHistory( userID );
    const { 
        filterOptions,
        isLoading,
        sales,
        salesTotal,
        seeMore,
        seeTotal,
    } = states;
    const {
        handleFilterChange,
        filterSelection,
        getSeeMoreSales,
    } = stateUpdaters;

    return (
        <>
            <div className = ' d-flex '> 
                <div className = ' col '>
                    <Select
                        defaultValue={filterOptions[0]}
                        onChange={handleFilterChange}
                        options={filterOptions}
                    />
                </div>
                { seeTotal && (
                    <div className = ' col d-flex flex-grow-1 align-items-center justify-content-end '>
                        <p>
                            <span className = ' text_bold text_purple h5 me-2 '>Total</span> 
                            <span>{formatMoney( salesTotal )}</span>
                        </p>
                    </div>
                )}
            </div>
            { (!isLoading && sales.length > 0) && (
                <div>
                    <table className = "table text-center ">
                        <thead>
                            <tr>
                            <th scope="col">Fecha</th>
                            <th scope="col">Folio</th>
                            <th scope="col">Total</th>
                            <th scope="col">Precio Crédito</th>
                            <th scope="col">Productos</th>
                            <th scope="col">Factura</th>
                            <th scope="col">Tipo Compra</th>
                            <th scope="col">Método Pago</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                sales.map(sale => (
                                    <tr key = { sale.date }>
                                        <th scope="row">{ formatDate( sale.date ) }</th>
                                        <th scope="row">{ sale.folio }</th>
                                        <td>{ formatMoney( sale.total ) }</td>
                                        <td>{ sale.creditPayment ? 'Sí' : 'No' }</td>
                                        <td>{ sale.products }</td>
                                        <td>{ sale.invoice ? 'Sí' : 'No' }</td>
                                        <td>{ sale.purchaseType === 'cash' ? 'Contado' : 'Crédito' }</td>
                                        <td>{ sale.paymentType === 'card' ? 'Tarjeta' : 'Efectivo' }</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    {(sales.length > 0 && !!seeMore) && (
                        <p 
                            className = ' btn purple-button '
                            onClick = {() => { 
                                filterSelection().then(res => {
                                    getSeeMoreSales( userID, res.date, res.lastDate )
                                })
                            }}
                        >Cargar Más</p>
                    )}
                </div>

            )}
            {(!isLoading && sales.length <= 0) && (
                <div className = 'mt-3'>
                    <p>No hay ventas registradas de este usuario.</p>
                </div>
            )}
            {(isLoading) && (
                <div className = ' mt-3 '>
                    <p>Cargando...</p>
                </div>
            )}
        </>
    )
})
