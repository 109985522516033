export const calculatePrices = ( costo, family ) => {
    let utilidad_contado;
    let utilidad_credito;
    switch( family ) {
        case '001': 
            utilidad_contado = 1.35;
            utilidad_credito = 1.50;
            break;
        case '002': 
            utilidad_contado = 1.32;
            utilidad_credito = 1.47;
            break;
        case '003': 
            utilidad_contado = 1.35;
            utilidad_credito = 1.50;
            break;
        case '004': 
            utilidad_contado = 1.35;
            utilidad_credito = 1.50;
            break;
        case '005': 
            utilidad_contado = 1.32;
            utilidad_credito = 1.47;
            break;
        case '006': 
            utilidad_contado = 1.32;
            utilidad_credito = 1.47;
            break;
        case '007': 
            utilidad_contado = 1.32;
            utilidad_credito = 1.47;
            break;
        case '008': 
            utilidad_contado = 1.32;
            utilidad_credito = 1.47;
            break;
        case '009': 
            utilidad_contado = 1.35;
            utilidad_credito = 1.50;
            break;
        default: 
            utilidad_contado = 1.35;
            utilidad_credito = 1.50;
    }
    let precio_contado = (Number(costo) * utilidad_contado) * 1.16;
    let precio_credito = (Number(costo) * utilidad_credito) * 1.16;
    return { 
        contado: precio_contado.toFixed(2),
        credito: precio_credito.toFixed(2),
    }
}