import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';

export const AddProductOrderItem = React.memo(({ allProducts, index, productsToAdd, setProductsToAdd, removeProduct }) => {

    const [foundError, setFoundError] = useState(false);
    const [foundProduct, setFoundProduct] = useState('');
    const [pieceNumber, setPieceNumber] = useState('');
    const [enteringAmmount, setEnteringAmmount] = useState(productsToAdd[index].quantity);

    const addQuantityToProduct = () => {
        let tempProds = [...productsToAdd];
        tempProds[index] = { id: productsToAdd[index].id, product: productsToAdd[index].product, quantity: enteringAmmount };
        setProductsToAdd([...tempProds]);
    }

    const handlePieceQuantity = ({ target: { value } }) => {
        setEnteringAmmount(Number(value));
    }

    const handlePieceNumber = ({ target: { value } }) => {
        setPieceNumber(value);
    }

    const searchProduct = () => {
        const searchPiece = pieceNumber.toLocaleUpperCase();
        firebase.firestore().collection('inventario')
            .orderBy('pieza')
            .where('pieza', '>=', searchPiece).where('pieza', '<=', searchPiece + '~').limit(1)
            .get().then(res => {
                if(res.docs.length > 0) {
                    let tempProds = [...productsToAdd];
                    tempProds[index] = { 
                        id: pieceNumber, 
                        product: res.docs[0],
                        quantity: productsToAdd[index].quantity 
                    };
                    setProductsToAdd([...tempProds]);
                    setFoundProduct(res.docs[0]);
                } else {
                    setFoundError(true);
                }
            });
    }

    return (
        <div>
            <div className=' col-12 inventario_add-product-input-container mb-2'>
                { productsToAdd[index].id === '' && (
                    <div className=' align-items-center row w-100 mt-3 mx-0 '>
                        <div className = ' col-4 me-2 '>
                            <input
                                className = ' me-2 form-control w-100 '
                                onChange = { handlePieceNumber }
                                placeholder = 'Número de pieza'
                            />
                        </div>
                        <div className = ' col-3 '>
                            <div
                                className=' block-button secondary-button '
                                onClick={searchProduct}
                            >
                                Buscar
                            </div>

                        </div>
                        {(foundError) && (
                            <div className = ' col-3 d-flex justify-content-start '>
                                <p className=' error-message m-0 ms-3 '>No se ha encontrado el producto { pieceNumber }.</p>
                            </div>
                        )}
                    </div>
                )}
                { productsToAdd[index].id !== '' && (
                    <div className='d-flex align-items-center row w-100 px-2 '>
                        <div className = ' col-12 col-md-6 mb-1 mb-md-0 p-2 p-md-0 '>
                            <p className = ' me-2 text_instructions text_bold '>{productsToAdd[index].product.data().pieza}</p>
                            <p className = ' me-2 text_data '>{productsToAdd[index].product.data().nombre}</p>
                            <p className = ' me-2 text_data '>Existencia actual {productsToAdd[index].product.data().existencia}</p>
                        </div>
                        <div className = ' col-12 col-md-6 mb-1 mb-md-0 p-2 p-md-0 '>
                            <input
                                className = ' me-2 w-100 d-flex '
                                placeholder = "Agregar"
                                type = 'number'
                                onChange={handlePieceQuantity}
                                onBlur={addQuantityToProduct}
                                value={enteringAmmount}
                            />
                            <div
                                className=" button cancel-button mt-1"
                                onClick={() => removeProduct(index)}
                            >
                                Borrar
                            </div>
                        </div>
                        <hr className = ' light-separator my-3 '/>
                    </div>
                )}
            </div>
        </div>

    )
})