import React, { useContext, useEffect, useState } from 'react'
import { ProductContext } from '../../../Context/ProductContext'
import { InputComponent } from '../../InputComponent/InputComponent'

const productInitialState = {
    name: '',
    price: '',
    price_credit: '',
    code: '',
    description: '',
}

export const RandomProductModal = ({ closeModal }) => {

    const { addToCart } = useContext(ProductContext);
    const [ product, setProduct ] = useState(productInitialState);

    const handleInputChange = ( e, state ) => {
        setProduct({
            ...product, 
            [state]: e.target.value,
        })
    }

    const addProductToCart = () => {
        if( product.name !== '' && product.description !== '' && product.price !== '' && product.code !== '' ) {
            const productData = {
                amount: 1,
                code: product.code,
                description: product.description,
                discount: 0,
                family: 'special',
                id: `generic-stock-${ product.code }`,
                name: product.name,
                price_cash: product.price,
                price_credit: Number(product.price * 1.10),
            }
            addToCart({...productData});
            closeModal();
        }
    }

    return (
        <div className={` modal NuevoProductoModal-container `}>
            <div className={` modal-content `}>

                <div className={` modal-header `}>
                    <p className='section-title'>Vender producto sin registrar</p>
                </div>
                <div className={` modal-body `}>
                    <div className = ' row mx-0 '>
                        <div className = ' col-12 col-sm-6 pe-3 '>
                            <InputComponent 
                                label = 'Nombre del Producto'
                                onChange = { (e) => handleInputChange(e, 'name') }
                                placeholder = 'Ingresa el nombre del producto.'
                            />
                        </div>
                        <div className = ' col-12 col-sm-6 pe-3 '>
                            <InputComponent 
                                label = 'Precio del producto'
                                number = { true }
                                onChange = { (e) => handleInputChange(e, 'price') }
                                placeholder = 'Ingresa el precio del producto.'
                            />
                        </div>
                        <div className = ' col-12 col-sm-6 pe-3 '>
                            <InputComponent 
                                label = 'Descripción del producto'
                                onChange = { (e) => handleInputChange(e, 'description') }
                                placeholder = 'Ingresa la descripción del producto.'
                            />
                        </div>
                        <div className = ' col-12 col-sm-6 pe-3 '>
                            <InputComponent 
                                label = 'Número de pieza'
                                onChange = { (e) => handleInputChange(e, 'code') }
                                placeholder = 'Ingresa número de pieza del producto.'
                            />
                        </div>
                    </div>
                    <div className = ' d-flex '>
                        <div
                            className = ' button purple-button me-2 '
                            onClick = { addProductToCart } 
                        >
                            Agregar Producto
                        </div>
                        <div
                            className = ' button cancel-button  '
                            onClick = { closeModal } 
                        >
                            Cancelar
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
