import firebase from 'firebase/compat/app';

/* 
 *      Functions that gets the continues x invoices matching a minimum date, and limit, after the last gotten result.
 *      @params { date } dateWhere. 
 *      @params { number } limit. How many results do we wanna get.
 *      @params { array } clients. Existing clients already gotten by another function.
 */
export const getMoreInvoicesByOneDate = ( dateWhere, limit, lastVisible ) => {
    return new Promise( async (resolve, reject) => {
        const dateWhereQuery = firebase.firestore.Timestamp.fromDate( new Date( dateWhere ) );
        let tempInvoices = [];
        await firebase.firestore().collection('invoices')
            .orderBy("date", "desc")
            .where('date', ">=", dateWhere)
            .startAfter(lastVisible)
            .limit( limit )
            .get()
            .then(res => {
                res.forEach(doc => {
                    const invoiceDoc = {
                        id: doc.id,
                        data: doc.data(),
                    }
                    tempInvoices.push( invoiceDoc );
                })
                resolve( tempInvoices );
            })
            .catch(e => {
                reject(e);
            });
    })
}
