import React from 'react';
import { formatDate } from '../../helpers/formatDate';
import { formatMoney } from '../../helpers/formatMoney';
import { UnselectedItemPlaceholder } from './../UnselectedItemPlaceholder/UnselectedItemPlaceholder';

import { useSalesHistory } from '../../hooks/useSalesHistory';
import { TicketPrint } from '../VentasComponents/TicketPrint/TicketPrint';
import { useSaleDetail } from '../../hooks/useSaleDetail';

export const HistorySaleDetail = React.memo(({ selectedSale, setSelectedSale, clients, users }) => {

    const SaleDetail = () => {
        const { stateUpdaters } = useSalesHistory();
        const { printTicket: printTicketAction } = stateUpdaters;
        const {
            client,
            date,
            discount,
            invoiceRequired,
            paymentType,
            products,
            purchaseType,
            total,
            creditPayed,
        } = selectedSale;
        
        const [ detailStates, detailUpdaters ] = useSaleDetail( selectedSale, setSelectedSale, users, clients, printTicketAction );
        const {
            clientName, 
            componentRef,
            printTicket,
            saleProducts, 
            showPrintButtons,
            showProducts, 
            ticketPaymentData,
            ticketType,
            time,
            userName,
            userType,
            user,
        } = detailStates;
        const {
            cancelPurchase,
            setPrintTicket,
            setShowProducts,
            setTicketType,
        } = detailUpdaters;

        const SaleCTA = ({ printTicket, showPrintButtons, sale, userType }) => {

            const { canceled, completed } = sale;
            if( (!completed && completed !== undefined) || (canceled && canceled !== undefined) ) {
                return (
                    <div className = ' d-flex align-items-center '>
                        <div className = ' icons_red-icon '>
                                <ion-icon name = 'alert-circle-outline'></ion-icon>
                        </div>
                        <span className = ' text_bold-label '>VENTA CANCELADA</span>
                    </div>
                )
            } else {
                return (
                    <>
                        { (!printTicket && !showPrintButtons)  && (
                            <div className = ' button purple-button me-2 ' onClick = {() => setPrintTicket(true)} >
                                Preparar Nota
                            </div>
                        )} 
                        {!!showPrintButtons && (
                            <>
                                <p className = 'button purple-button me-2 ' onClick = {() => {
                                    setTicketType('original');
                                    // handleTicketPrint()
                                }}>
                                    Imprimir Nota
                                </p>
                                { purchaseType === 'credit' && (
                                    <div className = ' button purple-button me-2 ' onClick = {() => {
                                        setTicketType('copy');
                                        // handleTicketPrint();
                                    }} >
                                        Imprimir Copia
                                    </div>
                                )}
                            </>
                        )}
                    
                        {userType === 'Administrador' && (
                            <div className = ' button cancel-button ' onClick = { cancelPurchase } >
                                Cancelar Venta
                            </div>
                        )}
                    </>
                )
            }

        }

        return (
            <div className = ' detail_container '>
                <div className = ' detail_header '>
                    <div className = {` ${(paymentType === 'cash' ? 'icons_purple-icon' : 'icons_blue-icon') }`}>
                        <ion-icon name = {(paymentType === 'cash' ? 'cash-outline' : 'card-outline')}></ion-icon>
                    </div>
                    <div className = ' flex-grow-1 '>
                        <p>
                            { selectedSale.folio }
                        </p>
                    </div>
                    <div className = ' d-flex '>
                        <SaleCTA 
                            printTicket = { printTicket } showPrintButtons = { showPrintButtons } 
                            sale = { selectedSale } userType = { userType }
                        />
                    </div>
                </div>
                <hr />
                <div className = ' row px-4 '>
                    <div className = ' col-4 pe-2 mt-2 '>
                        <p className = ' text_bold-label '>Fecha</p>
                        <p className = ' text_data '>{ formatDate( date ) }</p>
                    </div>
                    <div className = ' col-4 pe-2 mt-2 '>
                        <p className = ' text_bold-label '>Hora</p>
                        <p className = ' text_data '>{ time }</p>
                    </div>
                    <div className = ' col-4 pe-2 mt-2 '>
                        <p className = ' text_bold-label '>Tipo de compra</p>
                        <p className = ' text_data '>{ (purchaseType === 'cash' ? 'Contado' : 'Crédito') }</p>
                    </div>
                    <div className = ' col-4 pe-2 mt-4 '>
                        <p className = ' text_bold-label '>Tipo de pago</p>
                        <p className = ' text_data '>{ (paymentType === 'cash' ? 'Efectivo' : 'Tarjeta') }</p>
                    </div>
                    <div className = ' col-4 pe-2 mt-4 '>
                        <p className = ' text_bold-label '>Total de venta</p>
                        <p className = ' text_data '>{ formatMoney( total ) }</p>
                    </div>
                    <div className = ' col-4 pe-2 mt-4 '>
                        <p className = ' text_bold-label '>Compra Facturada</p>
                        <p className = ' text_data '>{ invoiceRequired ? 'Sí' : 'No'}</p>
                    </div>
                    <div className = ' col-4 pe-2 mt-4 '>
                        <p className = ' text_bold-label '>Descuento</p>
                        <p className = ' text_data '>{ discount > 0 ? formatMoney( discount ) : 'Sin descuento' }</p>
                    </div>
                    <div className = ' col-4 pe-2 mt-4 '>
                        <p className = ' text_bold-label '>Productos</p>
                        <p className = ' text_data '>{ products.length }</p>
                    </div>
                    <div className = ' col-6 pe-2 mt-4 '>
                        <p className = ' text_bold-label '>Cliente</p>
                        <p className = ' text_data '>{ client === 'public' ? 'Público' : client }</p>
                    </div>
                    { purchaseType === 'credit' && (
                        <div className = ' col-6 pe-2 mt-4 '>
                            <p className = ' text_bold-label '>Cuenta Pagada</p>
                            <p className = ' text_data '>{ creditPayed ? 'Pagado' : 'Por Cobrar' }</p>
                        </div>
                    )}
                    <div className = ' col-12 pe-2 mt-4 '>
                        <p className = ' text_bold-label '>Vendedor</p>
                        <p className = ' text_data '>{ user }</p>
                    </div>
                    <div className = ' col-12 pe-2 mt-4 '>
                        { !showProducts && (
                            <p className = 'btn purple-button mb-4' onClick = {() => setShowProducts(true)} >
                                Mostrar Productos
                            </p> 
                        )}
                        
                        { ( !!showProducts ) && (
                            <>
                                <p className = ' text_bold-label '>Productos</p>
                                <table className = "table text_data ">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Pieza</th>
                                            <th scope="col">Nombre</th>
                                            <th scope="col">Precio</th>
                                            <th scope="col">Cantidad</th>
                                            <th scope="col">Descuento</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* { saleProducts.length > 0 && saleProducts.map((product, index) => ( */}
                                        { products.length > 0 && products.map((product, index) => {
                                            const { code, discount, name, price_cash, price_credit } = product.productData;
                                            return (
                                                <tr key = { index }>
                                                    <th className = ' text_data ' scope="row">{ index + 1 }</th>
                                                    <td className = ' text_data '>{ code }</td>
                                                    <td className = ' text_data '>{ name }</td>
                                                    { purchaseType === 'cash' && (
                                                        <td className = ' text_data '>
                                                            { formatMoney( price_cash * ( 1 - (discount / 100)) )}
                                                        </td>
                                                    )}
                                                    { purchaseType === 'credit' && (
                                                        <td className = ' text_data '>
                                                            { formatMoney( price_credit )}  
                                                        </td>
                                                    )}
                                                    <td className = ' text_data text-center '>{ product.quantity }</td>
                                                    <td className = ' text_data text-center '>{ product.productData.discount }%</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </>
                        )}
                    </div>
                </div>
                <div style = {{ display: 'none' }}>
                    <TicketPrint
                        ref = { componentRef } 
                        { ...ticketPaymentData }
                        ticketType = { ticketType }
                        pagareName = { ticketPaymentData.client  }
                    />
                </div>
            </div>
        )
    }

    return (
        <div>
            {(selectedSale === '') && <UnselectedItemPlaceholder /> }
            {(selectedSale !== '') &&  <SaleDetail /> }
        </div>
    )
})