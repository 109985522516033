import firebase from 'firebase/compat/app';
import { products } from '../../../db/products';

export const getProductsNotifications = ( existence, limit ) => {
    return new Promise(async (resolve, reject) => {
        // await firebase.firestore().collection('inventario').orderBy('existencia', 'asc')
        // .where('highRotation', '==', true)
        // .where('existencia', '<=', existence)
        // .limit( limit )
        // .get()
        // .then( resolve )
        // .catch( reject )
        const resolveProducts = [];
        products.forEach((product, index, array) => {
            if( product.existencia <= 5 && product.highRotation ) {
                resolveProducts.push( product );
            }
            if( index === array.length - 1 ) {
                resolve( resolveProducts );
            }
        })
    })
}