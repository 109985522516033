
export const getProductFamily = ( group ) => {

    switch ( group ) {
        case '001': return 'Varios';
            break;
        case '002': return 'Aceite';
            break;
        case '003': return 'Acumulador';
            break;
        case '004': return 'Varios';
            break;
        case '005': return 'Eléctrico';
            break;
        case '006': return 'Suspensión y Dirección';
            break;
        case '007': return 'Motor';
            break;
        case '008': return 'Motor';
            break;
        case '009': return 'Clutch / Embrague / Transmisión';
            break;
        case '010': return 'Motor';
            break;
        case '011': return 'Frenos';
            break;
        case '012': return 'Motor';
            break;
        case '013': return 'Suspensión y Dirección';
            break;
        case '014': return 'Varios';
            break;
        case '015': return 'Motor';
            break;
        case '016': return 'Suspensión y Dirección';
            break;
        case '017': return 'Suspensión y Dirección';
            break;
        case '018': return 'Motor';
            break;
        case '019': return 'Motor';
            break;
        case '020': return 'Eléctrico';
            break;
        case '021': return 'Motor';
            break;
        case '022': return 'Enfriamiento';
            break;
        case '023': return 'Clutch / Embrague / Transmisión';
            break;
        case '024': return 'Suspensión y Dirección';
            break;
        case '025': return 'Eléctrico';
            break;
        case '026': return 'Frenos';
            break;
        case '027': return 'Motor';
            break;
        case '028': return 'Suspensión y Dirección';
            break;
        case '029': return 'Suspensión y Dirección';
            break;
        case '030': return 'Servicio / Afinación';
            break;
        case '031': return 'Eléctrico';
            break;
        case '032': return 'Eléctrico';
            break;
        case '033': return 'Clutch / Embrague / Transmisión';
            break;
        case '034': return 'Eléctrico';
            break;
        case '035': return 'Motor';
            break;
        case '036': return 'Motor';
            break;
        case '037': return 'Clutch / Embrague / Transmisión';
            break;
        case '038': return 'Eléctrico';
            break;
        case '039': return 'Frenos';
            break;
        case '040': return 'Clutch / Embrague / Transmisión';
            break;
        case '041': return 'Suspensión y Dirección';
            break;
        case '042': return 'Clutch / Embrague / Transmisión';
            break;
        case '043': return 'Suspensión y Dirección';
            break;
        case '044': return 'Suspensión y Dirección';
            break;
        case '045': return 'Suspensión y Dirección';
            break;
        case '046': return 'Motor';
            break;
        case '047': return 'Enfriamiento';
            break;
        case '048': return 'Motor';
            break;
        case '049': return 'Motor';
            break;
        case '050': return 'Motor';
            break;
        case '051': return 'Motor';
            break;
        case '052': return 'Motor';
            break;
        case '053': return 'Motor';
            break;
        case '054': return 'Motor';
            break;
        case '055': return 'Clutch / Embrague / Transmisión';
            break;
        case '056': return 'Motor';
            break;
        case '057': return 'Motor';
            break;
        case '058': return 'Suspensión y Dirección';
            break;
        case '059': return 'Enfriamiento';
            break;
        case '060': return 'Servicio / Afinación';
            break;
        case '061': return 'Servicio / Afinación';
            break;
        case '062': return 'Servicio / Afinación';
            break;
        case '063': return 'Suspensión y Dirección';
            break;
        case '064': return 'Varios';
            break;
        case '065': return 'Suspensión y Dirección';
            break;
        case '066': return 'Motor';
            break;
        case '067': return 'Clutch / Embrague / Transmisión';
            break;
        case '068': return 'Suspensión y Dirección';
            break;
        case '069': return 'Suspensión y Dirección';
            break;
        case '070': return 'Clutch / Embrague / Transmisión';
            break;
        case '071': return 'Motor';
            break;
        case '072': return 'Varios';
            break;
        case '073': return 'Frenos';
            break;
        case '074': return 'Frenos';
            break;
        case '075': return 'Suspensión y Dirección';
            break;
        case '076': return 'Motor';
            break;
        case '077': return 'Motor';
            break;
        case '078': return 'Motor';
            break;
        case '079': return 'Eléctrico';
            break;
        case '080': return 'Motor';
            break;
        case '081': return 'Suspensión y Dirección';
            break;
        case '082': return 'Motor';
            break;
        case '083': return 'Frenos';
            break;
        case '084': return 'Eléctrico';
            break;
        case '085': return 'Varios';
            break;
        case '086': return 'Varios';
            break;
        case '087': return 'Eléctrico';
            break;
        case '088': return 'Motor';
            break;
        case '089': return 'Suspensión y Dirección';
            break;
        case '090': return 'Varios';
            break;
        case '091': return 'Eléctrico';
            break;
        case '092': return 'Suspensión y Dirección';
            break;
        case '093': return 'Motor';
            break;
        case '094': return 'Motor';
            break;
        case '095': return 'Eléctrico';
            break;
        case '096': return 'Suspensión y Dirección';
            break;
        case '097': return 'Frenos';
            break;
        case '098': return 'Eléctrico';
            break;
        case '099': return 'Varios';
            break;
        case '100': return 'Enfriamiento';
            break;
        case '101': return 'Varios';
            break;
        case '102': return 'Enfriamiento';
            break;
        case '103': return 'Enfriamiento';
            break;
        case '104': return 'Suspensión y Dirección';
            break;
        case '105': return 'Suspensión y Dirección';
            break;
        case '106': return 'Servicio / Afinación';
            break;
        case '107': return 'Motor';
            break;
        case '108': return 'Varios';
            break;
        case '109': return 'Suspensión y Dirección';
            break;
        case '110': return 'Suspensión y Dirección';
            break;
        case '111': return 'Eléctrico';
            break;
        default: return 'Varios';
    }

}