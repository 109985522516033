import React from 'react'

export const PaymentResumenHeader = ({  printQuote, printVoucher }) => {
    return (
        <div className = ' pt-4 pb-2 '>

            <div className=' ps-sm-3 pe-sm-1 ps-2 '>

                <div className=' header_title '>
                    <h2 className=' section-title mb-0 '>Resumen de venta</h2>
                </div>

                <div className = ' mt-4  '>
                    <div className=' d-flex align-items-center '>
                        <p  className = ' flex-grow-1 text_bold-label '>
                            Imprimir
                        </p> 
                        <div 
                            className = ' button secondary-button me-3'
                            onClick = { printQuote }
                        >
                            COTIZACION
                        </div>
                        <div 
                            className=' button purple-button px-3 '
                            onClick = { printVoucher }
                        >
                            VALE
                        </div>
                    </div>
                </div>


            </div>
        </div>
    )
}
