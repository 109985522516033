import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';

export const useCars = () => {

    const [ cars, setCars ] = useState([]);
    const [ showCars, setShowCars ] = useState([]);

    useEffect(() => {
        getCars();
    }, []);

    const getCars = () => {
        firebase.firestore().collection('cars').doc('cars').get().then(doc => {
            let carsArray = [];
            doc.data().models.forEach(brand => {
                carsArray.push(brand);
            })
            setCars([...carsArray]);
            setShowCars([...carsArray]);
        });
    }

    return { 
        cars,
        showCars,
        getCars,
        setShowCars,
    }
}
