// import firebase from 'firebase/compat/app';
// import { apiUrl } from '../../../api/url';

/* 
 *      Get the product info from database with its Code or Piece Number.
 *      @params { string } code.
 */
export const getProductByCode = ( codeSearch, group, products ) => {
    return new Promise(async (resolve, reject) => {
        const resolveProducts = [];
        const code = codeSearch.trim().toUpperCase();
        if( group === 'all' ) {
            products.forEach((product, index, array) => {
                const { descripcion, nombre, pieza } = product;
                if( nombre.toUpperCase().includes( code ) || descripcion.toUpperCase().includes( code ) || pieza.toUpperCase().includes( code ) ) {
                    resolveProducts.push( product );
                }
                if( index === array.length - 1) {
                    resolve( resolveProducts );
                }
            })    
        } else if( group !== 'all' ) {
            products.forEach((product, index, array) => {
                const { descripcion, nombre, pieza } = product;
                if( product.family === group ) {
                    if( nombre.toUpperCase().includes( code ) || descripcion.toUpperCase().includes( code ) || pieza.toUpperCase().includes( code ) ) {
                        resolveProducts.push( product );
                    }
                }
                if( index === array.length - 1) {
                    resolve( resolveProducts );
                }
            })  
        }
    })
}