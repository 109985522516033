import React from 'react'
import { formatDate } from '../../helpers/formatDate';
import { formatMoney } from '../../helpers/formatMoney';

export const HistorySaleItem = ({ activeSale, sale, i, setSelectedSale, setActiveSale }) => {

    const { canceled, completed, date, folio, total } = sale;

    const SaleIcon = ({ sale }) => {
        const { canceled, completed, paymentType } = sale;
        if( (!completed && completed !== undefined) || (canceled && canceled !== undefined) ) {
            return (
                <div className = ' icons_red-icon '>
                        <ion-icon name = 'alert-circle-outline'></ion-icon>
                    </div>
            )
        } else {
            return (
                <div className = {` 
                        ${ paymentType === 'cash' ? 'icons_purple-icon' : 'icons_blue-icon'  }
                    `}>
                        <ion-icon name = {( paymentType === 'cash' ? 'cash-outline' : 'card-outline')}></ion-icon>
                    </div>
            )
        }
    }

    const SaleInfo = ({ sale }) => {
        const { canceled, completed } = sale;
        if( (!completed && completed !== undefined) || (canceled && canceled !== undefined) ) {
            return (<span className='bold'>CANCELADA</span> )
        } else {
            return ( <p>{ formatMoney(total) }</p> )
        }
    }

    return (
        <div
            className = {
                activeSale === sale 
                    ? ' history_sales-item history_sales-item-active mb-2 p-3 rounded me-3 ' 
                    : ' history_sales-item mb-2 p-3 rounded d-flex me-3 '
                + ` d-flex align-items-center `
            }
            key={i}
            onClick={() => {setSelectedSale(sale); setActiveSale(sale)}}
        >
            <SaleIcon sale = { sale } />
            <div className = ' flex-grow-1 '>
                <p>
                    <span className='bold'>Fecha </span>{formatDate( date.getTime() )} -
                    <span className='bold'> Folio</span> { folio }
                </p>
            </div>
            <div className=' text-end '>
                <SaleInfo sale = { sale } />
            </div>
        </div>
    )
}
