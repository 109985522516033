import React, { useState } from 'react';
import { formatMoney } from '../../helpers/formatMoney';

export const IncomeStatisticsList = ({ setSelectedSale, sales }) => {
 
    const [active, setActive] = useState(0);

    const elementSelected = (day, index) => {
        setSelectedSale(day)
        setActive(index);
    }

    console.log( sales );

    return (
        <div className = ' pt-4 '>
            { sales.length > 0 && (
                sales.map((day, index) => (
                    <div 
                        className = {` directory_list-container px-4 ${ active === index && 'active' } `}
                        key = { index }
                        onClick = { () => elementSelected(day, index) }
                    >
                        <div className = ' icons_purple-icon '>
                            <ion-icon name="cash-outline"></ion-icon>
                        </div>
                        <div>
                            <p>Día { day.day }</p>
                            <p className = ' text_instructions  '>
                                { formatMoney( day.total ) }
                            </p>
                        </div>
                    </div>
                ))
            )}
            { sales.length === 0 && (
                <div className = ' px-4 '>
                    <p>No hay ventas que mostrar.</p>
                </div>
            )}
        </div>
    )

}
