import React from 'react';
import { formatMoney } from './../../../helpers/formatMoney';

export const ChoosePaymentMethod = ({ ammounts, moneyType, handleChangeCalculation, setMoneyType }) => {
  
    return (
        <div>
            <p className='h6 mb-2'>Selecciona el método de pago.</p>
							
            <div>
                <div 
                    className = {`ChargeModal-payment-type ${ (moneyType === 'cash') && 'active' } `}
                    onClick = { () => setMoneyType('cash') }
                >
                    <ion-icon name="cash-outline"></ion-icon>
                    <span>Efectivo</span>
                </div>
                <div 
                    className = {`ChargeModal-payment-type mt-2  ${ (moneyType === 'card') && 'active' }`}
                    onClick = { () => setMoneyType('card') }
                >
                    <ion-icon name="card-outline"></ion-icon>
                    <span>Tarjeta</span>
                </div>
            </div>

            {/* {(moneyType === 'cash') && (
                <div className = ' mt-4 '>
                    <p className='h6 mb-2'>Calcula el cambio.</p>
                    <input 
                        className = ' ChargeModal-change-input ' 
                        onChange = { handleChangeCalculation }
                        type = 'number' 
                    />
                    { ammounts.change >= 0 && (
                        <p className = ' ChargeModal-change-result mt-2 '>El cambio es de { formatMoney( ammounts.change ) }.</p>
                    )}
                    { ammounts.change < 0 && (
                        <p className = ' ChargeModal-change-result mt-2 '>Faltan { formatMoney( ammounts.change * - 1 ) }.</p>
                    )}
                </div>
            )} */}

        </div>
    )

}
