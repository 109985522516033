import React from 'react';

import { Link } from 'react-router-dom'
import logoicon from './logoicon.png'
import { RecoverForm } from '../../../Components/AuthComponents/RecoverForm'

export const RecoverPasswordScreen = () => {
	return (
		<>
		<div className =  ' row  ' >
			<div className = ' login_image-container min-vh-100 col-sm-12 col-md-4 d-none d-md-flex '></div>
			<div className =  ' col-xs-12 col-md-8 col-lg-6 '>
				<div className = 
					' d-flex p-5 align-items-center justify-content-sm-center justify-content-md-start mx-4 mx-md-2 '
				>
					<img
						src = { logoicon }
						alt = 'icono-logo'
						className = ' login_logo '
					/>
				</div>
				<div className = ' mx-5 '>
					<p className = ' section-title '>
						Recupera tu contraseña.
					</p>
					<p className = ' login_subtitle '>Ingresa el correo electrónico para recibir el correo para reestablecer tu contraseña.</p>
					<RecoverForm />

					<div className = ' pt-4 '>
						<Link className = ' text-button-purple ' to = '/' >Regresar a Inicio de Sesión</Link>
					</div>
				</div>

			</div>
		</div>
		</>
	)
}
