import React, { useEffect } from 'react';
import { useAuth } from '../../../hooks/useAuth';

import { ProfileInfoContainer } from '../ProfileInfoContainer/ProfileInfoContainer';
import { ProfileEditableContainer } from '../ProfileInfoContainer/ProfileEditableContainer';
import { UploadImageComponent } from '../../UploadImageComponent/UploadImageComponent';
import { ProfileStatistics } from '../ProfileStatisticsComponent/ProfileStatistics';

export const ProfileContainer = ({ editable, setEditable, profileEdit, uploadImage, setUploadImage }) => {
	
	const { userInfo, signout, getUserInfo } = useAuth();

	useEffect(() => {
		let unsubscribe = true;

		getUserInfo().then(response => {
			if(unsubscribe) {
				console.log(response)
			}
		})

		return () => unsubscribe = false


	}, [])
	
	return (
		<>
			<div className = ' d-flex align-items-center py-4 '>
				<div className = ' d-flex flex-grow-1 '>
					{ editable ? 
						<UploadImageComponent defaultImg = { userInfo.image } userType = 'profile' uploadImage = { uploadImage } setUploadImage = { setUploadImage } />
					:
						<img className = ' profile_image ' src = { userInfo.image } alt = 'profile'></img>
					}
					<div className = {` d-flex justify-content-center flex-column ${ editable && 'ms-3' } `}>
						<p className = ' h2 mb-0 ' >{ userInfo.name }</p>
						<p>{ userInfo.position }</p>
					</div>
				</div>
				<div className = ' d-flex ' >
					<div 
						className = ' d-flex align-items-center button purple-icon-button me-2 ' 
						onClick = { () => setEditable(!editable) }
					>
						<ion-icon name = "create-outline"></ion-icon>
						{ editable ? 'Cancelar' : 'Editar' }
					</div>
					<div className = ' button logout-red-button px-3 ' onClick = {() => signout()} >
						<ion-icon name="power-outline"></ion-icon>
					</div>
				</div>
			</div>
			<div className = ' row '>
				{
					editable ? <ProfileEditableContainer profileEdit = { profileEdit } uploadImage = { uploadImage }/> : <ProfileInfoContainer/>
				}
				<div className = {` Profile-statistics-container col-5 `}>
					<ProfileStatistics/>
				</div>
			</div>
		</>
	)
}
