import React, { useState } from 'react';
import { InputComponent } from '../../InputComponent/InputComponent';
import { invoiceUses } from '../../../helpers/invoiceUses';
import Select from 'react-select';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import './../../../Theme/globalStyles.css';
import { useAuth } from '../../../hooks/useAuth';

const invoiceInitialState = {
    name: '',
    rfc: '', 
    email: '',
    use: '',
    cp: '',
    ammount: 0,
    purchseID: '',
}

export const RequiresInvoice = ({ cancelInvoice, closeModal, invoiceData, moneyType, saveData }) => {
    
    const { user } = useAuth();
    const [ invoice, setInvoice ] = useState(invoiceInitialState);

	 /*
     *  Handle formik form submission.
     * */
    const formik = useFormik({
        initialValues: {
            invoiceName: invoiceData.name,
            invoiceRfc: invoiceData.rfc,
            invoiceEmail: invoiceData.email,
            invoiceUse: invoiceData.use,
            invoicePostCode: invoiceData.postCode,
            invoicePhoneNumber: invoiceData.phoneNumber,
        },
        validationSchema: Yup.object({
			invoiceName: Yup.string()
						.required('El nombre del comprador es obligatorio'),
			invoiceRfc: Yup.string()
						.required('El RFC del comprador es obligatorio')
						.min(12, 'El rfc debe tener 12 o 13 caractéres.')
						.max(13, 'El rfc debe tener 12 o 13 caractéres.'),
			invoiceEmail: Yup.string()
						.required('El correo del comprador es obligatorio.'),
			invoiceUse: Yup.string()
						.required('El uso de la factura es obligatorio.'),
            invoicePostCode: Yup.number()
                        .required('El Código Postal es obligatorio.'),
            invoicePhoneNumber: Yup.number()
                        .typeError('El número de teléfono debe indicarse con un número')
                        .required('El número de teléfono es obligatorio.'),
        }),
        onSubmit: invoice => {
			const invoiceD = {
				name: invoice.invoiceName,
				rfc: invoice.invoiceRfc,
				email: invoice.invoiceEmail,
				use: invoice.invoiceUse,
                paymentType: moneyType,
                seller: user.uid,
                phoneNumber: invoice.invoicePhoneNumber,
                postCode: Number(invoice.invoicePostCode),
			}
			saveData({ ...invoiceD });
			closeModal();
        }
    })

	/*
     *  Handle when an Select is modified.
     *  @param { event } e Input event.
     * */
	const handleUseChange = ({ value }) => {
		formik.setFieldValue(`invoiceUse`, value);
	}

    return (
        <div className = ' modal  '>
            <div className={` modal-content `}>
                <div className={` modal-header d-flex align-items-center `}>
                    <p className='section-title'>Datos de factura</p>
                </div>
                <div className={` modal-body `}>

                    <form 
						className = ' row '
						onSubmit = { formik.handleSubmit }
					>
                        <div className = ' col-12 col-sm-6 pe-sm-3 '>
                            <InputComponent 
                                label = 'Nombre completo'
                                id = 'invoiceName'
                                // onChange = { ( e ) => handleInputChange( e, 'name' ) }
                                onChange = { formik.handleChange }
								value = { formik.values.invoiceName }
                                placeholder = 'Ingresa el nombre del cliente'
                            />
                            { formik.touched.invoiceName && formik.errors.invoiceName ? (
                                <div>
                                    <p className={` error-label `}>{ formik.errors.invoiceName }</p>
                                </div>
                            ) : null}
                        </div>
                        <div className = ' col-12 col-sm-6 pe-sm-3 '>
                            <InputComponent 
                                label = 'RFC'
                                id = 'invoiceRfc'
                                // onChange = { ( e ) => handleInputChange( e, 'rfc' ) }
								onChange = { formik.handleChange }
								value = { formik.values.invoiceRfc }
                                placeholder = 'Ingresa el RFC del cliente'
                            />
                            { formik.touched.invoiceRfc && formik.errors.invoiceRfc ? (
                                <div>
                                    <p className={` error-label `}>{ formik.errors.invoiceRfc }</p>
                                </div>
                            ) : null}
                        </div>
                        <div className = ' col-12 col-sm-6 pe-sm-3 '>
                            <InputComponent 
                                label = 'Correo Electrónico'
                                id = 'invoiceEmail'
                                // onChange = { ( e ) => handleInputChange( e, 'email' )}
								onChange = { formik.handleChange }
								value = { formik.values.invoiceEmail }
                                placeholder = 'Ingresa el correo del cliente'
                            />
                            { formik.touched.invoiceEmail && formik.errors.invoiceEmail ? (
                                <div>
                                    <p className={` error-label `}>{ formik.errors.invoiceEmail }</p>
                                </div>
                            ) : null}
                        </div>
                        <div className = ' col-12 col-sm-6 pe-sm-3 mt-1 '>
                            <p className = ' input-label '>Uso de la factura</p>
                            <Select 
                                id = 'invoiceUse'
                                options = { invoiceUses }
								onChange = { handleUseChange }
                            />
                            { formik.touched.invoiceUse && formik.errors.invoiceUse ? (
                                <div className = ' mt-3 ' >
                                    <p className={` error-label `}>{ formik.errors.invoiceUse }</p>
                                </div>
                            ) : null}
                        </div>
                        <div className = ' col-12 col-sm-6 pe-sm-3 mt-3 mt-sm-0 '>
                            <InputComponent 
                                label = 'Código Postal'
                                id = 'invoicePostCode'
                                number = { true }
								onChange = { formik.handleChange }
								value = { formik.values.invoicePostCode }
                                placeholder = 'Ingresa el código postal del cliente'
                            />
                            { formik.touched.invoicePostCode && formik.errors.invoicePostCode ? (
                                <div>
                                    <p className={` error-label `}>{ formik.errors.invoicePostCode }</p>
                                </div>
                            ) : null}
                        </div>
                        <div className = ' col-12 col-sm-6 pe-sm-3 '>
                            <InputComponent 
                                label = 'Télefono'
                                id = 'invoicePhoneNumber'
								onChange = { formik.handleChange }
								value = { formik.values.invoicePhoneNumber }
                                placeholder = 'Ingresa el teléfono del cliente'
                            />
                            { formik.touched.invoicePhoneNumber && formik.errors.invoicePhoneNumber ? (
                                <div>
                                    <p className={` error-label `}>{ formik.errors.invoicePhoneNumber }</p>
                                </div>
                            ) : null}
                        </div>
                        

						{/* Modal Footer  */}
						<div className={` modal-footer `}>
							<div>
								<button
									className = ' button cancel-button me-3 '
									onClick = { () => {
                                        cancelInvoice();
                                        closeModal();
                                    }}
                                    type='reset'

								>
									Cancelar
								</button>
								<button
									className = ' button purple-button '
									type = 'submit'
								>
									Guardar
								</button>
							</div>
                		</div>

                    </form>
                    
                </div>
                
            </div>
        </div>
    )

}
