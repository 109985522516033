import React, { useContext, useEffect, useState } from 'react';
import { NewSupplierModal } from '../../Components/SupplierComponents/NewSupplierModal/NewSupplierModal';
import { Modal } from '../../Components/Modal/Modal';
import { useProveedores } from '../../hooks/useProveedores';
import { SupplierDirectoryHeader } from '../../Components/SupplierComponents/SupplierDirectory/SupplierDirectoryHeader';
import { SupplierDirectoryList } from '../../Components/SupplierComponents/SupplierDirectory/SupplierDirectoryList';
import { SupplierDirectoryDetail } from '../../Components/SupplierComponents/SupplierDirectory/SupplierDirectoryDetail';

import { ProductContext } from '../../Context/ProductContext';
import { DirectoryContainer } from '../../Components/DirectoryComponents/DirectoryContainer';
import { DirectoryHeader } from '../../Components/DirectoryComponents/DirectoryHeader';

export const SuppliersScreen = () => {

    const [ openModal, setOpenModal ] = useState(false);
    const { 
        editable, proveedorSelected, uploadImage, 
        editSupplier, savedData, formik,
        setEditable, setProveedorSelected, setUploadImage, removeSupplier,
    } = useProveedores();

    const { allSuppliers, loadSuppliers } = useContext( ProductContext );

    return (
        <div className = {` `} >
            <div className = {` row mx-0 px-0 `}>
                <div className = {` col-lg-5 pt-4 white-background mx-0 `}>
                    <DirectoryContainer>
                        <div className = ' px-3  '>
                            <DirectoryHeader
                                updateData = { loadSuppliers }
                                content = {{ title: 'Proveedores', subtitle: 'Busca tus proveedores en el directorio' }}
                                actions = {[
                                    { action: () => loadSuppliers(), icon: 'refresh-outline' },
                                    { action: () => setOpenModal(true), icon: 'add-outline' },
                                ]}
                                input = {{ 
                                    show: true, 
                                    placeholder: 'Ingresa el nombre del proveedor', 
                                    action: () => console.log('Editando'),
                                }}
                            />
                        </div>
                        <SupplierDirectoryList 
                            setProveedorSelected = { setProveedorSelected }
                            suppliers = { allSuppliers } 
                        />
                    </DirectoryContainer>
                </div>
                <div className = {` col-lg-7 `}>
                    <SupplierDirectoryDetail 
                        editable = { editable } 
                        editForm = { editSupplier }
                        user = { proveedorSelected } 
                        setEditable = { setEditable }
                        uploadImage = { uploadImage }
                        setUploadImage = { setUploadImage }
                        removeUser = { removeSupplier }
                    />
                </div>
            </div>


            { !!openModal && (
                <Modal>
                    <NewSupplierModal 
                        closeModal = {() => setOpenModal(false)}
                        addSupplier = { formik }
                        savedData = { savedData }
                        uploadImage = { uploadImage }
                        setUploadImage = { setUploadImage }
                    />
                </Modal>
            )}
        </div>
    );

}