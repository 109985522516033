import firebase from 'firebase/compat/app';

/*
 *    Update the check property of a special bought product.
 *    @param { productID } string.
 *     @param { state } boolean. 
 */
export const setSpecialProductChecked = ( productID, state ) => {
    return new Promise((resolve, reject) => {
        firebase.firestore().collection('specialProductsSold')
            .doc( productID )
            .update({ checked: state })
            .then(() => {
                resolve( 'Success' )
            })
            .catch( reject );
    })
}