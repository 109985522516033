import React, { useEffect, useState } from 'react'

export const UploadImageComponent = ({ defaultImg, userType, uploadImage, setUploadImage }) => {

	const [ preview, setPreview ] = useState(defaultImg)
	const [ image, setImage ] = useState(null)

	useEffect(() => {

		if (image) {
			if (uploadImage) {
				const reader = new FileReader();
				reader.onloadend = () => {
					setPreview(reader.result)
				}
				reader.readAsDataURL(image)
				// console.log(uploadImage)
			} else {
				setPreview(defaultImg)
			}
		} else {
			setUploadImage(null)
		}

	}, [image])

	return (
		<div className = ' uploadimage_container '>
			<div>
				{ userType === 'product' ?
					<img className = { ' uploadimage_product-photo ' }  src = { preview } alt = 'default' id = 'default-img' />
				:
					<img className = { userType === 'newUser' ? ' uploadimage_new-photo ' : ' uploadimage_profile-photo ' }  src = { preview } alt = 'default' id = 'default-img' />
				}
			</div>
			<div>
				<input 
					type = 'file' name = 'image-upload' 
					id = 'img-upload' accept = 'image/*'  
					onChange = {(event) => { 
						const file = event.target.files[0];
						if (file) {
							setImage(file);
							setUploadImage(file)
						} else {
							setUploadImage(null)
						}
					}}
				/>
				{ (userType === 'profile') &&  (<label htmlFor = 'img-upload' className = ' uploadimage_profile-choose-image button purple-button' ><ion-icon name="camera-outline"></ion-icon></label>) }
				{ (userType === 'proveedor' || userType === 'user' || userType === 'client') &&  (<label htmlFor = 'img-upload' className = 'uploadimage_supplier-choose-image button purple-button' ><ion-icon name="camera-outline"></ion-icon>Cambiar Foto de Perfil </label>) }
				{ (userType === 'newUser') &&  (<label htmlFor = 'img-upload' className = ' uploadimage_supplier-choose-image button purple-button' >Seleccionar Fotografía (opcional)</label>) }
				{ (userType === 'product') &&  (<label htmlFor = 'img-upload' className = ' uploadimage_product-choose-image button purple-button' >Seleccionar Imagen de Producto</label>) }
			</div>
		</div>
	)
}
