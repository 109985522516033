import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { onAddNewProduct, onDeleteProduct, onEditProduct, onProductSold } from '../../store';

export const useProductsSlice = () => {

    const dispatch = useDispatch();

    const {  
        products,
    } = useSelector( state => state.products );
    
    const handleProductSold = ( modifiedID, amountSold ) => { 
        const soldIndex = products.findIndex( product => product.id === modifiedID );
        const soldProduct = products[soldIndex];
        const localProducts = [ ...products ];
        const { existencia } = soldProduct;
        
        localProducts[soldIndex] = { 
            ...soldProduct,
            existencia: existencia - amountSold,
        }
        dispatch( onProductSold( localProducts ) );
    }

    const handleAddNewProduct = ( product ) => {
        product.id = `${products.length + 1}`;
        product.image = 'https://firebasestorage.googleapis.com/v0/b/pos-web-a98f9.appspot.com/o/placeholders%2Ffamily%2F002.png?alt=media&token=9799d012-d3f0-4568-ada1-a365a70d2b6d';
        dispatch( onAddNewProduct( product ) );
    }

    const handleDeleteProduct = ( id ) => {
        dispatch(onDeleteProduct( id ));
    } 

    const handleEditProduct = ( id, data ) => {
        const temp = [ ...products ];
        const index = temp.findIndex(product => product.id === id);
        temp[index] = {
            ...temp[index],
            ...data,
        }
        dispatch(onEditProduct( temp ))
    }

    return {
        //* Properties.
        products,

        //* Methods.
        handleAddNewProduct,
        handleDeleteProduct,
        handleEditProduct,
        handleProductSold,
    };
}
