import React, { useContext } from 'react'
import { ProductContext } from '../../../Context/ProductContext'
import { DetallesProductoInfo } from '../../InventarioComponents/DetallesProducto/DetallesProductoInfo';

import './DetalleProductoVenta.css';

export const DetalleProductoVenta = ({ producto, closeModal, venta = false }) => {

    const { addToCart } = useContext(ProductContext);

    const { 
        aplicaciones, descripcion, existencia, family, image, nombre, pieza, 
        costo, precio_contado, precio_credito, proveedor, highRotation
    } = producto;

    return (
        <div className={` modal DetalleProductoVenta-container `}>
            <div className={` modal-content `}>
                <div className={` modal-header d-flex row `}>
                    <div className = ' col-12 col-sm-8 '>
                        <p className = ' section-title text-center text-sm-start '>{ nombre }</p>
                    </div>
                    <div className = ' col-12 col-sm-4 '>
                        <div className = ' d-flex align-items-center justify-content-center justify-content-sm-end mb-0 '>
                            <div className='button cancel-button mb-0' onClick = { closeModal }>CANCELAR</div>
                            { ( existencia > 0) && (
                                <div
                                    className='button purple-button ms-3' 
                                    onClick = { () => {
                                        addToCart(producto) 
                                        closeModal()
                                    }} 
                                >
                                    AGREGAR
                                </div>
                            )}
                            { ( existencia <= 0) && (
                                <div 
                                    className='button purple-button ms-3'
                                    onClick = { () => {
                                        addToCart(producto)
                                        closeModal()
                                    }}
                                >
                                    PEDIDO ESPECIAL
                                </div>
                            )}   
                        </div>
                    </div>
                </div>

                <div className={` modal-body `}>
                    <DetallesProductoInfo producto = { producto } venta = { venta } />
                </div>

                <div className={` modal-footer `}>
                    
                </div>
            </div>
        </div>
    )
}
