import firebase from 'firebase/compat/app';


/* 
 *      Update a document info in the database.
 *      @params { string } collection. 
 *      @params { string } docID. 
 *      @params { object } data. Data you want to update.
 */
export const updateDocument = ( collection, docID, data ) => {
    return new Promise(async (resolve, reject) => {
        await firebase.firestore().collection( collection )
            .doc(docID)
            .update(data)
            .then(res => {
                resolve(res);
            })
            .catch( reject );
    })
}