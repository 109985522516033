import React, { useEffect, useState } from 'react';
// import firebase from 'firebase/compat/app';
import { formatMoney } from './../../../helpers/formatMoney'
import { StatsItem } from '../StatsItem/StatsItem';
import { demoSalesStats } from '../../../helpers/sales/demoSalesStats';
import { useSalesSlice } from '../../../hooks/redux/useSalesSlice';

export const Stats = ({ title }) => {

    const { salesStats } = useSalesSlice();

    const [ todaySales, setTodaySales ] = useState(0);
    const [ todayCashSales, setTodayCashSales ] = useState(0);
    const [ todayCreditSales, setTodayCreditSales ] = useState(0);
    const [ todayNumberSales, setTodayNumberSales ] = useState(0);
    const [ todayNumberSalesCash, setTodayNumberSalesCash ] = useState(0);
    const [ todayNumberSalesCredit, setTodayNumberSalesCredit ] = useState(0);
    const [ monthSales, setMonthSales ] = useState(0);

    useEffect(() => {
        let unsubscribe = true;
        getTodaySales( demoSalesStats.data );
        getMonthSales( demoSalesStats.data );
        return () => unsubscribe = false;
    }, []);

    const getTodaySales = (salesArray) => {

        let today = new Date().getDate()

        if(salesArray) {
            let todaySale = salesStats[ demoSalesStats.data.length - 1]
            if(todaySale) {
                setTodayCashSales(todaySale.totalCash);
                setTodayCreditSales(todaySale.totalCredit);
                setTodaySales(todaySale.total);
                setTodayNumberSales(todaySale.sales);
                setTodayNumberSalesCash(todaySale.salesCash);
                setTodayNumberSalesCredit(todaySale.salesCredit);
            }
        }
    }

    const getMonthSales = (salesArray) => {

        let monthTotal = 0;

        if(salesArray) {
            salesArray.forEach(sale => {
                monthTotal = monthTotal + sale.total;
            })
            setMonthSales(monthTotal)
        }

    }


    return (
        <div className = {`  `}>
            <p className = 'section-title'>{ title }</p>
            <div className = {` ms-3 `}>
                <div className = ' row d-flex mb-1 '>

                    <div className = {` col-lg-12 mb-3 `}>
                        <StatsItem title = 'Ventas del mes' data = { formatMoney( monthSales ) } />
                    </div>
                    <div className = {` col-lg-6 mb-3 `}>
                        <StatsItem title = 'Ventas del día' data = { formatMoney( todaySales )  } />
                    </div>
                    <div className = {` col-lg-6 mb-3 `}>
                        <StatsItem title  = 'Ventas hoy' data = { todayNumberSales } />
                    </div>
                    <div className = {` col-lg-6 mb-3 `}>
                        <StatsItem title = 'Total del día contado' data = { formatMoney( todayCashSales )  } />
                    </div>
                    <div className = {` col-lg-6 mb-3 `}>
                        <StatsItem title = 'Total del día crédito' data = { formatMoney( todayCreditSales )  } />
                    </div>
                    <div className = {` col-lg-6 mb-3 `}>
                        <StatsItem title  = 'Ventas hoy contado' data = { todayNumberSalesCash } />
                    </div>
                    <div className = {` col-lg-6 mb-1 `}>
                        <StatsItem title  = 'Ventas hoy credito' data = { todayNumberSalesCredit } />
                    </div>

                </div>
            </div>
        </div>
    );

};
