import firebase from 'firebase/compat/app';

export const updateSaleState = ( docID, state ) => {
    return new Promise( async (resolve, reject) => {
        try {
            const query = await firebase.firestore().collection('sales').doc(docID).update({ completed: state })
            resolve( query );
        } catch(e) {
            reject( e );
        }
    });
}