import firebase from 'firebase/compat/app';

/* 
 *    Function that registers on the data base if a special product was sold.
 *    @param { object } cart - cart from Context.
 *    @param { object } paymentData - previously prepared payment data. 
 */

export const registerSpecialProduct = ( cart, paymentData ) => {
    return new Promise((resolve, reject) => {

        cart.forEach(product => {
            if( (product.id).includes('generic-stock') ) {
                const { amount, code, description, name, price_cash } = product;
                firebase.firestore().collection('specialProductsSold')
                    .add({
                            amount: amount,
                            checked: false,
                            client: paymentData.client,
                            code: code,
                            dateSold: new Date(),
                            description:description,
                            name: name,
                            price: price_cash,
                            seller: paymentData.user,
                    })
                    .then(res => {
                        resolve( res );
                    })
                    .catch(err => reject(err));
            } else {
                resolve('Not special product');
            }
        })
    })
}