import React, { useState } from 'react'
import firebase from 'firebase/compat/app';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { usePurchase } from './usePurchase';
import { AlertSuccess } from '../Components/AlertsComponents/AlertSuccess';

export const useClientHistory = () => {

    const { stateUpdater } = usePurchase();
    
    const { saveSaleStatistics, saveSellerStatistics } = stateUpdater;

	const MySwal = withReactContent(Swal);

    const [ filter, setFilter ] = useState('today')
    const [ isLoading, setIsLoading ] = useState(false);
    const [ lastDocument, setLastDocument ] = useState([]);
    const [ sales, setSales ] = useState([]);
    const [ seeMore, setSeeMore ] = useState(true);

    const filterOptions = [
        { label: 'Hoy', value: 'today' },
        { label: 'Mes Actual', value: 'month' },
        { label: 'Mes Pasado', value: 'two-month' },
        { label: 'Mes Antepasado', value: 'three-month' },
        { label: 'Todas del Año', value: 'year' },
    ];

    const handleFilterChange = (e) => {
        setFilter(e.value);
    }

    const filterSelection = () => {
        let date = new Date();
        const month = date.getMonth();
        const year = date.getFullYear();
        let lastDate;

        return new Promise((resolve, reject) => {
            if(filter === 'today') {
                lastDate = new Date();
                lastDate.setHours(0)
                lastDate.setMinutes(0)
                lastDate.setSeconds(0)
                lastDate.setMilliseconds(0)
                resolve({date, lastDate})
            } else if(filter === 'month') {
                date = new Date();
                lastDate = new Date(year, month, 1)
                resolve({date, lastDate})
            } else if (filter === 'two-month') {
                date = new Date(year, month, 0)
                lastDate = new Date(year, month-1, 1);
                resolve({date, lastDate})
            } else if (filter === 'three-month') {
                date = new Date(year, month-1, 0)
                lastDate = new Date(year, month-2, 1);
                resolve({date, lastDate})
            } else if (filter === 'year') {
                date = new Date();
                lastDate = new Date(year, 0, 1);
                resolve({date, lastDate})
            } else if (filter === 'refresh'){
                lastDate = new Date();
                lastDate.setHours(0)
                lastDate.setMinutes(0)
                lastDate.setSeconds(0)
                lastDate.setMilliseconds(0)
                resolve({date, lastDate})
            } else {
                reject('No existe al opción')
            }

        })
    }

	const getClientSales = ( userID, date, lastDate ) => {

        setIsLoading( true );
        setSales( [] );
        return firebase.firestore()
            .collection('sales')
            .orderBy('date', 'desc')
            .where('client', '==', userID)
            .where('creditPayed', '==', true)
            .where('date', '<=', date)
            .where('date', '>=', lastDate)
            .limit(10)
            .get()

    }

	const getClientCreditSales = ( userID, date, lastDate ) => {
        setIsLoading( true );
        setSales( [] );
        return firebase.firestore()
            .collection('sales')
            .orderBy('date', 'desc')
            .where('client', '==', userID)
            .where('purchaseType', '==', 'credit')
            .where('creditPayed', '==', false)
            .where('date', '<=', date)
            .where('date', '>=', lastDate)
            .limit(8)
            .get()
    }

    const setClientSales = ( sales ) => {
        sales.docs.forEach( doc => {
            const sale = {
                id: doc.id,
                date: doc.data().date,
                folio: doc.data().folio,
                total: doc.data().total,
                discount: doc.data().discount,
                products: doc.data().products.length,
                invoice: doc.data().invoiceRequired,
                purchaseType: doc.data().purchaseType,
                paymentType: doc.data().paymentType,
                user: doc.data().user,
            }
            setSales(state => [...state, sale]);
        })
        setLastDocument(sales.docs[sales.docs.length - 1]);
        setIsLoading(false)
    }

    const getSeeMoreSales = ( userID, date, lastDate ) => {

        firebase.firestore()
            .collection('sales')
            .orderBy('date', 'desc')
            .where('client', '==', userID)
            .where('creditPayed', '==', true)
            .where('date', '<=', date)
            .where('date', '>=', lastDate)
            .startAfter( lastDocument )
            .limit(10)
            .get().then(moreSales => {
                let tempSales = [];
                moreSales.docs.forEach(doc => {
                    const sale = {
                        id: doc.id,
                        date: doc.data().date,
                        total: doc.data().total,
                        discount: doc.data().discount,
                        folio: doc.data().folio,
                        products: doc.data().products.length,
                        invoice: doc.data().invoiceRequired,
                        purchaseType: doc.data().purchaseType,
                        paymentType: doc.data().paymentType,
                    }
                    tempSales.push(sale);
                })
                setSales(state => [ ...state, ...tempSales ]);
                setLastDocument(moreSales.docs[moreSales.docs.length - 1]);
                if(moreSales.docs.length < 2) {
                    setSeeMore(false);
                }
            }).catch(e => {
                console.log(e)
            })

    }

    const getSeeMoreCreditSales = ( userID, date, lastDate ) => {

        firebase.firestore()
            .collection('sales')
            .orderBy('date', 'desc')
            .where('client', '==', userID)
            .where('purchaseType', '==', 'credit')
            .where('creditPayed', '==', false)
            .where('date', '<=', date)
            .where('date', '>=', lastDate)
            .startAfter( lastDocument )
            .limit(10)
            .get().then(moreSales => {
                let tempSales = [];
                moreSales.docs.forEach(doc => {
                    const sale = {
                        id: doc.id,
                        date: doc.data().date,
                        total: doc.data().total,
                        discount: doc.data().discount,
                        folio: doc.data().folio,
                        products: doc.data().products.length,
                        invoice: doc.data().invoiceRequired,
                        purchaseType: doc.data().purchaseType,
                        paymentType: doc.data().paymentType,
                        user: doc.data().user,
                    }
                    tempSales.push(sale);
                })
                setSales(state => [ ...state, ...tempSales ]);
                setLastDocument(moreSales.docs[moreSales.docs.length - 1]);
                if(moreSales.docs.length < 2) {
                    setSeeMore(false);
                }
            }).catch(e => {
                console.log(e)
            })

    }

    const creditPayment = (docID) => {
        MySwal.fire({
            title: <strong>¿Realizar pago a compra?</strong>,
            html: 
                <i>
                    Esta venta se registrará a las estadísticas y al historial de vendedores, ¿cuál es el método de pago?.
                </i>,
            confirmButtonText: 'Efectivo',
            showDenyButton: true,
            denyButtonText: 'Tarjeta',
            denyButtonColor: '#8C26F3',
            showCancelButton: true,
            reverseButtons: true,
            cancelButtonText: 'Cancelar',
            cancelButtonColor: 'red',
            icon: 'question',
            customClass: {
                actions: 'sweet_my-actions',
                cancelButton: 'sweet_order-1 sweet_right-gap',
                confirmButton: 'sweet_cash-button',
                denyButton: 'sweet_card-button',
            }
        }).then(async response => {
            let userid = '';
            const saleRes = await firebase.firestore().collection('sales').doc(docID).get();
            const { total: saleTotal, purchaseType, invoiceRequired } = saleRes.data();
            userid = saleRes.data().user;
            console.log(`Total ${ saleTotal }, Tipo de compra: ${ purchaseType }, Require Factura ${ invoiceRequired } `);

            if( response.isConfirmed  ) {
                console.log('Pago en efectivo');
                saveSaleStatistics(saleTotal, purchaseType, invoiceRequired, 'cash');
                saveSellerStatistics(docID, saleTotal, userid)
                firebase.firestore().collection('sales').doc(docID).update({ creditPayed: true })
                    .then(() => {
                        console.log('Paid');
                    }).catch(error => {
                        console.error(new Error(error))
                    }).finally(() => {
                        AlertSuccess(
                            `Se ha registrado el pago de la venta`, 
                            `Actualiza la lista de clientes para ver los cambios reflejados.`
                        );
                        setFilter('refresh');
                    })
            } else if( response.isDenied ) {
                console.log('Pago con tarjeta');
                saveSaleStatistics(saleTotal, purchaseType, invoiceRequired, 'card');
                saveSellerStatistics(docID, saleTotal, userid)
                firebase.firestore().collection('sales').doc(docID).update({ creditPayed: true })
                    .then(() => {
                        console.log('Paid');
                    }).catch(error => {
                        console.error(new Error(error))
                    }).finally(() => {
                        AlertSuccess(
                            `Se ha registrado el pago de la venta`, 
                            `Actualiza la lista de clientes para ver los cambios reflejados.`
                        );
                        setFilter('refresh');
                    })
            } else if( response.isDismissed ) {
                console.log('Acción cancelada');
            }
          
          
          
          
          
          
          
            // if(response.isConfirmed) {
            //     let saleTotal = 0;
            //     let purchaseType = '';
            //     let userid = '';
            //     firebase.firestore().collection('sales').doc(docID).get().then(res => {
            //         const { total: saleTotal, purchaseType, invoiceRequired, paymentType } = res.data();
            //         userid = res.data().user;
            //         console.log(saleTotal, purchaseType, invoiceRequired, paymentType);
            //         saveSaleStatistics(saleTotal, purchaseType, invoiceRequired, paymentType);
            //         saveSellerStatistics(docID, saleTotal, userid)
            //     }).then(() => {
            //         firebase.firestore().collection('sales').doc(docID).update({creditPayed: true})
            //     }).catch(error => {
            //         console.error(new Error(error))
            //     }).finally(() => {
            //         AlertSuccess(
            //             `Se ha registrado el pago de la venta`, 
            //             `Actualiza la lista de clientes para ver los cambios reflejados.`
            //         );
            //     })
            // }
        })
    }

    const cancelPendingPayment = ( userID, docID ) => {
        MySwal.fire({
            title: <strong>¿Seguro que deseas cancelar este pago pendiente?</strong>,
            html: <i>Ya no se podrá consultar y los productos regresarán al inventario.</i>,
            confirmButtonText: 'Cancelar',
            showCancelButton: true,
            reverseButtons: true,
            cancelButtonText: 'Regresar',
            icon: 'warning'
        }).then(async response => {
            if(response.isConfirmed) {
                let saleProducts = [];
                await firebase.firestore().collection('sales').doc(docID).get().then(res => {
                    console.log('Cancelar venta a crédito.');
                    saleProducts = res.data().products;
                    console.log(saleProducts)
                    saleProducts.forEach(async product => {
                        console.log(`ID: ${ product.product }, cantidad: ${ product.quantity }`);
                        if( !product.product.includes('generic') ) {
                            firebase.firestore().collection('inventario').doc(product.product).get().then(async res => {
                                if( res.data() !== undefined ) {
                                    let existencia = res.data().existencia;
                                    await res.ref.update({ existencia: Number(existencia + product.quantity) }).then(async () => {
                                        console.log('Existencia recuperada');
                                    })
                                } else {
                                    return;
                                }
                            }).catch(e => console.error(new Error(e)));   
                        }
                    })
                }).finally(() => {
                    firebase.firestore().collection('sales').doc(docID).delete().then(() => {
                        MySwal.fire({
                            title: <strong>Se ha cancelado la venta a crédito.</strong>,
                            html: <i>Actualiza la lista de clientes para ver los cambios reflejados.</i>,
                            confirmButtonText: 'Entendido.',
                            icon: 'info'
                        })             
                    }).catch(e => console.error(new Error(e)))
                })
            }
        })
    }

    const state = {
        filter,
        filterOptions,
        isLoading,
        lastDocument,
        seeMore,
        sales,
    }

    const clientStateUpdater = {
        cancelPendingPayment,
        creditPayment,
        filterSelection,
        getClientCreditSales,
        getClientSales,
        getSeeMoreSales,
        getSeeMoreCreditSales,
        handleFilterChange,
        getClientSales,
        setClientSales,
    }

	return {
        state,
        clientStateUpdater,
	}
}
