import firebase from 'firebase/compat/app';
import { generateNewStats } from '../sales/generateNewStats';

/* 
 *    Function that saves the statistics from the sale.
 *    @param { array } products - products on context. 
 */
export const saveSaleStatistics = ( total, purchaseType, invoiceRequired, paymentType ) => {
    return new Promise(async (resolve, reject) => {

        const date = new Date();
        const today = date.getDate();
        const year = date.getFullYear();
        const month = date.getMonth() + 1;

        let currentSale = firebase.firestore().collection('saleStatistics').doc(`${year}-${month}`);
        let currentCompletedSale = firebase.firestore().collection('completedSaleStatistics').doc(`${year}-${month}`);

        if( currentSale ) {
            await currentSale.get().then(async res => {
                if( res.exists ) {
                    if(res.data()) {
                        let salesArray = []
                        salesArray = res.data().data;
                        let todaySale = salesArray.find(sale => sale.day === today);
                        
                        if( todaySale !== undefined ) {
                            let todaySaleIndex = salesArray.findIndex(sale => sale.day === todaySale.day );
                            let updateTodaySale = {
                                day: todaySale.day, 
                                totalCash: ( purchaseType === 'cash') ? todaySale.totalCash + total : todaySale.totalCash, 
                                salesCash: ( purchaseType === 'cash') ? todaySale.salesCash + 1 : todaySale.salesCash, 
                                totalCredit: ( purchaseType !== 'cash') ? todaySale.totalCredit + total : todaySale.totalCredit, 
                                salesCredit: ( purchaseType !== 'cash') ? todaySale.salesCredit + 1 : todaySale.salesCredit,
                                total: todaySale.total + total,
                                sales: todaySale.sales + 1,
                                salesNoInvoiceCash: (!invoiceRequired && paymentType === 'cash') ? todaySale.salesNoInvoiceCash + total : todaySale.salesNoInvoiceCash,
                                salesNoInvoiceCard: (!invoiceRequired && paymentType === 'card') ? todaySale.salesNoInvoiceCard + total : todaySale.salesNoInvoiceCard,
                                salesInvoiceCash: (invoiceRequired && paymentType === 'cash') ? todaySale.salesInvoiceCash + total : todaySale.salesInvoiceCash,
                                salesInvoiceCard: (invoiceRequired && paymentType === 'card') ? todaySale.salesInvoiceCard + total : todaySale.salesInvoiceCard,
                            }
                            salesArray[todaySaleIndex] = updateTodaySale;
                            await currentSale.update({ data: salesArray })
                            await currentCompletedSale.update({ data: salesArray })
                        } else {
                            let newTodaySale = [];
                            newTodaySale[0] = generateNewStats( total, purchaseType, invoiceRequired, paymentType );
                            await currentSale.update({ data: firebase.firestore.FieldValue.arrayUnion(...newTodaySale)})
                            await currentCompletedSale.update({ data: firebase.firestore.FieldValue.arrayUnion(...newTodaySale)})
                        }
                    } else {
                        // let daySales = [{day: today, total: total, sales: 1}]
                        let daySales = [];
                        let updateTodaySale = generateNewStats( total, purchaseType, invoiceRequired, paymentType );
                        daySales[0] = updateTodaySale
                        firebase.firestore().collection('saleStatistics').doc(`${year}-${month}`).set({ data: daySales })
                        firebase.firestore().collection('completedSaleStatistics').doc(`${year}-${month}`).set({ data: daySales })
                    }
                } else {
                    let daySales = [];
                    let updateTodaySale = generateNewStats( total, purchaseType, invoiceRequired, paymentType );
                    daySales[0]= updateTodaySale
                    firebase.firestore().collection('saleStatistics').doc(`${year}-${month}`).set({ data: daySales })
                    firebase.firestore().collection('completedSaleStatistics').doc(`${year}-${month}`).set({ data: daySales })
                        .catch(err => reject(err));
                }
            })
        } else {
            console.log('No se ha podido almacenar la estadísticas de la venta.')
            reject('Ha habido un error guardando las estadísticas')
        }
        

    })
}