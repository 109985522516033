import React, { useContext, useEffect, useState } from 'react';
import { ProductContext } from '../../../Context/ProductContext';
import { formatMoney } from './../../../helpers/formatMoney';

import './CobrarModal.css';

export const CobrarModalMonto = React.memo(({ applyFrequentDiscount, creditPayment, purchaseType, selectedClient, ammounts, setAmmounts }) => {

    const { cartTotal } = useContext(ProductContext);

    useEffect(() => {
        if(cartTotal.contado === '') return;
        calcularMontos();
    }, [ creditPayment, purchaseType, ammounts.discountPercentage, applyFrequentDiscount ]);

    const calcularMontos = () => {
        let purchaseTotal = (!creditPayment) ? cartTotal.contado : cartTotal.credito;

        let frequentClientDiscountMoney = (applyFrequentDiscount) ? purchaseTotal * Number(selectedClient.discount / 100): 0;
        let frequentClientTotal =  (applyFrequentDiscount) ? purchaseTotal - frequentClientDiscountMoney : purchaseTotal;
        let customDiscountMoney =  (ammounts.discountPercentage !== 0 && ammounts.discountPercentage !== undefined) ? (frequentClientTotal * (ammounts.discountPercentage / 100)) : 0;

        let discountedTotal = purchaseTotal - frequentClientDiscountMoney - customDiscountMoney
        const subtotal = discountedTotal / 1.16;
        const iva = discountedTotal - subtotal;

        setAmmounts({
            ...ammounts,
            subtotal: subtotal.toFixed(2),
            iva: iva.toFixed(2),
            total: discountedTotal,
            discount: frequentClientDiscountMoney + customDiscountMoney,
        })
    } 

    return (
        <div className = '  '>  
            <div className='row'>
                <div className=' col-6 '>
                    <p className='h6 mb-0'>Subtotal</p> 
                    <p className = ' ChargeModalAmmount-secondary-ammount '>{ formatMoney (ammounts.subtotal) }</p>
                </div>
                <div className=' col-6 '>
                    <p className='h6 mb-0'>IVA</p> 
                    <p className = ' ChargeModalAmmount-secondary-ammount '>{ formatMoney (ammounts.iva) }</p>
                </div>

                {(ammounts.discount !== 0) && (
                    <div className=' col-6 my-2'>
                        <p className = ' h6 mb-0 '>Descuento</p> 
                        <p className = ' ChargeModalAmmount-secondary-ammount '>{ formatMoney (ammounts.discount) }</p>
                    </div>
                )}
                
                <div className=' col-6 my-2'>
                    <p className = ' h5 mb-0 '>TOTAL</p> 
                    <p>{ formatMoney (ammounts.total) }</p>
                </div>

            </div>
        </div>
    )

})