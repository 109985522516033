import firebase from 'firebase/compat/app';

/* 
 *    Function that registers on the data base the sale.
 *    @param { object } paymentData - previously prepared payment data. 
 *    @param { string } purchaseID - purchaseID generated with date timestamp.
 */
export const registerSaleDataBase = ( paymentData, purchaseID, handleNewSale ) => {
    return new Promise(async (resolve, reject) => {
        handleNewSale( paymentData );
        resolve( true );
    });
}