import { useDispatch, useSelector } from 'react-redux';
import { onDeleteSupplier, onEditSupplier, onNewSupplier } from '../../store/suppliers/suppliersSlice';

export const useSuppliersSlice = () => {

    const dispatch = useDispatch();
    const { suppliers } = useSelector( state => state.suppliers );

    const handleDeleteSupplier = ( id ) => {
        dispatch(onDeleteSupplier( id ));
    }

    const handleEditSupplier = ( id, supplierData ) => {
        const tempSuppliers = [ ...suppliers ];
        const index = tempSuppliers.findIndex(supplier => supplier.id === id);
        tempSuppliers[index] = {
            ...tempSuppliers[index],
            ...supplierData,
        }
        dispatch( onEditSupplier( tempSuppliers ));
    }

    const handleNewSupplier = ( supplier ) => {
        dispatch(onNewSupplier( supplier ));
    }



    return {
        //* Properties.
        suppliers, 

        //* Methods.
        handleNewSupplier,
        handleDeleteSupplier,
        handleEditSupplier,
    };

}
