import React, { useContext, useEffect, useState } from 'react';
import { AplicacionCarroItem } from '../AplicacionCarroItem/AplicacionCarroItem';
import { DetalleProductoInfoEditable } from './DetalleProductoInfoEditable';
import { DetallesProductoCTA } from './DetallesProductoCTA';
import { DetallesProductoItem } from './DetallesProductoItem';
import { formatMoney } from './../../../helpers/formatMoney';
import { ProductContext } from '../../../Context/ProductContext';
import { useProducts } from '../../../hooks/useProducts';
import { productFamily } from '../../../helpers/ProductFamilies';
import { useAuth } from './../../../hooks/useAuth';
import { getProductFamily } from './../../../helpers/getProductFamily'

export const DetallesProducto = ({ producto, closeModal, venta = false, selectedProduct, setSelectedProduct }) => {

    const { userType } = useAuth();
    const { updateProduct, deleteProduct } = useContext(ProductContext);
    const { allSuppliers, loadSupplierData } = useContext(ProductContext);
    
    const { editable, setEditable, uploadImage, setUploadImage } = useProducts();
    const [productoEditado, setProductoEditado] = useState({})
    const [ proveedorNombre, setProveedorNombre ] = useState('');

    const { 
        aplicaciones, descripcion, existencia, family, image, nombre, pieza, 
        costo, precio_contado, precio_credito, proveedor, highRotation
    } = producto;

    useEffect(() => {
        loadSupplierData(proveedor)
            .then(res => setProveedorNombre(res.name))
            .catch(e => setProveedorNombre('Proveedor no encontrado'))
    }, [ selectedProduct ]);

    useEffect(() => {
        setEditable( false );
        setProductoEditado({
            aplicaciones: aplicaciones, 
            costo: costo, 
            descripcion: descripcion, 
            existencia: existencia, 
            family: family,
            image: image,
            nombre: nombre, 
            pieza: pieza.toUpperCase(), 
            precio_contado: precio_contado, 
            precio_credito: precio_credito, 
            proveedor: proveedor,
            highRotation: highRotation
        })
    }, [ producto ]);


    const saveUpdate = () => {
        console.log(productoEditado, producto.id);
        updateProduct(productoEditado, producto.id, setEditable, uploadImage, setSelectedProduct);
    }

    const deleteSelectedProduct = () => {
        deleteProduct( producto.id, setEditable, setSelectedProduct )
    }

    const getFamilyName = ( family ) => {
        if(family !== undefined && family !== '') {
            const foundFamily = productFamily.filter(fam => fam.value === family);
            if ( foundFamily.length > 0 && foundFamily[0].label !== undefined ) { 
                return foundFamily[0].label 
            } else {
                return 'Familia no encontrada';
            }
        } else {
            return ''
        }
    }


    return (
        <div className = ' inventario_detalle-container '>
            <div className = ' inventario_detalle-header '>
                <div className = ' d-flex flex-grow-1 align-items-center '>
                    <img src = { image } />
                    <p className = ' ms-3 '>{ nombre }</p>
                </div>
                { userType === 'Administrador' && ( 
                    <div 
                        className = ' button purple-button ms-3'
                        onClick = { () => setEditable(state => !state) }
                    >
                        {( editable ? 'Cancelar' : 'Editar' )}
                    </div>
                )}
            </div>
            <hr className = ' ui_grey-separator '/>
            <div>
                {(!editable) && (
                    <div className = ' row mx-0 ps-4 pe-2 '>
                        <DetallesProductoItem label = 'Número de pieza' value = { pieza } />
                        <DetallesProductoItem label = 'Nombre' value = { nombre } />
                        <DetallesProductoItem label = 'Descripción' value = { descripcion } />
                        <DetallesProductoItem label = 'Proveedor' value = { proveedorNombre } />
                        <DetallesProductoItem label = 'Familia' value = { getProductFamily( family ) } />
                        <DetallesProductoItem label = 'Grupo' value = { getFamilyName( family ) } />
                        <DetallesProductoItem label = 'Existencia' value = { existencia } />
                        { userType === 'Administrador' && (
                            <DetallesProductoItem label = 'Costo' value = { formatMoney( costo ) } />
                        )}
                        <DetallesProductoItem label = 'Contado' value = { formatMoney( precio_contado ) } />
                        <DetallesProductoItem label = 'Crédito' value = { formatMoney( precio_credito ) } />
                        { aplicaciones.length > 0 &&
                            aplicaciones.map(aplicacion => (
                                <AplicacionCarroItem
                                    item = { aplicacion }
                                    key = { aplicacion.id } 
                                />
                            ))
                        }
                    </div>
                )}
                {
                    (editable) && (
                        <DetalleProductoInfoEditable 
                            producto = { producto } 
                            productoEditado = { productoEditado } 
                            setProductoEditado = { setProductoEditado }
                            uploadImage = { uploadImage }
                            setUploadImage = { setUploadImage }
                        />
                    )
                 }

            </div>
            <DetallesProductoCTA 
                closeModal = { closeModal } 
                deleteSelectedProduct = { deleteSelectedProduct }
                editable = { editable } setEditable = { setEditable } 
                saveUpdate = { saveUpdate }
            />
        </div>
    )
}
