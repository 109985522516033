import { createSlice } from "@reduxjs/toolkit";
import { sales } from "../../db/sales";
import { saleStatsDB } from "../../db/saleStats";

const demoSales = [ ...sales ];

export const salesSlice = createSlice({
    name: 'sales',
    initialState: {
        folio: 999 + sales.length,
        sales: [ ...demoSales ],
        salesStats: [ ...saleStatsDB ],
    },
    reducers: {
        onAddNewSale: ( state, { payload } ) => {
            state.sales = [ ...state.sales, payload ];
            state.folio += 1;
        },
        onSaleCanceled: ( state, { payload } ) => {
            const salesTemp = [...state.sales];
            const saleIndex = salesTemp.findIndex( sale => sale.folio === payload );
            const saleToCancel = { ... salesTemp[saleIndex] };
            salesTemp[saleIndex] = {
                ...saleToCancel,
                canceled: true,
            }
            state.sales = salesTemp;
        },
    }
});

export const { onAddNewSale, onSaleCanceled } = salesSlice.actions;