import React, { createContext, useContext, useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { AlertSuccess } from '../Components/AlertsComponents/AlertSuccess';

const AuthContext = createContext();

const demoUserInfo = {
    id: 1,
    uid: 1,
    address: 'Durango, Dgo, México 34100',
    email: 'avluetsoftware@gmail.com',
    image: 'https://play-lh.googleusercontent.com/aFWiT2lTa9CYBpyPjfgfNHd0r5puwKRGj2rHpdPTNrz2N9LXgN_MbLjePd1OTc0E8Rl1',
    name: 'Avluet Software',
    phoneNumber: '6181169096',
    position: 'Administrador',
};

export const useAuth = () => {
    return useContext(AuthContext)
}

export const AuthProvider = ({ children }) => {

    const MySwal = withReactContent(Swal);

    const [ user, setUser] = useState(undefined)
    const [ userInfo, setUserInfo ] = useState(undefined)
    const [ userType, setUserType ] = useState('')

    const login = ( ) => {
        setUser(demoUserInfo);
        setUserInfo(demoUserInfo);
        setUserType('Administrador');
        return true;
    }

    const registerUser = ( userData ) => {
        AlertSuccess(
            'Usuario Registrado',
            'El usuario ha sido registrado exitosamente, ingresa con tu usuario y contraseña.'
        );
    }

    const getUserInfo = () => {
       return demoUserInfo;
    }

    const setUserLogs = (uid) => {
        return true;
    }

    const recoverPassword = ( email ) => {
        AlertSuccess(
            'Recuperar contraseña',
            'Se ha enviado el correo para reiniciar la contraseña.'
        )
    }

    const changePhotoURL = ( image ) => {
       console.log('Cambiando la imagen.');
    }

    const signout = () => {
        return MySwal.fire({
            title: <strong>Cerrar sesión</strong>,
            text: '¿Seguro que quieres cerrar la sesión?',
            confirmButtonText: 'Aceptar',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            icon: 'warning'
        }).then(response => {
            if(response.isConfirmed){
                firebase.auth().signOut().then(() => {
                    setUser(undefined)
                    setUserType('')
                })
            }
        })
        
    }

    const timerSignout = () => {
        return firebase.auth().signOut().then(() => {
            setUser(undefined)
            setUserType('')
        }).catch(error => {
            console.log('No hay usuario conectado.')
        })
    }

    const deleteUser = () => {
        return firebase.auth().currentUser
    }

    useEffect(() => {
        
        const unsubscribe = firebase.auth().onAuthStateChanged(user => {
            if(user) {
                setUser(user)
            } else {
                setUser(undefined)
            }
        })
        
        return () => unsubscribe;
    
    }, [])
    

    const values = {
        user,
        userInfo,
        userType,
        deleteUser,
        login,
        registerUser,
        getUserInfo,
        recoverPassword,
        changePhotoURL,
        signout,
        timerSignout,
    }

    return <AuthContext.Provider value = { values }>{ children }</AuthContext.Provider>

}