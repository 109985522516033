import { useFormik } from 'formik';
import * as Yup from 'yup';
import React, { useState } from 'react'
import { useAuth } from '../../hooks/useAuth.js'
import { InputComponent } from '../InputComponent/InputComponent.js'

export const RecoverForm = () => {

	const { recoverPassword } = useAuth();

	const [ error, setError ] = useState(false)
	const [ errorMessage, setErrorMessage ] = useState('')
	const [ emailSent, setEmailSent ] = useState(false)

	const formik = useFormik({
		initialValues: {
			email: '',
		},
		validationSchema: Yup.object({
            email: Yup.string().required('El campo de correo eléctronico no debe estar vacío.').email('El correo ingresado no es válido.'),
        }),
		onSubmit: async user => {
			try {
				setError(false)
				await recoverPassword(user.email)
				setEmailSent(true)
			} catch (e) {
				setError(false)
				console.log(e.code);
				if(e.code === 'auth/user-not-found'){
					setError(true);
					setErrorMessage('No existe usuario con el correo electrónico ingresado.');
				} 
			}
		}
	})
	

	return (
		<div>

			<form
				onSubmit = { formik.handleSubmit }
			>
				<div className = ' col-10 pe-3 '>
					<InputComponent
						id = 'email'
						label = 'Correo Electrónico'
						placeholder = 'correo@email.com'
						values = { formik.values.email }
						onChange = { formik.handleChange }
						type = 'email'
						disabled = { emailSent }
					/>

					{ (formik.touched.email && formik.errors.email) ? (
						<div>
							<p className = {` error-message `}>{ formik.errors.email }</p>
						</div>
					) : null }

					{ error ? (
						<div>
							<p className = {` error-message `}>{ errorMessage }</p>
						</div>
					) : null }

					{ emailSent ? (
						<div>
							<p className = {` success-message `}>Se ha enviado el link para reiniciar la contraseña, regresa a la pantalla de Inicio de Sesión.</p>
						</div>
					) : null }

					<button
						className = {` login-button button purple-button shadow px-5 `}
						type = 'submit'
						disabled = { emailSent }
					>
						Enviar
					</button>
				</div>

			</form>
			 
		</div>
		
	)

}
