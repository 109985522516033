import React, { useContext } from 'react';
import { ProductContext } from '../../../Context/ProductContext';

import './../../../Theme/globalStyles.css';

export const DetallesProductoCTA = ({ editable, closeModal, setEditable, saveUpdate, deleteSelectedProduct }) => {

    const { loadProducts } = useContext(ProductContext)

    return (
        <div className = ' mt-4 me-2 d-flex '>

            { (editable) && (
                <button
                    className={` button cancel-button px-5 ms-3 mb-3 `}
                    onClick = { deleteSelectedProduct }
                >
                    Borrar producto
                </button>
            )}
            {(editable) && (
                    <button
                        className = 'button purple-button ms-3 mb-3 '
                        onClick = { () => {
                            saveUpdate();
                            loadProducts();
                        }}
                    >
                        Guardar Cambios
                    </button>
            )}
        </div>
    )

}
