import React from 'react'
import Select from 'react-select';

export const SelectFrequentClient = ({ applyFrequentDiscount, clients, frequentClient, selectedClient, handleClientSelection, setApplyFrequentDiscount, setFrequentClient }) => {

    console.log(clients);

    return (
        <>
            <div 
                className = {` ChargeModal-frequent-client-container ${ (frequentClient) && 'active' } `}
                onClick = { () => { 
                    setFrequentClient(!frequentClient);
                }}
            >
                <div className = {` ChargeModal-frequent-client-button ${ (frequentClient) && 'active' } `}>
                    <ion-icon name="people-outline"></ion-icon>
                </div>
                <span className = ' ms-2 '>Es cliente registrado</span>
            </div>

            {(frequentClient) && (
                <div className = ' mt-2 '>
                    <Select
                        onChange = { handleClientSelection }
                        options = { clients }
                    />
                </div>
            )}

            {(selectedClient.selected) && (
                <div 
                    className = ' mt-2 ChargeModal-apply-frequent-discount-container '
                    onClick = { ( ) => setApplyFrequentDiscount(state => !state) }
                >
                    <div 
                        className = {` 
                            ChargeModal-apply-frequent-discount-button 
                            ${ applyFrequentDiscount && 'active' }
                        `} 
                    ></div>
                    <p className = ' ChargeModal-apply-frequent-discount-text '>
                        Aplicar descuento de { selectedClient.discount }%.
                    </p>
                </div>
            )} 
        </>
    )
}
