import firebase from 'firebase/compat/app';

export const getSalesQuotes = ( dateFrom, dateTo ) => {
    return new Promise(async (resolve, reject) => {
        dateFrom.setHours(0, 0, 0, 0);
        dateTo.setHours( 23, 59, 59, 999 );
        let fireDateFrom = firebase.firestore.Timestamp.fromDate( dateFrom );
        let fireDateTo = firebase.firestore.Timestamp.fromDate( dateTo );
        await firebase.firestore().collection('sales')
            .orderBy('date', "desc")
            .where('date', ">=", fireDateFrom)
            .where('date', '<=', fireDateTo)
            .where('client', '==', 'public')
            .where('purchaseType', '==', 'cash')
            .where('canceled', '==', false)
            .get()
            .then( resolve )
            .catch( reject );
    })
}