import firebase from 'firebase/compat/app';

/* 
 *    Function that discounts the existence of each product bought.
 *    @param { array } products - products on context. 
 */

export const removeProductsFromStock = ( products ) => {
    return new Promise( async (resolve, reject) => {
        // await products.forEach(async product => {
        //     console.log(product);
        //     if( !product.id.includes('generic-stock') ) {
        //         const currentExistence = product.currentExistence;
        //         const productID = product.id;
        //         const purchaseQuantity = product.amount;
        //         await firebase.firestore().collection('inventario').doc(productID).update({
        //             existencia: currentExistence - purchaseQuantity,
        //         })
        //         .then(() => {
        //             return;
        //         }).catch(e => { 
        //             console.error(new Error(e))
        //             reject(e);
        //         });
        //     }
        // })
        resolve( true );
    });
}