/*

    HOOK NAME: useSupplier.
    HOOK DESCRIPTION: Hook that reads suppliers data from the database.
    HOOK CONTENT:

    # HOOK CONFIG:
        - Initial State Config Declarations.
        - State Inizialization.

    # FIRESTORE PRODUCT FUNCTIONS.
        - Load all suppliers.
        - Load specific supplier.

*/
import { useEffect, useState } from 'react'
import { useSuppliersSlice } from './redux/useSuppliersSlice';

export const useSupplier = () => {

    const { suppliers } = useSuppliersSlice();

    const [allSuppliers, setAllSuppliers] = useState([...suppliers]); 

    /* 
     *  Get all suppliers from databse and set them in state.
     */
    const loadSuppliers = () => {
        setAllSuppliers([...suppliers]);
    }

    /* 
     *  Get specific supplier data and returns it as a promise.
     *  @param { string } docID Firestore document id to be searched.
     */
    const loadSupplierData = ( docID ) => {
        return new Promise((resolve, reject) => {
            if(docID !== '' && docID !== undefined) {
                const foundSuppliers = allSuppliers.filter(
                    supplier => supplier.id === docID
                );
                if(foundSuppliers.length > 0) {
                    resolve(foundSuppliers[0])
                } else {
                    reject('Proveedor no encontrado.');
                }
            }
        }) 
    }

    return {
        allSuppliers,
        loadSuppliers,
        loadSupplierData,
    }

}
