import { createSlice } from '@reduxjs/toolkit';
import { products } from '../../db/products';

export const productsSlice = createSlice({
    name: 'products', 
    initialState: {
        products: [ ...products ],
    }, 
    reducers: {
        onAddNewProduct: ( state, { payload }) => {
            state.products = [ ...products, payload ];
        },
        onProductSold: ( state, { payload } ) => {
            state.products = [ ...payload ];
        },
        onDeleteProduct: ( state, { payload } ) => {
            const tempProducts = [...state.products];
            const productIndex = tempProducts.findIndex(product => product.id === payload);
            tempProducts.splice( productIndex, 1);
            state.products = [ ...tempProducts ];
        },
        onEditProduct: ( state, { payload } ) => {
            state.products = [ ...payload ];
        }
    },
});

export const { 
    onAddNewProduct,
    onDeleteProduct, 
    onEditProduct,
    onProductSold,
} = productsSlice.actions;