import React, { useState } from 'react'
import Select from 'react-select';
import { productFamily } from '../../../helpers/ProductFamilies';

export const SelectProductGroup = ({ setGroup }) => {

    const [ groups, setGroups ] = useState([]);

    // const handleFamilyChange = ({ value, label }) => {
    //     switch(value) {
    //         case '001': 
    //             setGroups([
    //                 { label: 'Aceite', value: '001001' },
    //                 { label: 'Bujias', value: '001002' },
    //                 { label: 'Filtro de aceite', value: '001003' },
    //             ])
    //             break;
    //         case '005': 
    //             setGroups([
    //                 { label: 'BALATAS', value: '005001' },
    //                 { label: 'CALIPERS', value: '005002' },
    //                 { label: 'Líquido de frenos', value: '005003' },
    //             ])
    //             break;
    //         case '008': 
    //             setGroups([
    //                 { label: 'AMORTIGUADORES', value: '008001' },
    //             ])
    //             break;
    //     }
    // }

    const handleGroupChange = ({ value, label }) => {
        setGroup(value);
        console.log(value)
    }

    return (
        <div className = ' row mx-0 '>
            <div className = ' col-12 pe-2 '>
                <Select 
                    onChange = { handleGroupChange }
                    options = { productFamily }
                />
            </div>
            {/* <div className = ' col-6 ps-2 '>
                { groups.length == 0 && <p>Selecciona una familia para continuar.</p> }
                { groups.length > 0 && (
                    <Select 
                        onChange = { handleGroupChange }
                        options = { groups }
                    />
                )}
            </div> */}
        </div>
    )

}
