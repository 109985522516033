export const cars = [
    {
        brand: 'Todas las marcas',
    },
    {
        brand: 'Acura', 
        models: [
            {
                model: 'CL',
                fromYear: 1997,
                toYear: 2003,
                engines: [
                    { engine: '2.2', fromYear: 1997, toYear: 1997 },
                    { engine: '2.3', fromYear: 1997, toYear: 1999 },
                    { engine: '3.0', fromYear: 1997, toYear: 1999 },
                    { engine: '3.2', fromYear: 2001, toYear: 2003 },
                ],
            },
            {
                model: 'ILX',
                fromYear: 2013,
                toYear: 2020,
                engines: [
                    { engine: '1.5', fromYear: 1997, toYear: 2015 },
                    { engine: '2.0', fromYear: 1997, toYear: 2015 },
                    { engine: '2.4', fromYear: 1997, toYear: 2020 },
                ],
            },
            {
                model: 'Integra',
                fromYear: 1986,
                toYear: 2001,
                engines: [
                    { engine: '1.5', fromYear: 1997, toYear: 2015 },
                    { engine: '2.0', fromYear: 1997, toYear: 2015 },
                    { engine: '2.4', fromYear: 1997, toYear: 2020 },
                ],
            },
            {
                model: 'Legend',
                fromYear: 1980,
                toYear: 1986,
                engines: [
                    { engine: '3.5', fromYear: 2001, toYear: 2019 },
                    { engine: '3.6', fromYear: 2007, toYear: 2013 },
                    { engine: '3.0', fromYear: 2017, toYear: 2020 },
                ],
            },
            {
                model: 'MDX',
                fromYear: 2001,
                toYear: 2019,
                engines: [
                    { engine: '3.5', fromYear: 2001, toYear: 2019 },
                    { engine: '3.7', fromYear: 2007, toYear: 2013 },
                    { engine: '3.0', fromYear: 2017, toYear: 2020 },
                ],
            },
            {
                model: 'NSX',
                fromYear: 2016,
                toYear: 2020,
                engines: [
                    { engine: '3.5', fromYear: 2017, toYear: 2020 },
                ],
            },
            {
                model: 'RDX',
                fromYear: 2007,
                toYear: 2017,
                engines: [
                    { engine: '2.3', fromYear: 2001, toYear: 2012 },
                    { engine: '3.5', fromYear: 2013, toYear: 2018 },
                    { engine: '2.0', fromYear: 2019, toYear: 2021 },
                ],
            },
            {
                model: 'RL',
                fromYear: 2005,
                toYear: 2012,
                engines: [
                    { engine: '3.5', fromYear: 2005, toYear: 2008 },
                    { engine: '3.7', fromYear: 2005, toYear: 2012 },
                    { engine: '3.8', fromYear: 2005, toYear: 2008 },
                ],
            },
            {
                model: 'RLX',
                fromYear: 2013,
                toYear: 2015,
                engines: [
                    { engine: '3.5', fromYear: 2014, toYear: 2015 },
                ],
            },
            {
                model: 'TL',
                fromYear: 1999,
                toYear: 2014,
                engines: [
                    { engine: '3.2', fromYear: 1999, toYear: 2008 },
                    { engine: '3.5', fromYear: 2007, toYear: 2014 },
                    { engine: '3.7', fromYear: 2009, toYear: 2014 },
                ],
            },
            {
                model: 'TLX',
                fromYear: 2015,
                toYear: 2020,
                engines: [
                    { engine: '2.4', fromYear: 2015, toYear: 2020 },
                    { engine: '3.5', fromYear: 2015, toYear: 2020 },
                ],
            },
            {
                model: 'TSX',
                fromYear: 2004,
                toYear: 2014,
                engines: [
                    { engine: '2.4', fromYear: 2004, toYear: 2014 },
                    { engine: '3.5', fromYear: 2010, toYear: 2014 },
                ],
            },
            {
                model: 'ZDX',
                fromYear: 2010,
                toYear: 2013,
                engines: [
                    { engine: '3.7', fromYear: 2010, toYear: 2013 },
                ],
            },
        ],
    },
    {
        brand: 'Alfa Romeo', 
        models: [
            {
                model: '147 - 156',
                fromYear: 2001,
                toYear: 2006,
                engines: [
                    { engine: '2.0', fromYear: 2001, toYear: 2006 },
                ],
            },
            {
                model: 'MITO',
                fromYear: 2012,
                toYear: 2019,
                engines: [
                    { engine: '1.4', fromYear: 2012, toYear: 2019 },
                ],
            },
        ],
    },
    {
        brand: 'American Motors', 
        models: [
            {
                model: 'AMERICAN',
                fromYear: 1964,
                toYear: 1984,
                engines: [
                    { engine: '3.2', fromYear: 1964, toYear: 1968 },
                    { engine: '3.8', fromYear: 1964, toYear: 1968 },
                ],
            },
            {
                model: 'CLASSIC',
                fromYear: 1967,
                toYear: 1969,
                engines: [
                    { engine: '3.3', fromYear: 1967, toYear: 1968 },
                    { engine: '3.8', fromYear: 1967, toYear: 1968 },
                ],
            },
            {
                model: 'GREMLIN',
                fromYear: 1964,
                toYear: 1984,
                engines: [
                    { engine: '3.3', fromYear: 1970, toYear: 1970 },
                    { engine: '3.8', fromYear: 1970, toYear: 1978 },
                    { engine: '4.2', fromYear: 1973, toYear: 1978 },
                    { engine: '5.0', fromYear: 1973, toYear: 1976 },
                    { engine: '2.0', fromYear: 1977, toYear: 1978 },
                ],
            },
            {
                model: 'JAVELIN',
                fromYear: 1964,
                toYear: 1984,
                engines: [
                    { engine: '3.8', fromYear: 1968, toYear: 1974 },
                    { engine: '4.7', fromYear: 1968, toYear: 1969 },
                    { engine: '5.6', fromYear: 1968, toYear: 1969 },
                    { engine: '6.4', fromYear: 1968, toYear: 1974 },
                    { engine: '5.0', fromYear: 1970, toYear: 1974 },
                    { engine: '5.9', fromYear: 1970, toYear: 1974 },
                    { engine: '6.6', fromYear: 1971, toYear: 1974 },
                    { engine: '4.2', fromYear: 1971, toYear: 1974 },
                ],
            },
            {
                model: 'LERMA',
                fromYear: 1964,
                toYear: 1984,
                engines: [],
            },
            {
                model: 'RALLY',
                fromYear: 1964,
                toYear: 1984,
                engines: [],
            },
        ],
    },
    {
        brand: 'Audi',
        models: [
            {
                model: 'A1',
                fromYear: 2009,
                toYear: 2022,
                engines: [
                    { engine: '1.0T', fromYear: 2020, toYear: 2022 },
                    { engine: '1.4T', fromYear: 2013, toYear: 2019 },
                    { engine: '1.5T', fromYear: 2020, toYear: 2022 },
                    { engine: '1.8T', fromYear: 1998, toYear: 2019 },
                    { engine: '2.0T', fromYear: 2007, toYear: 2022 },
                ],
            },
            {
                model: 'A3',
                fromYear: 1996,
                toYear: 2022,
                engines: [
                    { engine: '1.4T', fromYear: 2013, toYear: 2019 },
                    { engine: '1.8', fromYear: 1998, toYear: 2007 },
                    { engine: '1.8T', fromYear: 2014, toYear: 2018 },
                    { engine: '1.9TDI', fromYear: 1996, toYear: 2002 },
                    { engine: '2.0T', fromYear: 2020, toYear: 2022 },
                ],
            },
            {
                model: 'A4',
                fromYear: 1992,
                toYear: 2018,
                engines: [
                    { engine: '1.6', fromYear: 1995, toYear: 1998 },
                    { engine: '1.8', fromYear: 1997, toYear: 2016 },
                    { engine: '1.9', fromYear: 1992, toYear: 2002 },
                    { engine: '2.0', fromYear: 2003, toYear: 2022 },
                    { engine: '2.8', fromYear: 1996, toYear: 2001 },
                    { engine: '3.0', fromYear: 2002, toYear: 2005 },
                    { engine: '3.2', fromYear: 2005, toYear: 2008 },
                ],
            },
            {
                model: 'A5',
                fromYear: 2008,
                toYear: 2018,
                engines: [
                    { engine: '1.8', fromYear: 2013, toYear: 2017 },
                    { engine: '2.0', fromYear: 2010, toYear: 2022 },
                    { engine: '3.0', fromYear: 2015, toYear: 2016 },
                    { engine: '3.2', fromYear: 2008, toYear: 2010 },
                ],
            },
            {
                model: 'A6',
                fromYear: 1992,
                toYear: 2015,
                engines: [
                    { engine: '1.8', fromYear: 1997, toYear: 2000 },
                    { engine: '1.8T', fromYear: 2016, toYear: 2018 },
                    { engine: '1.9', fromYear: 1992, toYear: 2002 },
                    { engine: '2.0T', fromYear: 2013, toYear: 2022 },
                    { engine: '2.5', fromYear: 1995, toYear: 1997 },
                    { engine: '2.8', fromYear: 1995, toYear: 2005 },
                    { engine: '2.8T', fromYear: 2008, toYear: 2013 },
                    { engine: '3.0', fromYear: 2001, toYear: 2006 },
                    { engine: '3.0T', fromYear: 2016, toYear: 2016 },
                    { engine: '3.2', fromYear: 2006, toYear: 2012 },
                ],
            },
            {
                model: 'A7',
                fromYear: 2012,
                toYear: 2015,
                engines: [
                    { engine: '2.0T', fromYear: 2012, toYear: 2017 },
                    { engine: '3.0T', fromYear: 2012, toYear: 2017 },
                ],
            },
            {
                model: 'A8',
                fromYear: 2003,
                toYear: 2015,
                engines: [
                    { engine: '2.8', fromYear: 1997, toYear: 2000 },
                    { engine: '3.0T', fromYear: 2015, toYear: 2019 },
                    { engine: '3.7', fromYear: 1997, toYear: 2000 },
                    { engine: '6.3', fromYear: 2015, toYear: 2019 },
                ],
            },
            {
                model: 'COUPE',
                fromYear: 1981,
                toYear: 1987,
                engines: [
                    { engine: '2.2', fromYear: 1981, toYear: 1987 },
                    { engine: '2.3', fromYear: 1987, toYear: 1987 },
                ],
            },
            {
                model: 'FOX',
                fromYear: 1978,
                toYear: 1983,
                engines: [],
            },
            {
                model: 'Q2',
                fromYear: 2017,
                toYear: 2022,
                engines: [
                    { engine: '2.0T', fromYear: 2017, toYear: 2022 },
                ],
            },
            {
                model: 'Q3',
                fromYear: 2013,
                toYear: 2022,
                engines: [
                    { engine: '1.4T', fromYear: 2013, toYear: 2022 },
                    { engine: '2.0T', fromYear: 2013, toYear: 2022 },
                ],
            },
            {
                model: 'Q5',
                fromYear: 2010,
                toYear: 2020,
                engines: [
                    { engine: '2.0', fromYear: 2010, toYear: 2022 },
                    { engine: '3.0', fromYear: 2010, toYear: 2017 },
                    { engine: '3.2', fromYear: 2008, toYear: 2012 },
                ],
            },
            {
                model: 'Q7',
                fromYear: 2007,
                toYear: 2019,
                engines: [
                    { engine: '2.0T', fromYear: 2016, toYear: 2020 },
                    { engine: '3.0T', fromYear: 2008, toYear: 2021 },
                    { engine: '3.6', fromYear: 2007, toYear: 2015 },
                    { engine: '4.2', fromYear: 2007, toYear: 2010 },
                ],
            },
            {
                model: 'S3',
                fromYear: 2015,
                toYear: 2019,
                engines: [
                    { engine: '2.0', fromYear: 2015, toYear: 2019 },
                ],
            },
            {
                model: 'TT',
                fromYear: 1998,
                toYear: 2018,
                engines: [
                    { engine: '1.8', fromYear: 1998, toYear: 2018 },
                    { engine: '2.0', fromYear: 1998, toYear: 2014 },
                    { engine: '3.2', fromYear: 2008, toYear: 2009 },
                    { engine: '3.2', fromYear: 2008, toYear: 2009 },
                    { engine: '2.5', fromYear: 2011, toYear: 2014 },
                ],
            },
        ],
    },
    {
        brand: 'BMW',
        models: [
            {
                model: 'CLUBMAN',
                fromYear: 2017,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'MINI (1)',
                fromYear: 2002,
                toYear: 2006,
                engines: [],
            },
            {
                model: 'MINI (F54)',
                fromYear: 2015,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'MINI (F55)',
                fromYear: 2014,
                toYear: 2019,
                engines: [],
            },
            {
                model: 'MINI (R39)',
                fromYear: 2012,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'MINI (R50)',
                fromYear: 2002,
                toYear: 2006,
                engines: [],
            },
            {
                model: 'MINI (R52)',
                fromYear: 2002,
                toYear: 2008,
                engines: [],
            },
            {
                model: 'MINI (R53)',
                fromYear: 2002,
                toYear: 2008,
                engines: [],
            },
            {
                model: 'MINI (R55)',
                fromYear: 2007,
                toYear: 2014,
                engines: [],
            },
            {
                model: 'MINI (R56)',
                fromYear: 2006,
                toYear: 2013,
                engines: [],
            },
            {
                model: 'MINI (R57)',
                fromYear: 2007,
                toYear: 2015,
                engines: [],
            },
            {
                model: 'MINI (R58)',
                fromYear: 2011,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'MINI (R60)',
                fromYear: 2010,
                toYear: 2016,
                engines: [],
            },
            {
                model: 'MINI (R61)',
                fromYear: 2012,
                toYear: 2016,
                engines: [],
            },
            {
                model: 'Serie 1 (E81)',
                fromYear: 2005,
                toYear: 2012,
                engines: [],
            },
            {
                model: 'Serie 1 (E82)',
                fromYear: 2007,
                toYear: 2013,
                engines: [],
            },
            {
                model: 'Serie 1 (E87)',
                fromYear: 2004,
                toYear: 2013,
                engines: [],
            },
            {
                model: 'Serie 1 - Serie 2 (F20, F22, F23)',
                fromYear: 2013,
                toYear: 2019,
                engines: [],
            },
            {
                model: 'Serie 2 Active Tourer',
                fromYear: 2016,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'Serie 3 (E36)',
                fromYear: 1992,
                toYear: 2001,
                engines: [],
            },
            {
                model: 'Serie 3 (E46)',
                fromYear: 1999,
                toYear: 2005,
                engines: [],
            },
            {
                model: 'Serie 3 (E60)',
                fromYear: 2005,
                toYear: 2010,
                engines: [],
            },
            {
                model: 'Serie 3 (E90)',
                fromYear: 2005,
                toYear: 2013,
                engines: [],
            },
            {
                model: 'Serie 3 (E92)',
                fromYear: 2007,
                toYear: 2013,
                engines: [],
            },
            {
                model: 'Serie 3 - Serie 4 (F30, F32, F33)',
                fromYear: 2012,
                toYear: 2018,
                engines: [],
            },
            {
                model: 'Serie 5 (E39)',
                fromYear: 1996,
                toYear: 2004,
                engines: [],
            },
            {
                model: 'Serie 5 (E60)',
                fromYear: 2004,
                toYear: 2010,
                engines: [],
            },
            {
                model: 'Serie 5 (F10)',
                fromYear: 2011,
                toYear: 2015,
                engines: [],
            },
            {
                model: 'Serie 6 (E63)',
                fromYear: 2003,
                toYear: 2011,
                engines: [],
            },
            {
                model: 'Serie 6 (E64)',
                fromYear: 2004,
                toYear: 2011,
                engines: [],
            },
            {
                model: 'Serie 6 (F06)',
                fromYear: 2012,
                toYear: 2018,
                engines: [],
            },
            {
                model: 'Serie 6 (F12)',
                fromYear: 2012,
                toYear: 2015,
                engines: [],
            },
            {
                model: 'Serie 6 (F13)',
                fromYear: 2011,
                toYear: 2019,
                engines: [],
            },
            {
                model: 'Serie 7 (E38)',
                fromYear: 1994,
                toYear: 1995,
                engines: [],
            },
            {
                model: 'Serie 7 (E65-E66)',
                fromYear: 2001,
                toYear: 2008,
                engines: [],
            },
            {
                model: 'Serie 7 (E65)',
                fromYear: 2003,
                toYear: 2005,
                engines: [],
            },
            {
                model: 'Serie 7 (E65)',
                fromYear: 2003,
                toYear: 2005,
                engines: [],
            },
            {
                model: 'Serie 7 (F01 - F02)',
                fromYear: 2008,
                toYear: 2015,
                engines: [],
            },
            {
                model: 'Serie 7 (F01)',
                fromYear: 2010,
                toYear: 2015,
                engines: [],
            },
            {
                model: 'Serie 7 (F02)',
                fromYear: 2008,
                toYear: 2015,
                engines: [],
            },
            {
                model: 'Serie 8 (E31)',
                fromYear: 1990,
                toYear: 1995,
                engines: [],
            },
            {
                model: 'X1',
                fromYear: 2010,
                toYear: 2020,
                engines: [
                    { engine: '2.0', fromYear: 2009, toYear: 2021 },
                    { engine: '2.5', fromYear: 2010, toYear: 2011 },
                    { engine: '3.0', fromYear: 2010, toYear: 2015 },
                    { engine: '3.0', fromYear: 2010, toYear: 2015 },
                    { engine: '1.5', fromYear: 2011, toYear: 2021 },
                ],
            },
            {
                model: 'X2',
                fromYear: 2018,
                toYear: 2020,
                engines: [
                    { engine: '2.0', fromYear: 2018, toYear: 2021 },
                    { engine: '1.5', fromYear: 2019, toYear: 2011 },
                ],
            },
            {
                model: 'X3',
                fromYear: 2004,
                toYear: 2017,
                engines: [
                    { engine: '2.0', fromYear: 2005, toYear: 2021 },
                    { engine: '2.5', fromYear: 2004, toYear: 2011 },
                    { engine: '3.0', fromYear: 2004, toYear: 2022 },
                ],
            },
            {
                model: 'X4',
                fromYear: 2014,
                toYear: 2018,
                engines: [],
            },
            {
                model: 'X5',
                fromYear: 1999,
                toYear: 2018,
                engines: [],
            },
            {
                model: 'X6',
                fromYear: 2008,
                toYear: 2019,
                engines: [],
            },
            {
                model: 'Z3',
                fromYear: 1996,
                toYear: 2002,
                engines: [],
            },
            {
                model: 'Z4',
                fromYear: 2003,
                toYear: 2015,
                engines: [],
            },
        ],
    },
    {
        brand: 'Buick', 
        models: [
            {
                model: 'Allure',
                fromYear: 2005,
                toYear: 2011,
                engines: [],
            },
            {
                model: 'Century',
                fromYear: 1985,
                toYear: 2005,
                engines: [],
            },
            {
                model: 'Electra',
                fromYear: 1985,
                toYear: 1999,
                engines: [],
            },
            {
                model: 'Enclave',
                fromYear: 2008,
                toYear: 2017,
                engines: [],
            },
            {
                model: 'Encore',
                fromYear: 2013,
                toYear: 2021,
                engines: [],
            },
            {
                model: 'Envision',
                fromYear: 2016,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'Lacrosse',
                fromYear: 2005,
                toYear: 2016,
                engines: [],
            },
            {
                model: 'Le Sabre',
                fromYear: 1985,
                toYear: 2005,
                engines: [],
            },
            {
                model: 'Lucerne',
                fromYear: 2006,
                toYear: 2011,
                engines: [],
            },
            {
                model: 'Park Avenue',
                fromYear: 1985,
                toYear: 2005,
                engines: [],
            },
            {
                model: 'Reatta',
                fromYear: 1979,
                toYear: 1999,
                engines: [],
            },
            {
                model: 'Regal',
                fromYear: 1988,
                toYear: 2017,
                engines: [],
            },
            {
                model: 'Rendezvous',
                fromYear: 2002,
                toYear: 2007,
                engines: [],
            },
            {
                model: 'Riviera',
                fromYear: 1979,
                toYear: 1999,
                engines: [],
            },
            {
                model: 'Skyhawk',
                fromYear: 1982,
                toYear: 1989,
                engines: [],
            },
            {
                model: 'Skylark',
                fromYear: 1980,
                toYear: 1998,
                engines: [],
            },
            {
                model: 'Somerset',
                fromYear: 1980,
                toYear: 1998,
                engines: [],
            },
            {
                model: 'Terraza',
                fromYear: 2005,
                toYear: 2007,
                engines: [],
            },
            {
                model: 'Verano',
                fromYear: 2012,
                toYear: 2015,
                engines: [],
            },
        ],
    },
    {
        brand: 'Cadillac', 
        models: [
            {
                model: 'Allante',
                fromYear: 1991,
                toYear: 1993,
                engines: [],
            },
            {
                model: 'ATS',
                fromYear: 2013,
                toYear: 2014,
                engines: [],
            },
            {
                model: 'BLS',
                fromYear: 2006,
                toYear: 2008,
                engines: [],
            },
            {
                model: 'Cimarron',
                fromYear: 1982,
                toYear: 1988,
                engines: [],
            },
            {
                model: 'CTS',
                fromYear: 2014,
                toYear: 2016,
                engines: [],
            },
            {
                model: 'Deville',
                fromYear: 1985,
                toYear: 2005,
                engines: [],
            },
            {
                model: 'DTS',
                fromYear: 2006,
                toYear: 2011,
                engines: [],
            },
            {
                model: 'El Dorado',
                fromYear: 1992,
                toYear: 2002,
                engines: [],
            },
            {
                model: 'Escalade',
                fromYear: 2000,
                toYear: 2021,
                engines: [],
            },
            {
                model: 'Escalade 4X4 (1)',
                fromYear: 1999,
                toYear: 2000,
                engines: [],
            },
            {
                model: 'Escalade 4X4 (2)',
                fromYear: 2000,
                toYear: 2006,
                engines: [],
            },
            {
                model: 'Escalade EXT',
                fromYear: 2010,
                toYear: 2013,
                engines: [],
            },
            {
                model: 'Seville',
                fromYear: 1986,
                toYear: 2004,
                engines: [],
            },
            {
                model: 'SRX',
                fromYear: 2010,
                toYear: 2016,
                engines: [],
            },
            {
                model: 'XLR',
                fromYear: 2004,
                toYear: 2009,
                engines: [],
            },
            {
                model: 'XTS',
                fromYear: 2013,
                toYear: 2017,
                engines: [],
            },
        ],
    },
    {
        brand: 'Chevrolet / GMC', 
        models: [
            {
                model: 'Acadia - Traverse',
                fromYear: 2007,
                toYear: 2017,
                engines: [],
            },
            {
                model: 'Astra',
                fromYear: 2000,
                toYear: 2010,
                engines: [],
            },
            {
                model: 'Astra (Europa)',
                fromYear: 2007,
                toYear: 2009,
                engines: [],
            },
            {
                model: 'Astro Minivan / Safari',
                fromYear: 1985,
                toYear: 2005,
                engines: [],
            },
            {
                model: 'Astro Minivan / Safari 4X4',
                fromYear: 1990,
                toYear: 2005,
                engines: [],
            },
            {
                model: 'Avalanche 1500',
                fromYear: 2000,
                toYear: 2016,
                engines: [],
            },
            {
                model: 'Avalanche 1500 4x4',
                fromYear: 2000,
                toYear: 2006,
                engines: [],
            },
            {
                model: 'Avalanche 2500',
                fromYear: 2001,
                toYear: 2010,
                engines: [],
            },
            {
                model: 'Avalanche 2500 4x4',
                fromYear: 2000,
                toYear: 2006,
                engines: [],
            },
            {
                model: 'Aveo I',
                fromYear: 2007,
                toYear: 2017,
                engines: [],
            },
            {
                model: 'Aveo II',
                fromYear: 2018,
                toYear: 2022,
                engines: [],
            },
            {
                model: 'Beat',
                fromYear: 2017,
                toYear: 2021,
                engines: [],
            },
            {
                model: 'Belair',
                fromYear: 1958,
                toYear: 1985,
                engines: [],
            },
            {
                model: 'Beretta',
                fromYear: 1987,
                toYear: 1996,
                engines: [],
            },
            {
                model: 'Biscayne',
                fromYear: 1958,
                toYear: 1985,
                engines: [],
            },
            {
                model: 'BLAZER 1500',
                fromYear: 1967,
                toYear: 1991,
                engines: [],
            },
            {
                model: 'BLAZER 4X4 K10, K20 (1)',
                fromYear: 1961,
                toYear: 1972,
                engines: [],
            },
            {
                model: 'BLAZER 4X4 K10, K20 (2)',
                fromYear: 1973,
                toYear: 1976,
                engines: [],
            },
            {
                model: 'BLAZER 4X4 K10, K20 (3)',
                fromYear: 1976,
                toYear: 1980,
                engines: [],
            },
            {
                model: 'BLAZER 4X4 K10, K20 (4)',
                fromYear: 1981,
                toYear: 1991,
                engines: [],
            },
            {
                model: 'BLAZER K1500 4X4',
                fromYear: 1988,
                toYear: 1994,
                engines: [],
            },
            {
                model: 'C10',
                fromYear: 1960,
                toYear: 1991,
                engines: [],
            },
            {
                model: 'C1500',
                fromYear: 1988,
                toYear: 1999,
                engines: [],
            },
            {
                model: 'C20',
                fromYear: 1960,
                toYear: 1991,
                engines: [],
            },
            {
                model: 'C2500 7200 #',
                fromYear: 1992,
                toYear: 1999,
                engines: [],
            },
            {
                model: 'C2500 8600 #',
                fromYear: 1988,
                toYear: 2000,
                engines: [],
            },
            {
                model: 'C30',
                fromYear: 1960,
                toYear: 2010,
                engines: [],
            },
            {
                model: 'C3500 (SUSPENSION INDEPENDIENTE)',
                fromYear: 1988,
                toYear: 2000,
                engines: [],
            },
            {
                model: 'C3500 H.D. (EJE SOLIDO)',
                fromYear: 1991,
                toYear: 2010,
                engines: [],
            },
            {
                model: 'CAMARO - Z28',
                fromYear: 1967,
                toYear: 2015,
                engines: [],
            },
            {
                model: 'CAPRICE',
                fromYear: 1965,
                toYear: 1996,
                engines: [],
            },
            {
                model: 'CAPTIVA',
                fromYear: 2008,
                toYear: 2016,
                engines: [],
            },
            {
                model: 'CAPTIVA SPORT',
                fromYear: 2010,
                toYear: 2014,
                engines: [],
            },
            {
                model: 'CAVALIER - Z24',
                fromYear: 1982,
                toYear: 2005,
                engines: [],
            },
            {
                model: 'CAVALIER II',
                fromYear: 2018,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'CELEBRITY',
                fromYear: 1981,
                toYear: 1996,
                engines: [],
            },
            {
                model: 'CENTURY',
                fromYear: 1982,
                toYear: 1996,
                engines: [],
            },
            {
                model: 'CHEVELLE',
                fromYear: 1964,
                toYear: 1983,
                engines: [],
            },
            {
                model: 'CHEVETTE',
                fromYear: 1976,
                toYear: 1987,
                engines: [],
            },
            {
                model: 'CHEVROLET',
                fromYear: 1949,
                toYear: 1957,
                engines: [],
            },
            {
                model: 'CHEVY (BRASIL)',
                fromYear: 1999,
                toYear: 2003,
                engines: [],
            },
            {
                model: 'CHEVY (MEX)',
                fromYear: 1994,
                toYear: 2012,
                engines: [],
            },
            {
                model: 'CHEVY II',
                fromYear: 1962,
                toYear: 1979,
                engines: [],
            },
            {
                model: 'CHEVY PICKUP',
                fromYear: 1999,
                toYear: 2003,
                engines: [],
            },
            {
                model: 'CHEYENNE',
                fromYear: 2000,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'CHEYENNE 4x4',
                fromYear: 2000,
                toYear: 2006,
                engines: [],
            },
            {
                model: 'CITATION',
                fromYear: 1980,
                toYear: 1985,
                engines: [],
            },
            {
                model: 'COBALT',
                fromYear: 2007,
                toYear: 2010,
                engines: [],
            },
            {
                model: 'COLORADO 4X2, CANYON 4X2',
                fromYear: 2004,
                toYear: 2015,
                engines: [],
            },
            {
                model: 'COLORADO 4X4, CANYON 4X4',
                fromYear: 2004,
                toYear: 2015,
                engines: [],
            },
            {
                model: 'COLORADO Z711 4X2, CANYON Z71 4X2',
                fromYear: 2004,
                toYear: 2015,
                engines: [],
            },
            {
                model: 'CORSA',
                fromYear: 2002,
                toYear: 2019,
                engines: [],
            },
            {
                model: 'CORSICA',
                fromYear: 1987,
                toYear: 1996,
                engines: [],
            },
            {
                model: 'CORVETTE',
                fromYear: 1963,
                toYear: 2019,
                engines: [],
            },
            {
                model: 'CRUZE',
                fromYear: 2010,
                toYear: 2019,
                engines: [],
            },
            {
                model: 'CRUZE II',
                fromYear: 2016,
                toYear: 2018,
                engines: [],
            },
            {
                model: 'CUTLASS',
                fromYear: 1982,
                toYear: 1996,
                engines: [],
            },
            {
                model: 'ENVOY',
                fromYear: 2002,
                toYear: 2008,
                engines: [],
            },
            {
                model: 'EPICA',
                fromYear: 2008,
                toYear: 2009,
                engines: [],
            },
            {
                model: 'EQUINOX',
                fromYear: 2005,
                toYear: 2021,
                engines: [],
            },
            {
                model: 'EXPRESS VAN 1500',
                fromYear: 1996,
                toYear: 2014,
                engines: [],
            },
            {
                model: 'EXPRESS VAN 2500 7300 #',
                fromYear: 1996,
                toYear: 2014,
                engines: [],
            },
            {
                model: 'EXPRESS VAN 2500 7700, 8500, 8600 #',
                fromYear: 1996,
                toYear: 2021,
                engines: [],
            },
            {
                model: 'EXPRESS VAN 3500',
                fromYear: 1996,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'G10',
                fromYear: 1971,
                toYear: 1995,
                engines: [],
            },
            {
                model: 'G20',
                fromYear: 1971,
                toYear: 1995,
                engines: [],
            },
            {
                model: 'G3',
                fromYear: 2006,
                toYear: 2009,
                engines: [],
            },
            {
                model: 'G30',
                fromYear: 1970,
                toYear: 1995,
                engines: [],
            },
            {
                model: 'HHR',
                fromYear: 2006,
                toYear: 2011,
                engines: [],
            },
            {
                model: 'HUMMER H2',
                fromYear: 2003,
                toYear: 2008,
                engines: [],
            },
            {
                model: 'HUMMER H3',
                fromYear: 2006,
                toYear: 2010,
                engines: [],
            },
            {
                model: 'IMPALA',
                fromYear: 1958,
                toYear: 2015,
                engines: [],
            },
            {
                model: 'IMPALA SS',
                fromYear: 1986,
                toYear: 1996,
                engines: [],
            },
            {
                model: 'JIMMY',
                fromYear: 1995,
                toYear: 2004,
                engines: [],
            },
            {
                model: 'K30 (DANA 60)',
                fromYear: 1977,
                toYear: 1991,
                engines: [],
            },
            {
                model: 'K3500 SUBURBAN',
                fromYear: 1988,
                toYear: 1994,
                engines: [],
            },
            {
                model: 'LUMINA',
                fromYear: 1990,
                toYear: 2001,
                engines: [],
            },
            {
                model: 'LUMINA APV',
                fromYear: 1990,
                toYear: 1996,
                engines: [],
            },
            {
                model: 'LUV',
                fromYear: 1972,
                toYear: 2005,
                engines: [],
            },
            {
                model: 'LUV 4X4',
                fromYear: 1979,
                toYear: 1983,
                engines: [],
            },
            {
                model: 'MALIBU',
                fromYear: 1964,
                toYear: 2018,
                engines: [],
            },
            {
                model: 'MATIZ',
                fromYear: 2005,
                toYear: 2015,
                engines: [],
            },
            {
                model: 'MERIVA',
                fromYear: 2003,
                toYear: 2008,
                engines: [],
            },
            {
                model: 'METRO',
                fromYear: 1998,
                toYear: 2001,
                engines: [],
            },
            {
                model: 'MONTECARLO',
                fromYear: 1964,
                toYear: 2007,
                engines: [],
            },
            {
                model: 'NOVA',
                fromYear: 1962,
                toYear: 1989,
                engines: [],
            },
            {
                model: 'OPTRA',
                fromYear: 2006,
                toYear: 2010,
                engines: [],
            },
            {
                model: 'P30',
                fromYear: 1995,
                toYear: 1999,
                engines: [],
            },
            {
                model: 'P30 (INDEPENDENT SUSPENSION) (SUSPENSION INDEPENDIENTE)',
                fromYear: 1960,
                toYear: 1991,
                engines: [],
            },
            {
                model: 'P30 11,000 # (INDEPENDENT SUSPENSION) (SUSPENSION INDEPENDIENTE)',
                fromYear: 1973,
                toYear: 1996,
                engines: [],
            },
            {
                model: 'P30 5000 # (SOLID AXLE) (EJE SOLIDO)',
                fromYear: 1985,
                toYear: 1999,
                engines: [],
            },
            {
                model: 'P30 MOTORHOME (INDEPENDENT SUSPENSION) (SUSPENSION INDEPENDIENTE)',
                fromYear: 1973,
                toYear: 1999,
                engines: [],
            },
            {
                model: 'PICKUP 4X4 K10, K20 (1)',
                fromYear: 1961,
                toYear: 1972,
                engines: [],
            },
            {
                model: 'PICKUP 4X4 K10, K20 (2)',
                fromYear: 1973,
                toYear: 1976,
                engines: [],
            },
            {
                model: 'PICKUP 4X4 K10, K20 (3)',
                fromYear: 1976,
                toYear: 1980,
                engines: [],
            },
            {
                model: 'PICKUP 4X4 K10, K20 (4)',
                fromYear: 1981,
                toYear: 1991,
                engines: [],
            },
            {
                model: 'PICKUP K1500 4X4',
                fromYear: 1988,
                toYear: 1999,
                engines: [],
            },
            {
                model: 'PICKUP K2500 4X4 7200 #',
                fromYear: 1988,
                toYear: 1999,
                engines: [],
            },
            {
                model: 'PICKUP K2500 4X4 8600 #',
                fromYear: 1988,
                toYear: 1999,
                engines: [],
            },
            {
                model: 'PICKUP K3500 4X4',
                fromYear: 1988,
                toYear: 1999,
                engines: [],
            },
            {
                model: 'PRIZM',
                fromYear: 1998,
                toYear: 2002,
                engines: [],
            },
            {
                model: 'S10',
                fromYear: 2016,
                toYear: 2017,
                engines: [],
            },
            {
                model: 'S10, S10 BLAZER, JIMMY, SONOMA, BRAVADA',
                fromYear: 1982,
                toYear: 2004,
                engines: [],
            },
            {
                model: 'SAVANNA 1500',
                fromYear: 1996,
                toYear: 2014,
                engines: [],
            },
            {
                model: 'SAVANNA 2500 7300 #',
                fromYear: 1996,
                toYear: 2014,
                engines: [],
            },
            {
                model: 'SAVANNA 2500 7700, 8500, 8600 #',
                fromYear: 1996,
                toYear: 2014,
                engines: [],
            },
            {
                model: 'SAVANNA 3500',
                fromYear: 1996,
                toYear: 2021,
                engines: [],
            },
            {
                model: 'SIERRA 1500',
                fromYear: 2000,
                toYear: 2021,
                engines: [],
            },
            {
                model: 'SIERRA 2500',
                fromYear: 2000,
                toYear: 2006,
                engines: [],
            },
            {
                model: 'SIERRA 2500 HD',
                fromYear: 2001,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'SIERRA 3500',
                fromYear: 2001,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'SILVERADO',
                fromYear: 1998,
                toYear: 2007,
                engines: [],
            },
            {
                model: 'SILVERADO 1500',
                fromYear: 2000,
                toYear: 2021,
                engines: [],
            },
            {
                model: 'SILVERADO 1500 4X4',
                fromYear: 2000,
                toYear: 2006,
                engines: [],
            },
            {
                model: 'SILVERADO 2500',
                fromYear: 1998,
                toYear: 2013,
                engines: [],
            },
            {
                model: 'SILVERADO 2500 4X4',
                fromYear: 2000,
                toYear: 2006,
                engines: [],
            },
            {
                model: 'SILVERADO 2500 HD',
                fromYear: 2001,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'SILVERADO 3500',
                fromYear: 2000,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'SILVERADO 3500 4X4',
                fromYear: 2000,
                toYear: 2006,
                engines: [],
            },
            {
                model: 'SILVERADO PICKUP 1500 H.D. 4X4',
                fromYear: 2000,
                toYear: 2006,
                engines: [],
            },
            {
                model: 'SILVERADO PICKUP 1500 HD 4X2',
                fromYear: 2001,
                toYear: 2010,
                engines: [],
            },
            {
                model: 'SILVERADO PICKUP 2500 H.D. 4X4',
                fromYear: 2000,
                toYear: 2006,
                engines: [],
            },
            {
                model: 'SILVERADO PICKUP 3500 4X4',
                fromYear: 2000,
                toYear: 2006,
                engines: [],
            },
            {
                model: 'SONIC',
                fromYear: 2012,
                toYear: 2018,
                engines: [],
            },
            {
                model: 'SONORA',
                fromYear: 1999,
                toYear: 2006,
                engines: [],
            },
            {
                model: 'SPARK',
                fromYear: 2010,
                toYear: 2017,
                engines: [],
            },
            {
                model: 'SPARK II',
                fromYear: 2016,
                toYear: 2021,
                engines: [],
            },
            {
                model: 'SPECTRUM',
                fromYear: 1985,
                toYear: 1988,
                engines: [],
            },
            {
                model: 'SPRINT',
                fromYear: 1985,
                toYear: 1991,
                engines: [],
            },
            {
                model: 'SUBURBAN',
                fromYear: 1994,
                toYear: 2021,
                engines: [],
            },
            {
                model: 'SUBURBAN 1500',
                fromYear: 1992,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'SUBURBAN 1500 4X4',
                fromYear: 2000,
                toYear: 2006,
                engines: [],
            },
            {
                model: 'SUBURBAN 2500',
                fromYear: 1988,
                toYear: 2010,
                engines: [],
            },
            {
                model: 'SUBURBAN 2500 4X4',
                fromYear: 2000,
                toYear: 2006,
                engines: [],
            },
            {
                model: 'SUBURBAN 4X4 K10, K20 (1)',
                fromYear: 1961,
                toYear: 1972,
                engines: [],
            },
            {
                model: 'SUBURBAN 4X4 K10, K20 (2)',
                fromYear: 1973,
                toYear: 1976,
                engines: [],
            },
            {
                model: 'SUBURBAN 4X4 K10, K20 (3)',
                fromYear: 1976,
                toYear: 1980,
                engines: [],
            },
            {
                model: 'SUBURBAN 4X4 K10, K20 (4)',
                fromYear: 1981,
                toYear: 1991,
                engines: [],
            },
            {
                model: 'SUBURBAN C10',
                fromYear: 1960,
                toYear: 1991,
                engines: [],
            },
            {
                model: 'SUBURBAN C20',
                fromYear: 1960,
                toYear: 1991,
                engines: [],
            },
            {
                model: 'SUBURBAN K1500 4X4',
                fromYear: 1988,
                toYear: 1999,
                engines: [],
            },
            {
                model: 'SUBURBAN K2500 4X4',
                fromYear: 1981,
                toYear: 1991,
                engines: [],
            },
            {
                model: 'TAHOE',
                fromYear: 2000,
                toYear: 2021,
                engines: [],
            },
            {
                model: 'TAHOE 4X4',
                fromYear: 1995,
                toYear: 2006,
                engines: [],
            },
            {
                model: 'TERRAIN',
                fromYear: 2010,
                toYear: 2021,
                engines: [],
            },
            {
                model: 'TIGRA',
                fromYear: 1997,
                toYear: 1999,
                engines: [],
            },
            {
                model: 'TORNADO (1)',
                fromYear: 2004,
                toYear: 2011,
                engines: [],
            },
            {
                model: 'TORNADO (2)',
                fromYear: 2011,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'TRACKER',
                fromYear: 1989,
                toYear: 2008,
                engines: [],
            },
            {
                model: 'TRACKER',
                fromYear: 1989,
                toYear: 2008,
                engines: [],
            },
            {
                model: 'TRAIL BLAZER 4X2, 4X4',
                fromYear: 2002,
                toYear: 2008,
                engines: [],
            },
            {
                model: 'TRAX',
                fromYear: 2013,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'UPLANDER',
                fromYear: 2005,
                toYear: 2009,
                engines: [],
            },
            {
                model: 'V10, V20 4X4',
                fromYear: 1961,
                toYear: 1991,
                engines: [],
            },
            {
                model: 'V1500 SUBURBAN-BLAZER (1)',
                fromYear: 1961,
                toYear: 1972,
                engines: [],
            },
            {
                model: 'V1500 SUBURBAN-BLAZER (2)',
                fromYear: 1973,
                toYear: 1976,
                engines: [],
            },
            {
                model: 'V1500 SUBURBAN-BLAZER (3)',
                fromYear: 1976,
                toYear: 1980,
                engines: [],
            },
            {
                model: 'V1500 SUBURBAN-BLAZER (4)',
                fromYear: 1981,
                toYear: 1991,
                engines: [],
            },
            {
                model: 'V2500 SUBURBAN (1)',
                fromYear: 1961,
                toYear: 1972,
                engines: [],
            },
            {
                model: 'V2500 SUBURBAN (2)',
                fromYear: 1973,
                toYear: 1976,
                engines: [],
            },
            {
                model: 'V2500 SUBURBAN (3)',
                fromYear: 1976,
                toYear: 1980,
                engines: [],
            },
            {
                model: 'V2500 SUBURBAN (4)',
                fromYear: 1981,
                toYear: 1991,
                engines: [],
            },
            {
                model: 'V30 (DANA 60)',
                fromYear: 1977,
                toYear: 1991,
                engines: [],
            },
            {
                model: 'V3500 (DANA 60)',
                fromYear: 1977,
                toYear: 1991,
                engines: [],
            },
            {
                model: 'VECTRA (EUROPA)',
                fromYear: 2003,
                toYear: 2008,
                engines: [],
            },
            {
                model: 'VENTURE',
                fromYear: 1997,
                toYear: 2005,
                engines: [],
            },
            {
                model: 'VOLT',
                fromYear: 2011,
                toYear: 2019,
                engines: [],
            },
            {
                model: 'YUKON',
                fromYear: 2007,
                toYear: 2021,
                engines: [],
            },
            {
                model: 'YUKON 1500',
                fromYear: 2000,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'YUKON 1500 4X4',
                fromYear: 2000,
                toYear: 2006,
                engines: [],
            },
            {
                model: 'YUKON 2500',
                fromYear: 2001,
                toYear: 2010,
                engines: [],
            },
            {
                model: 'ZAFIRA',
                fromYear: 2000,
                toYear: 2006,
                engines: [],
            },
            {
                model: 'ZAFIRA (EUROPA)',
                fromYear: 2007,
                toYear: 2008,
                engines: [],
            },
        ],
    },
    // {
    //     brand: 'DINA', 
    //     models: [
    //         {

    //         },
    //     ],
    // },
    {
        brand: 'DODGE / Chrysler',
        models: [
            {
                model: '200',
                fromYear: 2012,
                toYear: 2015,
                engines: [],
            },
            {
                model: '300-300C',
                fromYear: 2005,
                toYear: 2019,
                engines: [],
            },
            {
                model: '300M',
                fromYear: 1998,
                toYear: 2001,
                engines: [],
            },
            {
                model: '880 SERIES',
                fromYear: 1965,
                toYear: 1977,
                engines: [],
            },
            {
                model: 'ARIES',
                fromYear: 1981,
                toYear: 1988,
                engines: [],
            },
            {
                model: 'ASPEN',
                fromYear: 2007,
                toYear: 2009,
                engines: [],
            },
            {
                model: 'ATOS',
                fromYear: 2000,
                toYear: 2012,
                engines: [],
            },
            {
                model: 'ATTITUDE',
                fromYear: 2006,
                toYear: 2021,
                engines: [],
            },
            {
                model: 'AVENGER',
                fromYear: 1995,
                toYear: 2014,
                engines: [],
            },
            {
                model: 'B150 VAN',
                fromYear: 1970,
                toYear: 2004,
                engines: [],
            },
            {
                model: 'B250 VAN',
                fromYear: 1970,
                toYear: 2004,
                engines: [],
            },
            {
                model: 'B350 VAN',
                fromYear: 1970,
                toYear: 2004,
                engines: [],
            },
            {
                model: 'BARRACUDA',
                fromYear: 1962,
                toYear: 1982,
                engines: [],
            },
            {
                model: 'CALIBER',
                fromYear: 2007,
                toYear: 2012,
                engines: [],
            },
            {
                model: 'CARAVAN',
                fromYear: 1984,
                toYear: 2007,
                engines: [],
            },
            {
                model: 'CHALLENGER',
                fromYear: 1978,
                toYear: 2019,
                engines: [],
            },
            {
                model: 'CHARGER',
                fromYear: 1965,
                toYear: 2019,
                engines: [],
            },
            {
                model: 'CIRRUS',
                fromYear: 1995,
                toYear: 2014,
                engines: [],
            },
            {
                model: 'CIRRUS-STRATUS-COUPE',
                fromYear: 1995,
                toYear: 2006,
                engines: [],
            },
            {
                model: 'COLT',
                fromYear: 1985,
                toYear: 1994,
                engines: [],
            },
            {
                model: 'COLT FWD',
                fromYear: 1979,
                toYear: 1984,
                engines: [],
            },
            {
                model: 'COLT HT Y ST',
                fromYear: 1971,
                toYear: 1977,
                engines: [],
            },
            {
                model: 'COLT RWD',
                fromYear: 1977,
                toYear: 1980,
                engines: [],
            },
            {
                model: 'COLT VISTA WAGON',
                fromYear: 1984,
                toYear: 1991,
                engines: [],
            },
            {
                model: 'CONCORDE',
                fromYear: 1993,
                toYear: 2005,
                engines: [],
            },
            {
                model: 'CONQUEST',
                fromYear: 1984,
                toYear: 1985,
                engines: [],
            },
            {
                model: 'CORDOBA',
                fromYear: 1962,
                toYear: 2002,
                engines: [],
            },
            {
                model: 'CORONET',
                fromYear: 1965,
                toYear: 1979,
                engines: [],
            },
            {
                model: 'CROSSFIRE',
                fromYear: 2004,
                toYear: 2008,
                engines: [],
            },
            {
                model: 'D100',
                fromYear: 1972,
                toYear: 1993,
                engines: [],
            },
            {
                model: 'D100 (1/2)',
                fromYear: 1954,
                toYear: 1971,
                engines: [],
            },
            {
                model: 'D150',
                fromYear: 1972,
                toYear: 1993,
                engines: [],
            },
            {
                model: 'D200 (3/4)',
                fromYear: 1954,
                toYear: 1971,
                engines: [],
            },
            {
                model: 'D200 131" WB, 149" WB, 3000, 3300 #',
                fromYear: 1972,
                toYear: 1993,
                engines: [],
            },
            {
                model: 'D200, 165" 4000 #',
                fromYear: 1972,
                toYear: 1993,
                engines: [],
            },
            {
                model: 'D250 131" WB, 149" WB, 3000, 3300 #',
                fromYear: 1972,
                toYear: 1993,
                engines: [],
            },
            {
                model: 'D250, 165" 4000 #',
                fromYear: 1972,
                toYear: 1993,
                engines: [],
            },
            {
                model: 'D300',
                fromYear: 1972,
                toYear: 1993,
                engines: [],
            },
            {
                model: 'D300 (1 TON)',
                fromYear: 1954,
                toYear: 1971,
                engines: [],
            },
            {
                model: 'D350',
                fromYear: 1972,
                toYear: 1993,
                engines: [],
            },
            {
                model: 'D400',
                fromYear: 1960,
                toYear: 1970,
                engines: [],
            },
            {
                model: 'DAKOTA',
                fromYear: 2005,
                toYear: 2012,
                engines: [],
            },
            {
                model: 'DAKOTA - DURANGO 4X2',
                fromYear: 1987,
                toYear: 2004,
                engines: [],
            },
            {
                model: 'DAKOTA - DURANGO 4X4',
                fromYear: 1987,
                toYear: 2004,
                engines: [],
            },
            {
                model: 'DART',
                fromYear: 1962,
                toYear: 2016,
                engines: [],
            },
            {
                model: 'DART K',
                fromYear: 1982,
                toYear: 1988,
                engines: [],
            },
            {
                model: 'DAYTONA',
                fromYear: 1984,
                toYear: 1993,
                engines: [],
            },
            {
                model: 'DINASTY',
                fromYear: 1988,
                toYear: 1993,
                engines: [],
            },
            {
                model: 'DURANGO',
                fromYear: 2005,
                toYear: 2019,
                engines: [],
            },
            {
                model: 'DUSTER',
                fromYear: 1962,
                toYear: 1982,
                engines: [],
            },
            {
                model: 'EUROPA',
                fromYear: 1982,
                toYear: 1988,
                engines: [],
            },
            {
                model: 'GRAND CARAVAN',
                fromYear: 1984,
                toYear: 2019,
                engines: [],
            },
            {
                model: 'GRAND VOYAGER',
                fromYear: 1984,
                toYear: 2007,
                engines: [],
            },
            {
                model: 'H100',
                fromYear: 2003,
                toYear: 2010,
                engines: [],
            },
            {
                model: 'H100 VAN',
                fromYear: 2009,
                toYear: 2014,
                engines: [],
            },
            {
                model: 'I10',
                fromYear: 2012,
                toYear: 2014,
                engines: [],
            },
            {
                model: 'IMPERIAL',
                fromYear: 1990,
                toYear: 1993,
                engines: [],
            },
            {
                model: 'INTREPID',
                fromYear: 1993,
                toYear: 2005,
                engines: [],
            },
            {
                model: 'JOURNEY',
                fromYear: 2009,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'LANCER',
                fromYear: 1985,
                toYear: 2011,
                engines: [],
            },
            {
                model: 'LASER',
                fromYear: 1984,
                toYear: 1986,
                engines: [],
            },
            {
                model: 'LEBARON',
                fromYear: 1962,
                toYear: 1995,
                engines: [],
            },
            {
                model: 'LHS',
                fromYear: 1993,
                toYear: 2005,
                engines: [],
            },
            {
                model: 'MAGNUM (1)',
                fromYear: 1962,
                toYear: 1972,
                engines: [],
            },
            {
                model: 'MAGNUM (2)',
                fromYear: 1973,
                toYear: 1976,
                engines: [],
            },
            {
                model: 'MAGNUM (3)',
                fromYear: 1977,
                toYear: 1982,
                engines: [],
            },
            {
                model: 'MAGNUM (4)',
                fromYear: 1982,
                toYear: 1983,
                engines: [],
            },
            {
                model: 'MAGNUM (5)',
                fromYear: 1984,
                toYear: 1985,
                engines: [],
            },
            {
                model: 'MAGNUM (6)',
                fromYear: 1956,
                toYear: 1988,
                engines: [],
            },
            {
                model: 'MONACO',
                fromYear: 1965,
                toYear: 1977,
                engines: [],
            },
            {
                model: 'NEON',
                fromYear: 1995,
                toYear: 2018,
                engines: [],
            },
            {
                model: 'NEW YORKER',
                fromYear: 1982,
                toYear: 1997,
                engines: [],
            },
            {
                model: 'NITRO',
                fromYear: 2007,
                toYear: 2012,
                engines: [],
            },
            {
                model: 'OMNI',
                fromYear: 1978,
                toYear: 1990,
                engines: [],
            },
            {
                model: 'P200',
                fromYear: 1961,
                toYear: 1970,
                engines: [],
            },
            {
                model: 'P300',
                fromYear: 1961,
                toYear: 1970,
                engines: [],
            },
            {
                model: 'P300 (VANET)',
                fromYear: 1957,
                toYear: 1993,
                engines: [],
            },
            {
                model: 'P400 (1 1/2)',
                fromYear: 1960,
                toYear: 1970,
                engines: [],
            },
            {
                model: 'PACIFICA',
                fromYear: 2004,
                toYear: 2008,
                engines: [],
            },
            {
                model: 'PHANTOM',
                fromYear: 1989,
                toYear: 1995,
                engines: [],
            },
            {
                model: 'PROMASTER',
                fromYear: 2014,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'PROMASTER RAPID',
                fromYear: 2017,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'PT CRUISER',
                fromYear: 2001,
                toYear: 2010,
                engines: [],
            },
            {
                model: 'RAM 1500',
                fromYear: 2009,
                toYear: 2019,
                engines: [],
            },
            {
                model: 'RAM 1500 4X2',
                fromYear: 1994,
                toYear: 2002,
                engines: [],
            },
            {
                model: 'RAM 1500 4X2 Y 4X4 (INDEPENDENT SUSPENSION) (SUSPENSION INDEPENDIENTE)',
                fromYear: 2003,
                toYear: 2019,
                engines: [],
            },
            {
                model: 'RAM 1500 4X4 3850 # (SOLID AXLE) (EJE SOLIDO)',
                fromYear: 1994,
                toYear: 2002,
                engines: [],
            },
            {
                model: 'RAM 1500 VAN',
                fromYear: 1970,
                toYear: 2004,
                engines: [],
            },
            {
                model: 'RAM 2500 4X2 (INDEPENDENT SUSPENSION) (SUSPENSION INDEPENDIENTE)',
                fromYear: 1994,
                toYear: 2002,
                engines: [],
            },
            {
                model: 'RAM 2500 4X2 Y 4X4 (INDEPENDENT SUSPENSION) (SUSPENSION INDEPENDIENTE)',
                fromYear: 2003,
                toYear: 2014,
                engines: [],
            },
            {
                model: 'RAM 2500 4X4 3850 # (SOLID AXLE) (EJE SOLIDO)',
                fromYear: 1994,
                toYear: 1999,
                engines: [],
            },
            {
                model: 'RAM 2500 4X4 4500 # (SOLID AXLE) (EJE SOLIDO)',
                fromYear: 1994,
                toYear: 1999,
                engines: [],
            },
            {
                model: 'RAM 2500 4X4, 2500 HD 4X4 (SOLID AXLE) (EJE SOLIDO)',
                fromYear: 2000,
                toYear: 2018,
                engines: [],
            },
            {
                model: 'RAM 2500 VAN',
                fromYear: 1970,
                toYear: 2004,
                engines: [],
            },
            {
                model: 'RAM 3500 4X2 (INDEPENDENT SUSPENSION) (SUSPENSION INDEPENDIENTE)',
                fromYear: 1994,
                toYear: 2002,
                engines: [],
            },
            {
                model: 'RAM 3500 4X2 (SOLID AXLE) (EJE SOLIDO)',
                fromYear: 1994,
                toYear: 2002,
                engines: [],
            },
            {
                model: 'RAM 3500 4X2 Y 4X4 (INDEPENDENT SUSPENSION) (SUSPENSION INDEPENDIENTE)',
                fromYear: 2003,
                toYear: 2014,
                engines: [],
            },
            {
                model: 'RAM 3500 4X4 (SOLID AXLE) (EJE SOLIDO)',
                fromYear: 1994,
                toYear: 2018,
                engines: [],
            },
            {
                model: 'RAM 3500 VAN',
                fromYear: 1970,
                toYear: 2004,
                engines: [],
            },
            {
                model: 'RAM 4000',
                fromYear: 1994,
                toYear: 2018,
                engines: [],
            },
            {
                model: 'RAM 50 4X2 PICKUP',
                fromYear: 1979,
                toYear: 1993,
                engines: [],
            },
            {
                model: 'RAM 50 PICKUP 4X4',
                fromYear: 1982,
                toYear: 1994,
                engines: [],
            },
            {
                model: 'RAM 700',
                fromYear: 2015,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'RAM WAGON 1500',
                fromYear: 1970,
                toYear: 2004,
                engines: [],
            },
            {
                model: 'RAM WAGON 2500',
                fromYear: 1970,
                toYear: 2004,
                engines: [],
            },
            {
                model: 'RAM WAGON 3500',
                fromYear: 1970,
                toYear: 2004,
                engines: [],
            },
            {
                model: 'RAMCHARGER 4X2',
                fromYear: 1972,
                toYear: 2002,
                engines: [],
            },
            {
                model: 'RAMCHARGER 4X4',
                fromYear: 1974,
                toYear: 1993,
                engines: [],
            },
            {
                model: 'SEBRING COUPE',
                fromYear: 1995,
                toYear: 2006,
                engines: [],
            },
            {
                model: 'SHADOW',
                fromYear: 1989,
                toYear: 1995,
                engines: [],
            },
            {
                model: 'SPIRIT',
                fromYear: 1989,
                toYear: 1995,
                engines: [],
            },
            {
                model: 'STRATUS',
                fromYear: 1995,
                toYear: 2006,
                engines: [],
            },
            {
                model: 'SUPERBEE',
                fromYear: 1962,
                toYear: 1982,
                engines: [],
            },
            {
                model: 'TOWN & COUNTRY',
                fromYear: 1991,
                toYear: 2017,
                engines: [],
            },
            {
                model: 'TOWN & COUNTRY',
                fromYear: 1991,
                toYear: 2017,
                engines: [],
            },
            {
                model: 'VALIANT',
                fromYear: 1962,
                toYear: 1982,
                engines: [],
            },
            {
                model: 'VAN 1000',
                fromYear: 2008,
                toYear: 2009,
                engines: [],
            },
            {
                model: 'VERNA',
                fromYear: 2004,
                toYear: 2006,
                engines: [],
            },
            {
                model: 'VISION',
                fromYear: 2015,
                toYear: 2018,
                engines: [],
            },
            {
                model: 'VOLARE (1)',
                fromYear: 1962,
                toYear: 1972,
                engines: [],
            },
            {
                model: 'VOLARE (2)',
                fromYear: 1973,
                toYear: 1976,
                engines: [],
            },
            {
                model: 'VOLARE (3)',
                fromYear: 1977,
                toYear: 1982,
                engines: [],
            },
            {
                model: 'VOLARE (4)',
                fromYear: 1982,
                toYear: 1983,
                engines: [],
            },
            {
                model: 'VOLARE (5)',
                fromYear: 1984,
                toYear: 1985,
                engines: [],
            },
            {
                model: 'VOLARE (6)',
                fromYear: 1986,
                toYear: 1988,
                engines: [],
            },
            {
                model: 'VOYAGER',
                fromYear: 1984,
                toYear: 2007,
                engines: [],
            },
        ],
    },
    {
        brand: 'Eagle', 
        models: [
            {
                model: 'TALON',
                fromYear: 1990,
                toYear: 1998,
                engines: [],
            },
            {
                model: 'VISION',
                fromYear: 1993,
                toYear: 1997,
                engines: [],
            },
        ],
    },
    {
        brand: 'FIAT', 
        models: [
            {
                model: '500',
                fromYear: 2009,
                toYear: 2019,
                engines: [],
            },
            {
                model: '500 X',
                fromYear: 2016,
                toYear: 2017,
                engines: [],
            },
            {
                model: 'ALBEA',
                fromYear: 2009,
                toYear: 2011,
                engines: [],
            },
            {
                model: 'DUCATO',
                fromYear: 2006,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'GRANDE PUNTO',
                fromYear: 2005,
                toYear: 2011,
                engines: [],
            },
            {
                model: 'IDEA ADVENTURE',
                fromYear: 2008,
                toYear: 2009,
                engines: [],
            },
            {
                model: 'LINEA',
                fromYear: 2010,
                toYear: 2012,
                engines: [],
            },
            {
                model: 'MOBI',
                fromYear: 2017,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'PALIO',
                fromYear: 1996,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'PALIO - ADVENTURE - STRADA',
                fromYear: 2004,
                toYear: 2011,
                engines: [],
            },
            {
                model: 'PALIO ADVENTURE',
                fromYear: 2012,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'PANDA',
                fromYear: 2007,
                toYear: 2012,
                engines: [],
            },
            {
                model: 'STRADA',
                fromYear: 2012,
                toYear: 2017,
                engines: [],
            },
            {
                model: 'UNO',
                fromYear: 2013,
                toYear: 2020,
                engines: [],
            },
        ],
    },
    {
        brand: 'Ford / Mercury', 
        models: [
            {
                model: 'AEROSTAR Y 4X4',
                fromYear: 1986,
                toYear: 1997,
                engines: [],
            },
            {
                model: 'BRONCO II 4X2',
                fromYear: 1987,
                toYear: 1990,
                engines: [],
            },
            {
                model: 'BRONCO II 4X4',
                fromYear: 1984,
                toYear: 1990,
                engines: [],
            },
            {
                model: 'BRONCO II 4X4 (DANA 35)',
                fromYear: 1990,
                toYear: 1990,
                engines: [],
            },
            {
                model: 'BRONCO U150 4X4',
                fromYear: 1966,
                toYear: 1996,
                engines: [],
            },
            {
                model: 'CAPRI',
                fromYear: 1981,
                toYear: 1987,
                engines: [],
            },
            {
                model: 'CONTOUR',
                fromYear: 1995,
                toYear: 2001,
                engines: [],
            },
            {
                model: 'COUGAR',
                fromYear: 1973,
                toYear: 2002,
                engines: [],
            },
            {
                model: 'COUNTRY SQUIRE',
                fromYear: 1965,
                toYear: 1991,
                engines: [],
            },
            {
                model: 'COURIER',
                fromYear: 1972,
                toYear: 1982,
                engines: [],
            },
            {
                model: 'CROWN VICTORIA',
                fromYear: 1979,
                toYear: 2011,
                engines: [],
            },
            {
                model: 'E100 VAN',
                fromYear: 1961,
                toYear: 1991,
                engines: [],
            },
            {
                model: 'E150 VAN',
                fromYear: 1975,
                toYear: 2014,
                engines: [],
            },
            {
                model: 'E200 VAN',
                fromYear: 1969,
                toYear: 1974,
                engines: [],
            },
            {
                model: 'E250 VAN',
                fromYear: 1975,
                toYear: 2014,
                engines: [],
            },
            {
                model: 'E300 VAN',
                fromYear: 1969,
                toYear: 1974,
                engines: [],
            },
            {
                model: 'E350 VAN',
                fromYear: 1975,
                toYear: 2014,
                engines: [],
            },
            {
                model: 'ECOSPORT',
                fromYear: 2003,
                toYear: 2021,
                engines: [],
            },
            {
                model: 'EDGE',
                fromYear: 2007,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'ESCAPE',
                fromYear: 2001,
                toYear: 2019,
                engines: [],
            },
            {
                model: 'ESCAPE - MARINER',
                fromYear: 2001,
                toYear: 2019,
                engines: [],
            },
            {
                model: 'ESCORT - ZX2',
                fromYear: 1981,
                toYear: 2002,
                engines: [],
            },
            {
                model: 'EXCURSION 4X2',
                fromYear: 1999,
                toYear: 2006,
                engines: [],
            },
            {
                model: 'EXCURSION 4X4',
                fromYear: 2000,
                toYear: 2005,
                engines: [],
            },
            {
                model: 'EXPEDITION 4X2',
                fromYear: 1997,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'EXPEDITION 4X4',
                fromYear: 1997,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'EXPLORER',
                fromYear: 2011,
                toYear: 2019,
                engines: [],
            },
            {
                model: 'EXPLORER 4X2',
                fromYear: 1991,
                toYear: 2010,
                engines: [],
            },
            {
                model: 'EXPLORER 4X4',
                fromYear: 1991,
                toYear: 2010,
                engines: [],
            },
            {
                model: 'EXPLORER 4X4 (DANA 28)',
                fromYear: 1991,
                toYear: 1994,
                engines: [],
            },
            {
                model: 'EXPLORER 4X4 (DANA 35)',
                fromYear: 1991,
                toYear: 1994,
                engines: [],
            },
            {
                model: 'EXPLORER SPORT 4X2',
                fromYear: 1995,
                toYear: 2003,
                engines: [],
            },
            {
                model: 'EXPLORER SPORT 4X4',
                fromYear: 1995,
                toYear: 2003,
                engines: [],
            },
            {
                model: 'EXPLORER SPORT TRAC 4X2',
                fromYear: 2001,
                toYear: 2010,
                engines: [],
            },
            {
                model: 'EXPLORER SPORT TRAC 4X4',
                fromYear: 2001,
                toYear: 2010,
                engines: [],
            },
            {
                model: 'F-SUPER DUTY (CHASSIS CAB) (CHASIS CABINA)',
                fromYear: 1988,
                toYear: 1997,
                engines: [],
            },
            {
                model: 'F-SUPER DUTY (COMERCIAL CHASSIS) (CHASIS COMERCIAL)',
                fromYear: 1989,
                toYear: 1997,
                engines: [],
            },
            {
                model: 'F100 4X2',
                fromYear: 1961,
                toYear: 1998,
                engines: [],
            },
            {
                model: 'F100 4X4',
                fromYear: 1972,
                toYear: 1979,
                engines: [],
            },
            {
                model: 'F150 4X2',
                fromYear: 1961,
                toYear: 2019,
                engines: [],
            },
            {
                model: 'F150 4X2 USA',
                fromYear: 1997,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'F150 4X4',
                fromYear: 1972,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'F200 4X2',
                fromYear: 1985,
                toYear: 1986,
                engines: [],
            },
            {
                model: 'F250 - F350 4X4 (TWIN I BEAM) (DOBLE SEMIEJE)',
                fromYear: 1980,
                toYear: 1986,
                engines: [],
            },
            {
                model: 'F250 - F350 SUPER DUTY 4X2 (SOLID AXLE) (EJE SOLIDO)',
                fromYear: 1999,
                toYear: 2006,
                engines: [],
            },
            {
                model: 'F250 - F350 SUPER DUTY 4X4 (EJE SOLIDO)',
                fromYear: 1999,
                toYear: 2019,
                engines: [],
            },
            {
                model: 'F250 4X2',
                fromYear: 1961,
                toYear: 2003,
                engines: [],
            },
            {
                model: 'F250 4X2 USA',
                fromYear: 1998,
                toYear: 2003,
                engines: [],
            },
            {
                model: 'F250 4X4',
                fromYear: 1972,
                toYear: 2019,
                engines: [],
            },
            {
                model: 'F250 4X4 (TWIN I BEAM) (DOBLE SEMIEJE)',
                fromYear: 1987,
                toYear: 1997,
                engines: [],
            },
            {
                model: 'F250 4X4 USA',
                fromYear: 1998,
                toYear: 2003,
                engines: [],
            },
            {
                model: 'F250 SUPER DUTY',
                fromYear: 2005,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'F250 SUPER DUTY 4X2 (DOBLE SEMIEJE)',
                fromYear: 1999,
                toYear: 2021,
                engines: [],
            },
            {
                model: 'F350 4X2',
                fromYear: 1961,
                toYear: 2003,
                engines: [],
            },
            {
                model: 'F350 4X4',
                fromYear: 1991,
                toYear: 2014,
                engines: [],
            },
            {
                model: 'F350 4X4 (SOLID AXLE) (EJE SOLIDO)',
                fromYear: 1985,
                toYear: 1997,
                engines: [],
            },
            {
                model: 'F350 SUPER DUTY',
                fromYear: 2005,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'F350 SUPER DUTY 4X2 (DOBLE SEMIEJE)',
                fromYear: 1999,
                toYear: 2021,
                engines: [],
            },
            {
                model: 'F450 4X2',
                fromYear: 2003,
                toYear: 2019,
                engines: [],
            },
            {
                model: 'F450-F550',
                fromYear: 1999,
                toYear: 2019,
                engines: [],
            },
            {
                model: 'F550 4X2',
                fromYear: 1999,
                toYear: 2019,
                engines: [],
            },
            {
                model: 'F550 4X4',
                fromYear: 2008,
                toYear: 2010,
                engines: [],
            },
            {
                model: 'FAIRMONT',
                fromYear: 1978,
                toYear: 1984,
                engines: [],
            },
            {
                model: 'FALCON',
                fromYear: 1960,
                toYear: 1970,
                engines: [],
            },
            {
                model: 'FESTIVA',
                fromYear: 1988,
                toYear: 1993,
                engines: [],
            },
            {
                model: 'FIESTA',
                fromYear: 2011,
                toYear: 2019,
                engines: [],
            },
            {
                model: 'FIESTA (BRASIL)',
                fromYear: 2003,
                toYear: 2012,
                engines: [],
            },
            {
                model: 'FIESTA COURIER',
                fromYear: 2000,
                toYear: 2012,
                engines: [],
            },
            {
                model: 'Fiesta SE',
                fromYear: 2014,
                toYear: 2017,
                engines: [],
            },
            {
                model: 'FIESTA-IKON',
                fromYear: 1998,
                toYear: 2012,
                engines: [],
            },
            {
                model: 'FIESTA-IKON (INDIA)',
                fromYear: 2012,
                toYear: 2015,
                engines: [],
            },
            {
                model: 'FIGO',
                fromYear: 2016,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'FIVE HUNDRED - MONTEGO',
                fromYear: 2005,
                toYear: 2007,
                engines: [],
            },
            {
                model: 'FLEX',
                fromYear: 2009,
                toYear: 2015,
                engines: [],
            },
            {
                model: 'FOCUS (EUROPA)',
                fromYear: 2007,
                toYear: 2011,
                engines: [],
            },
            {
                model: 'FOCUS NORTE AMERICA',
                fromYear: 1999,
                toYear: 2019,
                engines: [],
            },
            {
                model: 'FORD',
                fromYear: 1957,
                toYear: 1964,
                engines: [],
            },
            {
                model: 'FREESTAR',
                fromYear: 2005,
                toYear: 2008,
                engines: [],
            },
            {
                model: 'FREESTYLE',
                fromYear: 2005,
                toYear: 2007,
                engines: [],
            },
            {
                model: 'FUSION - MILAN',
                fromYear: 2005,
                toYear: 2019,
                engines: [],
            },
            {
                model: 'FX4',
                fromYear: 2004,
                toYear: 2008,
                engines: [],
            },
            {
                model: 'GALAXIE 500',
                fromYear: 1965,
                toYear: 1978,
                engines: [],
            },
            {
                model: 'GRANADA',
                fromYear: 1975,
                toYear: 1980,
                engines: [],
            },
            {
                model: 'GRAND MARQUIS',
                fromYear: 1979,
                toYear: 2011,
                engines: [],
            },
            {
                model: 'KA',
                fromYear: 2001,
                toYear: 2008,
                engines: [],
            },
            {
                model: 'LOBO 4X2',
                fromYear: 1997,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'LOBO 4X4',
                fromYear: 1997,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'LTD',
                fromYear: 1965,
                toYear: 1986,
                engines: [],
            },
            {
                model: 'MAVERICK',
                fromYear: 1970,
                toYear: 1979,
                engines: [],
            },
            {
                model: 'MICROBUS',
                fromYear: 1980,
                toYear: 1998,
                engines: [],
            },
            {
                model: 'MONDEO',
                fromYear: 2002,
                toYear: 2007,
                engines: [],
            },
            {
                model: 'MOUNTAINEER',
                fromYear: 1995,
                toYear: 2010,
                engines: [],
            },
            {
                model: 'MUSTANG',
                fromYear: 1982,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'MUSTANG (6 CYL. M.S.) (6 CIL. STD.)',
                fromYear: 1965,
                toYear: 1966,
                engines: [],
            },
            {
                model: 'MUSTANG (6 CYL. P.S.) (6 CIL. HID.)',
                fromYear: 1965,
                toYear: 1966,
                engines: [],
            },
            {
                model: 'MUSTANG (8 CYL. M.S.) (8 CIL. STD.)',
                fromYear: 1965,
                toYear: 1966,
                engines: [],
            },
            {
                model: 'MUSTANG (8 CYL. P.S.) (8 CIL. HID.)',
                fromYear: 1965,
                toYear: 1966,
                engines: [],
            },
            {
                model: 'MUSTANG (M.S.) (STD.)',
                fromYear: 1967,
                toYear: 1981,
                engines: [],
            },
            {
                model: 'MUSTANG (P.S.) (HID.)',
                fromYear: 1967,
                toYear: 1981,
                engines: [],
            },
            {
                model: 'MYSTIQUE',
                fromYear: 1995,
                toYear: 2001,
                engines: [],
            },
            {
                model: 'PINTO',
                fromYear: 1971,
                toYear: 1980,
                engines: [],
            },
            {
                model: 'POLICE INTERCEPTOR SEDAN',
                fromYear: 2013,
                toYear: 2019,
                engines: [],
            },
            {
                model: 'PROBE',
                fromYear: 1989,
                toYear: 1997,
                engines: [],
            },
            {
                model: 'RANGER',
                fromYear: 2013,
                toYear: 2022,
                engines: [],
            },
            {
                model: 'RANGER 4X2',
                fromYear: 1983,
                toYear: 2012,
                engines: [],
            },
            {
                model: 'RANGER 4X4',
                fromYear: 1998,
                toYear: 2012,
                engines: [],
            },
            {
                model: 'RANGER 4X4 (DANA 28)',
                fromYear: 1983,
                toYear: 1997,
                engines: [],
            },
            {
                model: 'RANGER 4X4 (DANA 35)',
                fromYear: 1990,
                toYear: 1997,
                engines: [],
            },
            {
                model: 'RAPTOR',
                fromYear: 2010,
                toYear: 2014,
                engines: [],
            },
            {
                model: 'SABLE',
                fromYear: 1986,
                toYear: 2009,
                engines: [],
            },
            {
                model: 'TAURUS',
                fromYear: 1986,
                toYear: 2016,
                engines: [],
            },
            {
                model: 'TEMPO',
                fromYear: 1983,
                toYear: 1994,
                engines: [],
            },
            {
                model: 'THUNDERBIRD',
                fromYear: 1973,
                toYear: 2003,
                engines: [],
            },
            {
                model: 'TOPAZ',
                fromYear: 1983,
                toYear: 1994,
                engines: [],
            },
            {
                model: 'TRACER',
                fromYear: 1988,
                toYear: 1999,
                engines: [],
            },
            {
                model: 'TRANSIT',
                fromYear: 2007,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'VILLAGER',
                fromYear: 1993,
                toYear: 2002,
                engines: [],
            },
            {
                model: 'WINDSTAR',
                fromYear: 1995,
                toYear: 2004,
                engines: [],
            },
        ],
    },
    {
        brand: 'Geo', 
        models: [
            {
                model: 'METRO',
                fromYear: 1989,
                toYear: 1997,
                engines: [],
            },
            {
                model: 'PRIZM',
                fromYear: 1989,
                toYear: 1997,
                engines: [],
            },
            {
                model: 'SPECTRUM',
                fromYear: 1989,
                toYear: 1997,
                engines: [],
            },
            {
                model: 'TRACKER',
                fromYear: 1989,
                toYear: 1997,
                engines: [],
            },
        ],
    },
    {
        brand: 'Honda', 
        models: [
            {
                model: 'ACCORD',
                fromYear: 1976,
                toYear: 2021,
                engines: [],
            },
            {
                model: 'ACCORD CROSSTOUR',
                fromYear: 2010,
                toYear: 2014,
                engines: [],
            },
            {
                model: 'BR-V',
                fromYear: 2016,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'CITY',
                fromYear: 2009,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'CIVIC',
                fromYear: 1973,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'CR-Z',
                fromYear: 2011,
                toYear: 2014,
                engines: [],
            },
            {
                model: 'CRV',
                fromYear: 1997,
                toYear: 2021,
                engines: [],
            },
            {
                model: 'ELEMENT',
                fromYear: 2003,
                toYear: 2011,
                engines: [],
            },
            {
                model: 'FIT',
                fromYear: 2006,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'HR-V',
                fromYear: 2016,
                toYear: 2022,
                engines: [],
            },
            {
                model: 'INSIGHT',
                fromYear: 2011,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'ODYSSEY',
                fromYear: 1995,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'PASSPORT',
                fromYear: 1994,
                toYear: 2002,
                engines: [],
            },
            {
                model: 'PILOT',
                fromYear: 2003,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'PRELUDE',
                fromYear: 1979,
                toYear: 2001,
                engines: [],
            },
            {
                model: 'RIDGELINE',
                fromYear: 2006,
                toYear: 2014,
                engines: [],
            },
        ],
    },
    {
        brand: 'Hyundai', 
        models: [
            {
                model: 'ACCENT (1)',
                fromYear: 2000,
                toYear: 2006,
                engines: [],
            },
            {
                model: 'ACCENT (2)',
                fromYear: 2006,
                toYear: 2010,
                engines: [],
            },
            {
                model: 'ACCENT (3)',
                fromYear: 2011,
                toYear: 2017,
                engines: [],
            },
            {
                model: 'ACCENT (4)',
                fromYear: 2018,
                toYear: 2022,
                engines: [],
            },
            {
                model: 'AZERA',
                fromYear: 2012,
                toYear: 2014,
                engines: [],
            },
            {
                model: 'CRETA',
                fromYear: 2017,
                toYear: 2021,
                engines: [],
            },
            {
                model: 'ELANTRA',
                fromYear: 1992,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'EXCEL',
                fromYear: 1986,
                toYear: 1995,
                engines: [],
            },
            {
                model: 'GRAND I10',
                fromYear: 2015,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'IONIQ',
                fromYear: 2015,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'IONIQ',
                fromYear: 2018,
                toYear: 2022,
                engines: [],
            },
            {
                model: 'IX35',
                fromYear: 2011,
                toYear: 2015,
                engines: [],
            },
            {
                model: 'PONY',
                fromYear: 1984,
                toYear: 1987,
                engines: [],
            },
            {
                model: 'SANTA FE',
                fromYear: 2001,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'SCOUPE',
                fromYear: 1986,
                toYear: 1995,
                engines: [],
            },
            {
                model: 'SONATA',
                fromYear: 1989,
                toYear: 2018,
                engines: [],
            },
            {
                model: 'STAREX',
                fromYear: 2018,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'TIBURON',
                fromYear: 1997,
                toYear: 2001,
                engines: [],
            },
            {
                model: 'TUCSON',
                fromYear: 2005,
                toYear: 2021,
                engines: [],
            },
            {
                model: 'VELOSTER N',
                fromYear: 2019,
                toYear: 2020,
                engines: [],
            },
        ],
    },
    {
        brand: 'Infinity', 
        models: [
            {
                model: 'FX50',
                fromYear: 2008,
                toYear: 2013,
                engines: [],
            },
            {
                model: 'I30',
                fromYear: 1996,
                toYear: 1999,
                engines: [],
            },
            {
                model: 'I35',
                fromYear: 2001,
                toYear: 2004,
                engines: [],
            },
            {
                model: 'JX35',
                fromYear: 2011,
                toYear: 2013,
                engines: [],
            },
            {
                model: 'Q45',
                fromYear: 1997,
                toYear: 2005,
                engines: [],
            },
            {
                model: 'QX50',
                fromYear: 2018,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'QX56',
                fromYear: 2004,
                toYear: 2013,
                engines: [],
            },
            {
                model: 'QX60',
                fromYear: 2014,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'QX70',
                fromYear: 2014,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'QX70',
                fromYear: 2014,
                toYear: 2020,
                engines: [],
            },
        ],
    },
    {
        brand: 'Isuzu', 
        models: [
            {
                model: 'AMIGO 4X2',
                fromYear: 1989,
                toYear: 2002,
                engines: [],
            },
            {
                model: 'AMIGO 4X4',
                fromYear: 1998,
                toYear: 2002,
                engines: [],
            },
            {
                model: 'AXIOM',
                fromYear: 2002,
                toYear: 2003,
                engines: [],
            },
            {
                model: 'HOMBRE 4X2',
                fromYear: 1996,
                toYear: 2002,
                engines: [],
            },
            {
                model: 'I-MARK (1)',
                fromYear: 1981,
                toYear: 1985,
                engines: [],
            },
            {
                model: 'I-MARK (2)',
                fromYear: 1985,
                toYear: 1989,
                engines: [],
            },
            {
                model: 'IMPULSE',
                fromYear: 1983,
                toYear: 1989,
                engines: [],
            },
            {
                model: 'OASIS',
                fromYear: 1996,
                toYear: 1999,
                engines: [],
            },
            {
                model: 'PICKUP 4X2',
                fromYear: 1981,
                toYear: 1996,
                engines: [],
            },
            {
                model: 'PICKUP 4X4',
                fromYear: 1981,
                toYear: 1997,
                engines: [],
            },
            {
                model: 'RODEO 4X4',
                fromYear: 1998,
                toYear: 2002,
                engines: [],
            },
            {
                model: 'TROOPER 4X2',
                fromYear: 1998,
                toYear: 2002,
                engines: [],
            },
            {
                model: 'TROOPER 4X4',
                fromYear: 1984,
                toYear: 2002,
                engines: [],
            },
        ],
    },
    {
        brand: 'Jaguar', 
        models: [
            {
                model: 'F-PACE',
                fromYear: 2015,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'S TYPE',
                fromYear: 2000,
                toYear: 2008,
                engines: [],
            },
            {
                model: 'X TYPE',
                fromYear: 2002,
                toYear: 2008,
                engines: [],
            },
            {
                model: 'XE',
                fromYear: 2017,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'XJ',
                fromYear: 2004,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'XK8',
                fromYear: 1996,
                toYear: 2006,
                engines: [],
            },
            {
                model: 'XKR',
                fromYear: 2002,
                toYear: 2006,
                engines: [],
            },
        ],
    },
    {
        brand: 'JEEP', 
        models: [
            {
                model: 'CHEROKEE',
                fromYear: 1994,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'CHEROKEE - WAGONEER SPORTWAGON 4X2',
                fromYear: 1984,
                toYear: 1990,
                engines: [],
            },
            {
                model: 'CHEROKEE SPORTWAGON',
                fromYear: 1991,
                toYear: 2001,
                engines: [],
            },
            {
                model: 'CHEROKEE Y GRAND CHEROKEE 4.0L 6 CYL 4X4',
                fromYear: 1984,
                toYear: 1998,
                engines: [],
            },
            {
                model: 'CJ-5 Y CJ-7',
                fromYear: 1962,
                toYear: 1986,
                engines: [],
            },
            {
                model: 'COMANCHE',
                fromYear: 1985,
                toYear: 1992,
                engines: [],
            },
            {
                model: 'COMPASS',
                fromYear: 2007,
                toYear: 2022,
                engines: [],
            },
            {
                model: 'GRAND CHEROKEE',
                fromYear: 1999,
                toYear: 2018,
                engines: [],
            },
            {
                model: 'GRAND CHEROKEE - COMMANDER',
                fromYear: 2005,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'GRAND CHEROKEE 4.0 L 6 CYL. 4X2',
                fromYear: 1993,
                toYear: 1998,
                engines: [],
            },
            {
                model: 'GRAND CHEROKEE 5.2 L 8 CYL 4X2',
                fromYear: 1993,
                toYear: 1998,
                engines: [],
            },
            {
                model: 'GRAND CHEROKEE 5.2 L 8 CYL. 4X4',
                fromYear: 1993,
                toYear: 1998,
                engines: [],
            },
            {
                model: 'LIBERTY',
                fromYear: 2002,
                toYear: 2012,
                engines: [],
            },
            {
                model: 'LIBERTY 4X4',
                fromYear: 2002,
                toYear: 2007,
                engines: [],
            },
            {
                model: 'PATRIOT-COMPASS',
                fromYear: 2002,
                toYear: 2007,
                engines: [],
            },
            {
                model: 'RENEGADE',
                fromYear: 2017,
                toYear: 2021,
                engines: [],
            },
            {
                model: 'WAGONEER J-10 J-20 SERIE 10',
                fromYear: 1974,
                toYear: 1991,
                engines: [],
            },
            {
                model: 'WRANGLER',
                fromYear: 1987,
                toYear: 2020,
                engines: [],
            },
        ],
    },
    {
        brand: 'KIA', 
        models: [
            {
                model: 'FORTE',
                fromYear: 2014,
                toYear: 2022,
                engines: [],
            },
            {
                model: 'NIRO',
                fromYear: 2017,
                toYear: 2019,
                engines: [],
            },
            {
                model: 'OPTIMA',
                fromYear: 2011,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'RIO',
                fromYear: 2006,
                toYear: 2022,
                engines: [],
            },
            {
                model: 'SEDONA',
                fromYear: 2015,
                toYear: 2021,
                engines: [],
            },
            {
                model: 'SORENTO',
                fromYear: 2015,
                toYear: 2021,
                engines: [],
            },
            {
                model: 'SOUL',
                fromYear: 2010,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'SPORTAGE',
                fromYear: 2005,
                toYear: 2021,
                engines: [],
            },
            {
                model: 'SPORTAGE II',
                fromYear: 2016,
                toYear: 2021,
                engines: [],
            },
        ],
    },
    {
        brand: 'Land Rover', 
        models: [
            {
                model: 'DISCOVERY',
                fromYear: 2004,
                toYear: 2021,
                engines: [],
            },
            {
                model: 'DISCOVERY SPORT',
                fromYear: 2016,
                toYear: 2021,
                engines: [],
            },
            {
                model: 'FREELANDER',
                fromYear: 1999,
                toYear: 2006,
                engines: [],
            },
            {
                model: 'RANGE ROVER',
                fromYear: 2009,
                toYear: 2006,
                engines: [],
            },
            {
                model: 'RANGE ROVER EVOQUE',
                fromYear: 2011,
                toYear: 2021,
                engines: [],
            },
            {
                model: 'RANGE ROVER SPORT',
                fromYear: 2013,
                toYear: 2021,
                engines: [],
            },
            {
                model: 'RANGE ROVER VELAR',
                fromYear: 2017,
                toYear: 2021,
                engines: [],
            },
        ],
    },
    {
        brand: 'Lexus', 
        models: [
            {
                model: 'ES250',
                fromYear: 1990,
                toYear: 1992,
                engines: [],
            },
            {
                model: 'ES300',
                fromYear: 1992,
                toYear: 1995,
                engines: [],
            },
        ],
    },
    {
        brand: 'Lincoln', 
        models: [
            {
                model: 'AVIATOR',
                fromYear: 2002,
                toYear: 2005,
                engines: [],
            },
            {
                model: 'BLACKWOOD',
                fromYear: 2001,
                toYear: 2002,
                engines: [],
            },
            {
                model: 'CONTINENTAL',
                fromYear: 1995,
                toYear: 2002,
                engines: [],
            },
            {
                model: 'LINCOLN TOWNCAR',
                fromYear: 1979,
                toYear: 2011,
                engines: [],
            },
            {
                model: 'LS',
                fromYear: 2000,
                toYear: 2007,
                engines: [],
            },
            {
                model: 'MARK LT',
                fromYear: 2006,
                toYear: 2014,
                engines: [],
            },
            {
                model: 'MARK LT 4X2',
                fromYear: 2006,
                toYear: 2014,
                engines: [],
            },
            {
                model: 'MARK LT 4X4',
                fromYear: 2006,
                toYear: 2014,
                engines: [],
            },
            {
                model: 'MARK VIII',
                fromYear: 1993,
                toYear: 1998,
                engines: [],
            },
            {
                model: 'MKC',
                fromYear: 2015,
                toYear: 2019,
                engines: [],
            },
            {
                model: 'MKS',
                fromYear: 2009,
                toYear: 2016,
                engines: [],
            },
            {
                model: 'MKT',
                fromYear: 2010,
                toYear: 2019,
                engines: [],
            },
            {
                model: 'MKX',
                fromYear: 2007,
                toYear: 2018,
                engines: [],
            },
            {
                model: 'MKZ - ZEPHYR',
                fromYear: 2006,
                toYear: 2019,
                engines: [],
            },
            {
                model: 'NAVIGATOR 4X2',
                fromYear: 1997,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'NAVIGATOR 4X4',
                fromYear: 1997,
                toYear: 2020,
                engines: [],
            },
        ],
    },
    {
        brand: 'Mazda', 
        models: [
            {
                model: '2',
                fromYear: 2011,
                toYear: 2020,
                engines: [],
            },
            {
                model: '3',
                fromYear: 2003,
                toYear: 2018,
                engines: [],
            },
            {
                model: '323',
                fromYear: 1986,
                toYear: 1998,
                engines: [],
            },
            {
                model: '5',
                fromYear: 2003,
                toYear: 2015,
                engines: [],
            },
            {
                model: '6',
                fromYear: 2002,
                toYear: 2020,
                engines: [],
            },
            {
                model: '626',
                fromYear: 1979,
                toYear: 2002,
                engines: [],
            },
            {
                model: '929',
                fromYear: 1988,
                toYear: 1991,
                engines: [],
            },
            {
                model: 'CX3',
                fromYear: 2015,
                toYear: 2022,
                engines: [],
            },
            {
                model: 'CX5',
                fromYear: 2013,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'CX7',
                fromYear: 2007,
                toYear: 2012,
                engines: [],
            },
            {
                model: 'CX9',
                fromYear: 2007,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'GLC FWD',
                fromYear: 1981,
                toYear: 1985,
                engines: [],
            },
            {
                model: 'GLC RWD',
                fromYear: 1977,
                toYear: 1986,
                engines: [],
            },
            {
                model: 'MPV MINIVAN 4X2',
                fromYear: 1989,
                toYear: 2006,
                engines: [],
            },
            {
                model: 'MX3',
                fromYear: 1992,
                toYear: 2006,
                engines: [],
            },
            {
                model: 'MX6',
                fromYear: 1993,
                toYear: 1997,
                engines: [],
            },
            {
                model: 'NAVAJO 4X2',
                fromYear: 1992,
                toYear: 1994,
                engines: [],
            },
            {
                model: 'NAVAJO 4X4',
                fromYear: 1991,
                toYear: 1994,
                engines: [],
            },
            {
                model: 'PICKUP 4X2 B1600',
                fromYear: 1972,
                toYear: 1981,
                engines: [],
            },
            {
                model: 'PICKUP 4X2 B1800',
                fromYear: 1972,
                toYear: 1981,
                engines: [],
            },
            {
                model: 'PICKUP 4X2 B2000',
                fromYear: 1972,
                toYear: 1981,
                engines: [],
            },
            {
                model: 'PICKUP 4X2 B2200',
                fromYear: 1982,
                toYear: 1993,
                engines: [],
            },
            {
                model: 'PICKUP 4X2 B2300',
                fromYear: 1994,
                toYear: 2010,
                engines: [],
            },
            {
                model: 'PICKUP 4X2 B2500',
                fromYear: 1998,
                toYear: 2010,
                engines: [],
            },
            {
                model: 'PICKUP 4X2 B2600',
                fromYear: 1986,
                toYear: 1993,
                engines: [],
            },
            {
                model: 'PICKUP 4X2 B3000',
                fromYear: 1994,
                toYear: 2010,
                engines: [],
            },
            {
                model: 'PICKUP 4X2 B4000',
                fromYear: 1994,
                toYear: 2010,
                engines: [],
            },
            {
                model: 'PICKUP 4X4 B2000',
                fromYear: 1987,
                toYear: 1993,
                engines: [],
            },
            {
                model: 'PICKUP 4X4 B2200',
                fromYear: 1987,
                toYear: 1993,
                engines: [],
            },
            {
                model: 'PICKUP 4X4 B2300',
                fromYear: 1994,
                toYear: 1997,
                engines: [],
            },
            {
                model: 'PICKUP 4X4 B2600',
                fromYear: 1987,
                toYear: 1999,
                engines: [],
            },
            {
                model: 'PICKUP 4X4 B3000',
                fromYear: 1994,
                toYear: 1997,
                engines: [],
            },
            {
                model: 'PICKUP 4X4 B4000',
                fromYear: 1994,
                toYear: 2001,
                engines: [],
            },
            {
                model: 'PROTEGE',
                fromYear: 1986,
                toYear: 2003,
                engines: [],
            },
            {
                model: 'RX-7',
                fromYear: 1979,
                engines: [],
                toYear: 1992,
            },
            {
                model: 'TRIBUTE',
                fromYear: 2001,
                toYear: 2012,
                engines: [],
            },
        ],
    },
    {
        brand: 'Mercedes Benz', 
        models: [
            {
                model: 'C W204',
                fromYear: 2007,
                toYear: 2014,
                engines: [],
            },
            {
                model: 'C W205',
                fromYear: 2015,
                toYear: 2019,
                engines: [],
            },
            {
                model: 'CLA',
                fromYear: 2013,
                toYear: 2018,
                engines: [],
            },
            {
                model: 'CLASE A',
                fromYear: 2000,
                toYear: 2018,
                engines: [],
            },
            {
                model: 'CLASE B',
                fromYear: 2006,
                toYear: 2017,
                engines: [],
            },
            {
                model: 'CLASE C W202',
                fromYear: 1998,
                toYear: 2001,
                engines: [],
            },
            {
                model: 'CLASE C W203',
                fromYear: 2000,
                toYear: 2006,
                engines: [],
            },
            {
                model: 'CLASE C W204',
                fromYear: 2007,
                toYear: 2014,
                engines: [],
            },
            {
                model: 'CLASE CLA C117',
                fromYear: 2013,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'CLASE CLK W208',
                fromYear: 1997,
                toYear: 2003,
                engines: [],
            },
            {
                model: 'CLASE CLK W209',
                fromYear: 2002,
                toYear: 2010,
                engines: [],
            },
            {
                model: 'CLASE CLS W219',
                fromYear: 2004,
                toYear: 2011,
                engines: [],
            },
            {
                model: 'CLASE E W124',
                fromYear: 1985,
                toYear: 1995,
                engines: [],
            },
            {
                model: 'CLASE E W210',
                fromYear: 1995,
                toYear: 2002,
                engines: [],
            },
            {
                model: 'CLASE E W211',
                fromYear: 2003,
                toYear: 2009,
                engines: [],
            },
            {
                model: 'CLASE G W463',
                fromYear: 1998,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'CLASE GL W164',
                fromYear: 2007,
                toYear: 2012,
                engines: [],
            },
            {
                model: 'CLASE GL W166',
                fromYear: 2013,
                toYear: 2015,
                engines: [],
            },
            {
                model: 'CLASE GLA X156',
                fromYear: 2013,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'CLASE GLE W166',
                fromYear: 2016,
                toYear: 2019,
                engines: [],
            },
            {
                model: 'CLASE M',
                fromYear: 1997,
                toYear: 2011,
                engines: [],
            },
            {
                model: 'CLASE R',
                fromYear: 2006,
                toYear: 2011,
                engines: [],
            },
            {
                model: 'E W212',
                fromYear: 2009,
                toYear: 2016,
                engines: [],
            },
            {
                model: 'E W213',
                fromYear: 2017,
                toYear: 2019,
                engines: [],
            },
            {
                model: 'GLA',
                fromYear: 2014,
                toYear: 2018,
                engines: [],
            },
            {
                model: 'GLC C253',
                fromYear: 2016,
                toYear: 2019,
                engines: [],
            },
            {
                model: 'GLK',
                fromYear: 2009,
                toYear: 2012,
                engines: [],
            },
            {
                model: 'SMART FOR TWO',
                fromYear: 2002,
                toYear: 2007,
                engines: [],
            },
            {
                model: 'SPRINTER',
                fromYear: 2003,
                toYear: 2018,
                engines: [],
            },
        ],
    },
    {
        brand: 'Mitsubishi', 
        models: [
            {
                model: 'ASX',
                fromYear: 2013,
                toYear: 2015,
                engines: [],
            },
            {
                model: 'ECLIPSE',
                fromYear: 1990,
                toYear: 2012,
                engines: [],
            },
            {
                model: 'ECLIPSE CROSS',
                fromYear: 2019,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'ENDEAVOR',
                fromYear: 2003,
                toYear: 2011,
                engines: [],
            },
            {
                model: 'GALANT',
                fromYear: 1985,
                toYear: 2009,
                engines: [],
            },
            {
                model: 'GRANDIS',
                fromYear: 2007,
                toYear: 2009,
                engines: [],
            },
            {
                model: 'L200',
                fromYear: 2008,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'LANCER',
                fromYear: 2003,
                toYear: 2016,
                engines: [],
            },
            {
                model: 'MIRAGE',
                fromYear: 1985,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'MONTERO',
                fromYear: 1983,
                toYear: 2017,
                engines: [],
            },
            {
                model: 'MONTERO SPORT',
                fromYear: 1992,
                toYear: 2007,
                engines: [],
            },
            {
                model: 'OUTLANDER',
                fromYear: 2003,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'PICKUP 4X2',
                fromYear: 1983,
                toYear: 1996,
                engines: [],
            },
            {
                model: 'PICKUP 4X4',
                fromYear: 1983,
                toYear: 1994,
                engines: [],
            },
            {
                model: 'PRECIS',
                fromYear: 1987,
                toYear: 1995,
                engines: [],
            },
            {
                model: 'RAIDER',
                fromYear: 2006,
                toYear: 2009,
                engines: [],
            },
            {
                model: 'SPACE STAR',
                fromYear: 2003,
                toYear: 2004,
                engines: [],
            },
            {
                model: 'STARION',
                fromYear: 1983,
                toYear: 1989,
                engines: [],
            },
            {
                model: 'VAN WAGON',
                fromYear: 1987,
                toYear: 1990,
                engines: [],
            },
        ],
    },
    {
        brand: 'Nissan', 
        models: [
            {
                model: '200 SX',
                fromYear: 1977,
                toYear: 1988,
                engines: [],
            },
            {
                model: '210',
                fromYear: 1979,
                toYear: 1982,
                engines: [],
            },
            {
                model: '240 SX',
                fromYear: 1989,
                toYear: 1998,
                engines: [],
            },
            {
                model: '240Z-260Z-280Z',
                fromYear: 1970,
                toYear: 1979,
                engines: [],
            },
            {
                model: '280 ZX',
                fromYear: 1979,
                toYear: 1983,
                engines: [],
            },
            {
                model: '300 ZX',
                fromYear: 1984,
                toYear: 1989,
                engines: [],
            },
            {
                model: '310',
                fromYear: 1979,
                toYear: 1982,
                engines: [],
            },
            {
                model: '510',
                fromYear: 1968,
                toYear: 1982,
                engines: [],
            },
            {
                model: '610',
                fromYear: 1973,
                toYear: 1976,
                engines: [],
            },
            {
                model: '710',
                fromYear: 1974,
                toYear: 1978,
                engines: [],
            },
            {
                model: '810',
                fromYear: 1977,
                toYear: 1983,
                engines: [],
            },
            {
                model: 'ALMERA',
                fromYear: 2001,
                toYear: 2005,
                engines: [],
            },
            {
                model: 'ALTIMA',
                fromYear: 1993,
                toYear: 2022,
                engines: [],
            },
            {
                model: 'APRIO',
                fromYear: 2007,
                toYear: 2011,
                engines: [],
            },
            {
                model: 'ARMADA',
                fromYear: 2004,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'AXXES',
                fromYear: 1990,
                toYear: 1992,
                engines: [],
            },
            {
                model: 'B100',
                fromYear: 1970,
                toYear: 1973,
                engines: [],
            },
            {
                model: 'B210',
                fromYear: 1973,
                toYear: 1978,
                engines: [],
            },
            {
                model: 'CABSTAR',
                fromYear: 2008,
                toYear: 2015,
                engines: [],
            },
            {
                model: 'CUBE',
                fromYear: 2009,
                toYear: 2014,
                engines: [],
            },
            {
                model: 'D21 4X2',
                fromYear: 1986,
                toYear: 1994,
                engines: [],
            },
            {
                model: 'D21 4X4',
                fromYear: 1987,
                toYear: 1996,
                engines: [],
            },
            {
                model: 'FRONTIER 4X2',
                fromYear: 1998,
                toYear: 2022,
                engines: [],
            },
            {
                model: 'FRONTIER 4X4',
                fromYear: 2005,
                toYear: 2022,
                engines: [],
            },
            {
                model: 'FRONTIER 4X4 Y FRONTIER V6',
                fromYear: 1998,
                toYear: 2004,
                engines: [],
            },
            {
                model: 'ICHIVAN',
                fromYear: 1987,
                toYear: 1993,
                engines: [],
            },
            {
                model: 'JUKE',
                fromYear: 2012,
                toYear: 2019,
                engines: [],
            },
            {
                model: 'KICKS',
                fromYear: 2017,
                toYear: 2021,
                engines: [],
            },
            {
                model: 'LEAF',
                fromYear: 2012,
                toYear: 2017,
                engines: [],
            },
            {
                model: 'LUCINO',
                fromYear: 1996,
                toYear: 2000,
                engines: [],
            },
            {
                model: 'MARCH',
                fromYear: 2011,
                toYear: 2021,
                engines: [],
            },
            {
                model: 'MAXIMA',
                fromYear: 1981,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'MICRA',
                fromYear: 2005,
                toYear: 2008,
                engines: [],
            },
            {
                model: 'MURANO',
                fromYear: 2003,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'NP300 FRONTIER',
                fromYear: 2015,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'NP300 PICKUP 4X2',
                fromYear: 2009,
                toYear: 2022,
                engines: [],
            },
            {
                model: 'NP300 PICKUP 4X4',
                fromYear: 2009,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'NX',
                fromYear: 1987,
                toYear: 1994,
                engines: [],
            },
            {
                model: 'PATHFINDER 4X2',
                fromYear: 1987,
                toYear: 2021,
                engines: [],
            },
            {
                model: 'PATHFINDER 4X4 (3)',
                fromYear: 2005,
                toYear: 2012,
                engines: [],
            },
            {
                model: 'PATHFINDER 4X4 (4)',
                fromYear: 2013,
                toYear: 2021,
                engines: [],
            },
            {
                model: 'PATHFINDER 4X4 (R50)',
                fromYear: 1996,
                toYear: 2004,
                engines: [],
            },
            {
                model: 'PATHFINDER 4X4 (WD21)',
                fromYear: 1987,
                toYear: 1996,
                engines: [],
            },
            {
                model: 'PICKUP 4X2',
                fromYear: 1966,
                toYear: 2008,
                engines: [],
            },
            {
                model: 'PICKUP 4X4',
                fromYear: 1980,
                toYear: 2002,
                engines: [],
            },
            {
                model: 'PLATINA',
                fromYear: 2002,
                toYear: 2010,
                engines: [],
            },
            {
                model: 'PULSAR',
                fromYear: 1983,
                toYear: 1990,
                engines: [],
            },
            {
                model: 'QUEST',
                fromYear: 1993,
                toYear: 2009,
                engines: [],
            },
            {
                model: 'ROGUE',
                fromYear: 2008,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'SAKURA',
                fromYear: 1982,
                toYear: 1987,
                engines: [],
            },
            {
                model: 'SEDAN',
                fromYear: 1965,
                toYear: 1984,
                engines: [],
            },
            {
                model: 'SENTRA',
                fromYear: 1996,
                toYear: 2022,
                engines: [],
            },
            {
                model: 'SENTRA USA',
                fromYear: 1982,
                toYear: 1995,
                engines: [],
            },
            {
                model: 'STANZA',
                fromYear: 1982,
                toYear: 1992,
                engines: [],
            },
            {
                model: 'STANZA WAGON',
                fromYear: 1986,
                toYear: 1988,
                engines: [],
            },
            {
                model: 'TIIDA',
                fromYear: 2006,
                toYear: 2018,
                engines: [],
            },
            {
                model: 'TITAN',
                fromYear: 2004,
                toYear: 2019,
                engines: [],
            },
            {
                model: 'TSUBAME',
                fromYear: 1992,
                toYear: 2004,
                engines: [],
            },
            {
                model: 'TSURU I',
                fromYear: 1984,
                toYear: 1987,
                engines: [],
            },
            {
                model: 'TSURU II',
                fromYear: 1988,
                toYear: 1991,
                engines: [],
            },
            {
                model: 'TSURU III',
                fromYear: 1992,
                toYear: 2017,
                engines: [],
            },
            {
                model: 'URVAN',
                fromYear: 2000,
                toYear: 2022,
                engines: [],
            },
            {
                model: 'V-DRIVE',
                fromYear: 2020,
                toYear: 2022,
                engines: [],
            },
            {
                model: 'VERSA II',
                fromYear: 2020,
                toYear: 2022,
                engines: [],
            },
            {
                model: 'VERSA-NOTE',
                fromYear: 2012,
                toYear: 2021,
                engines: [],
            },
            {
                model: 'X-TRAIL',
                fromYear: 2002,
                toYear: 2022,
                engines: [],
            },
            {
                model: 'XTERRA',
                fromYear: 1998,
                toYear: 2015,
                engines: [],
            },
        ],
    },
    {
        brand: 'Oldsmobile', 
        models: [
            {
                model: '88 Y 98',
                fromYear: 1971,
                toYear: 1992,
                engines: [],
            },
            {
                model: 'ACHIEVA',
                fromYear: 1992,
                toYear: 1998,
                engines: [],
            },
            {
                model: 'ALERO',
                fromYear: 1999,
                toYear: 2004,
                engines: [],
            },
            {
                model: 'AURORA',
                fromYear: 1995,
                toYear: 2003,
                engines: [],
            },
            {
                model: 'BRAVADA',
                fromYear: 2002,
                toYear: 2008,
                engines: [],
            },
            {
                model: 'CALAIS',
                fromYear: 1985,
                toYear: 1991,
                engines: [],
            },
            {
                model: 'CUTLASS',
                fromYear: 1988,
                toYear: 1999,
                engines: [],
            },
            {
                model: 'CUTLASS SUPREME',
                fromYear: 1988,
                toYear: 1997,
                engines: [],
            },
            {
                model: 'DELTA 88 ROYALE',
                fromYear: 1988,
                toYear: 1999,
                engines: [],
            },
            {
                model: 'FIRENZA',
                fromYear: 1985,
                toYear: 1991,
                engines: [],
            },
            {
                model: 'INTRIGUE',
                fromYear: 1998,
                toYear: 2002,
                engines: [],
            },
            {
                model: 'LSS',
                fromYear: 1988,
                toYear: 1999,
                engines: [],
            },
            {
                model: 'OLDSMOBILE',
                fromYear: 1961,
                toYear: 1970,
                engines: [],
            },
            {
                model: 'OMEGA',
                fromYear: 1980,
                toYear: 1984,
                engines: [],
            },
            {
                model: 'SILHOUETTE',
                fromYear: 1990,
                toYear: 2004,
                engines: [],
            },
            {
                model: 'TORONADO',
                fromYear: 1986,
                toYear: 1992,
                engines: [],
            },
        ],
    },
    {
        brand: 'OPEL', 
        models: [
            {
                model: 'OPEL',
                fromYear: 1963,
                toYear: 1972,
                engines: [],
            },
        ],
    },
    {
        brand: 'Peugeot', 
        models: [
            {
                model: '2008',
                fromYear: 2014,
                toYear: 2016,
                engines: [],
            },
            {
                model: '206',
                fromYear: 1999,
                toYear: 2008,
                engines: [],
            },
            {
                model: '207',
                fromYear: 2008,
                toYear: 2012,
                engines: [],
            },
            {
                model: '207 COMPACT',
                fromYear: 2008,
                toYear: 2011,
                engines: [],
            },
            {
                model: '208',
                fromYear: 2014,
                toYear: 2020,
                engines: [],
            },
            {
                model: '3008',
                fromYear: 2011,
                toYear: 2018,
                engines: [],
            },
            {
                model: '301',
                fromYear: 2013,
                toYear: 2020,
                engines: [],
            },
            {
                model: '306',
                fromYear: 1997,
                toYear: 2004,
                engines: [],
            },
            {
                model: '307',
                fromYear: 2004,
                toYear: 2010,
                engines: [],
            },
            {
                model: '308',
                fromYear: 2009,
                toYear: 2014,
                engines: [],
            },
            {
                model: '405',
                fromYear: 1997,
                toYear: 1999,
                engines: [],
            },
            {
                model: '406',
                fromYear: 2000,
                toYear: 2005,
                engines: [],
            },
            {
                model: '407',
                fromYear: 2006,
                toYear: 2009,
                engines: [],
            },
            {
                model: 'MANAGER',
                fromYear: 2009,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'PARTNER-GRAN RAID',
                fromYear: 2004,
                toYear: 2012,
                engines: [],
            },
            {
                model: 'PARTNER-TEPEE-MAXI',
                fromYear: 2012,
                toYear: 2019,
                engines: [],
            },
        ],
    },
    {
        brand: 'Plymouth', 
        models: [
            {
                model: 'ACCLAIM',
                fromYear: 1989,
                toYear: 1995,
                engines: [],
            },
            {
                model: 'ARROW',
                fromYear: 1976,
                toYear: 1980,
                engines: [],
            },
            {
                model: 'BREEZE',
                fromYear: 1995,
                toYear: 2000,
                engines: [],
            },
            {
                model: 'CARAVELLE',
                fromYear: 1984,
                toYear: 1988,
                engines: [],
            },
            {
                model: 'CHAMP',
                fromYear: 1979,
                toYear: 1982,
                engines: [],
            },
            {
                model: 'HORIZON',
                fromYear: 1978,
                toYear: 1980,
                engines: [],
            },
            {
                model: 'LASER',
                fromYear: 1990,
                toYear: 1994,
                engines: [],
            },
            {
                model: 'PLYMOUTH',
                fromYear: 1941,
                toYear: 1964,
                engines: [],
            },
            {
                model: 'RELIANT',
                fromYear: 1984,
                toYear: 1989,
                engines: [],
            },
            {
                model: 'SAPPORO',
                fromYear: 1978,
                toYear: 1983,
                engines: [],
            },
            {
                model: 'SUNDANCE',
                fromYear: 1989,
                toYear: 1995,
                engines: [],
            },
        ],
    },
    {
        brand: 'Pontiac', 
        models: [
            {
                model: '6000',
                fromYear: 1982,
                toYear: 1991,
                engines: [],
            },
            {
                model: 'ASTRE',
                fromYear: 1975,
                toYear: 1980,
                engines: [],
            },
            {
                model: 'AZTEK',
                fromYear: 2001,
                toYear: 2005,
                engines: [],
            },
            {
                model: 'BONEVILLE',
                fromYear: 1987,
                toYear: 2005,
                engines: [],
            },
            {
                model: 'FIERO',
                fromYear: 1984,
                toYear: 1988,
                engines: [],
            },
            {
                model: 'FIREBIRD',
                fromYear: 1982,
                toYear: 2002,
                engines: [],
            },
            {
                model: 'FIREFLY',
                fromYear: 1985,
                toYear: 2001,
                engines: [],
            },
            {
                model: 'G3',
                fromYear: 2006,
                toYear: 2009,
                engines: [],
            },
            {
                model: 'G4',
                fromYear: 2005,
                toYear: 2006,
                engines: [],
            },
            {
                model: 'G5',
                fromYear: 2007,
                toYear: 2009,
                engines: [],
            },
            {
                model: 'G6',
                fromYear: 2004,
                toYear: 2010,
                engines: [],
            },
            {
                model: 'GRAND AM',
                fromYear: 1985,
                toYear: 2005,
                engines: [],
            },
            {
                model: 'GRAND PRIX',
                fromYear: 1988,
                toYear: 2008,
                engines: [],
            },
            {
                model: 'GTO',
                fromYear: 1964,
                toYear: 2006,
                engines: [],
            },
            {
                model: 'LEMANS',
                fromYear: 1988,
                toYear: 1993,
                engines: [],
            },
            {
                model: 'MATIZ',
                fromYear: 2004,
                toYear: 2009,
                engines: [],
            },
            {
                model: 'MONTANA',
                fromYear: 1999,
                toYear: 2007,
                engines: [],
            },
            {
                model: 'MONTANA SV6',
                fromYear: 2005,
                toYear: 2009,
                engines: [],
            },
            {
                model: 'SOLSTICE',
                fromYear: 2005,
                toYear: 2009,
                engines: [],
            },
            {
                model: 'SUNBIRD',
                fromYear: 1975,
                toYear: 1994,
                engines: [],
            },
            {
                model: 'SUNFIRE',
                fromYear: 1995,
                toYear: 2005,
                engines: [],
            },
            {
                model: 'T1000',
                fromYear: 1981,
                toYear: 1987,
                engines: [],
            },
            {
                model: 'TEMPEST',
                fromYear: 1987,
                toYear: 1991,
                engines: [],
            },
            {
                model: 'TORRENT',
                fromYear: 2004,
                toYear: 2009,
                engines: [],
            },
            {
                model: 'TRANS AM',
                fromYear: 1967,
                toYear: 2002,
                engines: [],
            },
            {
                model: 'TRANS SPORT',
                fromYear: 1990,
                toYear: 1998,
                engines: [],
            },
            {
                model: 'TRANSPORT',
                fromYear: 1990,
                toYear: 1998,
                engines: [],
            },
            {
                model: 'VIBE',
                fromYear: 2003,
                toYear: 2010,
                engines: [],
            },
        ],
    },
    {
        brand: 'Porsche', 
        models: [
            {
                model: '911',
                fromYear: 1997,
                toYear: 2005,
                engines: [],
            },
            {
                model: '911 TURBO',
                fromYear: 2000,
                toYear: 2005,
                engines: [],
            },
            {
                model: 'BOXSTER',
                fromYear: 1996,
                toYear: 2004,
                engines: [],
            },
            {
                model: 'CAYENNE',
                fromYear: 2003,
                toYear: 2018,
                engines: [],
            },
            {
                model: 'MACAN',
                fromYear: 2014,
                toYear: 2020,
                engines: [],
            },
        ],
    },
    {
        brand: 'Renault', 
        models: [
            {
                model: 'CAPTUR',
                fromYear: 2017,
                toYear: 2021,
                engines: [],
            },
            {
                model: 'CLIO',
                fromYear: 2002,
                toYear: 2010,
                engines: [],
            },
            {
                model: 'CLIO EUROPA',
                fromYear: 2007,
                toYear: 2009,
                engines: [],
            },
            {
                model: 'CLIO SPORT',
                fromYear: 2002,
                toYear: 2006,
                engines: [],
            },
            {
                model: 'DUSTER',
                fromYear: 2011,
                toYear: 2022,
                engines: [],
            },
            {
                model: 'FLUENCE',
                fromYear: 2011,
                toYear: 2014,
                engines: [],
            },
            {
                model: 'KANGOO',
                fromYear: 2004,
                toYear: 2019,
                engines: [],
            },
            {
                model: 'KOLEOS',
                fromYear: 2009,
                toYear: 2021,
                engines: [],
            },
            {
                model: 'KWID',
                fromYear: 2019,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'LAGUNA',
                fromYear: 2004,
                toYear: 2006,
                engines: [],
            },
            {
                model: 'LOGAN',
                fromYear: 2015,
                toYear: 2019,
                engines: [],
            },
            {
                model: 'MEGANE',
                fromYear: 2001,
                toYear: 2010,
                engines: [],
            },
            {
                model: 'OROCH',
                fromYear: 2018,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'R11 ENCORE-ALLIANCE',
                fromYear: 1984,
                toYear: 1986,
                engines: [],
            },
            {
                model: 'R12',
                fromYear: 1972,
                toYear: 1983,
                engines: [],
            },
            {
                model: 'R18',
                fromYear: 1980,
                toYear: 1986,
                engines: [],
            },
            {
                model: 'R18 2LTS',
                fromYear: 1984,
                toYear: 1986,
                engines: [],
            },
            {
                model: 'R5',
                fromYear: 1976,
                toYear: 1984,
                engines: [],
            },
            {
                model: 'R9 ALLIANCE',
                fromYear: 1984,
                toYear: 1986,
                engines: [],
            },
            {
                model: 'SAFRANE',
                fromYear: 2009,
                toYear: 2010,
                engines: [],
            },
            {
                model: 'SANDERO-STEPWAY',
                fromYear: 2010,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'SCALA',
                fromYear: 2010,
                toYear: 2013,
                engines: [],
            },
            {
                model: 'SCENIC',
                fromYear: 2001,
                toYear: 2009,
                engines: [],
            },
            {
                model: 'TRAFIC',
                fromYear: 2007,
                toYear: 2014,
                engines: [],
            },
        ],
    },
    {
        brand: 'Rover', 
        models: [
            {
                model: '75',
                fromYear: 2000,
                toYear: 2005,
                engines: [],
            },
        ],
    },
    {
        brand: 'SAAB', 
        models: [
            {
                model: 'SAAB 9-3',
                fromYear: 1999,
                toYear: 2007,
                engines: [],
            },
            {
                model: 'SAAB 9-5',
                fromYear: 1999,
                toYear: 2001,
                engines: [],
            },
        ],
    },
    {
        brand: 'Saturn', 
        models: [
            {
                model: 'AURA',
                fromYear: 2007,
                toYear: 2009,
                engines: [],
            },
            {
                model: 'ION',
                fromYear: 2003,
                toYear: 2007,
                engines: [],
            },
            {
                model: 'OUTLOOK',
                fromYear: 2007,
                toYear: 2010,
                engines: [],
            },
            {
                model: 'RELAY',
                fromYear: 2007,
                toYear: 2007,
                engines: [],
            },
            {
                model: 'SATURN ASTRA',
                fromYear: 2008,
                toYear: 2009,
                engines: [],
            },
            {
                model: 'SATURN L-SERIES',
                fromYear: 2000,
                toYear: 2005,
                engines: [],
            },
            {
                model: 'SATURN S-SERIES',
                fromYear: 1991,
                toYear: 2002,
                engines: [],
            },
            {
                model: 'VUE',
                fromYear: 2002,
                toYear: 2009,
                engines: [],
            },
        ],
    },
    {
        brand: 'Seat', 
        models: [
            {
                model: 'ALHAMBRA',
                fromYear: 2002,
                toYear: 2008,
                engines: [],
            },
            {
                model: 'ALTEA',
                fromYear: 2004,
                toYear: 2015,
                engines: [],
            },
            {
                model: 'ALTEA XL',
                fromYear: 2006,
                toYear: 2015,
                engines: [],
            },
            {
                model: 'ARONA',
                fromYear: 2017,
                toYear: 2021,
                engines: [],
            },
            {
                model: 'ATECA',
                fromYear: 2016,
                toYear: 2022,
                engines: [],
            },
            {
                model: 'CORDOBA',
                fromYear: 2000,
                toYear: 2009,
                engines: [],
            },
            {
                model: 'IBIZA',
                fromYear: 2001,
                toYear: 2022,
                engines: [],
            },
            {
                model: 'LEON',
                fromYear: 2001,
                toYear: 2022,
                engines: [],
            },
            {
                model: 'TARRACO',
                fromYear: 2018,
                toYear: 2021,
                engines: [],
            },
            {
                model: 'TOLEDO',
                fromYear: 2001,
                toYear: 2019,
                engines: [],
            },
        ],
    },
    {
        brand: 'Subaru', 
        models: [
            {
                model: '1800',
                fromYear: 1985,
                toYear: 1989,
                engines: [],
            },
            {
                model: 'FORESTER',
                fromYear: 2000,
                toYear: 2009,
                engines: [],
            },
            {
                model: 'IMPREZA',
                fromYear: 1993,
                toYear: 2014,
                engines: [],
            },
            {
                model: 'LEGACY',
                fromYear: 1990,
                toYear: 2009,
                engines: [],
            },
            {
                model: 'OUTBACK',
                fromYear: 2000,
                toYear: 2009,
                engines: [],
            },
            {
                model: 'SUBARU',
                fromYear: 1984,
                toYear: 1989,
                engines: [],
            },
        ],
    },
    {
        brand: 'Suzuki', 
        models: [
            {
                model: 'AERIO',
                fromYear: 2002,
                toYear: 2006,
                engines: [],
            },
            {
                model: 'GRAND VITARA',
                fromYear: 1999,
                toYear: 2013,
                engines: [],
            },
            {
                model: 'JIMNY',
                fromYear: 1983,
                toYear: 1988,
                engines: [],
            },
            {
                model: 'KIZASHI',
                fromYear: 2010,
                toYear: 2016,
                engines: [],
            },
            {
                model: 'SIDEKICK',
                fromYear: 1989,
                toYear: 1997,
                engines: [],
            },
            {
                model: 'SWIFT',
                fromYear: 1990,
                toYear: 2017,
                engines: [],
            },
            {
                model: 'SX4',
                fromYear: 2006,
                toYear: 2014,
                engines: [],
            },
            {
                model: 'XL7',
                fromYear: 2007,
                toYear: 2009,
                engines: [],
            },
        ],
    },
    {
        brand: 'Toyota', 
        models: [
            {
                model: '4-RUNNER',
                fromYear: 1990,
                toYear: 2019,
                engines: [],
            },
            {
                model: '4-RUNNER 4X4',
                fromYear: 1986,
                toYear: 2002,
                engines: [],
            },
            {
                model: 'AVALON',
                fromYear: 1995,
                toYear: 2016,
                engines: [],
            },
            {
                model: 'AVANZA',
                fromYear: 2007,
                toYear: 2021,
                engines: [],
            },
            {
                model: 'C-HR',
                fromYear: 2018,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'CAMRY',
                fromYear: 1983,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'CELICA',
                fromYear: 1971,
                toYear: 2003,
                engines: [],
            },
            {
                model: 'COROLLA',
                fromYear: 1971,
                toYear: 2022,
                engines: [],
            },
            {
                model: 'COROLLA COUPE',
                fromYear: 1984,
                toYear: 1987,
                engines: [],
            },
            {
                model: 'CORONA',
                fromYear: 1979,
                toYear: 1982,
                engines: [],
            },
            {
                model: 'CRESSIDA',
                fromYear: 1978,
                toYear: 1992,
                engines: [],
            },
            {
                model: 'CROWN',
                fromYear: 1968,
                toYear: 1972,
                engines: [],
            },
            {
                model: 'FJ CRUISER',
                fromYear: 2007,
                toYear: 2014,
                engines: [],
            },
            {
                model: 'HIACE',
                fromYear: 2004,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'HIGHLANDER',
                fromYear: 2001,
                toYear: 2021,
                engines: [],
            },
            {
                model: 'HILUX 4X2',
                fromYear: 2004,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'HILUX 4X4',
                fromYear: 2004,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'LAND CRUISER',
                fromYear: 1998,
                toYear: 2013,
                engines: [],
            },
            {
                model: 'MATRIX',
                fromYear: 2003,
                toYear: 2013,
                engines: [],
            },
            {
                model: 'MR2',
                fromYear: 1985,
                toYear: 1995,
                engines: [],
            },
            {
                model: 'PASEO',
                fromYear: 1992,
                toYear: 1996,
                engines: [],
            },
            {
                model: 'PICKUP',
                fromYear: 1972,
                toYear: 1994,
                engines: [],
            },
            {
                model: 'PICKUP 4X4',
                fromYear: 1986,
                toYear: 2002,
                engines: [],
            },
            {
                model: 'PREVIA VAN',
                fromYear: 1990,
                toYear: 1997,
                engines: [],
            },
            {
                model: 'PRIUS',
                fromYear: 2004,
                toYear: 2022,
                engines: [],
            },
            {
                model: 'PRIUS C',
                fromYear: 2004,
                toYear: 2022,
                engines: [],
            },
            {
                model: 'RAV4',
                fromYear: 1996,
                toYear: 2018,
                engines: [],
            },
            {
                model: 'SEQUOIA',
                fromYear: 2001,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'SIENNA',
                fromYear: 1998,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'SOLARA',
                fromYear: 1999,
                toYear: 2008,
                engines: [],
            },
            {
                model: 'SUPRA',
                fromYear: 1982,
                toYear: 1992,
                engines: [],
            },
            {
                model: 'T100',
                fromYear: 1993,
                toYear: 1998,
                engines: [],
            },
            {
                model: 'T100 4X4',
                fromYear: 1993,
                toYear: 1998,
                engines: [],
            },
            {
                model: 'TACOMA',
                fromYear: 2016,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'TACOMA 4X2',
                fromYear: 1995,
                toYear: 2015,
                engines: [],
            },
            {
                model: 'TACOMA 4X4',
                fromYear: 1995,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'TACOMA PRERUNNER',
                fromYear: 1995,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'TERCEL',
                fromYear: 1980,
                toYear: 1998,
                engines: [],
            },
            {
                model: 'TERCEL STATION WAGON',
                fromYear: 1987,
                toYear: 1988,
                engines: [],
            },
            {
                model: 'TUNDRA',
                fromYear: 2000,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'VAN',
                fromYear: 1984,
                toYear: 1989,
                engines: [],
            },
            {
                model: 'VAN 4X4',
                fromYear: 1987,
                toYear: 1989,
                engines: [],
            },
            {
                model: 'YARIS (JAPON / FRANCIA)',
                fromYear: 2014,
                toYear: 2016,
                engines: [],
            },
            {
                model: 'YARIS (JAPON)',
                fromYear: 1999,
                toYear: 2013,
                engines: [],
            },
            {
                model: 'YARIS (TAILANDIA)',
                fromYear: 2017,
                toYear: 2018,
                engines: [],
            },
            {
                model: 'YARIS R',
                fromYear: 2016,
                toYear: 2019,
                engines: [],
            },
        ],
    },
    {
        brand: 'Volkswagen', 
        models: [
            {
                model: 'AMAROK',
                fromYear: 2011,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'ATLANTIC',
                fromYear: 1977,
                toYear: 1987,
                engines: [],
            },
            {
                model: 'BEETLE',
                fromYear: 1998,
                toYear: 2019,
                engines: [],
            },
            {
                model: 'BEETLE RSI',
                fromYear: 1999,
                toYear: 2004,
                engines: [],
            },
            {
                model: 'BORA',
                fromYear: 2006,
                toYear: 2010,
                engines: [],
            },
            {
                model: 'BRASILIA',
                fromYear: 1973,
                toYear: 1983,
                engines: [],
            },
            {
                model: 'CABRIO',
                fromYear: 1995,
                toYear: 2002,
                engines: [],
            },
            {
                model: 'CADDY',
                fromYear: 2010,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'CARIBE',
                fromYear: 1977,
                toYear: 1993,
                engines: [],
            },
            {
                model: 'CC',
                fromYear: 2011,
                toYear: 2016,
                engines: [],
            },
            {
                model: 'COMBI - BUS',
                fromYear: 1973,
                toYear: 2001,
                engines: [],
            },
            {
                model: 'CORSAR',
                fromYear: 1984,
                toYear: 1989,
                engines: [],
            },
            {
                model: 'CRAFTER',
                fromYear: 2007,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'CROSSFOX',
                fromYear: 2007,
                toYear: 2018,
                engines: [],
            },
            {
                model: 'DERBY',
                fromYear: 1995,
                toYear: 2009,
                engines: [],
            },
            {
                model: 'EOS',
                fromYear: 2009,
                toYear: 2010,
                engines: [],
            },
            {
                model: 'EUROVAN - TRANSPORTER',
                fromYear: 2001,
                toYear: 2020,
                engines: [],
            },
            {
                model: 'GOL',
                fromYear: 2008,
                toYear: 2021,
                engines: [],
            },
            {
                model: 'GOLF',
                fromYear: 1983,
                toYear: 2017,
                engines: [],
            },
            {
                model: 'GOLF ESTATE/VARIANT',
                fromYear: 2016,
                toYear: 2017,
                engines: [],
            },
            {
                model: 'GOLF II',
                fromYear: 1983,
                toYear: 1992,
                engines: [],
            },
            {
                model: 'GOLF III',
                fromYear: 1993,
                toYear: 1999,
                engines: [],
            },
            {
                model: 'GOLF IV',
                fromYear: 1998,
                toYear: 2007,
                engines: [],
            },
            {
                model: 'GOLF RS32',
                fromYear: 1999,
                toYear: 2004,
                engines: [],
            },
            {
                model: 'GOLF V',
                fromYear: 2006,
                toYear: 2009,
                engines: [],
            },
            {
                model: 'GOLF VI',
                fromYear: 2010,
                toYear: 2012,
                engines: [],
            },
            {
                model: 'GOLF VII',
                fromYear: 2013,
                toYear: 2021,
                engines: [],
            },
            {
                model: 'GOLF VII',
                fromYear: 2013,
                toYear: 2021,
                engines: [],
            },
            {
                model: 'GOLF VR6',
                fromYear: 1999,
                toYear: 2004,
                engines: [],
            },
            {
                model: 'JETTA',
                fromYear: 1980,
                toYear: 1984,
                engines: [],
            },
            {
                model: 'JETTA II',
                fromYear: 1985,
                toYear: 1992,
                engines: [],
            },
            {
                model: 'JETTA III',
                fromYear: 1993,
                toYear: 1999,
                engines: [],
            },
            {
                model: 'JETTA IV',
                fromYear: 1998,
                toYear: 2015,
                engines: [],
            },
            {
                model: 'JETTA V',
                fromYear: 2006,
                toYear: 2010,
                engines: [],
            },
            {
                model: 'JETTA VI',
                fromYear: 2010,
                toYear: 2018,
                engines: [],
            },
            {
                model: 'JETTA VII',
                fromYear: 2015,
                toYear: 2018,
                engines: [],
            },
            {
                model: 'JETTA VIII',
                fromYear: 2018,
                toYear: 2022,
                engines: [],
            },
            {
                model: 'JETTA VR6',
                fromYear: 1999,
                toYear: 2004,
                engines: [],
            },
            {
                model: 'LUPO',
                fromYear: 2005,
                toYear: 2009,
                engines: [],
            },
            {
                model: 'PASSAT',
                fromYear: 1989,
                toYear: 2019,
                engines: [],
            },
            {
                model: 'PASSAT CC',
                fromYear: 2008,
                toYear: 2016,
                engines: [],
            },
            {
                model: 'POINTER',
                fromYear: 1997,
                toYear: 2009,
                engines: [],
            },
            {
                model: 'POINTER PICKUP',
                fromYear: 1997,
                toYear: 2010,
                engines: [],
            },
            {
                model: 'POLO',
                fromYear: 2003,
                toYear: 2022,
                engines: [],
            },
            {
                model: 'RABBIT',
                fromYear: 1975,
                toYear: 1984,
                engines: [],
            },
            {
                model: 'RABBIT PICKUP',
                fromYear: 1980,
                toYear: 1983,
                engines: [],
            },
            {
                model: 'ROUTAN',
                fromYear: 2009,
                toYear: 2014,
                engines: [],
            },
            {
                model: 'SAFARI - THE THING',
                fromYear: 1970,
                toYear: 1981,
                engines: [],
            },
            {
                model: 'SAVEIRO',
                fromYear: 2010,
                toYear: 2022,
                engines: [],
            },
            {
                model: 'SEDAN - OLD BEETLE',
                fromYear: 1956,
                toYear: 2004,
                engines: [],
            },
            {
                model: 'SHARAN',
                fromYear: 2002,
                toYear: 2008,
                engines: [],
            },
            {
                model: 'SPORT VAN',
                fromYear: 2003,
                toYear: 2010,
                engines: [],
            },
            {
                model: 'T-CROSS',
                fromYear: 2020,
                toYear: 2022,
                engines: [],
            },
            {
                model: 'TIGUAN',
                fromYear: 2008,
                toYear: 2021,
                engines: [],
            },
            {
                model: 'TIGUAN II',
                fromYear: 2018,
                toYear: 2022,
                engines: [],
            },
            {
                model: 'TOUAREG',
                fromYear: 2004,
                toYear: 2018,
                engines: [],
            },
            {
                model: 'UP',
                fromYear: 2016,
                toYear: 2018,
                engines: [],
            },
            {
                model: 'VANAGON',
                fromYear: 1980,
                toYear: 1991,
                engines: [],
            },
            {
                model: 'VENTO',
                fromYear: 2013,
                toYear: 2022,
                engines: [],
            },
            {
                model: 'VIRTUS',
                fromYear: 2020,
                toYear: 2022,
                engines: [],
            },
        ],
    },
    {
        brand: 'Volvo', 
        models: [
            {
                model: '240-260',
                fromYear: 1975,
                toYear: 1987,
                engines: [],
            },
            {
                model: 'C30',
                fromYear: 2007,
                toYear: 2013,
                engines: [],
            },
            {
                model: 'S40',
                fromYear: 1996,
                toYear: 2011,
                engines: [],
            },
            {
                model: 'S60',
                fromYear: 2001,
                toYear: 2014,
                engines: [],
            },
            {
                model: 'S80',
                fromYear: 2000,
                toYear: 2006,
                engines: [],
            },
            {
                model: 'XC60',
                fromYear: 2010,
                toYear: 2017,
                engines: [],
            },
            {
                model: 'XC70',
                fromYear: 2001,
                toYear: 2005,
                engines: [],
            },
            {
                model: 'XC90',
                fromYear: 2003,
                toYear: 2014,
                engines: [],
            },
        ],
    },
];