import React from 'react'

export const InventoryReportSelection = ({ closeModal, openInventoryReport, openSaledProductsReport, openSpecialProductReport }) => {

    const inventoryReport = () => {
        closeModal();
        openInventoryReport();
    }

    const saledProductsReport = () => {
        closeModal();
        openSaledProductsReport();
    }

    return (
        <div className={` modal `}>
            <div className={` modal-content `}>

                <div className={` modal-header `}>
                    <p className='section-title'>Reportes</p>
                </div>
                <div className = ' modal-body '>
                    <p className = ' small mb-3 '>Selecciona el tipo de reporte que quieres consultar.</p>
                    <div className = ' d-flex '>
                        <div className = ' button purple-button '
                            onClick = { inventoryReport }
                        >
                            Reporte de Inventario
                        </div>
                        <div className = ' button purple-button ms-2 '
                            onClick = { saledProductsReport }
                        >
                            Reporte de Productos Vendidos
                        </div>
                        <div className = ' button purple-button ms-2 '
                            onClick = { openSpecialProductReport }
                        >
                            Reporte de Productos Especiales
                        </div>

                    </div>
                </div>
                <div className={` modal-footer `}>
                    <button
                        type='reset'

                        className={` button cancel-button shadow px-5 `}
                        onClick={closeModal}
                    >
                        Cerrar Ventana
                    </button>
                    {/* <button
                        className={` button purple-button shadow px-5 ms-3 `}
                    >
                        Guardar
                    </button> */}
                </div>
            </div>
        </div>
    );

}
