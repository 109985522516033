const Units = ( num ) => {
    switch( num ) {
        case 1: return 'UN';
        case 2: return 'DOS';
        case 3: return 'TRES';
        case 4: return 'CUATRO';
        case 5: return 'CINCO';
        case 6: return 'SEIS';
        case 7: return 'SIETE';
        case 8: return 'OCHO';
        case 9: return 'NUEVE';
    }

    return '';
}

const Tens = ( num ) => {
    let tens = Math.floor( num / 10 );
    let unit = num - ( tens * 10 );

    switch( tens ) {
        case 1:
            switch( unit ) {
                case 0: return 'DIEZ';
                case 1: return 'ONCE';
                case 2: return 'DOCE';
                case 3: return 'TRECE';
                case 4: return 'CATORCE';
                case 5: return 'QUINCE';
                default: return 'DIECI' + Units(unit);
            }
        case 2:
            switch( unit ) {
                case 0: return 'VEINTE';
                default: return 'VEINTI' + Units(unit);
            }
        case 3: return TensY('TREINTA', unit);
        case 4: return TensY('CUARENTA', unit);
        case 5: return TensY('CINCUENTA', unit);
        case 6: return TensY('SESENTA', unit);
        case 7: return TensY('SETENTA', unit);
        case 8: return TensY('OCHENTA', unit);
        case 9: return TensY('NOVENTA', unit);
        case 0: return Units(unit);
    }
}

const TensY = ( strSin, numberUnits ) => {
    if (numberUnits > 0) return strSin + ' Y ' + Units(numberUnits)

    return strSin;
}

const Hundreds = ( num ) => {
    let hundres = Math.floor(num / 100);
    let tens = num - (hundres * 100);

    switch(hundres)
    {
        case 1:
            if ( tens > 0 ) return 'CIENTO ' + Tens(tens);
            return 'CIEN';
        case 2: return 'DOSCIENTOS ' + Tens(tens);
        case 3: return 'TRESCIENTOS ' + Tens(tens);
        case 4: return 'CUATROCIENTOS ' + Tens(tens);
        case 5: return 'QUINIENTOS ' + Tens(tens);
        case 6: return 'SEISCIENTOS ' + Tens(tens);
        case 7: return 'SETECIENTOS ' + Tens(tens);
        case 8: return 'OCHOCIENTOS ' + Tens(tens);
        case 9: return 'NOVECIENTOS ' + Tens(tens);
    }

    return Tens( tens );
}

const Section = ( num, divisor, strSingular, strPlural ) => {
    let hundreds = Math.floor( num / divisor )
    let rest = num - ( hundreds * divisor )

    let letters = '';

    if ( hundreds > 0 )
        if ( hundreds > 1 )
            letters = Hundreds( hundreds ) + ' ' + strPlural;
        else
            letters = strSingular;

    if ( rest > 0 )
        letters += '';

    return letters;
}

const Thousands = ( num ) => {
    let divisor = 1000;
    let hundreds = Math.floor(num / divisor)
    let rest = num - (hundreds * divisor)

    let strMiles = Section( num, divisor, 'MIL', 'MIL' );
    let strCentenas = Hundreds( rest );

    if( strMiles == '' )
        return strCentenas;

    return strMiles + ' ' + strCentenas;
}

const Millons = ( num ) => {
    let divisor = 1000000;
    let hundreds = Math.floor( num / divisor )
    let rest = num - ( hundreds * divisor )

    let strMillones = Section( num, divisor, 'UN MILLON DE', 'MILLONES DE' );
    let strMiles = Thousands( rest ) || 0;

    if( strMillones === '' )
        return strMiles;

    return strMillones + ' ' + strMiles;
}

export const NumberToText = ( num ) => {
    var data = {
        number: num,
        ints: Math.floor(num),
        pennies: (((Math.round(num * 100)) - (Math.floor(num) * 100))),
        letterPennies: '',
        letterCoinPlural: 'PESOS',
        letterCoinSingular: 'PESOS', 

        letterCoinPenniePlural: 'CENTAVOS',
        letterCoinPennieSingular: 'CENTAVO'
    };

    if ( data.pennies > 0 ) {
        data.letterPennies = 'CON ' + (function (){
            if (data.pennies == 1)
                return Millons(data.pennies) + ' ' + data.letterCoinPennieSingular;
            else
                return Millons(data.pennies) + ' ' + data.letterCoinPenniePlural;
            })();
    };

    if ( data.ints == 0 ) return 'CERO ' + data.letterCoinPlural + ' ' + data.letterPennies;
    if ( data.ints == 1 ) return Millons(data.ints) + ' ' + data.letterCoinSingular + ' ' + data.letterPennies;
    else return Millons(data.ints) + ' ' + data.letterCoinPlural + ' ' + data.letterPennies;
}