import React, { useContext, useEffect, useState } from 'react';
import { formatMoney } from './../../helpers/formatMoney';
import { ProductContext } from '../../Context/ProductContext';
import { productFamily } from '../../helpers/ProductFamilies';

export const ProductsListContainer = ({ setSelectedProduct, active, setActive }) => {

    const { showProducts, searchTerm } = useContext(ProductContext);
    const [ searchTermProducts, setSearchTermProducts ] = useState([]);

    useEffect(() => {
        handleSearchTermResults();
    }, [ searchTerm ]);

    const getFamilyName = ( family ) => {
        if(family !== undefined && family !== '') {
            const foundFamily = productFamily.filter(fam => fam.value === family);
            if ( foundFamily.length > 0 && foundFamily[0].label !== undefined ) { 
                return foundFamily[0].label 
            } else {
                return 'Familia no encontrada';
            }
        } else {
            return ''
        }
    }

    const handleSearchTermResults = () => {
        // if( searchTerm !== '' ) {
        //     let productsTemp = [];
        //     showProducts.forEach( product => {
        //         if( (product.nombre.toLowerCase()).includes(searchTerm.toLowerCase()) ||
        //             (product.pieza.toLowerCase()).includes(searchTerm.toLowerCase()) ) {
        //                 productsTemp.push( product );
        //         }
        //     });
        //     setSearchTermProducts( productsTemp );
        // }
    }

    const NoProductsAvailable = () => {
        return (
            <div className = ' inventario_product-not-found text-center px-5 '>
                No se encontró ningún producto, añade uno para comenzar o intenta con 
                otro término de búsqueda.
            </div>
        )
    }

    return (
        <div className = ' mb-4 pb-4 '>
            {
                showProducts.length > 0 && (
                    showProducts.map((product, index) => (
                        <div key = { index }>
                            {
                                searchTerm === '' && (
                                    <div 
                                        className = {` inventario_list-item ${ active === product.id && ' active' } `}
                                        key = { product.id }
                                        onClick = { () => {
                                            setSelectedProduct(product) 
                                            setActive( product.id )
                                        }}
                                    >
                                        <div className = ' inventario_item-image-container '>
                                            <img src = { product.image } />
                                        </div>
                                        <div className = ' inventario_item-info ps-2 '>
                                            <p className = ' text_data '>{ product.nombre }</p>
                                            <p className = ' text_instructions '>{ getFamilyName( product.family ) }</p>
                                        </div>
                                        <div className = ' ProductList-item-data-container '>
                                            <p className = ' Ptext_data '>{ formatMoney( product.precio_contado ) }</p>
                                            <p className = ' text_instructions '>{ product.existencia } disponibles</p>
                                        </div>
                                    </div>
                                )

                            }
                        </div>
                    ))
                )
            }
            {/* { searchTerm !== '' && searchTermProducts.length > 0 && (
                searchTermProducts.map((product, index) => (
                    <div key = { index }>
                        <div 
                            className = {` inventario_list-item ${ active === product.id && ' active' } `}
                            key = { product.id }
                            onClick = { () => {
                                setSelectedProduct(product) 
                                setActive( product.id )
                            }}
                        >
                            <div className = ' inventario_item-image-container '>
                                <img src = { product.image } />
                            </div>
                            <div className = ' inventario_item-info ps-2 '>
                                <p className = ' text_data '>{ product.nombre }</p>
                                <p className = ' text_instructions '>{ getFamilyName( product.family ) }</p>
                            </div>
                            <div className = ' ProductList-item-data-container '>
                                <p className = ' Ptext_data '>{ formatMoney( product.precio_contado ) }</p>
                                <p className = ' text_instructions '>{ product.existencia } disponibles</p>
                            </div>
                        </div>
                    </div>
                ))
            )} */}
            {/* {(searchTerm !== '' && searchTermProducts.length == 0) && <NoProductsAvailable />} */}
            {(showProducts.length == 0) && <NoProductsAvailable />}
            
        </div>
    ); 

}
