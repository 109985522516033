import React, { useState } from 'react';

export const SupplierDirectoryList = ({ setProveedorSelected, suppliers }) => {

    const [active, setActive] = useState(0);
    
    const elementSelected = (user, index) => {
        setProveedorSelected(user);
        setActive(index);
    }

    return (
        <div>
            { suppliers.length > 0 && (
                suppliers.map((supplier, index) => (
                    <div 
                        className = {` directory_list-container ${ active === index && 'active' } px-4 `}
                        key = { index }
                        onClick = { () => elementSelected(supplier, index) }
                    >
                        <img
                            className = ' directory_list-image '
                            src = { supplier.image }
                            alt = 'Fotografia de perfil'
                        />
                        <div>
                            <p>{ supplier.name }</p>
                            <p className = ' text_instructions '>
                                { supplier.email }
                            </p>
                        </div>
                    </div>
                ))
            )}
            { suppliers.length <= 0 && (
                <div className = ' mx-0 px-3 '>
                    <p>No hay proveedores que mostrar.</p>
                </div>
            )}
        </div>
    )

}
