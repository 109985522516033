import React, { useEffect } from 'react';
import './../../../Theme/globalStyles.css'
import { InputComponent } from '../../InputComponent/InputComponent';
import { UploadImageComponent } from '../../UploadImageComponent/UploadImageComponent';
import camera from '../../../cameraPP.png';

export const NewSupplierModal = ({ closeModal, addSupplier, savedData, uploadImage, setUploadImage }) => {

    useEffect(() => {
        (!!savedData) && closeModal();
    }, [savedData]);
    
    return (
        <div className = {` modal `}>
            <div className = {` modal-content `}>

                <div className = {` modal-header `}>
                    <p className='section-title'>Registrar un nuevo proveedor.</p>
                </div>
                <div className = {` modal-body `}>

                    <form
                        onSubmit = { addSupplier.handleSubmit }
                    >   
                        <div className = {` row `}>

                            <div className = {` col-lg-6 `}>

                                <InputComponent 
                                    id = "supplierName"
                                    onChange = { addSupplier.handleChange }
                                    value = { addSupplier.values.supplierName }
                                    label = 'Proveedor'
                                    placeholder = 'Ingresa el nombre del proveedor'
                                />
                                { addSupplier.touched.supplierName && addSupplier.errors.supplierName ? (
                                    <div className = 'error-message'>
                                        <p>{ addSupplier.errors.supplierName }</p>
                                    </div>
                                ) : null}

                                <InputComponent 
                                    id = "supplierContactName"
                                    onChange = { addSupplier.handleChange }
                                    value = { addSupplier.values.supplierContactName }
                                    label = 'Nombre del Encargado'
                                    placeholder = 'Ingresa el nombre del encargado'
                                />
                                { addSupplier.touched.supplierContactName && addSupplier.errors.supplierContactName ? (
                                    <div className = 'error-message'>
                                        <p>{ addSupplier.errors.supplierContactName }</p>
                                    </div>
                                ) : null}

                                <InputComponent 
                                    id = "supplierAddress"
                                    onChange = { addSupplier.handleChange }
                                    value = { addSupplier.values.supplierAddress }
                                    label = 'Dirección del negocio'
                                    placeholder = 'Ingresa la direccion del negocio'
                                />
                                { addSupplier.touched.supplierAddress && addSupplier.errors.supplierAddress ? (
                                    <div className = 'error-message'>
                                        <p>{ addSupplier.errors.supplierAddress }</p>
                                    </div>
                                ) : null}

                                <UploadImageComponent defaultImg = { camera } userType = 'newUser' uploadImage = { uploadImage } setUploadImage = { setUploadImage }  />

                            </div>
                            <div className = {` offset-lg-1 col-lg-5 `}>
                                <InputComponent 
                                    id = "supplierPhoneNumber"
                                    onChange = { addSupplier.handleChange }
                                    value = { addSupplier.values.supplierPhoneNumber }
                                    label = 'Teléfono'
                                    placeholder = 'Ingresa el telefono del negocio'
                                />
                                { addSupplier.touched.supplierPhoneNumber && addSupplier.errors.supplierPhoneNumber ? (
                                    <div className = 'error-message'>
                                        <p>{ addSupplier.errors.supplierPhoneNumber }</p>
                                    </div>
                                ) : null}

                                <InputComponent 
                                    id = "supplierEmail"
                                    onChange = { addSupplier.handleChange }
                                    value = { addSupplier.values.supplierEmail }
                                    label = 'Correo'
                                    placeholder = 'Ingresa el correo del proveedor'
                                />
                                { addSupplier.touched.supplierEmail && addSupplier.errors.supplierEmail ? (
                                    <div className = 'error-message'>
                                        <p>{ addSupplier.errors.supplierEmail }</p>
                                    </div>
                                ) : null}

                            </div>
                        </div>
                        <div className = {` modal-footer `}>
                            <button 
                                className = {` button cancel-button shadow px-5 `}
                                onClick = { closeModal }
                                type = 'reset'
                            >
                                Cancelar
                            </button>
                            <button 
                                className = {` button purple-button shadow px-5 ms-3 `}
                                type = 'submit'
                            >
                                Guardar
                            </button>
                        </div>
                    </form>

                </div>
            </div>    
        </div>
    )

}
