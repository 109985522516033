import React, { useContext, useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { ProductContext } from '../../Context/ProductContext';
import { AlertSuccess } from '../../Components/AlertsComponents/AlertSuccess';

const selectedCarInitialState = {
    brandName: '',
    brandID: '',
    model: '',
    yearFrom: '',
    yearTo: '',
    engines: [],
}

export const useCarModelsScreen = () => {

    const MySwal = withReactContent(Swal)
    const { cars, showCars, setShowCars, getCars } = useContext(ProductContext)
    const [ selectedCar, setSelectedCar ] = useState(selectedCarInitialState);
    const [ tempCars, setTempCars ] = useState([ ...showCars ]);

    useEffect(() => {
        setSelectedCar(selectedCarInitialState);
    }, []);

    const deleteBrand = ( index, brandName ) => {
        MySwal.fire({
            title: <strong>¿Seguro que deseas eliminar { brandName }?</strong>,
            html: <i>Recuerda que no podrás recuperar sus datos.</i>,
            confirmButtonText: 'Eliminar',
            showCancelButton: true,
            reverseButtons: true,
            cancelButtonText: 'Regresar',
            icon: 'info'
        }).then(response => {
            if(response.isConfirmed) {
                let newCarsArray = [ ...cars ];
                newCarsArray.splice(index, 1);
                firebase.firestore().collection('cars').doc('cars').set({
                    models: [...newCarsArray],
                }).then(() => {
                    setSelectedCar({
                        ...selectedCar,
                        brandName: '',
                        brandID: '',
                        model: '',
                        yearTo: '',
                        engines: [],
                    })
                    getCars();
                    AlertSuccess('Modelo Elimiando', 'El modelo se ha eliminado exitosamente.');
                }).catch(e => {
                    console.error(new Error(e));
                    return MySwal.fire({
                        title: <p>Ha habido un error</p>,
                        html: <i>Verifica tu conexión a internet, e intenta de nuevo.</i>,
                        icon: 'error',
                    })
                })
            }
        })
    }

    const deleteCar = ( brandIndex, modelIndex ) => {
        console.log( brandIndex, modelIndex );
        MySwal.fire({
            title: <strong>¿Seguro que deseas eliminar este modelo?</strong>,
            html: <i>Recuerda que no podrás recuperar sus datos.</i>,
            confirmButtonText: 'Eliminar',
            showCancelButton: true,
            reverseButtons: true,
            cancelButtonText: 'Regresar',
            icon: 'info'
        }).then(response => {
            if(response.isConfirmed) {

                const brandData = cars[ brandIndex ];
                const modelData = brandData.models[modelIndex];
                brandData.models.splice(modelIndex, 1);
                firebase.firestore().collection('cars').doc('cars').update({ 
                    models: cars,
                }).then(() => {
                    getCars();
                    setSelectedCar({
                        ...selectedCar,
                        model: '',
                        yearTo: '',
                        engines: [],
                    })
                    AlertSuccess('Realizado con éxito.', 'El modelo ha sido eliminado exitosamente.');
                }).catch(e => {
                    return MySwal.fire({
                        title: <p>Ha habido un error</p>,
                        html: <i>Verifica tu conexión a internet, e intenta de nuevo.</i>,
                        icon: 'error',
                    })
                })
            }
        })
    }

    const handleBrandSearch = ({ target: { value }}) => {
        const searchTerm = value.toLowerCase();
        if( searchTerm === '' ) {
            setShowCars( cars );
        } else {
            const foundBrand = showCars.filter( car => car.brand.toLowerCase().includes(searchTerm) );
            setShowCars( foundBrand );
        }
    }

    const states = {
        cars,
        selectedCar,
    }

    const stateUpdaters = {
        deleteBrand,
        deleteCar,
        handleBrandSearch,
        setSelectedCar,
    }

    return [ states, stateUpdaters ];

}
