import React from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export const AlertFailed = ( title, description ) => {

    const MySwal = withReactContent(Swal)

    return MySwal.fire({
        title: <p>{ title }</p>,
        html: <i>{ description }</i>,
        icon: 'error',
    }).then(() => {
        console.log('Clickeado terminar');
    })
}
