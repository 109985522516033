import { useState } from 'react';
import firebase from 'firebase/compat/app';
import { getGroupsFromFamily } from '../helpers/getGroupsFromFamily';

export const useInventory = () => {

    const [ searchGroups, setSearchGroups ] = useState([]);
    const [ activeDateButtons, setActivateDateButtons ] = useState(false);
    const [ activateFileGeneration, setActivateFileGeneration ] = useState(false);
    const [ activateDownload, setActivateDownload ] = useState(false);

    const getFamilyProducts = ( family ) => {
        return new Promise((resolve, reject) => {
            console.log( family ); 
            firebase.firestore().collection('inventario').where('family', '==', family).get().then(res => {
                const products = [];
                if( res ) {
                    res.docs.forEach((doc, index) => {
                        const { pieza, nombre, precio_contado, precio_credito, existencia } = doc.data();
                        products.push({ 
                            pieza: pieza !== undefined ? `${pieza.replace(/"/g, '""')}` : `N/A`, 
                            nombre: nombre !== undefined ? `${nombre.replace(/"/g, '""')}` : `No encontrado`, 
                            precio_contado: precio_contado !== undefined ? `${precio_contado}` : `No encontrado` , 
                            precio_credito: precio_credito !== undefined ? `${precio_credito}` : `No encontrado`,  
                            existencia: existencia !== undefined ? `${existencia}` : `No encontrada`,
                        })
                        if(index === res.docs.length - 1) {
                            resolve(products);
                        }
                    })
                }
            }).catch(e => reject(e));

        });
    }

    const getSalesByDate = ( fromDate, toDate ) => {
        return new Promise ((resolve, reject) => {
            firebase.firestore().collection('sales').orderBy('date')
                .where('date', '>=', fromDate)
                .where('date', '<=', toDate).get().then(res => {
                    if( res.docs.length > 0 ) {
                        let tempProducts = [];
                        res.docs.forEach((doc, index) => {
                            if( doc.data().products.length > 0 ) {
                                doc.data().products.forEach(product => {
                                    const foundProduct = tempProducts.findIndex( p => p.productId === product.product );
                                    if( foundProduct !== -1 ) {
                                        tempProducts[foundProduct] = { 
                                            productId: tempProducts[foundProduct].productId,
                                            quantity: Number( tempProducts[foundProduct].quantity + product.quantity )
                                        }
                                    } else {
                                        tempProducts.push({ productId: product.product, quantity: product.quantity });
                                    }
                                })
    
                            }
                            if( index === res.docs.length - 1 ) {
                                resolve( [...tempProducts] );
                            }
                        });
                    } 
                }).catch(e => reject(new Error(e)));
        });
    }

    const getProductsData = ( products ) => {
        return new Promise ( async (resolve, reject) => {
            if( products.length > 0 ) {
                products.sort((a, b) => b.quantity - a.quantity );
                let tempProds = [];
                let productIDS = [];
                products.forEach( async (product, index) => {
                    if( !product.productId.includes('generic') ) productIDS.push( product.productId );
                })

                if( searchGroups.length > 0 ) {
                    productIDS.forEach( async (product, index) => {
                        console.log('En el producto ', index + 1, ' ', product);
                        for( let i = 0 ; i < searchGroups.length ; i += 10 ) {
                            let tempGroups = [...searchGroups];
                            let sliceTop = ( i == 0) ? 10 : i + 10;
                            console.log('Corde del batch desde ', i, ' hasta ', sliceTop);
                            let batch = tempGroups.slice(i, sliceTop);
    
                            await firebase.firestore().collection('inventario')
                                .where('family', 'in', batch)
                                .where(firebase.firestore.FieldPath.documentId(), '==', product).get().then(res => {
                                    if( res.docs.length > 0 ) {
                                        res.docs.forEach((doc, index) => {
                                            const productFound = products.filter(prod => prod.productId === doc.id);
                                            if( productFound.length > 0 ) {
                                                // console.log( doc.id, productFound[0].quantity );
                                                const { pieza, nombre, existencia } = doc.data();
                                                const data = { 
                                                    pieza: `${ pieza.replace(/"/g, '""') }`,
                                                    nombre: `${ nombre.replace(/"/g, '""') }`,
                                                    existencia: existencia, 
                                                    vendidos: productFound[0].quantity,
                                                }
                                                tempProds.push( data );
                                                // console.log(data);
                                                i = searchGroups.length + 1;
                                            }
                                        })
                                    }
                            }).catch(e => reject(new Error(e)));
                        }
                        if ( index === productIDS.length - 1 ) {
                            resolve([...tempProds]);
                        }
                    })

                }
            }
        })
    }


    const handleFamilySelection = ( e ) => {
        const groups = getGroupsFromFamily( e.value );
        setSearchGroups([ ...groups ]);
        setActivateDateButtons(true);
        setActivateFileGeneration(false);
        setActivateDownload(false);
    }

    return {
        activeDateButtons,
        activateDownload,
        activateFileGeneration,
        getFamilyProducts,
        getProductsData,
        getSalesByDate,
        handleFamilySelection,
        setActivateFileGeneration,
        setActivateDownload
    };

}
