import React from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export const AlertSuccess = ( title, description, callback = '' ) => {

    const MySwal = withReactContent(Swal)

    return MySwal.fire({
        title: <p>{ title }</p>,
        html: <i>{ description }</i>,
        icon: 'success',
    }).then(() => {
        console.log('Clickeado terminar');
        if( callback !== '' ) {
            callback();
        }
    })
}
