import React, { useContext, useEffect, useState } from 'react';
import { ProductContext } from '../../../Context/ProductContext';
import { AplicacionCarroItem } from '../AplicacionCarroItem/AplicacionCarroItem';
import { DetallesProductoItem } from './DetallesProductoItem';

import { productFamily } from '../../../helpers/ProductFamilies';
import { getProductFamily } from '../../../helpers/getProductFamily';

export const DetallesProductoInfo = ({ producto, venta = false }) => {

    const { loadSupplierData } = useContext(ProductContext);

    const [proveedorNombre, setProveedorNombre] = useState('');
    
    const { 
        aplicaciones, descripcion, existencia, image, nombre, pieza, 
        precio_contado, precio_credito, family
    } = producto;

    const getFamilyName = ( family ) => {
        const familyFound = productFamily.filter(fam => fam.value === family);
        if(familyFound.length > 0) {
            return familyFound[0].label;
        } else {
            return 'Familia no encontrada';
        }
    }
 
    return (
        <div>
            <div className = 'row px-3 mb-4'>
            <div className = ' col-12 col-sm-4 ps-md-4 d-flex align-items-center order-sm-2 '>
                    <div className = ' col-lg-12 my-4 mb-my-0 '>
                        <img className = ' DetallesProductoInfo-image w-100 ' src = { image } alt = 'imagen-producto'/>
                    </div>
                </div>
                <div className = ' col-12 col-sm-8 order-sm-1 row '>
                    <div className = 'col-12 '>
                        <DetallesProductoItem label = 'Número de pieza' value = { pieza } />
                    </div>
                    <div className = 'col-12 col-sm-6 '>
                        <DetallesProductoItem label = 'Nombre del producto' value = { nombre } />
                    </div>
                    <div className = 'col-12 col-sm-6 '>
                        <DetallesProductoItem label = 'Familia' value = { getProductFamily( family ) } />
                    </div>  
                    <div className = 'col-12 col-sm-6 '>
                        <DetallesProductoItem label = 'Grupo' value = { getFamilyName( family ) } />
                    </div>  
                    <div className = 'col-12 col-sm-6 '>
                        <DetallesProductoItem label = 'Existencia' value = { existencia } />
                    </div>
                    <div className = 'col-12'>
                        <DetallesProductoItem label = 'Descripción' value = { descripcion } />
                    </div>
                    <div className = 'row px-3 mb-4'>
                        <div className = 'col-6 col-sm-4'>
                            <DetallesProductoItem label = 'Contado' value = { `$${precio_contado}` } />
                        </div>
                        <div className = 'col-6 col-sm-4'>
                            <DetallesProductoItem label = 'Crédito' value = { `$${precio_credito}` } />
                        </div>
                    </div>
                </div>
                
            </div>
           
        
            {
                aplicaciones.map(aplicacion => (
                    <AplicacionCarroItem
                        item = { aplicacion }
                        key = { aplicacion.id } 
                    />
                ))
            }
        </div>
    );
}
