import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import { formatDate } from '../../helpers/formatDate';
import { formatMoney } from '../../helpers/formatMoney';
import { UnselectedItemPlaceholder } from './../UnselectedItemPlaceholder/UnselectedItemPlaceholder';
import { AlertConfirmation } from '../AlertsComponents/AlertConfirmation';
import { useInvoicesSlice } from '../../hooks/redux/useInvoicesSlice';

export const AccountingDetail = ({ selectedInvoice, setModifiedInvoice }) => {

    const { handleUpdate } = useInvoicesSlice();

    /*  
     *      Set an invoice as status with state given.  
     *      @params { string } docID. Document ID to modify.
     *      @params { boolean } state. New value to modify.
     */
    const markAsFinished = async ( id, state ) => {
        AlertConfirmation(
            'Actualizar el estado de la factura',
            () => updateInvoiceQuery( id, state),
        );
    }

    const updateInvoiceQuery = ( id, state ) => {
        handleUpdate( id, state )
        setModifiedInvoice( true );
    }
    
    const InvoiceDetail = () => {
        
        const {
            date, 
            email,
            invoiceFulfilled,
            name,
            paymentType,
            folio,
            phoneNumber,
            postCode,
            purchaseID,
            rfc,
            seller,
            total, 
            use
        } = selectedInvoice;

        return (
            <div className=' detail_container '>
                <div className=' detail_header '>
                    <div className={` ${(paymentType === 'cash' ? 'icons_purple-icon' : 'icons_blue-icon')}`}>
                        <ion-icon name={(paymentType === 'cash' ? 'cash-outline' : 'card-outline')}></ion-icon>
                    </div>
                    <div className = ' flex-grow-1 '>
                        <p>
                            { folio }
                        </p>
                    </div>
                    <p 
                        className = {` text_bold-label btn ${ invoiceFulfilled === true ? ' btn-success' : ' btn-danger'} `}
                    >
                        Estado {invoiceFulfilled === true ? ' Terminada' : ' Pendiente'}
                    </p>
                </div>
                <hr />
                <div className=' row px-4 '>
                    <div className=' col-12 pe-2 mt-3 '>
                        <p className=' text_bold-label '>Fecha</p>
                        <p className=' text_data '>{ formatDate( date ) }</p>
                    </div>
                    <div className=' col-12 pe-2 mt-3 '>
                        <p className=' text_bold-label '>Correo Electrónico</p>
                        <p className=' text_data '>{ email }</p>
                    </div>
                    <div className=' col-12 pe-2 mt-3 '>
                        <p className=' text_bold-label '>Nombre del Cliente</p>
                        <p className=' text_data '>{ name }</p>
                    </div>
                    <div className=' col-7 pe-2 mt-3 '>
                        <p className=' text_bold-label '>RFC</p>
                        <p className=' text_data '>{ rfc }</p>
                    </div>
                    <div className=' col-5 pe-2 mt-3 '>
                        <p className=' text_bold-label '>Código Postal</p>
                        <p className=' text_data '>{ postCode }</p>
                    </div>
                    <div className=' col-6 pe-2 mt-3 '>
                        <p className=' text_bold-label '>Número de teléfono</p>
                        <p className=' text_data '>{ phoneNumber }</p>
                    </div>
                    <div className=' col-7 pe-2 mt-3 '>
                        <p className=' text_bold-label '>Total de venta</p>
                        <p className=' text_data '>{ formatMoney( total ) }</p>
                    </div>
                    <div className=' col-5 pe-2 mt-3 '>
                        <p className=' text_bold-label '>Folio Venta</p>
                        <p className=' text_data '>{ folio }</p>
                    </div>
                    <div className=' col-7 pe-2 mt-3 '>
                        <p className=' text_bold-label '>Uso de la factura</p>
                        <p className=' text_data '>{ use }</p>
                    </div>
                    <div className=' col-5 pe-2 mt-3 '>
                        <p className=' text_bold-label '>Estatus de la factura</p>
                        <p className=' text_data '>{ invoiceFulfilled === true ? 'Terminada' : 'Pendiente' }</p>
                    </div>
                    <div className=' col-12 pe-2 mt-4'>
                        <p 
                            className = "btn purple-button"
                            onClick = { () => markAsFinished( selectedInvoice.id, invoiceFulfilled ) }
                        >
                            Marcar como {invoiceFulfilled === false ? ' Terminada' : ' Pendiente'}
                        </p>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div>
            {(selectedInvoice === '') && <UnselectedItemPlaceholder />}
            {(selectedInvoice !== '') && <InvoiceDetail />}
        </div>
    )
}
