import React, { useContext, useState } from 'react';
import { Modal } from '../../Components/Modal/Modal';
import { DetalleProductoVenta } from '../../Components/VentasComponents/DetalleProductoVenta/DetalleProductoVenta';
import { RandomProductModal } from '../../Components/VentasComponents/RandomProductModal/RandomProductModal';
import { ResumenVenta } from '../../Components/VentasComponents/ResumenVenta/ResumenVenta';
import { SalesHeader } from '../../Components/VentasComponents/SalesHeader/SalesHeader';
import { SalesProductCard } from '../../Components/VentasComponents/SalesProductCard/SalesProductCard';
import { ProductContext } from '../../Context/ProductContext';
import { InventarioSearch } from './../../Components/InventarioComponents/InventarioSearch/InventarioSearch';

export const VentasScreen = () => {

    const [ openModal, setOpenModal ] = useState(false);
    const [ openCartModal, setOpenCartModal ] = useState(false);
    const [ openRandomProductModal, setOpenRandomProductModal ] = useState(false);
    const [ selectedProduct, setSelectedProduct ] = useState([]);

    const { searchTerm, showProducts } = useContext(ProductContext);

    const abrirDetallesProducto = (producto) => {
        setSelectedProduct(producto)
        setOpenModal(true);
    }

    return (
        <div className = {` px-4 background-white `}>

           <div className = ' pb-3 '>
                <SalesHeader
                    openCart = { () => setOpenCartModal(true) }
                    openRandomProduct = { () => setOpenRandomProductModal(true) }
                    mainTitle = 'Venta Actual'
                />
                <InventarioSearch  />
           </div>

            <div className = {` row pb-2 `}>
                {  showProducts.map((product, index) => (
                    <React.Fragment key = { index }>
                        { searchTerm === '' && (
                            <div  className = ' col-6 col-md-4 col-lg-3 px-2 pb-3 ' >
                                <div 
                                    onClick = { () => abrirDetallesProducto(product, index) } 
                                >
                                    <SalesProductCard product = { product }  />
                                </div>
                            </div>
                        )}
                        {
                            searchTerm !== '' &&
                                ( 
                                    (product.data().nombre.toLowerCase()).includes(searchTerm.toLowerCase()) || 
                                    (product.data().pieza.toLowerCase()).includes(searchTerm.toLowerCase())
                                ) && (
                                <div  className = ' col-3 px-2 pb-3 ' >
                                    <div 
                                        onClick = { () => abrirDetallesProducto(product, index) } 
                                    >
                                        <SalesProductCard product = { product }  />
                                    </div>
                                </div>
                            )
                        }
                    
                    </React.Fragment>
                
                ))}
            </div>

            { !!openModal && (
                <Modal>
                    <DetalleProductoVenta
                        closeModal = { () => setOpenModal(false) }
                        producto = { selectedProduct }
                        venta = { true }
                    />
                </Modal>
            )}
            { !!openCartModal && (
                <Modal>
                    <ResumenVenta 
                        closeModal = { () => setOpenCartModal(false) }
                    />
                </Modal>
            )}
            { !!openRandomProductModal && (
                <Modal>
                    <RandomProductModal 
                        closeModal = { () => setOpenRandomProductModal(false) }
                    />
                </Modal>
            )}

        </div>
    );

};
