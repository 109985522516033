
const names = [
    'Sofia Verónica Ro',
    'Alejandro Vázquez',
    'Ramses Flores',
];

const paymentTypes = [
    'cash',
    'card',
];

const generateInvoices = ( ) => {
    const invoices = [];

    for( let i = 1; i < 15; i++ ) {
        invoices.push({ 
            id: i,
            date: new Date(`2022-07-${ i < 10 ? `0${ i }` : i }T${ Math.floor(Math.random() * 13) + 10 }:00:00`),
            email: 'facturacion@empresa.com',
            folio: 1000 + i,
            invoiceFulfilled: false,
            name: names[ Math.floor(Math.random() * 2) ],
            paymentType: paymentTypes[ Math.floor(Math.random() * 1) ],
            phoneNumber: '6181112233',
            postCode: 34000 + i * 2,
            rfc: 'XXXX551128XX7',
            seller: 'Avluet Software',
            total: Math.floor(Math.random() * 1000) + 100,
            use: 'Gastos en General',
        });
    }


    return invoices;
}

export const invoicesDB = generateInvoices();