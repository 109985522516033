import React from 'react'

import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { LoginScreen } from '../../Screens/AuthScreens/LoginScreen/LoginScreen'
import { RecoverPasswordScreen } from '../../Screens/AuthScreens/RecoverPasswordScreen/RecoverPasswordScreen'

export const AuthRoutes = () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route path = '/' element = { <LoginScreen /> } />
				<Route path = '/login' element = { <LoginScreen /> } />
				<Route path = '/recuperar-cuenta' element = { <RecoverPasswordScreen /> } />
				<Route path = '/*' element = { <LoginScreen /> } />
			</Routes>
		</BrowserRouter>
	)
}
