/*

    HOOK NAME: useProfile.
    HOOK DESCRIPTION: Hook to edit, read and update the user profile.
    HOOK CONTENT:

    # HOOK CONFIG:
        - State Initialization.

    # FIRESTORE PRODUCT FUNCTIONS.
        - Load current user data.
        - Load specific supplier.

*/

import React, { useState } from 'react';

import firebase from 'firebase/compat/app';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { useUploadImage } from './useUploadImage';
import { useAuth } from './useAuth';

export const useProfile = () => {

    const { changePhotoURL } = useAuth();
    const { uploadToStorage, uploadToFirestore } = useUploadImage();

    const MySwal = withReactContent(Swal);

    const [ uploadImage, setUploadImage ] = useState('')
    const [ editable, setEditable ] = useState(false);
    const [ savedData, setSavedData ] = useState(false);

    // Profile Functions

    const profileEdit = useFormik({
        initialValues: {
            profilePhoneNumber: '',
            profileAddress: '',
            profileImage: '',
            docID: '',
        },
        validationSchema: Yup.object({
            profilePhoneNumber: Yup.string()
                        .min(10, 'El telefono debe tener al menos 10 numeros')
                        .required('El telefono del usuario es obligatorio'),
            profileAddress: Yup.string()
                        .required('El nombre de usuario es obligatorio'),
        }),
        onSubmit: profileUser => {
            try {
                MySwal.fire({
                    title: <strong>¿Seguro que deseas actualizar?</strong>,
                    html: <i>Se actualizaran tus datos del perfil.</i>,
                    confirmButtonText: 'Guardar',
                    showCancelButton: true,
                    reverseButtons: true,
                    cancelButtonText: 'Regresar',
                    icon: 'info'
                }).then(async response => {
                    
                    if(response.isConfirmed) {
                        console.log(uploadImage)
                        if(uploadImage) {
                            try {
                                uploadToStorage('usuarios', profileUser.docID, uploadImage, 'ProfilePicture').then(url => {
                                    uploadToFirestore('usuarios', profileUser.docID, url)
                                    changePhotoURL(url)
                                    console.log('Se ha cambiado el photoURL.')
                                })
                            } catch (error) {
                                console.log(error)
                            }
                        }
                        firebase.firestore().collection('usuarios')
                        .doc(profileUser.docID)
                        .update({
                            address: profileUser.profileAddress,
                            phoneNumber: profileUser.profilePhoneNumber,
                        })
                        .then(() => {
                            setSavedData(true);
                            return MySwal.fire({
                                title: <p>Guardado con éxito</p>,
                                html: <i>Actualiza tu perfil para consultarlo.</i>,
                                icon: 'success',
                            }).then(() => {
                                setEditable(false);
                            })
                        }).catch(e => {
                            console.error(e)
                            setSavedData(false);
                            setEditable(true);
                            return MySwal.fire({
                                title: <p>Ha ocurrido un error</p>,
                                html: <i>Verifica tu conexión a internet, e intenta de nuevo.</i>,
                                icon: 'error',
                            })
                        })

                    }
                })
            } catch (error) {
                console.log(error)
            }
        }
    });
    
    return {
        profileEdit,
        editable,
        savedData,
        uploadImage,
        setEditable,
        setSavedData,
        setUploadImage,
    }

}
