import { createSlice } from "@reduxjs/toolkit";
import { clientsDB } from "../../db/clientsDB";

export const clientsSlice = createSlice({
    name: 'clients', 
    initialState: {
        clients: [ ...clientsDB ],
    },
    reducers: {
        onAddNewClient: ( state, { payload } ) => {
            state.clients = [ ...state.clients, payload ];
        },
        onDeleteClient: ( state, { payload }) => {
            const tempClients = [...state.clients];
            const supplierIndex = tempClients.findIndex(client => client.id === payload);
            tempClients.splice( supplierIndex, 1);
            state.clients = [ ...tempClients ];
        },
        onEditClient: ( state, { payload }) => {
            state.clients = [ ...payload ];
        }
    }
})

export const { onAddNewClient, onDeleteClient, onEditClient } = clientsSlice.actions;