import React from 'react'
import { ProfileContainer } from '../../Components/ProfileComponents/ProfileContainer/ProfileContainer'
import { useProfile } from '../../hooks/useProfile';

export const ProfileScreen = () => {

	const { profileEdit, editable, setEditable, uploadImage, setUploadImage } = useProfile();

	return (
		<div className = {` container `}>
			<ProfileContainer editable = { editable } setEditable = { setEditable } profileEdit = { profileEdit } uploadImage = { uploadImage } setUploadImage = { setUploadImage } />
		</div>
	)
}
