import React, { useState } from 'react'

export const UserDirectoryList = ({ setSelectedUser, users }) => {
 
    const [active, setActive] = useState(0);
    
    const elementSelected = (user, index) => {
        setSelectedUser(user);
        setActive(index);
    }

    return (
        <div>
            { users.length > 0 && (
                users.map((user, index) => (
                    <div 
                        className = {` directory_list-container ${ active === index && 'active' } px-4 `}
                        key = { index }
                        onClick = { () => elementSelected(user, index) }
                    >
                        <img
                            className = ' directory_list-image '
                            src = { user.image }
                            alt = 'Fotografia de perfil'
                        />
                        <div>
                            <p>{ user.name }</p>
                            <p className = ' text_instructions '>
                                { user.email }
                            </p>
                        </div>
                    </div>
                ))
            )}
            { users.length <= 0 && (
                <div className = ' px-4 '>
                    <p>No hay usuarios que mostrar.</p>
                </div>
            )}
        </div>
    )

}
