import { createSlice } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { onAddNewUser, onDeleteUser, onEditUser } from "../../store/users/usersSlice";

export const useUsersSlice = () => {
    
    const dispatch = useDispatch();
    const { users } = useSelector( state => state.users);

    const handleAddNewUser = ( user ) => {
        dispatch(onAddNewUser( user ));
    }

    const handleDeleteUser = ( id ) => {
        dispatch(onDeleteUser( id ));
    } 

    const handleEditUser = ( id, data ) => {
        const temp = [ ...users ];
        const index = temp.findIndex(user => user.id === id);
        temp[index] = {
            ...temp[index],
            ...data,
        }
        dispatch( onEditUser( temp ));
    }

    return {
        //* Properties.
        users,
        
        //* Methods.
        handleAddNewUser,
        handleDeleteUser,
        handleEditUser,
    }
}