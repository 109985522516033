import firebase from 'firebase/compat/app';
import { getUserByUid } from '../db/getUserByUID';
import { NumberToText } from '../NumberToText';

export const createTicketData = ( ticketParams ) => {

    const {
        cart, 
        paymentData,
        total,
        user,
    } = ticketParams;


    const { creditPayment, folio, paymentType, purchaseType } = paymentData;

    return new Promise(async (resolve, reject) => {
        let username = '';
        username = user.name;
        // await getUserByUid( user.uid )
        //     .then(res => {
        //         username = res.name || 'VENTAS';
        //     })
        //     .catch( reject );
        const ticketProducts = cart.map(product => {
            return { 
                productName: (product.name).toUpperCase(), 
                productCode: product.code, 
                productPrice: 
                    (purchaseType === 'credit' && !!creditPayment) ? product.price_credit : product.price_cash,
                productTotalPrice:
                    Number( (purchaseType === 'credit' && !!creditPayment) ? product.price_credit : product.price_cash) * Number(product.amount),
                productQuantity: product.amount,
                productDiscount: product.discount,
            };
        });
        const date = new Date();
        const baseHours = date.getHours();
        const hours = ( baseHours < 10) ? `0${ baseHours }` : `${ baseHours }`;
        const baseMinutes = date.getMinutes();
        const minutes = ( baseMinutes < 10 ) ? `0${ baseMinutes }` : `${ baseMinutes }`;
        const ticketData = {
            date: `${ date.getDate().toString().padStart(2, '0') }/${ (date.getMonth() + 1).toString().padStart(2, '0') }/${ date.getFullYear() }`,
            discount: total.discount,
            paymentType: paymentType,
            products: ticketProducts,
            total: total.total,
            totalLetter: NumberToText( total.total ),
            user: username,
            time: `${ hours }:${ minutes }`,
            folio: folio,
            purchaseType: purchaseType,
        }
        resolve(ticketData);
    })
}