import { Provider } from 'react-redux';
import { AuthProvider } from './hooks/useAuth';
import { AppContainer } from './Navigation/AppContainer/AppContainer';
import { store } from './store';

function App() {

	return (
		<>
			<Provider store = { store }>
				<AuthProvider>
						<AppContainer/>	
				</AuthProvider>
			</Provider>
		</>
	);
}

export default App;
