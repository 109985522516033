import { useDispatch, useSelector } from "react-redux"
import { onAddNewClient, onDeleteClient, onEditClient } from "../../store/clients/clientsSlice";

export const useClientsSlice = () => {

    const dispatch = useDispatch();

    const { clients } = useSelector(state => state.clients);

    const handleAddNewClient = ( client ) => {
        dispatch(onAddNewClient( client ));
    }

    const handleDeleteClient = ( id ) => {
        dispatch(onDeleteClient( id ));
    }

    const handleEditClient = ( id, clientData ) => {
        const tempClients = [ ...clients ];
        const index = tempClients.findIndex(client => client.id === id);
        tempClients[index] = {
            ...tempClients[index],
            ...clientData,
        }
        dispatch( onEditClient( tempClients ));
    }

    return {
        //* Properties.
        clients, 
        
        //* Methods.
        handleAddNewClient,
        handleDeleteClient,
        handleEditClient,
    }
}
