import React, { useEffect, useState } from 'react';

export const DetalleProductoItemEditable = ({ label, value, state, productoEditado, setProductoEditado }) => {

    const [inputValue, setInputValue] = useState(value);

    useEffect(() => {
        // if(inputValue === value) return;
        console.log( value );
        setProductoEditado({
            ...productoEditado,
            [state]: inputValue,
        })
    }, [ inputValue ]);

    useEffect(() => {
        if( state === 'precio_contado' || state === 'precio_credito' ) {
            setInputValue( value );
        }
    }, [ productoEditado ]);

    const handleInputChange = ( e ) => {
        setInputValue(e.target.value);
    }

    return (
        <div className = ' mb-3 '>
            <p className = ' text_bold-label '>{ label }</p>
            <input 
                className = 'form-control mt-2'
                onChange = { handleInputChange }
                value = { inputValue }
            />
        </div>
    )
    
}
