/*

    HOOK NAME: useProducts.
    HOOK DESCRIPTION: Hook that allows to consume and write the products database.

*/

import React, { useEffect, useState } from 'react'
// import firebase from 'firebase/compat/app';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import withReactContent from 'sweetalert2-react-content';
import { useUploadImage } from './useUploadImage';
import { getProductsByModel } from '../helpers/db/ProductsDB/getProductsByModel';
import { AlertSuccess } from '../Components/AlertsComponents/AlertSuccess';
import { AlertFailed } from '../Components/AlertsComponents/AlertFailed';
import { getProductByCode } from '../helpers/db/ProductsDB/getProductByCode';
import { getProductsByFamily } from '../helpers/db/ProductsDB/getProductsByFamily';
import { useProductsSlice } from './redux/useProductsSlice';

const filterInitialState = {
    brand: '',
    model: '',
    fromYear: '',
    toYear: '',
    engine: '',
}

export const useProducts = () => {

    const MySwal = withReactContent(Swal)
    const { deleteImage, uploadToStorage, uploadToFirestore } = useUploadImage();
    const { products, handleAddNewProduct, handleDeleteProduct: handleDeleteProductSlice, handleEditProduct } = useProductsSlice();

    /*  Hook state declarations.  */
    const [allProducts, setAllProducts] = useState([...products]);                            /*  Stores the productos and the loading state.      */
    const [aplications, setAplications] = useState([]);                            /*  Stores the existing aplications of a product,    */
    const [editable, setEditable] = useState(false)                                /*  Is true when the item is editable or not.        */
    const [firestoreSaved, setFirestoreSaved] = useState(false);                   /*  Is true when the item is saved to firestore.     */
    const [selectedProduct, setSelectedProduct] = useState('');                    /*  Selected product for product placeholder.        */
    const [filter, setFilter] = useState(filterInitialState);     /*  Stores the productos and the loading state.      */
    const [showProducts, setShowProducts] = useState([...products]);                          /*  Products to be shown on UI.                      */
    const [uploadImage, setUploadImage] = useState('');                            /*  Product image for UploadImage Component.         */

    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => handleLoadProducts(), []);

    useEffect(() => {
        filterProducts();
    }, [filter.engine]);

    useEffect(() => {
        if (filter.brand === '' || filter.brand === 'Todas las marcas') return;
        setFilter({
            ...filter,
            model: '',
        })
    }, [filter.brand])

    useEffect(() => {
        setFilter({
            ...filter,
            fromYear: '',
            toYear: '',
        })
    }, [filter.model])

    useEffect(() => {
        if (filter.toYear === '') return;
        setFilter({
            ...filter,
            toYear: '',
            engine: '',
        })
    }, [filter.fromYear]);

    const formik = useFormik({
        initialValues: {
            nombre: '',
            pieza: '',
            descripcion: '',
            costo: 0,
            existencia: '',
            family: '',
            precio_contado: 0,
            precio_credito: 0,
            proveedor: '',
            highRotation: false,
            image: 'https://firebasestorage.googleapis.com/v0/b/pos-web-a98f9.appspot.com/o/placeholders%2FProducto.png?alt=media&token=c133ed56-3c6b-405a-8f11-f5c817a05c7e',
        },
        validationSchema: Yup.object({
            nombre: Yup.string()
                .min(3, 'Los nombres de producto deben tener al menos 3 caracteres')
                .required('El nombre de el producto es obligatorio'),
            pieza: Yup.string()
                .min(1, 'Los números de pieza deben tener al menos 5 caracteres')
                .required('El número de pieza es obligatorio'),
            descripcion: Yup.string(),
            existencia: Yup.number()
                .typeError('La existencia debe indicarse con un número'),
            family: Yup.string().required('La familia de producto es obligatoria.'),
            costo: Yup.number()
                .typeError('El costo debe indicarse con un número')
                .min(1, 'El costo de productos deben tener al menos 1 caracter')
                .required('El costo de producto es obligatorio'),
            precio_contado: Yup.number()
                .typeError('El precio de contado debe indicarse con un número')
                .required('El precio del producto es obligatorio'),
            precio_credito: Yup.number()
                .typeError('El precio de credito debe indicarse con un número')
                .required('El precio a crédito del producto es obligatorio'),
        }),
        onSubmit: product => {
            try {
                const firestoreProduct = {
                    nombre: product.nombre,
                    pieza: (product.pieza).toUpperCase(),
                    descripcion: product.descripcion,
                    costo: product.costo,
                    existencia: product.existencia,
                    family: product.family,
                    precio_contado: product.precio_contado,
                    precio_credito: product.precio_credito,
                    proveedor: product.proveedor,
                    highRotation: product.highRotation,
                    aplicaciones: [...aplications],
                }
                MySwal.fire({
                    title: <strong>¿Seguro que deseas registrar?</strong>,
                    html: <i>Se guardará {product.nombre}</i>,
                    confirmButtonText: 'Guardar',
                    showCancelButton: true,
                    reverseButtons: true,
                    cancelButtonText: 'Regresar',
                    icon: 'info'
                }).then(async response => {
                    if (response.isConfirmed) {
                        handleAddNewProduct( firestoreProduct );
                        return MySwal.fire({
                            title: <p>Guardado con éxito</p>,
                            html: <i>Actualiza tu inventario para consultarlo</i>,
                            icon: 'success',
                        }).then(() => {
                            setAplications([]);
                            setFirestoreSaved(true);
                            formik.resetForm();
                            setSelectedProduct('');
                        });
                    }
                })
            } catch (error) {
                console.error(error)
            }
        }
    });

    const productImage = ( family ) => {
        return new Promise((resolve, reject) => {
            // firebase.storage().ref('placeholders/family').child(`002.png`).getDownloadURL().then(data => {
            //     resolve(data)
            // }).catch(e => {
            //     reject('https://firebasestorage.googleapis.com/v0/b/pos-web-a98f9.appspot.com/o/placeholders%2FProducto.png?alt=media&token=c133ed56-3c6b-405a-8f11-f5c817a05c7e')
            // })
            resolve('https://firebasestorage.googleapis.com/v0/b/pos-web-a98f9.appspot.com/o/placeholders%2FProducto.png?alt=media&token=c133ed56-3c6b-405a-8f11-f5c817a05c7e')
        })
    }

    /* 
     *  Delete product from firebase database.
     *  @param { string } docID Firestore document id to be deleted.
     *  @param { function } closeModal function to disable modal.  
     */
    const handleDeleteProduct = (id, setEditable, setSelectedProduct) => {
        MySwal.fire({
            title: <strong>¿Seguro que deseas eliminar este producto?</strong>,
            html: <i>No podrás recuperar sus datos.</i>,
            confirmButtonText: 'Eliminar',
            showCancelButton: true,
            reverseButtons: true,
            cancelButtonText: 'Regresar',
            icon: 'info'
        }).then(async response => {
            if (response.isConfirmed) {
                handleDeleteProductSlice( id );
                AlertSuccess(
                    'Eliminado con éxito',
                    'Actualiza tu inventario para consultar los cambios',
                    () => {
                        setEditable(false);
                        setSelectedProduct('');
                    }
                )
            }
        });
    }

    /* 
     *  Filter existing productos with Select.
     */
    const filterProducts = () => {
        if (filter.engine === '' || filter.engine === 'Todos los motores' || filter.engine === undefined) {
            setShowProducts([...allProducts]);
        } else {
            setShowProducts([]);
            allProducts.forEach(product => {
                let engineFound = false;
                if (!engineFound) {
                    product.data().aplicaciones.forEach(aplicacion => {
                        if (!engineFound) {
                            const existsEngine = aplicacion.engines.filter(engine => engine === filter.engine || engine === 'Todos los motores');
                            if (existsEngine.length > 0) {
                                setShowProducts(state => [...state, product]);
                                engineFound = true;
                            } else {
                                engineFound = true;
                            }
                        }
                    })
                }
            })
        }
    }

    /* 
     *  Search on firestore for specific product by code.
     */
    const handleGetProductByCode = async ( code, group = 'all' ) => {
        const codeSearch = code.toUpperCase();
        await getProductByCode( codeSearch, group, products )
            .then(res => {
                let tempProduct = [];
                res.forEach(doc => tempProduct.push(doc))
                setShowProducts([...tempProduct]);
                setAllProducts([...tempProduct]);
            })
            .catch( console.error );
    }

    const handleGetProductsByFamily = async (family) => {
        await getProductsByFamily( family )
            .then( res => {
                let tempProduct = [];
                res.docs.forEach(doc => tempProduct.push(doc))
                setShowProducts([...tempProduct]);
            })
            .catch( console.error );
    }

    const handleGetProductsByModel = async ( group ) => {
        setShowProducts([]);
        setAllProducts([]);
        await getProductsByModel( filter, group, products, searchTerm )
            .then(res => {
                setAllProducts([ ...res ])
                setShowProducts([ ...res ]);
            })
            .catch( console.error );
    }

    /* 
     *  Load Products from database.
     */
    const handleLoadProducts = async () => {
        try {
            // const temporaryProducts = await getProductsWithLimit( 10 ); 
            setAllProducts([...products]);
            setShowProducts([...products]);
        } catch(err) {
            console.error( err );
        }
    }

    /* 
     *  Search product from allProducts object.
     *  @param { event } e Event from input.
     */
    const searchProduct = (e) => {
        setSearchTerm(e.target.value);
    }

    /* 
     *  Update product from database.
     *  @param { object } data Object with product info.
     *  @param { string } docID Firestore document id to modify.
     *  @param { function } closeModal Function to disable modal.  
     */
    const updateProduct = (data, id, setEditable, selectedImage, setSelectedProduct) => {
        const productData = {
            nombre: data.nombre,
            pieza: (data.pieza).toUpperCase(),
            descripcion: data.descripcion,
            costo: data.costo,
            existencia: data.existencia,
            family: data.family,
            precio_contado: data.precio_contado,
            precio_credito: data.precio_credito,
            proveedor: data.proveedor,
            highRotation: data.highRotation,
            aplicaciones: data.aplicaciones,
        };
        MySwal.fire({
            title: <strong>¿Seguro que deseas actualizar {data.nombre}?</strong>,
            html: <i>Se modificará en el inventario este producto.</i>,
            confirmButtonText: 'Guardar',
            showCancelButton: true,
            reverseButtons: true,
            cancelButtonText: 'Regresar',
            icon: 'info'
        }).then(async response => {
            if (response.isConfirmed) {
                handleEditProduct( id, productData )
                return MySwal.fire({
                    title: <p>Guardado con éxito</p>,
                    html: <i>Actualiza tu inventario para consultarlo</i>,
                    icon: 'success',
                }).then(() => {
                    setEditable(false);
                    setSelectedProduct('');
                });
            }
        });
    }


    /*     Car aplications handle functions.     */

    /* 
     *  Add new car aplication to aplications hook array.
     *  @param { string } brand Car brand.
     *  @param { string } model Car model.
     *  @param { number } fromYear Car minimum year.
     *  @param { number } toYear Car maximun year.
     */
    const addNewAplication = (brand, model, fromYear, toYear, engines) => {
        const aplicacion = {
            id: new Date().getTime(),
            brand,
            model,
            fromYear: fromYear,
            toYear: toYear,
            engines,
        }
        setAplications(aplicaciones => [...aplicaciones, aplicacion]);
    }

    /* 
     *  Delete a car aplication from aplications array.
     *  @param { object } item Aplication object.
     */
    const deleteAplication = (item) => {
        setAplications(apps => apps.filter(apps => (apps.id !== item.id)));
    }

    return {
        aplications,
        editable,
        filter,
        formik,
        firestoreSaved,
        showProducts,
        allProducts,
        searchTerm,
        selectedProduct,
        uploadImage,
        updateProduct,
        addNewAplication,
        searchProduct,
        deleteAplication,
        deleteProduct: handleDeleteProduct,
        getProductByCode: handleGetProductByCode,
        getProductsByFamily: handleGetProductsByFamily,
        getProductsByModel: handleGetProductsByModel,
        loadProducts: handleLoadProducts,
        setEditable,
        setFirestoreSaved,
        setFilter,
        setSelectedProduct,
        setUploadImage,
    }

}
