import React from 'react';
import { IncomeStatisticsDetail } from '../../Components/IncomeStatisticsComponents/IncomeStatisticsDetail';
import { IncomeStatisticsHeader } from '../../Components/IncomeStatisticsComponents/IncomeStatisticsHeader';
import { IncomeStatisticsList } from '../../Components/IncomeStatisticsComponents/IncomeStatisticsList';
import { useIncomeStats } from '../../hooks/useIncomeStats';

export const IncomeStatisticsScreen = () => {

    const { states, stateUpdaters} = useIncomeStats();
    const { date, sales, selectedSale } = states;
    const { setDate, setSelectedSale } = stateUpdaters;

    return (
        <div>
            <div className = {` row mx-0 px-0 `}>
                <div className = {` col-12 col-sm-4 col-lg-5 white-background screen_section pt-4 `}>
                    <IncomeStatisticsHeader setDate = { setDate } />
                    <IncomeStatisticsList sales = { sales } setSelectedSale = { setSelectedSale }/>
                </div>
                <div className = {` col-12 col-sm-8 col-lg-7 `}>
                    { selectedSale === '' && (
                        <p className = 'p-4'>Aun no has seleccionado ningún día.</p>
                    ) }
                    { selectedSale !== '' && (
                        <IncomeStatisticsDetail selectedSale = { selectedSale } date = { date } />
                    )}
                </div>
            </div>
        </div>
    )

}
