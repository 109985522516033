import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { generateSelectableMonths } from '../../helpers/generateSelectableMonths';

export const IncomeStatisticsHeader = ({ setDate }) => {
 
    let currentDates = generateSelectableMonths();
    const [ optionalDates, setOptionalDates ] = useState( currentDates );
    const [ selectedYear, setSelectedYear ] = useState( currentDates.years[0] | '' );
    const [ selectedMonth, setSelectedMonth ] = useState( '' );
    
    useEffect(() => {
        if( selectedMonth === '' ) return;
        setDate({
            month: selectedMonth,
            year: selectedYear,
        })
        console.log( selectedMonth );
    }, [ selectedMonth ]);

    const handleYearChange = ( e ) => {
        setOptionalDates( generateSelectableMonths(e.value) );
        setSelectedYear( e.value );
        setSelectedMonth( '' );
    }

    const handleMonthChange = ( e ) => {
        setSelectedMonth( e.value );
    }

    return (
        <div className = ' IncomeStatiticsHeader-container d-flex flex-column px-3 '>
            <div className = ' d-flex align-items-center w-100 '>
                <div className = 'DirectorioHeader-title '>
                    <h2 className = ' section-title '>Reporte de Ingresos</h2>
                    <p className = ' title-description '>Consulta el historial de ingresos por día.</p>
                </div>
            </div>
            <div className = ' mt-3 w-100 row mx-0 '>
                <div className = ' col-6 pe-1 '>
                    <Select 
                        defaultValue={{ value: optionalDates.years[0], label: optionalDates.years[0] }}
                        onChange = { handleYearChange }
                        options = { optionalDates.years.map(y => ({ value: y, label: y })) }
                    />
                </div>
                <div className = ' col-6 ps-1 '>
                    <Select 
                        defaultValue = {{ label: optionalDates.months.slice(-1), value: optionalDates.months.slice(-1) }}
                        onChange = { handleMonthChange }
                        options = { optionalDates.months.map((m, index) => ({ value: index + 1, label: m }))}
                    />
                </div>
            </div>
        </div>
    )
}
