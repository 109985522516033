import React, { useContext, useEffect, useState } from 'react'
import { ProductContext } from '../Context/ProductContext';
import { calculatePrices } from '../helpers/CalculatePrices';

export const useNuevoProductoModal = ( params ) => {

    const { 
        closeModal, 
        formik, 
        firestoreSaved, 
        uploadImage, 
    } = params;
 
    const { allProducts, allSuppliers, loadSuppliers, loadProducts } = useContext(ProductContext);
    const [ costo, setCosto ] = useState(0);
    const [ costEdited, setCostEdited ] = useState( false );
    const [ existentPiece, setExistentPiece ] = useState(false);
    const [ family, setFamily ] = useState(0);
    const [ highRotation, setHighRotation ] = useState(false);

    useEffect(() => {
        loadProducts();
    }, []);

    useEffect(() => {
        if(!firestoreSaved) return;
        if(firestoreSaved) {
            loadProducts();
            closeModal();
        }
    }, [firestoreSaved]);

    useEffect(( ) => {
        formik.setFieldValue('highRotation', highRotation);
    }, [ highRotation ]);

    useEffect(() => {
        formik.setFieldValue('image', uploadImage);
    }, [ uploadImage ]);

    useEffect(() => {
        formik.setFieldValue('costo', costo);
        if( family !== 0  ) {
            try {
                const { contado, credito } = calculatePrices( costo, family );
                formik.setFieldValue('precio_contado', contado);
                formik.setFieldValue('precio_credito', credito);
            } catch(e) {
                console.error(e);
            }
        }
    }, [ costo ])

    useEffect(() => {
        formik.setFieldValue( 'family', family );
        const prices = calculatePrices( costo, family );
        formik.setFieldValue('precio_contado', prices.contado);
        formik.setFieldValue('precio_credito', prices.credito);
    }, [ family ])

    
    const handleProveedorSelect = (e) => {
        const selectedOption = e.value;
        formik.setFieldValue('proveedor', selectedOption);
    }

    const findExistingPieceNumber = ({ target: { value } }) => {
        setExistentPiece(false);
        if(value !== '') {
            const foundProduct = allProducts.filter(product => product.pieza === value);
            if(foundProduct.length > 0) {
                setExistentPiece(true);
            }
        }
    }

    const handleCostChange = ({ target: { value } }) => {
        setCosto( value );
        setCostEdited( true );
    }

    const handleFamilyChange = ({ value }) => {
        setFamily( value );
    }


    const states = {
        allSuppliers,
        costEdited,
        existentPiece,
        family,
        highRotation,
    }
    
    const stateUpdaters = {
        findExistingPieceNumber,
        handleCostChange,
        handleFamilyChange,
        handleProveedorSelect,
        setHighRotation,
    }

    return [ states, stateUpdaters ];

}
