import { createSlice } from "@reduxjs/toolkit";
import { suppliersDB } from "../../db/suppliersDB";

export const suppliersSlice = createSlice({
    name: 'suppliers', 
    initialState: {
        suppliers: [ ...suppliersDB ],
    },
    reducers: {
        onNewSupplier: ( state, { payload }) => {
            state.suppliers = [ ...state.suppliers, payload ];
        },
        onDeleteSupplier: ( state, { payload }) => {
            const tempSuppliers = [...state.suppliers];
            const supplierIndex = tempSuppliers.findIndex(supplier => supplier.id === payload);
            tempSuppliers.splice( supplierIndex, 1);
            state.suppliers = [ ...tempSuppliers ];
        },
        onEditSupplier: ( state, { payload }) => {
            state.suppliers = [ ...payload ];
        }
    },
})

export const { onDeleteSupplier, onEditSupplier, onNewSupplier } = suppliersSlice.actions;