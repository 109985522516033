import React from 'react';

export const AplicacionCarroItem = ({ borrarAplicacion = '', item }) => {

    const { brand, engines, model, toYear, fromYear } = item;

    return (
        <div className = ' inventario_aplicacion-carro-item-container p-3 mb-2'>
            <p>
                <span className = ' inventario_aplicacion-info-container '>{ brand }</span> { model } · { fromYear } a { toYear } 
                <span className = ' bold '> Motores: </span> { engines.map(engine => `${ engine } `) }</p>
            {
                (borrarAplicacion !== '') && (
                    <div 
                        className = ' inventario_aplicacion-carro-item-delete '
                        onClick = { () => borrarAplicacion(item) }
                    >
                        <ion-icon name="trash-outline"></ion-icon>
                    </div>
                )
            }
           
        </div>
    )
}
