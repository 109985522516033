import React, { useState } from 'react'
import { SupportDetail } from '../SupportDetailComponent/SupportDetail'
import inventory from './inventary.png'
import sales from './sales.png'
import users from './users.png'
import suppliers from './suppliers.png'
import clients from './clients.png'
import dashboard from './dashboard.png'
import profile from './profile.png'
import { useAuth } from '../../../hooks/useAuth'

export const SupportMenu = () => {

	const { userInfo } = useAuth();
	const [ active, setActive ] = useState('');
	const [ index, setIndex ] = useState(0);
	const [ module, setModule ] = useState('');

	return (
		<div className = ' row '>

			<div className = ' support_subject-container col '>
				<div className = ' pt-4 section-title '>
						<p>Manual de usuario</p>
				</div>

				<div className = ' pe-1 '>

					{/* Inventory */}
					{ (userInfo.position === 'Administrador' || userInfo.position === 'Almacen') && (

						<div className = "accordion pb-3 " id = "accordionInventory">
							<div className = "accordion-item rounded SupportMenuSubjects-accordion" >
								<h2 className = "accordion-header" id = "inventory">
									<button className = "accordion-button collapsed" type = "button" data-bs-toggle = "collapse" data-bs-target = "#inventory-collapse" aria-expanded = 'false' aria-controls = "inventory-collapse">
										<img className = ' support_subject-icon ' src = { inventory } alt = 'inventario' />
										<div>
											<p className = ' section-title mb-1 ' >Inventario</p>
											<p className = ' support_title-description ' >Qué se puede hacer en el módulo de inventario.</p>
										</div>
									</button>
								</h2>
								<div id = "inventory-collapse" className = "accordion-collapse collapse " aria-labelledby = "inventory" data-bs-parent = "#accordionInventory">
									<div className = "accordion-body">
										<div 
											className = {active === 'inventory1' ? ' support_question-container support_question-active' : ' support_question-container '} 
											onClick = { () => {setModule('inventory'); setIndex(1); setActive('inventory1') }}
											>
											<p>Agregar un nuevo producto</p>
										</div>
										<div 
											className = {active === 'inventory2' ? ' support_question-container support_question-active' : ' support_question-container '}  
											onClick = { () => {setModule('inventory'); setIndex(2); setActive('inventory2') }}
										>
											<p>Editar información o existencias a un producto.</p>
										</div>
										<div 
											className = {active === 'inventory3' ? ' support_question-container support_question-active' : ' support_question-container '} 
											onClick = { () => {setModule('inventory'); setIndex(3); setActive('inventory3') }}
										>
											<p>Eliminar un producto.</p>
										</div>
										<div 
											className = {active === 'inventory4' ? ' support_question-container support_question-active' : ' support_question-container '} 
											onClick = { () => {setModule('inventory'); setIndex(4); setActive('inventory4') } }
										>
											<p>Buscar un producto en específico.</p>
										</div>
										<div 
											className = {active === 'inventory5' ? ' support_question-container support_question-active' : ' support_question-container '}  
											onClick = { () => {setModule('inventory'); setIndex(5); setActive('inventory5') } }
										>
											<p>¿Cómo agregar una aplicación al producto?</p>
										</div>
										<div 
											className = {active === 'inventory6' ? ' support_question-container support_question-active' : ' support_question-container '}  
											onClick = { () => {setModule('inventory'); setIndex(6); setActive('inventory6') } }
										>
											<p>Entrada de mercancia de mayoreo.</p>
										</div>
									</div>
								</div>
							</div>
						</div>

					)} 


					{/* Sales */}
					{ (userInfo.position === 'Administrador' || userInfo.position === 'Ventas') && (

						<div className = "accordion pb-3 " id = "accordionSales">
							<div className = "accordion-item SupportMenuSubjects-accordion" >
								<h2 className = "accordion-header" id = "sales">
									<button className = "accordion-button collapsed" type = "button" data-bs-toggle = "collapse" data-bs-target = "#sales-collapse" aria-expanded = 'false' aria-controls = "sales-collapse">
										<img className = ' support_subject-icon ' src = { sales } alt = 'ventas' />
										<div>
											<p className = ' section-title mb-1 ' >Ventas</p>
											<p className = ' support_title-description ' >Opciones en el módulo de ventas.</p>
										</div>
									</button>
								</h2>
								<div id = "sales-collapse" className = "accordion-collapse collapse " aria-labelledby = "sales" data-bs-parent = "#accordionSales">
									<div className = "accordion-body">
										<div 
											className = {active === 'sales1' ? ' support_question-container support_question-active' : ' support_question-container '} 
											onClick = { () => {setModule('sales'); setIndex(1); setActive('sales1')}}
										>
											<p>Agregar productos a la venta.</p>
										</div>
										<div 
											className = {active === 'sales2' ? ' support_question-container support_question-active' : ' support_question-container '} 
											onClick = { () => {setModule('sales'); setIndex(2); setActive('sales2')}}
										>
											<p>Ticket de venta.</p>
										</div>
										<div 
											className = {active === 'sales3' ? ' support_question-container support_question-active' : ' support_question-container '}
											onClick = { () => {setModule('sales'); setIndex(3); setActive('sales3')}}
										>
											<p>Voucher de producto de inventario.</p>
										</div>
										<div 
											className = {active === 'sales4' ? ' support_question-container support_question-active' : ' support_question-container '}
											onClick = { () => {setModule('sales'); setIndex(4); setActive('sales4')}}
										>
											<p>Facturación de venta.</p>
										</div>
										<div 
											className = {active === 'sales5' ? ' support_question-container support_question-active' : ' support_question-container '}
											onClick = { () => {setModule('sales'); setIndex(5); setActive('sales5')}}
										>
											<p>Descuentos a venta.</p>
										</div>
										<div 
											className = {active === 'sales6' ? ' support_question-container support_question-active' : ' support_question-container '}
											onClick = { () => {setModule('sales'); setIndex(6); setActive('sales6')}}
										>
											<p>Pedidos especiales.</p>
										</div>
									</div>
								</div>
							</div>
						</div>

					)}

					{/* Dashboard */}
					{ userInfo.position === 'Administrador' && (

						<div className = "accordion pb-3 " id = "accordionDashboard">
							<div className = "accordion-item SupportMenuSubjects-accordion" >
								<h2 className = "accordion-header" id = "dashboard">
									<button className = "accordion-button collapsed" type = "button" data-bs-toggle = "collapse" data-bs-target = "#dashboard-collapse" aria-expanded = 'false' aria-controls = "dashboard-collapse">
										<img className = ' support_subject-icon ' src = { dashboard } alt = 'inicio' />
										<div>
											<p className = ' section-title mb-1 ' >Dashboard</p>
											<p className = ' support_title-description ' >Estadísticas y notificaciones del Inicio.</p>
										</div>
									</button>
								</h2>
								<div id = "dashboard-collapse" className = "accordion-collapse collapse " aria-labelledby = "dashboard" data-bs-parent = "#accordionDashboard">
									<div className = "accordion-body">
										<div 
											className = {active === 'dashboard1' ? ' support_question-container support_question-active' : ' support_question-container '}
											onClick = { () => {setModule('dashboard'); setIndex(1); setActive('dashboard1') }}
										>
											<p>Estadísticas que se encuentran en el dashboard.</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						
					)}

					{/* Users */}
					{ userInfo.position === 'Administrador' && (

						<div className = "accordion pb-3 " id = "accordionUsers">
							<div className = "accordion-item SupportMenuSubjects-accordion" >
								<h2 className = "accordion-header" id = "users">
									<button className = "accordion-button collapsed" type = "button" data-bs-toggle = "collapse" data-bs-target = "#users-collapse" aria-expanded = 'false' aria-controls = "users-collapse">
										<img className = ' support_subject-icon ' src = { users } alt = 'usuarios' />
										<div>
											<p className = ' section-title mb-1 ' >Usuarios</p>
											<p className = ' support_title-description ' >Manejo de usuarios.</p>
										</div>
									</button>
								</h2>
								<div id = "users-collapse" className = "accordion-collapse collapse " aria-labelledby = "users" data-bs-parent = "#accordionUsers">
									<div className = "accordion-body">
										<div 
											className = {active === 'users1' ? ' support_question-container support_question-active' : ' support_question-container '}
											onClick = { () => {setModule('users'); setIndex(1); setActive('users1')}}
										>
											<p>Registrar un nuevo usuario.</p>
										</div>
										<div 
											className = {active === 'users2' ? ' support_question-container support_question-active' : ' support_question-container '}
											onClick = { () => {setModule('users'); setIndex(2); setActive('users2')}}
										>
											<p>Editar la información o el puesto de un usuario.</p>
										</div>
										<div 
											className = {active === 'users3' ? ' support_question-container support_question-active' : ' support_question-container '}
											onClick = { () => {setModule('users'); setIndex(3); setActive('users3')}}
										>
											<p>Eliminar un usuario y proceso.</p>
										</div>
										<div 
											className = {active === 'users4' ? ' support_question-container support_question-active' : ' support_question-container '}
											onClick = { () => {setModule('users'); setIndex(4); setActive('users4')}}
										>
											<p>Recuperar la contraseña de un usuario.</p>
										</div>
									</div>
								</div>
							</div>
						</div>

					)}

					{/* Suppliers */}
					{ userInfo.position === 'Administrador' && (

						<div className = "accordion pb-3 " id = "accordionSuppliers">
							<div className = "accordion-item SupportMenuSubjects-accordion" >
								<h2 className = "accordion-header" id = "suppliers">
									<button className = "accordion-button collapsed" type = "button" data-bs-toggle = "collapse" data-bs-target = "#suppliers-collapse" aria-expanded = 'false' aria-controls = "suppliers-collapse">
										<img className = ' support_subject-icon ' src = { suppliers } alt = 'proveedores' />
										<div>
											<p className = ' section-title mb-1 ' >Proveedores</p>
											<p className = ' support_title-description ' >Manejo de proveedores.</p>
										</div>
									</button>
								</h2>
								<div id = "suppliers-collapse" className = "accordion-collapse collapse " aria-labelledby = "suppliers" data-bs-parent = "#accordionSuppliers">
									<div className = "accordion-body">
										<div className = ' support_question-container ' onClick = { () => {setModule('suppliers'); setIndex(1)}}>
											<p>Registrar un nuevo proveedor.</p>
										</div>
										<div className = ' support_question-container ' onClick = { () => {setModule('suppliers'); setIndex(2)}}>
											<p>Editar la información de un proveedor.</p>
										</div>
										<div className = ' support_question-container ' onClick = { () => {setModule('suppliers'); setIndex(3)}}>
											<p>Eliminar un proveedor.</p>
										</div>
									</div>
								</div>
							</div>
						</div>

					)}
					
					{/* Clients */}
					{ userInfo.position === 'Administrador' && (
						
						<div className = "accordion pb-3 " id = "accordionClients">
							<div className = "accordion-item SupportMenuSubjects-accordion" >
								<h2 className = "accordion-header" id = "clients">
									<button className = "accordion-button collapsed" type = "button" data-bs-toggle = "collapse" data-bs-target = "#clients-collapse" aria-expanded = 'false' aria-controls = "clients-collapse">
										<img className = ' support_subject-icon ' src = { clients } alt = 'clientes' />
										<div>
											<p className = ' section-title mb-1 ' >Clientes</p>
											<p className = ' support_title-description ' >Manejo de clientes.</p>
										</div>
									</button>
								</h2>
								<div id = "clients-collapse" className = "accordion-collapse collapse " aria-labelledby = "clients" data-bs-parent = "#accordionClients">
									<div className = "accordion-body">
										<div className = ' support_question-container ' onClick = { () => {setModule('clients'); setIndex(1)}}>
											<p>Registrar un nuevo cliente.</p>
										</div>
										<div className = ' support_question-container ' onClick = { () => {setModule('clients'); setIndex(2)}}>
											<p>Editar la información de un cliente y su descuento.</p>
										</div>
										<div className = ' support_question-container ' onClick = { () => {setModule('clients'); setIndex(3)}}>
											<p>Eliminar un cliente.</p>
										</div>
									</div>
								</div>
							</div>
						</div>

					)}

					{/* Profile */}
					<div className = "accordion pb-3 " id = "accordionProfile">
						<div className = "accordion-item SupportMenuSubjects-accordion" >
							<h2 className = "accordion-header" id = "profile">
								<button className = "accordion-button collapsed" type = "button" data-bs-toggle = "collapse" data-bs-target = "#profile-collapse" aria-expanded = 'false' aria-controls = "profile-collapse">
									<img className = ' support_subject-icon ' src = { profile } alt = 'perfil' />
									<div>
										<p className = ' section-title mb-1 ' >Perfil</p>
										<p className = ' support_title-description ' >Información personal del usuario actual.</p>
									</div>
								</button>
							</h2>
							<div id = "profile-collapse" className = "accordion-collapse collapse " aria-labelledby = "profile" data-bs-parent = "#accordionProfile">
								<div className = "accordion-body">
									<div className = ' support_question-container ' onClick = { () => {setModule('profile'); setIndex(1)}}>
										<p>Información que se puede ver y modificar en el perfil.</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					
				</div>

			</div>

			<div className = 'col bg-white'>
				<SupportDetail 
					module = { module }
					index = { index }
				/>
			</div>
		</div>
	)
}
