import React from 'react'
import { UnselectedItemPlaceholder } from '../UnselectedItemPlaceholder/UnselectedItemPlaceholder'
import { UploadImageComponent } from '../UploadImageComponent/UploadImageComponent'
import { ClientDirectoryEdit } from './ClientDirectoryEdit'
import { ClientPurchaseHistory } from './ClientPurchaseHistory'
import { UnsettledPurchaseHistory } from './UnsettledPurchaseHistory'

export const ClientDirectoryDetail = ({ user, editForm, removeUser, editable, setEditable, uploadImage, setUploadImage }) => {
    
    React.useEffect(() => {
        const tabElement = document.getElementById('info-tab');
        if( tabElement ) tabElement.click();
        
    }, [ user ]);

    return (
        <div>
            { user !== '' && (
                <div className = ' detail_bordered-container '>
                    <>
                        <div className = {` detail_branding-container `}>
                            { editable ? 
                                <UploadImageComponent 
                                    defaultImg = { user.image } 
                                    userType = { 'proveedor' } 
                                    uploadImage = { uploadImage } 
                                    setUploadImage = { setUploadImage } 
                                />
                            :
                                <img
                                    className = {` detail_branding `}
                                    src = { user.image }
                                    alt = 'directorio'
                                />
                            }
                        </div>
                        <div className = {` container px-3 pt-5 pb-1 `}>
                            <div className = ' d-flex align-items-center '>
                                <p className = ' flex-grow-1 '>{ user.name }</p>
                                <p className='button purple-button' onClick = {() => setEditable(!editable)}>
                                    { (editable) ? 'Cancelar' : 'Editar' }
                                </p>
                            </div>
                        </div>
                    </>
                    <hr className = ' ui_grey-separator ' />

                    <div className = ' px-3 row mx-0 '>
                        { !editable && (
                            <>
                                <ul className = "nav nav-tabs mb-4" id="myTab" role="tablist">
                                    <li className = "nav-item" role="presentation">
                                        <button 
                                            className = "nav-link active" 
                                            id = "info-tab" 
                                            data-bs-toggle="tab" 
                                            data-bs-target="#info" 
                                            type="button" role="tab" aria-controls="info" aria-selected="true"
                                        >
                                            Informacion
                                        </button>
                                    </li>
                                    <li className = "nav-item" role="presentation">
                                        <button 
                                            className = "nav-link" i
                                            d = "history-tab" 
                                            data-bs-toggle="tab" 
                                            data-bs-target="#history" 
                                            type="button" role="tab" 
                                            aria-controls="history" aria-selected="false"
                                        >
                                            Historial de Compras
                                        </button>
                                    </li>
                                    <li className = "nav-item" role = "presentation">
                                        <button 
                                            className = "nav-link" 
                                            id="payments-tab" 
                                            data-bs-toggle="tab" 
                                            data-bs-target="#payments" 
                                            type="button" role="tab" 
                                            aria-controls="payments" aria-selected="false"
                                        >
                                            Pagos Pendientes
                                        </button>
                                    </li>
                                </ul>
            
                                <div className = "tab-content " id="myTabContent">
                                    <div 
                                        className = "tab-pane fade show active " 
                                        id="info" role="tabpanel" aria-labelledby="info-tab"
                                    >
                                        <div className = ' row px-4 '>
                                            <div className = {` col-lg-6 mb-4`}>
                                                <p className = {` text_bold-label `}>Nombre</p>
                                                <p className = {` text_data `}>{ user.name }</p>
                                            </div>
                                            <div className = {` col-lg-6 mb-4`}>
                                                <p className = {` text_bold-label `}>Encargado</p>
                                                <p className = {` text_data `}>{ user.contactName }</p>
                                            </div>
            
                                            <div className = {` col-lg-6 mb-4`}>
                                                <p className = {` text_bold-label `}>Descuento</p>
                                                <p className = {` text_data `}>{ user.discount }%</p>
                                            </div>
                                            <div className = {` col-lg-6 mb-4 `}>
                                                <p className = {` text_bold-label `}>Correo Electrónico</p>
                                                <a
                                                    className = {` text_data `}
                                                    href = {`mailto:${ user.email }`}
                                                >
                                                    { user.email }
                                                </a>
                                            </div>
                                            <div className = {` col-lg-6 mb-4 `}>
                                                <p className = {` text_bold-label `}>Dirección</p>
                                                <p className = {` text_data `}>{ user.address }</p>
                                            </div>
                                            <div className = {` col-lg-6 mb-4 `}>
                                                <p className = {` text_bold-label `}>Código Postal</p>
                                                <p className = {` text_data `}>{ user.cp || 'Falta registrar'}</p>
                                            </div>
                                            <div className = {` col-lg-6 mb-4 `}>
                                                <p className = {` text_bold-label `}>RFC</p>
                                                <p className = {` text_data `}>{ user.rfc }</p>
                                            </div>
                                            <div className = {` col-lg-6 mb-4 `}>
                                                <p className = {` text_bold-label `}>Teléfono</p>
                                                <p className = {` text_data `}>{ user.phoneNumber }</p>
                                            </div>
                                            <div className = {` col-lg-6 mb-4 `}>
                                                <p className = {` text_bold-label `}>Crédito Autorizado</p>
                                                <p className = {` text_data `}>
                                                    { (!user.creditAuthorized && user.creditAuthorized !== undefined) ? 'No' : 'Sí' }
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className = "tab-pane fade mb-3 "
                                        id="history"
                                        role="tabpanel"
                                        aria-labelledby="history-tab"
                                    >
                                        <ClientPurchaseHistory userID = { user.id } />
                                    </div>
                                    <div
                                        className = "tab-pane fade mb-3 "
                                        id="payments"
                                        role="tabpanel"
                                        aria-labelledby="payments-tab"
                                    >
                                        <UnsettledPurchaseHistory userID = { user.id } />
                                    </div>
                                </div>
                            </>    
                        )}
                        { !!editable && (
                            <ClientDirectoryEdit 
                                user = { user } 
                                editClient = { editForm } 
                                removeClient = { removeUser } 
                                uploadImage = { uploadImage } 
                            />
                        ) }
                    </div>
                </div>
            )}
            { user === '' && (
                <UnselectedItemPlaceholder />
            ) }
        </div>
    )
}
