import React, { useState } from 'react'

export const ClientDirectoryList = ({ setSelectedClient, clients }) => {

    const [active, setActive] = useState(0);
    
    const elementSelected = (user, index) => {
        setSelectedClient(user);
        setActive(index);
    }

    return (
        <div>
            { clients.length > 0 && (
                clients.map((user, index) => (
                    <div 
                        className = {` directory_list-container ${ active === index && 'active' } px-4 `}
                        key = { index }
                        onClick = { () => elementSelected(user, index) }
                    >
                        <img
                            className = ' directory_list-image '
                            src = { user.image }
                            alt = 'Fotografia de perfil'
                        />
                        <div>
                            <p>{ user.name }</p>
                            <p className = ' text_instructions '>
                                { user.email }
                            </p>
                        </div>
                    </div>
                ))
            )}
            { clients.length <= 0 && (
                <div className = ' mx-0 px-3 '>
                    <p>No hay clientes que mostrar.</p>
                </div>
            )}
        </div>
    )


}
