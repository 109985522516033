import React, { useContext, useEffect, useRef, useState } from 'react'
import { AlertFailed } from '../Components/AlertsComponents/AlertFailed';
import { ProductContext } from '../Context/ProductContext';
import { buildPaymentData } from '../helpers/buildPaymentData';
import { registerInvoice } from '../helpers/db/registerInvoice';
import { registerSaleDataBase } from '../helpers/db/registerSaleDataBase';
import { removeProductsFromStock } from '../helpers/db/removeProductsFromStock';
import { saveSaleStatistics } from '../helpers/db/saveSalesStatistics';
import { getClients } from '../helpers/db/getClients';
import { getPurchaseFolio } from '../helpers/getPurchaseFolio';
import { saveSellerStatistics } from '../helpers/sales/saveSellerStatistics';
import { useAuth } from './useAuth';
import { createTicketData } from '../helpers/sales/createTicketData';
import { useReactToPrint } from 'react-to-print';
import { registerSpecialProduct } from '../helpers/db/registerSpecialProduct';
import { updateFolio } from '../helpers/db/updateFolio';
import { useProductsSlice } from './redux/useProductsSlice';
import { useSalesSlice } from './redux/useSalesSlice';
import { useClientsSlice } from './redux/useClientsSlice';

const invoiceInitialState = {
	name: '',
	rfc: '',
	email: '', 
	use: '', 
	purchaseID: '', 
	postCode: '',
	phoneNumber: '',
    invoiceFulfilled: false,
}

const totalInitialState = {
    total: 0,
    subtotal: 0,
    iva: 0,
    discount: 0,
    discountPercentage: 0,
}

const selectedClientInitalState = { 
	id: 'public',
	name: '',
	discount: 0,
	selected: false,
    cp: '',
}

export const usePayment = () => {

    const { cart, cartTotal, cleanCart } = useContext(ProductContext);
    const { user } = useAuth();
    const { handleProductSold } = useProductsSlice();
    const { folio, handleNewSale } = useSalesSlice();
    const { clients } = useClientsSlice();

    let componentRef = useRef();
    let voucherRef = useRef();
    let quoteRef = useRef();

    // Purchase Attributes.
    const [ creditPayment, setCreditPayment ] = useState(true);
    const [ invoiceData, setInvoiceData ] = useState( invoiceInitialState );
    const [ invoiceType, setInvoiceType ] = useState('');
    const [ paymentData, setPaymentData ] = useState({});
    const [ paymentType, setPaymentType ] = useState('cash');
    const [ purchaseType, setPurchaseType ] = useState('cash');
    const [ requiresInvoice, setRequiresInvoice ] = useState( false );
    const [ seller, setSeller ] = useState( user.name );
    const [ selectedClient, setSelectedClient ] = useState( user );

    // Hook states and variables.
    const [ applyClientDiscount, setApplyClientDiscount ] = useState( false );
    const [ clientsState, setClients ] = useState([ ...clients ]);
    const [ payedAccount, setPayedAccount ] = useState(false);
    const [ paymentStarted, setPaymentStarted ] = useState(false);
    const [ ticketPaymentData, setTicketPaymentData ] = useState({});
    const [ ticketType, setTicketType ] = useState('original');
    const [ total, setTotal ] = useState(totalInitialState);

    useEffect( async () => {
        const tempClients = await getClients();
        setClients( tempClients );
    }, []);

    useEffect(() => {
        setPurchaseType('cash');
        setCreditPayment(false);
        setApplyClientDiscount( false );
    }, [ selectedClient ]);

    useEffect(() => {
        calculateTotal();
    }, [ cartTotal, purchaseType, creditPayment, applyClientDiscount, total.discountPercentage ]);

    /* 
     *    Calculate total based on cart total.
     */
    const calculateTotal = () => {  
        let totalTemp = 0;
        if( purchaseType === 'cash' || ( purchaseType === 'credit' && !creditPayment )) {
            totalTemp = cartTotal.contado;
        } else if( purchaseType === 'credit' && creditPayment ) {
            totalTemp = cartTotal.credito;
        }

        let clientDiscount = ( totalTemp * ( total.discountPercentage / 100 ));
        const newTotals = {
            total: totalTemp - clientDiscount,
            subtotal: totalTemp * 0.84,
            iva: totalTemp * 0.16,
            discount: clientDiscount,
            discountPercentage: total.discountPercentage,
        }
        setTotal( newTotals );
    }

    const deleteProductFromStock = ( soldProducts ) => {
        return new Promise(async (resolve, reject) => {
            soldProducts.forEach( soldProduct => {
                handleProductSold( soldProduct.id, soldProduct.amount );
            })

            resolve( true );
        })
    }

    /* 
     *    Find client in clients array through id;
     *    @param { string } id - Client's ID.
     */
    const findClient = ( id ) => {
        const foundClient = clients.find(client => client.id === id);
        return foundClient || {};
    }

    const finishPurchase = ( ) => {
        cleanCart().then(res => {
            setPayedAccount( false );
        }).catch(e => console.error(new Error(e)))
    }

    /*
     *    Handle the Client's react select.
     *    @param { object } client. The selected client object.
     */
     const handleClientChange = ( client ) => {
        if( client.value !== 'public' ) {
            const clientData = findClient( client.value );
            console.log(clientData);
            const { name, discount, creditAuthorized, email, rfc, phoneNumber, cp } = clientData;
            setSelectedClient({
                name: name,
                discount: discount,
                id: client.value,
                selected: true,
                creditAuthorized: (creditAuthorized !== undefined) ? creditAuthorized : true,
                email: email, 
                rfc: rfc,
                phoneNumber: phoneNumber,
                cp: cp,
            });
        } else {
            setSelectedClient({ id: 'public' });
            setRequiresInvoice( false );
        }
    }
    
    /*
     *    Handle the checkbox change 'Cobrar a precio de crédito'.
     *    @param { event } e. Checkbox event.
     */
    const handleCreditPaymentChange = ({ target: { checked } }) => {
        setCreditPayment( !!checked ? true : false );
    }

    /*
     *    Handle the input discount change'.
     *    @param { event } e. Input event.
     */
    const handleDiscountChange = ({ target: { value } }) => {
        if( value >= 0 ) {
            setTotal({
                ...total,
                discountPercentage: Number( value ),
            })
        } else {
            setTotal({
                ...total,
                discountPercentage: 0,
            })
        }
    }

    /*
     *    Handle the react select input invoice type.
     *    @param { event } e. Select event.
     */
    const handleInvoiceTypeChange = ({ label, value }) => {
        setInvoiceType( value );
    }

    /*
     *    Handle when the user clicks on 'Cobrar'.
     */
    const handlePayment = () => {
        const buildParams = {
            cart,
            creditPayment,
            paymentType,
            purchaseType,
            requiresInvoice,
            selectedClient,
            total,
            user,
            seller,
        }
        
        console.log(selectedClient);

        if( ((requiresInvoice === true && invoiceType !== '' && selectedClient.id !== 'public') ) || !requiresInvoice ) {
            setPaymentStarted( true );
            buildPaymentData( buildParams ).then(async res => {
                console.log( res );
                const paymentDataRes = res;
                const purchaseID = String(new Date().getTime());
                
                const invoice = { ...invoiceData };
                invoice.total = paymentDataRes.total;
                invoice.purchaseID = purchaseID;
                invoice.date = new Date();
                invoice.use = invoiceType || 'Por definir';
                
                paymentDataRes.folio = folio + 1;
                invoice.folio = folio + 1;
                setPaymentData({ ...paymentDataRes });
                await registerSaleDataBase( paymentDataRes, purchaseID, handleNewSale );
                if( requiresInvoice && invoice.id !== 'public' ) {
                    await registerInvoice( invoice, purchaseID );
                }
                const newFolio = folio + 1;
                await updateFolio( newFolio )
                    .then(async () => {
                        // await removeProductsFromStock( cart )
                        await deleteProductFromStock( cart )
                            .then( async () => {
                                await registerSpecialProduct( cart, paymentDataRes );
                                setPayedAccount( true );
                                setPaymentStarted( false );
                            })
                            .catch(e => {
                                console.error(new Error(e));
                            })

                        if( paymentDataRes.creditPayed === true ) {
                            await saveSaleStatistics( paymentDataRes.total, paymentDataRes.purchaseType, paymentDataRes.invoiceRequired, paymentDataRes.paymentType );
                            await saveSellerStatistics( purchaseID, paymentDataRes.total, user.uid );
                        }
                        
                    }).catch(err => {
                        console.error(err)
                        setPaymentStarted( false );
                });
                      
            });
        } else {
            AlertFailed(
                'Selecciona un uso para la factura',
                'Para continuar, debes seleccionar el uso de la factura.'
            )
        }
    }

    /*
     *    Handle the checkbox change 'Pago en efectivo y tarjeta'.
     *    @param { event } e. Checkbox event.
     */
    const handlePaymentTypeChange = ({ target: { checked } }, field ) => {
        if( checked && field === 'cash' ) {
            setPaymentType('cash');
        } else if( checked && field === 'card' ) {
            setPaymentType('card');
        }
    }

    /*
     *    Handle the checkbox change 'Compra a crédito'.
     *    @param { event } e. Checkbox event.
     */
    const handlePurchaseTypeChange = ({ target: { checked } }) => {
        if( checked && selectedClient === 'public') {
            AlertFailed(
                '¡Selecciona un cliente!',
                'Para realizar una compra a crédito deberás seleccionar un cliente autorizado.'
            )
            setPurchaseType('cash');
        } else {
            setPurchaseType( !!checked ? 'credit' : 'cash' );
            if( checked ) setCreditPayment( true );
        }
    }

    /*
     *    Handle the checkbox change 'Compra a crédito'.
     *    @param { event } e. Select event.
     */
    const handleSellerChange = ({ label, value }) => {
        setSeller( value );
    }

    const print = useReactToPrint({
		content: () => componentRef.current,
	})


    const printVoucher = useReactToPrint({
		content: () => voucherRef.current,
	})

    const printQuote = useReactToPrint({
        content: () => quoteRef.current
    })

    const handlePrint = () => {
        const ticketParams = {
            cart,
            paymentData,
            total, 
            user,
        }
		createTicketData( ticketParams ).then(res => {
			setTicketPaymentData(res);
			print();	
		}).catch(e => console.error(new Error(e)));
    }

    /*
     *    Handle the checkbox change 'Require factura'.
     *    @param { event } e. Checkbox event.
     */
    const handleRequiresInvoiceChange = ({ target: { checked } }) => {
        setRequiresInvoice( !!checked ? true : false );
        setInvoiceData({
            ...invoiceData,
            name: selectedClient.name,
            rfc: selectedClient.rfc,
            email: selectedClient.email,
            postCode: selectedClient.cp,
            phoneNumber: selectedClient.phoneNumber,
        })
    }

    const states = {
        applyClientDiscount,
        clients: clientsState,
        componentRef,
        creditPayment,
        payedAccount,
        paymentStarted,
        paymentType,
        purchaseType,
        requiresInvoice,
        selectedClient,
        ticketPaymentData,
        ticketType,
        total,
        quoteRef,
        voucherRef,
    }

    const stateUpdaters = {
        finishPurchase,
        handleClientChange,
        handleCreditPaymentChange,
        handleDiscountChange,
        handleInvoiceTypeChange,
        handlePayment,
        handlePaymentTypeChange,
        handlePurchaseTypeChange,
        handlePrint,
        handleRequiresInvoiceChange,
        handleSellerChange,
        printQuote,
        printVoucher,
        setCreditPayment,
        setPurchaseType,
        setTicketType,
    }
    
    return [states, stateUpdaters];
    
}
