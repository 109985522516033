import React, { useContext } from 'react'
import { ProductContext } from '../../Context/ProductContext';
import { SalesProductCard } from '../VentasComponents/SalesProductCard/SalesProductCard'

export const PaymentProductsList = () => {

    const { addToCart, searchTerm, showProducts } = useContext(ProductContext);

    return (
        <div className={` row pb-2 mt-3 `}>
            <p 
                className = ' text_instructions mb-1 w-100 text-end '
            >
                { showProducts.length } productos encontrados...
            </p>
            { showProducts.map((product, index) => (
                <React.Fragment key = { index }>
                    { searchTerm === '' && (
                        <div className=' col-6 col-md-6 col-lg-4 px-1 pb-3 ' >
                            <SalesProductCard 
                                product = { product } 
                                addToCart = { () => addToCart( product ) }
                            />
                        </div>
                    )}
                    { searchTerm !== '' &&
                        (
                            (product.nombre.toLowerCase()).includes(searchTerm.toLowerCase()) ||
                            (product.pieza.toLowerCase()).includes(searchTerm.toLowerCase())
                        ) && (
                            <div className=' col-6 col-md-6 col-lg-4 px-1 pb-3 '>
                                <SalesProductCard 
                                    product = { product } 
                                    addToCart = { () => addToCart( product ) }
                                />
                            </div>
                        )
                    }
                </React.Fragment>
            ))}
            
        </div>
    )

}
