import React from 'react'
import { formatMoney } from '../../helpers/formatMoney';

export const IncomeStatisticsDetail = ({ date, selectedSale }) => {

    const { 
        day, 
        sales,
        salesCash,
        salesCredit,
        salesInvoiceCard,
        salesInvoiceCash,
        salesNoInvoiceCard,
        salesNoInvoiceCash,
        total, totalCash, totalCredit
    } = selectedSale;

    if( selectedSale.total === 0 ) {
        return (
            <div className = ' detail_container '>
                <div className = ' detail_header '>
                    <div className = {` icons_purple-icon `}>
                        <ion-icon name = 'cash-outline'></ion-icon>
                    </div>
                    <div className = ' flex-grow-1 '>
                        <p>
                            Venta del { day }/{ date.month }/{ date.year }
                        </p>
                    </div>
                </div>
                <hr />
                <div className = ' row pe-4 '>
                    <p>No hay ventas registradas este día.</p>
                </div>
            </div>
        )
    }

    return (
        <div className = ' detail_container '>
            <div className = ' detail_header '>
                <div className = {` icons_purple-icon `}>
                    <ion-icon name = 'cash-outline'></ion-icon>
                </div>
                <div className = ' flex-grow-1 '>
                    <p>
                        Venta del { day }/{ date.month }/{ date.year }
                    </p>
                </div>
            </div>
            <hr />
            <div className = ' row px-4 '>
                <div className = ' col-4 pe-2 mt-2 '>
                    <p className = ' text_bold-label '>Total de venta</p>
                    <p className = ' text_data '>{ formatMoney( total ) }</p>
                </div>
                <div className = ' col-4 pe-2 mt-2 '>
                    <p className = ' text_bold-label '>Total al público</p>
                    <p className = ' text_data '>{ formatMoney( salesNoInvoiceCard + salesNoInvoiceCash ) }</p>
                </div>
                <div className = ' col-4 pe-2 mt-2 '>
                    <p className = ' text_bold-label '>Total facturado</p>
                    <p className = ' text_data '>{ formatMoney( salesInvoiceCard + salesInvoiceCash ) }</p>
                </div>
                
                <div className = ' col-4 pe-2 mt-4 '>
                    <p className = ' text_bold-label '>Total en efectivo</p>
                    <p className = ' text_data '>{ formatMoney( salesNoInvoiceCash + salesInvoiceCash ) }</p>
                </div>
                <div className = ' col-4 pe-2 mt-4 '>
                    <p className = ' text_bold-label '>Venta al público efectivo</p>
                    <p className = ' text_data '>{ formatMoney( salesNoInvoiceCash ) }</p>
                </div>
                <div className = ' col-4 pe-2 mt-4 '>
                    <p className = ' text_bold-label '>Facturado en efectivo</p>
                    <p className = ' text_data '>{ formatMoney( salesInvoiceCash ) }</p>
                </div>

                <div className = ' col-4 pe-2 mt-4'>
                    <p className = ' text_bold-label '>Total con tarjeta</p>
                    <p className = ' text_data '>{ formatMoney( salesNoInvoiceCard + salesInvoiceCard ) }</p>
                </div>
                <div className = ' col-4 pe-2 mt-4 '>
                    <p className = ' text_bold-label '>Venta al público tarjeta</p>
                    <p className = ' text_data '>{ formatMoney( salesNoInvoiceCard ) }</p>
                </div>
                <div className = ' col-4 pe-2 mt-4 '>
                    <p className = ' text_bold-label '>Facturado con tarjeta</p>
                    <p className = ' text_data '>{ formatMoney( salesInvoiceCard ) }</p>
                </div>
                
            </div>
        </div>
    )
}
