import React, { useEffect, useRef, useState } from 'react'

import { CalculateDiscount } from './CalculateDiscount';
import { CobrarModalMonto } from './CobrarModalMonto';
import { ChoosePaymentMethod } from './ChoosePaymentMethod';
import { Modal } from '../../Modal/Modal';
import { RequiresInvoice } from './RequiresInvoice';
import { SelectFrequentClient } from './SelectFrequentClient';
import { usePurchase } from '../../../hooks/usePurchase';

import './CobrarModal.css';
import { TicketPrint } from '../TicketPrint/TicketPrint';

const invoiceInitialState = {
	name: '',
	rfc: '',
	email: '', 
	use: '', 
	saleID: '', 
	postCode: '',
	phoneNumber: '',
}

export const CobrarModal = React.memo(({ closeModal, purchaseType }) => {

	const [ creditPayment, setCreditPayment ] = useState((purchaseType === 'cash') ? false : true);

	const { state, stateUpdater } = usePurchase( purchaseType, creditPayment, closeModal );
	const { 
		ammounts, applyFrequentDiscount, clients, componentRef, moneyType, frequentClient, selectedClient,
		ticketPaymentData, payedAccount,
	} = state;
	const { 
		handleChangeCalculation, handleClientSelection, handleDiscountChange, setApplyFrequentDiscount, 
		setAsPayed, setMoneyType, setAmmounts, setFrequentClient, handlePrint, finishPurchase,
		creditUnauthorizedMessage
	} = stateUpdater;

	const [ invoiceData, setInvoiceData ] = useState(invoiceInitialState);
	const [ requieresInvoice, setRequieresInvoice ] = useState(false);
	const [ openModal, setOpenModal ] = useState(false); 
	const [ loadPay, setLoadPay ] = useState(false);
	const [ ticketType, setTicketType ] = useState('original');
	const [ pagareName, setPagareName ] = useState('CLIENTE');

	useEffect(() => {
        if( requieresInvoice ) setOpenModal( true );
    }, [ requieresInvoice ]);

	/*
     *   useEffect to listen when a client is selected, and
     *   validate if he is allow to purchase credit.
     */
	 useEffect(( ) => {
		if( selectedClient.name === '') return;
		if( !selectedClient.creditAuthorized && purchaseType === 'credit' ) {
			creditUnauthorizedMessage( closeModal );
		}
	}, [ selectedClient ]);

	/*
     *   useEffect to listen when a client is selected, and
     *   set its data as invoiceData.
     */
	useEffect(() => {
		if( selectedClient.name !== '') {
			setInvoiceData({
				...invoiceData,
				name: selectedClient.name,
				email: selectedClient.email,
				phoneNumber: selectedClient.phoneNumber,
				rfc: selectedClient.rfc,
				postCode: selectedClient.cp,
			})
		}
	}, [ selectedClient ]);

	/* 
	 *  Define if the user is ready to continue to purchase.
	 */
	const continuePurschase = ( ) => {
		if(purchaseType === 'credit') {
			if( selectedClient.name !== '' && selectedClient.name !== undefined && purchaseType === 'credit') {
				setAsPayed( invoiceData, requieresInvoice );
				setLoadPay(true);
			} else  {
				alert('Selecciona un cliente registrado');
			}
		} else if( purchaseType === 'cash' ) {
			setAsPayed( invoiceData, requieresInvoice );
			setLoadPay(true);
		}
	}

	return (
		<div className={` modal NuevoProductoModal-container `}>
			<div className={` modal-content `}>

				<div className={` modal-header `}>
					<p className='section-title'>Cobrar Venta.</p>
				</div>

				<div className={` modal-body container `}>
					{ !payedAccount && (
						<div className = 'row'>
							<div className = ' col-12 col-sm-6 pe-sm-3 '>
								{ purchaseType === 'credit' && (
									<div className = ' mb-3 '>
										<div 
											className = {` CreditPaymentButton ${ creditPayment && 'active' } `} 
											onClick = { () => setCreditPayment(state => !state )}
										>
											Cobrar a precio de crédito: { creditPayment ? 'Sí' : 'No' }
										</div>
									</div>
								)}
								<ChoosePaymentMethod
									ammounts = { ammounts }
									moneyType = { moneyType }
									handleChangeCalculation = { handleChangeCalculation }
									setMoneyType = { setMoneyType }
								/>
								<CalculateDiscount handleDiscountChange = { handleDiscountChange } />
							</div>
							<div className = ' col-12 col-sm-6 ps-sm-3 mt-3 mt-sm-0 '>
								<SelectFrequentClient 
									applyFrequentDiscount = { applyFrequentDiscount }
									clients = { clients }
									frequentClient = { frequentClient }
									selectedClient = { selectedClient }
									handleClientSelection = { handleClientSelection }
									setApplyFrequentDiscount = { setApplyFrequentDiscount }
									setFrequentClient = { setFrequentClient }
								/>
								<div className = ' mt-3 '>
									<div
										className = {`ChargeModal-payment-type ${ ( requieresInvoice ) && 'active' }`}
										onClick = { () => {
											setRequieresInvoice(state => {
												(state) && setInvoiceData(invoiceInitialState);
												return !state;
											}) 
										}}
									>
										<ion-icon name="reader-outline"></ion-icon>
										<span>Requiere factura</span>
									</div>
								</div>

								<div className = ' px-3 mt-4 '>
									<hr />
									<CobrarModalMonto 
										ammounts = { ammounts }
										applyFrequentDiscount = { applyFrequentDiscount }
										creditPayment = { creditPayment }
										purchaseType = { purchaseType }
										selectedClient = { selectedClient }
										setAmmounts = { setAmmounts }
									/>
								</div>
							</div>
							
						</div>
					)}
					{ payedAccount && (
						<div className = ' d-flex align-items-center justify-content-center py-4 '>
							<p>Venta registrada, imprime el ticket o termina para continuar.</p>
						</div>
					)}
				</div>

				<div>
					<div className = ' modal-footer '>

						{ (!payedAccount) && (
							<div className = ' row mx-1  '>
								<div className = ' col-6 '>
									<button
										type='reset'
										className = ' button cancel-button shadow w-100 d-flex justify-content-center'
										onClick = { closeModal }
									>
										Cancelar
									</button>
								</div>
								<div className = ' col-6 ps-2 '>
									<button
										className = { loadPay ? ' button white-button shadow ' : ` button purple-button shadow `}
										onClick = { continuePurschase }
										disabled = { loadPay }
									>
										CONTINUAR
									</button>
								</div>
							</div>
						)}
						{/* { (!payedAccount) && (
							<div className = ' row mx-1  '>
								<button
									type='reset'
									className={` button cancel-button shadow px-5 `}
									onClick = { closeModal }
								>
									Cancelar
								</button>
								<button
									className = {loadPay ? ' button white-button shadow px-5 ms-3 ' : ` button purple-button shadow px-5 ms-3 `}
									onClick = { continuePurschase }
									disabled = { loadPay }
								>
									CONTINUAR
								</button>
							</div>
						)} */}
						
						{ !!payedAccount && (
							<div className = ' row '>
								<button
									className = {` button cancel-button shadow px-5 mx-auto`}
									onClick = { finishPurchase }
								>
									TERMINAR
								</button>
								{ purchaseType !== 'credit' && (
									<button
										className = {` button purple-button shadow px-5 mx-auto mt-3 mt-sm-0 ms-sm-2 `}
										onClick = { handlePrint }
									>
										MANDAR A CAJA
									</button>
								)}
								{ purchaseType === 'credit' && (
									<>
										<button
											className = {` button purple-button shadow px-5 mx-auto `}
											onClick = { () => { 
												setTicketType('original');
												handlePrint();
											}}
										>
											IMPRIMIR ORIGINAL
										</button>
										<button
											className = {` button secondary-button shadow px-5 mx-auto `}
											onClick = { () => { 
												setTicketType('copy');
												handlePrint();
											}}
										>
											IMPRIMIR COPIA
										</button>
									</>
								)}
							</div>
						) }

					</div>

					<div style = {{ display: 'none' }}>
						<TicketPrint 
							ref = { componentRef } 
							{ ...ticketPaymentData }
							ticketType = { ticketType }
							pagareName = { selectedClient.name }
						/>
					</div>
				</div>
			</div>


			{( openModal ) && (
				<Modal>
					<RequiresInvoice 
						cancelInvoice = { () => setRequieresInvoice(false)  }
						closeModal = { () => setOpenModal(false) }
						invoiceData = { invoiceData }
						moneyType = { moneyType }
						saveData = { setInvoiceData }
					/>
				</Modal>	
			)}


		</div>
	)
})
