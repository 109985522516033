import React, { useContext } from 'react';
import { ProductContext } from '../../../Context/ProductContext';
import { useAuth } from '../../../hooks/useAuth';

import './ProductVoucher.css';

export const ProductVoucher = React.forwardRef(( props, ref ) => {

    const { userInfo } = useAuth();
    const { cart } = useContext(ProductContext);
    const date = new Date();
    const today = `${ date.getDate().toString().padStart(2, '0') }/${ (date.getMonth() + 1).toString().padStart(2, '0') }/${ date.getFullYear() }`;
    const baseHours = date.getHours();
    const hours = ( baseHours < 10) ? `0${ baseHours }` : `${ baseHours }`;
    const baseMinutes = date.getMinutes();
    const minutes = ( baseMinutes < 10 ) ? `0${ baseMinutes }` : `${ baseMinutes }`;
    const time = `${ hours }:${ minutes }`;
    
    return (
        <div ref = { ref } className = 'ProductVoucher-ticket-content'>
            <div className = "ProductVoucher-container">
        
                <div id = "ProductVoucher-ticket">
            
                    <div className = 'ProductVoucher-info-container'>
                        <p className = "ProductVoucher-title">VALE DE ALMACEN</p>
                        <p className = 'ProductVoucher-info my-1' >DURANGO, DGO A { today }</p>
                        <p className = 'ProductVoucher-info mb-1'>Hora: { time }</p>
                        
                        <hr className = 'ProductVoucher-info-separator'></hr>
            
                        <div>
                            <table className = 'ProductVoucher-info-table'>
                                <thead>
                                    <tr>
                                        <th>CANT.</th>
                                        <th>PRODUCTO</th>
                                    </tr>
                                </thead>
                            </table>  
                            { cart.map((product, i) => (
                                ( !product.id.includes('generic-stock') ) && (
                                    <div key = { i }>
                                        <table className = 'ProductVoucher-info-table'>
                                            <tbody>
                                                <tr>
                                                    <td>{ product.amount }</td>
                                                    <td>{ product.code }</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <p className = ' ProductVoucher-product-name '>{ product.name }</p>    
                                    </div>
                                )
                            )) }
                        </div>
            
                    </div>

                    <br/>
                    <p className = 'ProductVoucher-info mt-2 text-center'>SOLICITO: { userInfo.name }</p>
                    
                    <br/>
                    <hr className = 'ProductVoucher-signature' />

                    <p className = 'ProductVoucher-info text-center'>NOMBRE Y FIRMA</p>
                </div>
            </div>
        </div>
    )
    
})
