import firebase from 'firebase/compat/app';
import { sales } from '../../db/sales';

/* 
 *      Functions that gets the first x sales matching a period of two dates, and limit.
 *      @params { date } fromDate. 
 *      @params { date } toDate. 
 *      @params { number } limit. How many results do we wanna get.
 *      @params { array } clients. Existing clients already gotten by another function.
 */
export const getSalesByPeriod = ( fromDate, toDate, limit, clients ) => {
    return new Promise(async (resolve, reject) => {
        const toDateWithTime = new Date( toDate );
        toDateWithTime.setHours( 23, 59, 59, 999 );
        const fromDateQuery =  new Date( fromDate );
        const toDateQuery = new Date( toDateWithTime );
        
        const salesArray = [];
        sales.forEach((sale, index, array) => {
            if( sale.date >= fromDateQuery && sale.date <= toDateQuery ) {
                salesArray.push( sale )
            }
            if( index === array.length - 1) {
                resolve( salesArray );
            }
        })

    })
}
