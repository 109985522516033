import React, { useContext, useEffect, useState } from 'react';
import { ProductContext } from '../../../Context/ProductContext';
import { searchFamilies } from '../../../helpers/ProductFamilies';
import { SelectAplicacionFilter } from '../SelectAplicacionFilter/SelectAplicacionFilter';
import { SelectProductGroup } from '../SelectProductGroup/SelectProductGroup';
import Select from 'react-select';

export const InventarioSearch = ({  }) => {

    const { 
        getProductByCode, getProductsByFamily, getProductsByModel, searchProduct, showProducts, filter 
    } = useContext(ProductContext);
    const [ searchFamily, setSearchFamily ] = useState('');
    const [ searchType, setSearchType ] = useState('code');
    const [ group, setGroup ] = useState('');
    const [ code, setCode ] = useState('');

    useEffect(() => setSearchFamily('all'), [  ]);

    const handleCodeChange = ({ target: { value }}) => setCode(value);

    const handleKeyDown = ( event ) => {
        if ( event.key === 'Enter' ) {
            if( searchType === 'code' ) {
                getProductByCode( code, searchFamily );
            } else if( searchType === 'model' ) {
                getProductsByModel( code );
            } else if( searchType === 'family' ) {
                getProductsByFamily( searchFamily );
            }
        }
    }

    const handleFamilyChange = ({ label, value }) => {
        setSearchFamily( value );
    } 

    return (
        <div className = {` mb-2  `}>

            <div className = ' row mb-3 mx-0'>
                <div className = ' col-6 pe-1'>
                    <div 
                        className = {` white-purple-button ${ searchType === 'code' && 'active' } `}
                        onClick = { () => setSearchType('code') }
                    >
                            Buscar por pieza
                    </div>
                </div>
                <div className = ' col-6 ps-1'>
                    <div 
                        className = {` white-purple-button ${ searchType === 'model' && 'active' } `}
                        onClick = { () => setSearchType('model') }
                    >
                            Buscar por modelo
                    </div>
                </div>
            </div>

            { searchType === 'code' && (
                <div className = ' d-flex alig-items-center row mx-0 '>
                    <div className = ' col-4 pe-1 '>
                        <Select 
                            defaultValue = {{ label: 'TODOS LOS GRUPOS', value: 'all' }}
                            options = {[ { label: 'TODOS LOS GRUPOS', value: 'all' }, ...searchFamilies ]}
                            onChange = { handleFamilyChange }
                        />
                    </div>
                    <div className = ' col-8 ps-1 d-flex alig-items-center '>
                        <input 
                            className = ' search-bar flex-grow-1 '
                            onChange = { handleCodeChange }
                            placeholder = 'Busca un producto por número de pieza.'
                            onKeyDown = { handleKeyDown }
                        />
                        { code !== '' && (
                            <div className = ' d-flex ms-2 align-items-center '>
                                <div className = ' button purple-button ' onClick = { () => getProductByCode( code, searchFamily ) } >BUSCAR</div>
                            </div>
                        )}
                    </div>
                </div>
            )}


            { searchType === 'model' && (
                <div>
                    <div>
                        <SelectProductGroup setGroup = { setGroup } />
                    </div>
                    <div className = ' '>
                        <SelectAplicacionFilter 
                            forSearch = { true }
                        />
                    </div>
                    <input 
                        className = {` search-bar me-3 `} 
                        onChange = { searchProduct }
                        placeholder = 'Busca un producto por nombre o número de pieza.'
                        onKeyDown = { handleKeyDown }
                    />
                    { (filter.fromYear !== '') && (
                        <div 
                            className = ' button purple-button mt-2 '
                            onClick = { () => getProductsByModel( group ) }
                        >
                            Buscar
                        </div>
                    )}

                </div>
            )}

            { searchType === 'family' && (
                <div className = ' row mx-0'>
                    <div className = ' col-6 pe-1 '>
                        <Select 
                            options = { searchFamilies }
                            onChange = { handleFamilyChange }
                        />
                    </div>
                    <div className = ' col-6 ps-1 '>
                        { searchFamily !== '' && (
                            <div className = ' button purple-button ' onClick = { () => getProductsByFamily( searchFamily ) }>
                                BUSCAR
                            </div>
                        )}
                    </div>
                    { 
                        showProducts.length > 0 && (
                            <input 
                                className = {` search-bar me-3 mt-2 `} 
                                onChange = { searchProduct }
                                placeholder = 'Busca un producto por nombre o número de pieza.'
                                onKeyDown = { handleKeyDown }
                            />
                        )
                    }
                </div>
            )}

        </div>
    )
    
}
