import React from 'react'
import { useAuth } from '../../../hooks/useAuth'

export const ProfileInfoContainer = () => {

	const { userInfo } = useAuth();

	return (
		<div className = ' profile_info col '>
			<div className = ' profile_info-header '>
				<p className = ' h5 '>Mi Información</p>
				<p>Detalles de la cuenta.</p>
			</div>
			<div className = ' profile_info-data '>
				<div className = ' col '>
					<div className = ' py-3 '>
						<p className = ' text_bold-label '>Perfil</p>
						<p>{ userInfo.position }</p>
					</div>
					<div className = ' py-3 '>
						<p className = ' text_bold-label '>Teléfono</p>
						<p>{ userInfo.phoneNumber }</p>
					</div>
				</div>
				<div className = ' col '>
					<div className = ' py-3 '>
						<p className = ' text_bold-label '>Email</p>
						<p>{ userInfo.email }</p>
					</div>
					<div className = ' py-3 '>
						<p className = ' text_bold-label '>Dirección</p>
						<p>{ userInfo.address }</p>
					</div>
				</div>
			</div>
		</div>
	)
}
