import React, { useState, useEffect } from 'react'

export const UserDirectoryEdit = ({ user, editUser, removeUser }) => {

    const [ editableUser, setEditableUser ] = useState({
        userName: user.name,
        userPhoneNumber: user.phoneNumber,
        userPosition: user.position,
        userAddress: user.address,
    })

    const { userName, userAddress, userPosition, userPhoneNumber }  = editableUser; 

    useEffect(() => {
        setDefaultFormikValues();
    }, []);

    const setDefaultFormikValues = () => {
        editUser.setFieldValue(`userName`, userName);
        editUser.setFieldValue(`userPhoneNumber`, userPhoneNumber);
        editUser.setFieldValue(`userPosition`, userPosition);
        editUser.setFieldValue(`userAddress`, userAddress);
        editUser.setFieldValue(`docID`, user.id);
    }

    const handleInputChange = ( e, state ) => {
        setEditableUser({
            ...editableUser,
            [state]: e.target.value,
        })
        editUser.setFieldValue(`${state}`, e.target.value);
    }

    return (
       <>
            <form
                className = ' mt-4 '
                onSubmit = { editUser.handleSubmit }
            >

                <div className = ' input_input-container '>
                    <p>Nombre del Usuario</p>
                    <input 
                        onChange = { (e) => handleInputChange(e, 'userName') }
                        placeholder = 'Nombre del Cliente'
                        value = { userName }
                    />
                </div>
                
                <div className = ' input_input-container '>
                    <p>Dirección</p>
                    <input 
                        onChange = { (e) => handleInputChange(e, 'userAddress') }
                        placeholder = 'Dirección'
                        value = { userAddress }
                    />
                </div>

                <div className = ' input_input-container '>
                    <p>Encargado</p>
                    <input 
                        id = 'userPosition'
                        onChange = { (e) => handleInputChange(e, 'userPosition') }
                        placeholder = 'Encargado'
                        value = { userPosition }
                    />
                </div>

                <div className = ' input_input-container '>
                    <p>Teléfono</p>
                    <input 
                        onChange = { (e) => handleInputChange(e, 'userPhoneNumber') }
                        placeholder = 'Teléfono'
                        value = { userPhoneNumber }
                    />
                </div>

                <div className = 'd-flex mt-4'>
                    <p 
                        className = 'button cancel-button'
                        onClick = { () => removeUser( user.id ) }
                    >
                        Eliminar    
                    </p> 

                    <button 
                        className = 'button purple-button ms-3'
                        type = 'submit'
                    >
                        Guardar
                    </button>
                </div>
            </form>
        </>
    )

}
