import React from 'react';
import { SupportMenu } from '../../Components/SupportComponents/SupportMenuComponent/SupportMenu';
import '../../Theme/globalStyles.css'

export const SupportScreen = () => {

    return (
        <div className = ' mx-0 '>
            <div className = ' px-3 '>
                <SupportMenu/>
            </div>
        </div>
    );

};
