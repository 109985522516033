import React from 'react';

import { Link }from "react-router-dom";

export const SideNavigationItem = ({ active, icon, text, link, setActive }) => {

    return (
        <Link 
            to = { link } 
            className = {(active === text) ? ' sidenav_item-container sidenav_item-active' : ' sidenav_item-container '}
            onClick = { () => setActive(text) }
        >
            <div className = ' d-flex align-items-center sidenav_info '>
                <ion-icon name = { icon }></ion-icon>
                <p  className = ' sidenav_link-name mb-0 '>{ text }</p>
            </div>
        </Link>
    )

};