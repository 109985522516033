import React, { useContext, useRef, useState } from 'react';
import { AddSpecialProduct } from './../AddSpecialProduct/AddSpecialProduct';
import { CobrarModal } from '../CobrarModal/CobrarModal';
import { Modal } from './../../Modal/Modal';
import { ProductContext } from '../../../Context/ProductContext';
import { ProductVoucher } from '../ProductVoucherComponent/ProductVoucher';
import { ResumenVentaProductos } from './ResumenVentaProductos';
import { useReactToPrint } from 'react-to-print';

import './ResumenVenta.css';
import './../../../Theme/globalStyles.css'
import { QuoteVoucher } from '../QuoteVoucher/QuoteVoucher';
import { formatMoney } from '../../../helpers/formatMoney';

export const ResumenVenta = React.memo(({ closeModal }) => {

    const { addSpecialProduct, cart, cartTotal, cancelCart } = useContext(ProductContext);

    const [ openCobrarModal, setOpenCobrarModal ] = useState(false);
    const [ purchaseType, setPurchaseType ] = useState('cash');

    let componentRef = useRef();
    let quoteRef = useRef();

    const print = useReactToPrint({
		content: () => componentRef.current,
	})

    const printQuote = useReactToPrint({
        content: () => quoteRef.current
    })

    const EmptyCart = () => {

        return (
            <>
                <div className={` modal-header `}>
                    <p className='section-title'>Venta actual.</p>
                </div>
                <p>Aún no hay ningun producto seleccionado.</p>
                <p className='button purple-button text-center h5 me-3 mt-3' onClick = { closeModal }>Regresar</p>
            </>
        );

    }

    const SalesResume = () => {
        return (
            <>
                <div className={` modal-header d-flex align-items-center row `}>
                    <p className=' section-title h5 mb-2 mb-sm-0 col-12 col-sm-7 '>Venta actual</p>
                    <div className = ' ResumenVenta-totals col-12 col-sm-5 mx-1 mx-sm-0 row'>
                        <div className = ' col-12 col-sm-6 '>
                            <p 
                                className = ' button purple-button w-100 d-flex justify-content-center  '
                                onClick = { () => {
                                    setPurchaseType('cash');
                                    setOpenCobrarModal(true);
                                } }
                            >
                                Cobrar contado { formatMoney( cartTotal.contado ) }
                            </p>
                        </div>
                        <div className = ' col-12 col-sm-6 mt-3 mt-sm-0 ps-sm-2 d-flex '>
                            <p 
                                className = '  button secondary-button w-100 text-center d-flex justify-content-center '
                                onClick = { () => {
                                    setPurchaseType('credit');
                                    setOpenCobrarModal(true);
                                } }
                            >
                                Cobrar crédito { formatMoney( cartTotal.credito ) }
                            </p>
                        </div>
                    </div>
                </div>
                <div className={` modal-body `}>
                    <div className = {` ResumenVenta-container p-2 py-3 `}>

                        { cart.length > 0 && (
                           <div>
                                <div className = {` ResumenVenta-products `}>
                                    <ResumenVentaProductos />
                                </div>
                           </div>

                        )}
                        { cart.length === 0 && (
                            <p>Aun no hay productos en la venta actual.</p>
                        )}


                    </div>
                </div>

                {/* Footer for xs  */}
                <div className  = ' modal-footer d-sm-none '>
                    <div className = ' ResumenVenta-cta row mx-0 '>
                        <div className = ' col-6 col-sm-3  '>
                            <button 
                                className = ' button purple-button text-center h5 align-self-end w-100 d-flex justify-content-center  ms-sm-2 ' 
                                onClick = { print }
                            >
                                {/* <ion-icon size = 'small' name="print-outline"></ion-icon> */}
                                Imprimir Vale
                            </button>
                        </div>
                        <div className = ' col-6 col-sm-3  '>
                            <button 
                                className = 'button purple-button text-center h5 align-self-end w-100 d-flex justify-content-center ms-2 ' 
                                onClick = { printQuote }
                            >
                                {/* <ion-icon size = 'small' name="print-outline"></ion-icon> */}
                                Imprimir Cotización
                            </button>
                        </div>
                        <div className = ' col-6 col-sm-3  '>
                            <p 
                                className = ' button purple-button text-center h5 w-100 d-flex justify-content-center ' 
                                onClick = { closeModal }
                            >
                                SEGUIR VENDIENDO
                            </p>
                        </div>
                        <div className = ' col-6 col-sm-3  '>
                            <p 
                                className = ' button cancel-button h5 w-100 d-flex justify-content-center ms-2 ' 
                                onClick = { cancelCart }
                            >
                                Cancelar Compra
                            </p>
                        </div>
                    </div> 
                </div>
               
                {/* Footer for sm and upper.  */}
                <div className  = ' modal-footer d-none d-sm-block '>
                    <div className = ' ResumenVenta-cta '>
                        <div className = ' me-2 '>
                            <p 
                                className = ' button purple-button text-center h5 w-100 d-flex justify-content-center ' 
                                onClick = { closeModal }
                            >
                                SEGUIR VENDIENDO
                            </p>
                        </div>
                        <div className = ' me-2 '>
                            <p 
                                className = ' button cancel-button h5 w-100 d-flex justify-content-center ms-2 ' 
                                onClick = { cancelCart }
                            >
                                Cancelar Compra
                            </p>
                        </div>
                        <div className = ' me-2 '>
                            <button 
                                className = ' button purple-button text-center h5 align-self-end w-100 d-flex justify-content-center  ms-sm-2 ' 
                                onClick = { print }
                            >
                                Imprimir Vale
                            </button>
                        </div>
                        <div className = ' me-2 '>
                            <button 
                                className = 'button purple-button text-center h5 align-self-end w-100 d-flex justify-content-center ms-2 ' 
                                onClick = { printQuote }
                            >
                                Imprimir Cotización
                            </button>
                        </div>
                    </div> 
                </div>





                {/* Secciones de impresión  */}
                <div style = {{ display: 'none' }}>
                    <ProductVoucher 
                        ref = { componentRef } 
                    />
                </div>
                <div style = {{ display: 'none' }}>
                    <QuoteVoucher
                        ref = { quoteRef }
                        products = { cart }
                    />
                </div>
            </>
        );
    }
    
    return (
        <div className={` modal `}>
            <div className={` modal-content `}>
                { (cart.length === 0) && <EmptyCart /> }
                { (cart.length > 0) && <SalesResume /> }    
            </div>
            {(!!openCobrarModal) && (
                <Modal>
                    <CobrarModal
                        closeModal = { () => setOpenCobrarModal(false) }
                        purchaseType = { purchaseType }
                    />
                </Modal>
            )}
            { addSpecialProduct.active && (
                <Modal>
                    <AddSpecialProduct />
                </Modal>
            )}

        </div>

    );
})
