import React, { useEffect } from 'react';
import Select from 'react-select';
import { formatDate } from '../../helpers/formatDate';
import { formatMoney } from '../../helpers/formatMoney';
import { useClientHistory } from '../../hooks/useClientHistory';

export const UnsettledPurchaseHistory = React.memo(({ userID }) => {

    const { state, clientStateUpdater } = useClientHistory();

    const { filter, filterOptions, isLoading, sales, seeMore } = state;
    const { cancelPendingPayment, creditPayment, filterSelection, handleFilterChange, getClientCreditSales, getSeeMoreCreditSales, setClientSales } = clientStateUpdater;
    
    useEffect(() => {

        let unsubscribe = true
        
        filterSelection().then(res => {
            getClientCreditSales(userID, res.date, res.lastDate).then(response => {
                if(unsubscribe) {
                    setClientSales(response)
                }
            })
        })

        return () => unsubscribe = false

    }, [filter, userID]);

    return (
        <>
            <div> 
                <Select
                    defaultValue={filterOptions[0]}
                    onChange={handleFilterChange}
                    options={filterOptions}
                />
            </div>
            { (!isLoading && sales.length > 0) && (

                <div>
                    <table className = ' table text-center client_unsettled-table mt-3 '>
                        <thead>
                            <tr>
                                <th scope="col">Fecha</th>
                                <th scope="col">Folio</th>
                                <th scope="col">Total</th>
                                <th scope="col">Descuento</th>
                                <th scope="col">Productos</th>
                                <th scope="col">Factura</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                sales.map(sale => (
                                    <tr key = { sale.date }>
                                        <th scope="row">{ formatDate( sale.date ) }</th>
                                        <td>{ sale.folio }</td>
                                        <td>{ formatMoney( sale.total ) }</td>
                                        <td>{ formatMoney( sale.discount ) }</td>
                                        <td>{ sale.products }</td>
                                        <td>{ sale.invoice ? 'Sí' : 'No' }</td>
                                        <td><p className = ' btn purple-button client_unsettled-pay ' onClick = {() => creditPayment( sale.id )}>Pagar</p></td>
                                        <td className = ' client_unsettled-cancel ' onClick = {() => cancelPendingPayment(sale.user, sale.id)} ><ion-icon name="ban-outline"></ion-icon></td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    {(sales.length > 0 && !!seeMore) && (
                        <p 
                            className = ' btn purple-button mt-2 '
                            onClick = {() => { 
                                filterSelection().then(res => {
                                    getSeeMoreCreditSales( userID, res.date, res.lastDate )
                                })
                            }}
                        >Cargar Más</p>
                    )}
                </div>

            )}
            {(!isLoading && sales.length <= 0) && (
                <div className = ' pt-4 text-center '>
                    <p>No hay ventas registradas para este cliente.</p>
                </div>
            )}
            {(isLoading) && (
                <div className = ' pt-3 text-center '>
                    <p>Cargando...</p>
                </div>
            )}
        </>
    )
})
