
export const getFamilyCodeByName = ( name ) => {

    switch( name ) {
        case 'Varios': return '001'
        case 'Aceite': return '002';
        case 'Acumulador': return '003';
        case 'Eléctrico': return '004';
        case 'Suspensión y Dirección': return '005';
        case 'Motor': return '006';
        case 'Frenos': return '007';
        case 'Enfriamiento': return '008';
        case 'Clutch / Embrague / Transmisión': return '009';
        case 'Servicio / Afinación': return '010';
    }

}