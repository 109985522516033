import { createSlice } from "@reduxjs/toolkit";
import { usersDB } from "../../db/usersDB";

export const usersSlice = createSlice({
    name: 'users',
    initialState: {
        users: [ ...usersDB ],
    }, 
    reducers: {
        onAddNewUser: ( state, { payload }) => {
            state.users = [ ...state.users, payload ];
        },
        onDeleteUser: ( state, { payload }) => {
            const tempUsers = [...state.users];
            const userIndex = tempUsers.findIndex(user => user.id === payload);
            tempUsers.splice( userIndex, 1);
            state.users = [ ...tempUsers ];
        },
        onEditUser: ( state, { payload }) => {
            state.users = [ ...payload ];
        },
    },
});

export const { onAddNewUser, onDeleteUser, onEditUser } = usersSlice.actions;