import React, { useContext } from 'react'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { ProductContext } from '../../Context/ProductContext'
import { formatMoney } from '../../helpers/formatMoney';

export const PaymentResumenProducts = ({ PaymentResumeProps }) => {

    const MySwal = withReactContent(Swal)
    const { cart, addOnePiece, deleteCartProduct, deleteOnePiece, setCart } = useContext(ProductContext);
    const { 
        total,
    } = PaymentResumeProps;

    const handleSingleProductDiscount = async ( product ) => {
        const { code, name } = product;
        const { value } = await MySwal.fire({
            title: `Ingresa el descuento para ${ code } ${ name }`,
            input: 'number',
            inputLabel: 'Aplicar descuento en porcentaje, ejemplo: 10',
            showCancelButton: true,
            inputValidator: (value) => {
              if (!value) {
                return 'Necesitas asignar un descuento'
              } else {
                calculateProductPrice( product, value );
              }
            }
        })
    }

    const calculateProductPrice = ( product, discount ) => {
        console.log(product, discount);
        const productEditing = cart.find(cartProduct => cartProduct.id === product.id);
        const productIndex = cart.findIndex(cartProduct => cartProduct.id === product.id);
        const products = [ ...cart ];
        products[productIndex] = {
            amount: productEditing.amount,
            code: productEditing.code,
            description: productEditing.description,
            discount: Number( discount ),
            family: productEditing.family,
            id: productEditing.id,
            name: productEditing.name,
            price_cash: productEditing.price_cash,
            price_credit: productEditing.price_credit,
            currentExistence: productEditing.currentExistence,
        }
        setCart([ ...products ]);
    }


    return (
        <div className = ' col-12 '>

            <div className = ' px-4 w-100 d-flex justify-content-end mb-3 '>
                <p className = ' w-100 '>
                    <span>Total </span>
                    <span className = ' text_purple h5 '>
                        { formatMoney( total.total ) }
                    </span>
                </p>
                { total.discountPercentage > 0 && (
                    <p className = ' w-100 '>
                        <span>Descuento </span>
                        <span className = ' text_purple '>
                            { formatMoney( total.discount ) }
                        </span>
                    </p>
                )} 
            </div>
            
            { cart.length > 0 && (
                cart.map((product, index) => {
                    const { amount, code, discount, price_cash, price_credit } = product;
                    return (
                        <div key = { index } className = '  d-flex background-lightgrey align-items-center p-2 mb-1 '>
                            <div className = ' d-block w-100'>
                                <div className = ' d-flex row mx-0 w-100 '>
                                    <div className = ' col-5 d-flex align-items-center '>
                                        <p className = ' text_bold-label '>{ code }</p>
                                    </div>
                                    <div className = ' col-3 d-flex flex-column px-2 '>
                                        <p className = ' text_data '>{ formatMoney( price_cash * amount * ( 1 - ( discount / 100 )) ) }</p>
                                        <p className = ' text_data text_instructions text_purple '>
                                        { formatMoney( price_credit * amount * ( 1 - ( discount / 100 )) ) }
                                    </p>
                                    </div>
                                    <div className = ' col-4 d-flex align-items-center payment_cta-container '>
                                        <div className = ' product-counter-button d-flex align-items-center '>
                                            <ion-icon name="remove-circle-outline" onClick = { () => deleteOnePiece( product ) }></ion-icon>
                                        </div>
                                        <p className = 'mx-2' >{ product.amount }</p>
                                        <div className = ' product-counter-button d-flex align-items-center '>
                                            <ion-icon name="add-circle-outline" onClick = { () => addOnePiece( product ) }></ion-icon>
                                        </div>
                                        <div className = ' icons_delete-list-icon ms-2 ' >
                                            <ion-icon name="trash-bin-outline" onClick = { () => deleteCartProduct( product ) } ></ion-icon>
                                        </div>
                                    </div>
                                </div>
                                <div className = ' w-100 mt-1 row mx-0 '>
                                    <div className = ' col-6 '>
                                        <p className = ' text_instructions '>
                                            { product.name } - &nbsp; <br />
                                            { formatMoney( price_cash * amount ) } / &nbsp;
                                            { formatMoney( price_credit * amount ) }
                                        </p>
                                    </div>
                                    { discount === 0 && (
                                        <div 
                                            className = ' col-6 d-flex align-items-center pointer '
                                            onClick = { () => handleSingleProductDiscount( product ) }
                                        >
                                            <label className = ' text_instructions text-end pe-2 pointer underline-hover'>
                                                Aplicar Descuento
                                            </label>
                                            <div className = ' icons_apply-discount '>
                                                <ion-icon name="pricetag-outline"></ion-icon>
                                            </div>
                                        </div>
                                    )}
                                    { discount > 0 && (
                                        <div 
                                            className = ' col-6 d-flex align-items-center pointer '
                                            onClick = { () => calculateProductPrice( product, 0 ) }
                                        >
                                            <label className = ' text_delete text-end pe-2 pointer underline-hover'>
                                                Eliminar descuento { discount }%
                                            </label>
                                            <div className = ' icons_apply-discount '>
                                                <ion-icon name="pricetag-outline"></ion-icon>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>  
                        </div>
                    )
                })
            )} 
        </div>
    )
}
