import React, { useState } from 'react'
import { formatDate } from '../../helpers/formatDate'
import { formatMoney } from '../../helpers/formatMoney'

export const CancelSaleDetail = ({ selectedSale }) => {

    const {
        client,
        date,
        discount,
        invoiceRequired,
        paymentType,
        products,
        purchaseType,
        total,
        user,
    } = selectedSale.data();

    const generateTime = ( ) => {
        const baseHours = new Date( date.seconds * 1000 ).getHours();
        const hours = ( baseHours < 10) ? `0${ baseHours }` : `${ baseHours }`;
        const baseMinutes = new Date( date.seconds * 1000 ).getMinutes();
        const minutes = ( baseMinutes < 10 ) ? `0${ baseMinutes }` : `${ baseMinutes }`;
        
        return `${ hours }:${ minutes }`
    }

    const [ time, setTime ] = useState(generateTime( ));

    return (
        <div className = ' detail_container '>
            <div className = ' detail_header '>
                <div className = ' icons_purple-icon '>
                    <ion-icon name = 'cash-outline'></ion-icon>
                </div>
                <div className = ' flex-grow-1 '>
                    <p>
                        { selectedSale.data().folio }
                    </p>
                </div>
            </div>
            <hr />
            <div className = ' row px-4 '>
                <div className = ' col-4 pe-2 mt-2 '>
                    <p className = ' text_bold-label '>Fecha</p>
                    <p className = ' text_data '>{ formatDate( date ) }</p>
                </div>
                <div className = ' col-4 pe-2 mt-2 '>
                    <p className = ' text_bold-label '>Hora</p>
                    <p className = ' text_data '>{ time }</p>
                </div>
                <div className = ' col-4 pe-2 mt-2 '>
                    <p className = ' text_bold-label '>Tipo de cotización</p>
                    <p className = ' text_data '>{ (purchaseType === 'cash' ? 'Contado' : 'Crédito') }</p>
                </div>
                <div className = ' col-4 pe-2 mt-4 '>
                    <p className = ' text_bold-label '>Tipo de pago</p>
                    <p className = ' text_data '>{ (paymentType === 'cash' ? 'Efectivo' : 'Tarjeta') }</p>
                </div>
                <div className = ' col-4 pe-2 mt-4 '>
                    <p className = ' text_bold-label '>Total de venta</p>
                    <p className = ' text_data '>{ formatMoney( total ) }</p>
                </div>
                <div className = ' col-4 pe-2 mt-4 '>
                    <p className = ' text_bold-label '>Requerirá factura</p>
                    <p className = ' text_data '>{ invoiceRequired ? 'Sí' : 'No'}</p>
                </div>
                <div className = ' col-4 pe-2 mt-4 '>
                    <p className = ' text_bold-label '>Descuento</p>
                    <p className = ' text_data '>{ discount > 0 ? formatMoney( discount ) : 'Sin descuento' }</p>
                </div>
                <div className = ' col-4 pe-2 mt-4 '>
                    <p className = ' text_bold-label '>Productos</p>
                    <p className = ' text_data '>{ products.length }</p>
                </div>
                <div className = ' col-12 pe-2 mt-4 '>
                    <p className = ' text_bold-label '>Cliente</p>
                    <p className = ' text_data '>Público en General</p>
                </div>
            </div>
        </div>
    )
}