import React from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export const AlertConfirmation = ( title, onSuccess ) => {

    const MySwal = withReactContent(Swal)

    return MySwal.fire({
        title: <p>{ title }</p>,
        html: <i>¿Estás seguro que deseas realizar esta acción?</i>,
        icon: 'success',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
    }).then(res => {
        console.log('Clickeado terminar');
        if( res.isConfirmed ) {
            onSuccess();
        }
    })
}
