import React, { useContext } from 'react'
import { ProductContext } from '../../../Context/ProductContext';

export const SalesHeader = ({ openCart, openRandomProduct }) => {

    const { cart } = useContext(ProductContext);

    return (
        <div className = ' d-flex align-items-center py-4 '>
            
            <div className = ' header_title '>
                <h2 className = ' section-title mb-0 '>Ventas</h2>
                <p className = ' header_title-description '>Cotiza y realiza ventas de tus productos.</p>
                
            </div>

            <div className = ' d-flex '>
                <div 
                    className=' header-button me-2'
                    onClick = { openRandomProduct }
                >
                    <ion-icon name="shuffle-outline"></ion-icon>
                </div>
                <div 
                    className=' header-button me-2 position-relative'
                    onClick = { openCart }
                >
                    <ion-icon name="cart-outline"></ion-icon>
                    { (cart.length  > 0) && (
                        <span className = "position-absolute top-0 start-0 translate-middle badge rounded-pill bg-danger">
                            { cart.length }
                            <span className ="visually-hidden">productos en el carrito</span>
                        </span>
                    )}
                </div>
            </div>

        </div>
    )
}
