import firebase from 'firebase/compat/app';

/* 
 *    Function that registers on the data base the sale.
*     @param { string } purchaseID - purchaseID generated with date timestamp.
 *    @param { number } total - sale total. 
 *    @param { string } user - seller id. 
 */


export const saveSellerStatistics = ( purchaseID, total, user ) => {
    return new Promise(async (resolve, reject) => {

        const date = new Date();
        const today = date.getDate();
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
    
        let currentUserData = firebase.firestore().collection('usuarios').doc(user).collection('sales').doc(`${year}-${month}`)
        
        if(currentUserData) {
            await currentUserData.get().then(async res => {
                if(res.exists){
                    let salesArray = []
                    salesArray = res.data().data;
                    let todaySale = salesArray.find(sale => sale.day === today);
                    if(res.data()){
                        if( todaySale !== undefined ) {
                            let todaySaleIndex = salesArray.findIndex(sale => sale.day === todaySale.day );
                            let updateTodaySale = []
                            updateTodaySale = {
                                day: todaySale.day, 
                                total: todaySale.total + total, 
                                doneSales: todaySale.doneSales +1, 
                                sales: [...todaySale.sales, purchaseID],
                            }
                            salesArray[todaySaleIndex] = updateTodaySale
                            await currentUserData.update({ data: salesArray })
                        } else {
                            let newTodaySale = [];
                            let todaySale = {
                                day: today, 
                                total: total,
                                doneSales: 1,
                                sales: [purchaseID]
                            }
                            newTodaySale[0] = todaySale
                            await currentUserData.update({ data: firebase.firestore.FieldValue.arrayUnion(...newTodaySale)})
                        }
                    }
    
                } else {
                    //console.log('No encuentra el doc.')
                    let daySales = [];
                    let todaySale = {
                        day: today, 
                        total: total,
                        doneSales: 1,
                        sales: [purchaseID]
                    }
                    daySales[0] = todaySale
                    await currentUserData.set({ data: daySales })
                        .catch(e => console.log(e))
                }
            })
        }
    });
}
