import React, { useContext, useState } from 'react'
import { InputComponent } from '../InputComponent/InputComponent';
import { useCarNewBrand } from '../../hooks/CarComponentHooks/useCarNewBrand';

export const CarNewBrand = ({ closeModal }) => {

    const [ states, stateUpdaters ] = useCarNewBrand( closeModal );
    const { 
        addNewBrand,
        handleBrandChange,
    } = stateUpdaters;

    return (
        <div className={` modal NuevoProductoModal-container `}>
            <div className={` modal-content `}>

                <div className={` modal-header `}>
                    <p className='section-title'>Registrar nueva marca.</p>
                </div>
                <div className={` modal-body `}>
                    <InputComponent 
                        label = 'Nombre de la marca'
                        placeholder = 'Ingresa el nombre de la marca'
                        onChange = { handleBrandChange }
                    />
                </div>
                <div className={` modal-footer `}>
                    <button
                        type='reset'
                        className={` button cancel-button px-5 `}
                        onClick = { closeModal }
                    >
                        Cancelar
                    </button>
                    <button
                        className={` button purple-button shadow px-5 ms-3 `}
                        onClick = { addNewBrand }
                    >
                        Agregar
                    </button>
                </div>
            </div>
        </div>
    )
}
