import React, { useContext, useEffect, useState } from 'react'
import { ProductContext } from '../Context/ProductContext';
import { calculatePrices } from '../helpers/CalculatePrices';
import { getFamilyCodeByName } from '../helpers/getFamilyCodeByName';
import { getProductFamily } from '../helpers/getProductFamily';
import { useProducts } from './useProducts';

export const useDetalleInfoEditable = (producto, productoEditado, setProductoEditado, uploadImage, setUploadImage) => {

    const { loadSupplierData, loadSuppliers, allSuppliers } = useContext(ProductContext);
    const { aplications, deleteAplication, filter, setFilter } = useProducts();
 
    const { 
        descripcion, nombre, pieza, image, highRotation, family: familyFS = '110',
        costo: costoFS, precio_contado, precio_credito, proveedor, existencia,
    } = producto;
    
    const [ family, setFamily ] = useState(familyFS);
    const [proveedorData, setProveedorData] = useState('');
    const [ highRotationState, setHighRotationState ] = useState(highRotation);
    const [ costEdited, setCostEdited ] = useState( false );

    useEffect(() => {
        console.log(productoEditado);
        setProveedorData('');
        loadSupplierData(proveedor).then(res => {
            if(res !== undefined) {
                setProveedorData(res);
            }
        }).catch(() => {
            setProveedorData('');
        })
    }, []);

    useEffect(( ) => {
        setProductoEditado({
            ...productoEditado,
            highRotation: highRotationState,
        })
    }, [ highRotationState ]);

    useEffect(( ) => {
        setProductoEditado({
            ...productoEditado,
            family: String(family),
        })
      
    }, [family])

    useEffect(() => {
        if( costEdited && family !== null && family !== undefined ) {
            if( family ) {
                try {
                    const realFamily = getProductFamily( family );
                    const familyCode = getFamilyCodeByName( realFamily )
                    const { contado, credito } = calculatePrices( productoEditado.costo, familyCode );
                    setProductoEditado({
                        ...productoEditado,
                        precio_contado: contado,
                        precio_credito: credito,
                    })
                } catch(e) {
                    console.error(e);
                }
            }
        }
    }, [ productoEditado.costo ]);

    const handleProveedorChange = (e) => {
        const selected = e.value;
        setProductoEditado({
            ...productoEditado,
            proveedor: selected,
        });
    }

    const addNewAppExistingProduct = (brand, model, fromYear, toYear, engines) => {
        const aplicacion = {
            id: new Date().getTime(),
            brand, model, fromYear, toYear, engines
        }
        const nuevasAplicaciones = [...productoEditado.aplicaciones, aplicacion]
        setProductoEditado({
            ...productoEditado,
            aplicaciones: nuevasAplicaciones,
        });
    }

    const borrarAplicacion = (item) => {
        const nuevasAplicaciones = productoEditado.aplicaciones.filter( apps => (apps.id !== item.id));
        setProductoEditado({
            ...productoEditado,
            aplicaciones: nuevasAplicaciones,
        });
    }

    const handleCostChange = ({ target: { value }}) => {
        console.log( value );
        setCostEdited( true );
        setProductoEditado({
            ...productoEditado,
            costo: value,
        })
        // if( family ) {
        //     try {
        //         const realFamily = getProductFamily( family );
        //         const familyCode = getFamilyCodeByName( realFamily )
        //         const { contado, credito } = calculatePrices( value, familyCode );
        //         setProductoEditado({
        //             ...productoEditado,
        //             precio_contado: contado,
        //             precio_credito: credito,
        //         })
        //         // console.log( contado, credito );
        //     } catch(e) {
        //         console.error(e);
        //     }
        // }
    }

    const handleFamilyChange = ({ value }) => {
        setFamily( value );
    }
 
    const states = {
        aplications,
        allSuppliers,
        costoFS,
        descripcion, 
        existencia,
        familyFS,
        filter,
        highRotationState,
        image,
        nombre,
        pieza, 
        precio_contado,
        precio_credito,
        proveedorData,
    }

    const stateUpdaters = {
        addNewAppExistingProduct,
        borrarAplicacion,
        deleteAplication,
        handleCostChange,
        handleFamilyChange,
        handleProveedorChange,
        setFilter,
        setHighRotationState,
    }

    return [ states, stateUpdaters ];

}
