import firebase from 'firebase/compat/app'
import Compressor from 'compressorjs';

export const useUploadImage = () => {

	/*
	Compress Images to 80% before uploading
		Image: Blob or file type value
	*/

	const compressImage = (image) => {

	 	return new Promise((resolve, reject) => {

			new Compressor(image, {
				quality: 0.8,
				success: (compressedResult) => { 
				resolve(compressedResult)
				if(compressedResult === null || compressedResult === undefined){
					reject('https://firebasestorage.googleapis.com/v0/b/testingrefaccionariaogando.appspot.com/o/placeholders%2F%C3%B1.png?alt=media&token=5d322d97-c8a0-4ee9-962d-2e9f361cb110')
				}
			},
			});

		})

	}

	/* 
	Uploads Image to Storage
		Collection: The name of the collection in Storage
		DocID: The document ID in Storage
		Image: Blob or file type value
		Name: Name of the file that will be saved in Storage 
	*/

	const uploadToStorage = ( collection, docID, image, name ) => {

		const pictureRef = firebase.storage().ref(`${collection}/${docID}/${name}`)

		return new Promise((resolve, reject) => {
			compressImage(image).then(res => {
				pictureRef.put(res).then(snapshot => {
					resolve(pictureRef.getDownloadURL())
				})
			}).catch(e => { reject(console.log(e) )})
		})

		// return pictureRef.put(compressedImg).then(snapshot => {
		// 	console.log('File uploaded to Storage.');
		// 	return pictureRef.getDownloadURL()
		// })

	}

	/* 
	Uploads Image to Firestore
		Collection: The name of the collection in Storage
		DocID: The document ID in Storage
		Url: Url to the image file from Storage
	*/

	const uploadToFirestore = ( collection, docID, url ) => {

		return firebase.firestore().collection(collection).doc(docID).update({
			image: url
		}).then(async response => {
			//console.log('Se ha cambiado la imagen en Firestore.')
		}).catch(error => {
			console.log(error)
		})

	}
	
	const deleteImage = ( collection, docID, fileName ) => {
		
		return firebase.storage().ref(`${collection}/${docID}/${fileName}`).delete().then(() => {
			//console.log('Se ha eliminado la imagen de Storage.')
		}).catch(error => {
			console.log('Este usuario tenía la imagen por defecto.')
		})
	} 
	
	return {
		deleteImage,
		uploadToStorage,
		uploadToFirestore,
  	}

}
