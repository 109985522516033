import React, { useState, useEffect } from 'react'

export const ClientDirectoryEdit = ({ user, editClient, removeClient, uploadImage }) => {

    const [editableUser, setEditableUser] = useState({
        clientName: user.name,
        clientAddress: user.address,
        clientCP: user.cp || '',
        clientContactName: user.contactName,
        clientEmail: user.email,
        clientPhoneNumber: user.phoneNumber,
        clientDiscount:   user.discount,
        clientRFC: user.rfc,
        creditAuthorized: user.creditAuthorized,
    })

    const { 
        clientName, clientAddress, clientContactName, clientEmail, clientCP,
        clientPhoneNumber, clientDiscount, clientRFC, creditAuthorized 
    }  = editableUser; 

    useEffect(() => {
        setDefaultFormikValues();
    }, []);

    const setDefaultFormikValues = () => {
        editClient.setFieldValue(`clientName`, clientName);
        editClient.setFieldValue(`clientAddress`, clientAddress);
        editClient.setFieldValue(`clientCP`, clientCP);
        editClient.setFieldValue(`clientContactName`, clientContactName);
        editClient.setFieldValue(`clientEmail`, clientEmail);
        editClient.setFieldValue(`clientPhoneNumber`, clientPhoneNumber);
        editClient.setFieldValue(`clientDiscount`, clientDiscount);
        editClient.setFieldValue(`docID`, user.id);
        editClient.setFieldValue(`clientImage`, uploadImage);
        editClient.setFieldValue(`clientRFC`, clientRFC);
        editClient.setFieldValue(`creditAuthorized`, creditAuthorized);
    }

    const handleInputChange = ( e, state ) => {
        setEditableUser({
            ...editableUser,
            [state]: e.target.value,
        })
        editClient.setFieldValue(`${state}`, e.target.value);
    }

    return (
       <>
            <form
                className = ' mt-4 mb-5 '
                onSubmit = { editClient.handleSubmit }
            >

                <div className = ' input_input-container '>
                    <p>Nombre del cliente</p>
                    <input 
                        onChange = { (e) => handleInputChange(e, 'clientName') }
                        placeholder = 'Nombre del Cliente'
                        value = { clientName }
                    />
                </div>
                
                <div className = ' input_input-container '>
                    <p>Dirección</p>
                    <input 
                        onChange = { (e) => handleInputChange(e, 'clientAddress') }
                        placeholder = 'Dirección'
                        value = { clientAddress }
                    />
                </div>

                <div className = ' input_input-container '>
                    <p>Encargado</p>
                    <input 
                        id = 'clientContactName'
                        onChange = { (e) => handleInputChange(e, 'clientContactName') }
                        placeholder = 'Encargado'
                        value = { clientContactName }
                    />
                </div>
                
                <div className = ' input_input-container '>
                    <p>Correo</p>
                    <input 
                        onChange = { (e) => handleInputChange(e, 'clientEmail') }
                        placeholder = 'Correo'
                        value = { clientEmail }
                    />
                </div>
                
                <div className = ' input_input-container '>
                    <p>Correo</p>
                    <input 
                        onChange = { (e) => handleInputChange(e, 'clientCP') }
                        placeholder = 'Código Postal'
                        type = 'number'
                        value = { clientCP }
                    />
                </div>

                <div className = ' input_input-container '>
                    <p>Teléfono</p>
                    <input 
                        onChange = { (e) => handleInputChange(e, 'clientPhoneNumber') }
                        placeholder = 'Teléfono'
                        value = { clientPhoneNumber }
                    />
                </div>
                
                <div className = ' input_input-container '>
                    <p>RFC</p>
                    <input 
                        onChange = { (e) => handleInputChange(e, 'clientRFC') }
                        placeholder = 'RFC'
                        value = { clientRFC }
                    />
                </div>

                <div className = ' input_input-container '>
                    <p>Descuento</p>
                    <input 
                        onChange = { (e) => handleInputChange(e, 'clientDiscount') }
                        placeholder = 'Descuento'
                        value = { clientDiscount }
                    />
                </div>

                <p className = 'input-label mt-3' >Autorizar Crédito a Cliente</p>
                <div className = 'form-check form-switch'>
                    <input 
                        className = "form-check-input" 
                        id = "creditAuthorized" 
                        type = "checkbox" 
                        onChange = { editClient.handleChange } 
                        defaultChecked = { creditAuthorized }
                        value = { creditAuthorized }
                    />
                    <label className = "form-check-label" htmlFor = "creditAuthorized">
                        Crédito Autorizado
                    </label>
                </div>

                <div className = 'd-flex mt-4'>
                    <p 
                        className = 'button cancel-button'
                        onClick = { () => removeClient( user.id ) }
                    >
                        Eliminar    
                    </p> 

                    <button 
                        className = 'button purple-button ms-3'
                        type = 'submit'
                    >
                        Guardar
                    </button>
                </div>
            </form>
        </>
    )

}
