import firebase from 'firebase/compat/app';
import { sales } from '../../db/sales';

/* 
 *      Functions that gets the first x sales matching a minimum date, and limit.
 *      @params { date } dateWhere. 
 *      @params { number } limit. How many results do we wanna get.
 *      @params { array } clients. Existing clients already gotten by another function.
 */
export const getSalesByOneDate = ( dateWhere, sales ) => {
    return new Promise(async (resolve, reject) => {
        const dateWithTime = dateWhere.setHours( 0, 0, 0, 0 );
        const dateSearch = new Date( dateWithTime );    

        const salesArray = [];
        sales.forEach((sale, index, array) => {
            if( sale.date.getTime() >= dateSearch ) {
                salesArray.push( sale );
            }
            if( index === array.length - 1) {
                resolve( salesArray );
            }
        })
    })
}
