import React, { useContext, useEffect, useState } from 'react';

import { InputComponent } from '../InputComponent/InputComponent';
import { ProductContext } from '../../Context/ProductContext';
import { useCarModelDetail } from '../../hooks/CarComponentHooks/useCarModelDetail';

export const CarModelDetail = ({ selectedCar, setSelectedCar = { setSelectedCar }}) => {

    const [ editable, setEditable ] = useState(false);
    // const {  } = useContext( ProductContext );

    useEffect(() => {
        return () => setEditable(false);
    }, []);

    const SelectedCarFound = () => { 

        const NonEditableInfo = () => {
            return (
                <>
                    <div>
                        <p className = ' small '>
                            Años en los que está disponible este modelo.
                        </p>
                        <p className = ' pt-3 '>
                            <span className = ' bg-white p-2 rounded me-3 '>{ selectedCar.fromYear }</span>hasta
                            <span className = ' bg-white p-2 rounded ms-3 '>{ selectedCar.toYear }</span>
                        </p>
                    </div>
                    <div className = ' pt-4 '>
                        <h6 className = ' mb-3 '>Motores</h6>
                        { selectedCar.engines?.length > 0 && (
                            selectedCar.engines.map((engine, index) => (
                                <div className = ' d-flex bg-white py-3 px-2 rounded mb-2 ' key = { index } >
                                    <p className = ' pb-0 flex-grow-1 '>
                                        <span className = ' p-2 purple-button rounded me-2 '>{ engine.engine }</span> 
                                        { engine.fromYear } - { engine.toYear }
                                    </p>
                                    
                                </div>
                            ))
                        )}
                    </div>
                </>
            )
        }

        const EditableInfo = () => {

            const [ states, stateUpdaters ] = useCarModelDetail( selectedCar, setSelectedCar, setEditable);
            const { 
                addNewEngine,
                activateUpdate,
                activateEngineUpdate,
                currentEngineData,
                modelData,
            } = states;
            const { 
                deleteEngine,
                handleEngineAdd,
                handleInfoChange,
                updateEngines,
                updateInfo,
            } = stateUpdaters;

            return (
                <div>
                    <div>
                        <div className = ' my-3 '>
                                <p className = ' small '>Nombre del modelo</p>
                                <input 
                                    className = ' form-control ' 
                                    type = 'text'
                                    onChange = { e => handleInfoChange(e, 'model') }
                                    value = { modelData.model }
                                />
                            </div>
                        <p className = ' small '>
                            Años en los que está disponible este modelo.
                        </p>
                        <div className = '  '>
                            <div className = ' mt-2 '>
                                <p className = ' small '>Año mínimo</p>
                                <input 
                                    className = ' form-control ' 
                                    type = 'number'
                                    onChange = { e => handleInfoChange( e, 'fromYear') }
                                    value = { modelData.fromYear }
                                />
                            </div>
                            <div className = ' mt-2 '>
                                <p className = ' small '>Año máximo</p>
                                <input 
                                    className = ' form-control ' 
                                    type = 'number'
                                    onChange = { e => handleInfoChange( e, 'toYear') }
                                    value = { modelData.toYear }
                                />
                            </div>
                            { activateUpdate && ( 
                                <div 
                                    className = ' button purple-button mt-3'
                                    onClick = { updateInfo }
                                >
                                    Actualizar datos
                                </div> 
                            )}
                        </div>
                    </div>
                    <div className = ' pt-4 '>
                        <h6 className = ' mb-3 '>Motores</h6>
                        { modelData.engines?.length > 0 && (
                            modelData.engines.map((engine, index) => (
                                <div className = ' d-flex bg-white py-3 px-2 rounded mb-2 ' key = { index } >
                                    <p className = ' pb-0 flex-grow-1 '>
                                        <span className = ' p-2 purple-button rounded me-2 '>{ engine.engine }</span> 
                                        { engine.fromYear } - { engine.toYear }
                                    </p>
                                    <div className = ' icons_delete-list-icon '>
                                        <ion-icon 
                                            onClick={ () => deleteEngine(index) }
                                            name = "close-circle-outline"
                                        ></ion-icon>
                                    </div>
                                </div>
                            ))
                        )}
                        <div className = ' my-2 '>
                            { activateEngineUpdate && ( 
                                <div 
                                    className = ' button purple-button mt-3'
                                    onClick = { updateEngines }
                                >
                                    Actualizar motores
                                </div> 
                            )}
                        </div>
                        <div className = ' my-3 '>
                            <p className = ' small bold mb-2 ' >Agregar nuevo motor</p>
                            <InputComponent 
                                label = 'Nombre del motor'
                                placeholder = 'Nombre del motor'
                                onChange = { e => handleEngineAdd( e, 'engine' ) }
                                value = { currentEngineData.engine } 
                            />
                            <InputComponent 
                                label = 'Año mínimo'
                                placeholder = 'Año mínimo'
                                number = { true }
                                onChange = { e => handleEngineAdd( e, 'fromYear' ) }
                                value = { currentEngineData.fromYear} 
                            />
                            <InputComponent 
                                label = 'Año máximo'
                                placeholder = 'Año máximo'
                                number = { true }
                                onChange = { e => handleEngineAdd( e, 'toYear' ) }
                                value = { currentEngineData.toYear} 
                            />
                            <div
                                className = ' button purple-button my-2'
                                onClick = { addNewEngine }
                            >
                                Agregar Motor
                            </div>

                        </div>
                        
                    </div>
                </div>
            )
        }
        
        return (
            <div className = ' screen_section p-4 '>
                <div className = ' d-flex '>
                    <h4 className = ' flex-grow-1 '>{ selectedCar.model }</h4>
                    <p 
                        className = ' button purple-button '
                        onClick = { () => setEditable(!editable) }
                    >
                        { !editable ? 'Editar' : 'Cancelar Editable' }
                    </p>
                </div>
                <hr />
                { editable ? <EditableInfo /> : <NonEditableInfo /> }
                
            </div>
        )
    }

    const NoSelectedCar = () => {
        return (
            <div className = ' CarModelDetail p-4 '>
                <p className = ' px-2 pt-4 text-center '>Selecciona un modelo para continuar.</p>
            </div>
        )
    }

    return (
        <div>
            { selectedCar.model !== '' && <SelectedCarFound /> }
            { selectedCar.model === '' && <NoSelectedCar /> }

        </div>
    )
}
