import React, { useState } from 'react'
import { CSVLink } from "react-csv";
import { productFamily } from '../../../helpers/ProductFamilies'
import Select from 'react-select'
import { useInventory } from '../../../hooks/useInventory'


export const InventoryReportModal = ({ closeModal }) => {
 
    const { getFamilyProducts } = useInventory();
    const [ family, setFamily] = useState('');
    const [ reportName, setReportName ] = useState('inventario');
    const [ activateDownload, setActivateDownload ] = useState(false);
    const [ products, setProducts ] = useState([]);
    
    const handleFamilyChange = ({ label, value}) => {
        setActivateDownload( false );
        setFamily( value );
        const familyFound = productFamily.filter( family => family.value === value );
        if( familyFound.length > 0 ) {
            setReportName( familyFound[0].label )
        }
    }

    const headers = [
        { label: 'Pieza', key: 'pieza' },
        { label: 'Nombre', key: 'nombre' },
        { label: 'Precio Contado', key: 'precio_contado' },
        { label: 'Precio Credito', key: 'precio_credito' },
        { label: 'Existencia', key: 'existencia' },
      ];

    const generateFile = ( ) => {
        if( family !== '' ) {
            setActivateDownload(false);
            getFamilyProducts( family ).then(res => {
                console.log(res);
                setActivateDownload(true); 
                setProducts([...res]);
            });
        } else {
            alert('Para generar un reporte, selecciona un grupo.');
        }
    }

    const setProductsData = () => [...products];

    return (
        <div className = ' modal '>
            <div className = ' modal-content '>

                <div className = ' modal-header '>
                    <p className = 'section-title'>Descargar reporte de inventario por grupo.</p>
                </div>
                <div className = ' modal-body '>

                    <div className = ' mb-3 '>
                        <p className='section-subtitle'>Selecciona una familia</p>
                        <Select 
                            options = { productFamily } 
                            onChange = { handleFamilyChange }
                        />
                    </div>

                    <div className = ' d-flex '>
                        { !activateDownload && (
                            <div className = ' button purple-button ' onClick = { generateFile } >
                                Generar Reporte
                            </div> 
                        )} 

                        { !!activateDownload && (
                            <CSVLink 
                                data = { setProductsData() } headers = { headers }
                                filename = { `${ reportName }.csv` }
                                className = " button purple-button ms-3 "
                                onClick = { generateFile }
                                separator = ";"
                                enclosingCharacter={`"`}
                            >
                                { activateDownload && 'Descargar' }
                            </CSVLink>
                        )}
                    </div>



                </div>
                <div className = ' modal-footer '>
                    <button
                        className = ' button cancel-button shadow px-5 '
                        onClick = { closeModal }
                    >
                        Cerrar Ventana
                    </button>
                </div>
            </div>
        </div>
    )

}
