/*

    HOOK NAME: useClients.
    HOOK DESCRIPTION: Hook to edit, read and update clients.
    HOOK CONTENT:

    # HOOK CONFIG:
        - State Initialization.

    # FIRESTORE PRODUCT FUNCTIONS.
        - 

*/

import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import firebase from 'firebase/compat/app';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { useUploadImage } from './useUploadImage';
import { useClientsSlice } from './redux/useClientsSlice';
import imgPlaceholder from '../assets/placeholders/client.png'

export const useClients = () => {

	const MySwal = withReactContent(Swal)
    const { uploadToStorage, uploadToFirestore, deleteImage } = useUploadImage();
    const { clients, handleAddNewClient, handleDeleteClient, handleEditClient } = useClientsSlice();

    const [ clientSelected, setClientSelected ] = useState('');
    const [ clientsState, setClientsState ] = useState([]);
    const [ showClients, setShowClients ] = useState([]);
    const [ loading, setLoading ] = useState(true);

    const [ editable, setEditable ] = useState(false);
    const [ savedData, setSavedData ] = useState(false);
    const [ uploadImage, setUploadImage ] = useState('');

    useEffect(() => {
        if( clientSelected === '') return;
        if( editable ) setEditable( false );
    }, [ clientSelected ]);

	const getClients = () => {            
        setClientsState( [...clients ]);
        setShowClients([ ...clients ]);
        setLoading(false);
    }

	const formik = useFormik({
        initialValues: {
            clientName: '',
            clientPhoneNumber: '',
            clientContactName: '',
            clientEmail: '',
            clientAddress: '',
            clientCP: '',
            clientRFC: '',
            creditAuthorized: '',
            discount: '',
            image: imgPlaceholder,
        },
        validationSchema: Yup.object({
            clientName: Yup.string()
                        .min(3, 'Los nombres del cliente deben tener al menos 3 caracteres.')
                        .required('El nombre del cliente es obligatorio.'),
            clientPhoneNumber: Yup.string()
                        .min(10, 'El teléfono debe tener al menos 10 números.')
                        .required('El teléfono del usuario es obligatorio.'),
            clientContactName: Yup.string(),
            clientEmail: Yup.string()
                        .email('Ingrese un correo valido.')
                        .required('El correo del usuario es obligatorio.'),
            clientAddress: Yup.string(),
            clientCP: Yup.number(),
            clientRFC: Yup.string()
                        .min(12, 'El RFC debe contener entre 12 y 13 caracteres.')
                        .max(13, 'El RFC debe contener entre 12 y 13 caracteres.')
                        .required('El RFC del cliente es obligatorio.'),
            discount: Yup.number()
                        .typeError('El descuento debe ser un número.')
        }),
        onSubmit: client => {
            createClient( client );			
        }
    });

	const formikEdit = useFormik({
        initialValues: {
            clientName: '',
            clientPhoneNumber: '',
            clientContactName: '',
            clientEmail: '',
            clientAddress: '',
            clientDiscount: '',
            clientImage: '',
            clientRFC: '',
            creditAuthorized: '',
            clientCP: '',
        },
        validationSchema: Yup.object({
            clientName: Yup.string()
                        .min(3, 'Los nombres de usuario deben tener al menos 3 caracteres.')
                        .required('El nombre de usuario es obligatorio.'),
            clientPhoneNumber: Yup.string()
                        .min(10, 'El teléfono debe tener al menos 10 numeros.')
                        .required('El teléfono del usuario es obligatorio.'),
            clientContactName: Yup.string(),
            clientEmail: Yup.string()
                        .email('Ingrese un correo valido.')
                        .required('El correo del usuario es obligatorio.'),
            clientRFC: Yup.string()
                        .min(12, 'El RFC debe contener entre 12 y 13 caracteres.')
                        .max(13, 'El RFC debe contener entre 12 y 13 caracteres.')
                        .required('El RFC del cliente es obligatorio.'),
            clientAddress: Yup.string(),
            clientCP: Yup.number(),
            clientDiscount: Yup.number()
                        .typeError('El descuento debe ser un número.')
        }),
        onSubmit: client => {
            try {
                MySwal.fire({
                    title: <strong>¿Seguro que deseas actualizar?</strong>,
                    html: <i>Se actualizará el cliente { client.clientName }</i>,
                    confirmButtonText: 'Actualizar',
                    showCancelButton: true,
                    reverseButtons: true,
                    cancelButtonText: 'Regresar',
                    icon: 'info'
                }).then(response => {
                    if(response.isConfirmed) {
                        const clientData = {
                            name: client.clientName,
                            phoneNumber: client.clientPhoneNumber,
                            contactName: client.clientContactName,
                            email: client.clientEmail,
                            address: client.clientAddress,
                            discount: client.clientDiscount,
                            rfc: client.clientRFC,
                            creditAuthorized: client.creditAuthorized,
                            cp: Number(client.clientCP) || '',
                        }
                        handleEditClient( client.docID, clientData );

                        return MySwal.fire({
                            title: <p>Actualización con éxito</p>,
                            html: <i>Actualiza tus clientes para consultarlo.</i>,
                            icon: 'success',
                        }).then(() => {
                            getClients();
                            setEditable(false);
                        })

                        // if(uploadImage) {
                        //     try {
                        //         uploadToStorage('clients', client.docID, uploadImage, 'ClientPicture').then(url => {
                        //             uploadToFirestore('clients', client.docID, url)
                        //         })
                        //     } catch (error) {
                        //         console.log(new Error(error));
                        //     }
                        // }
                        // console.log(client.creditAuthorized)
                        // firebase.firestore().collection('clients')
                        //     .doc(client.docID)
                        //     .update({
                        //         name: client.clientName,
                        //         phoneNumber: client.clientPhoneNumber,
                        //         contactName: client.clientContactName,
                        //         email: client.clientEmail,
                        //         address: client.clientAddress,
                        //         discount: client.clientDiscount,
                        //         rfc: client.clientRFC,
                        //         creditAuthorized: client.creditAuthorized,
                        //         cp: Number(client.clientCP) || '',
                        //     })
                        //     .then(() => {
                        //         return MySwal.fire({
                        //             title: <p>Actualización con éxito</p>,
                        //             html: <i>Actualiza tus clientes para consultarlo.</i>,
                        //             icon: 'success',
                        //         }).then(() => {
                        //             getClients();
                        //             setEditable(false);
                        //         })
                        //     }).catch(e => {
                        //         console.error(e)
                        //         return MySwal.fire({
                        //             title: <p>Ha ocurrido un error</p>,
                        //             html: <i>Verifica tu conexión a internet, e intenta de nuevo.</i>,
                        //             icon: 'error',
                        //         })
                        //     })
                    }   
                })
            } catch (error) {
                console.log(error)
            }
        }
    });

    /* 
    *  Get text written into input.
    *  @param { object } user Formik user object.
    */
    const NewUrl = ( client ) => {
        return new Promise((resolve, reject) => {
            // const inicial = (client.clientName).trim().split('', 1).toString().toLowerCase()
            // console.log(inicial)
            // firebase.storage().ref('placeholders').child(`${inicial}.png`).getDownloadURL().then(data => {
            //     //console.log('Dentro del response');
            //     resolve(data)
            // }).catch(e => {
            //     reject('https://firebasestorage.googleapis.com/v0/b/pos-web-a98f9.appspot.com/o/placeholders%2FPerfil.jpeg?alt=media&token=a152dbaa-4d16-4bb3-aacd-ccecbfe317de')
            // })
            resolve(imgPlaceholder)
        })
    }

    /* 
     *  Upload new client to Firebase database, used inside formik.
     *  @param { client } object Formik created object.
     */

    const createClient = ( client ) => {
        const clientData = {
            id: clients.length + 1,
            name: client.clientName,
            phoneNumber: client.clientPhoneNumber,
            contactName: client.clientContactName,
            email: client.clientEmail,
            address: client.clientAddress,
            discount: client.discount,
            rfc: client.clientRFC,
            image: imgPlaceholder,
            creditAuthorized: client.creditAuthorized,
            cp: Number(client.clientCP) || ''
        }

        try {
            MySwal.fire({
                title: <strong>¿Seguro que deseas registrar cliente?</strong>,
                html: <i>Se registrará { client.clientName }</i>,
                confirmButtonText: 'Registrar',
                showCancelButton: true,
                reverseButtons: true,
                cancelButtonText: 'Regresar',
                icon: 'info'
            }).then(response => {
                if(response.isConfirmed) {
                    handleAddNewClient( clientData );
                    return MySwal.fire({
                        title: <p>Registrado con éxito</p>,
                        html: <i>Se pueden consultar los datos de los clientes.</i>,
                        icon: 'success',
                    }).then(() => {
                        setSavedData(true);
                        getClients();
                        formik.resetForm();
                    })
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    const removeClient = ( id ) => {
        MySwal.fire({
            title: <strong>¿Seguro que deseas eliminar?</strong>,
            html: <i>No podrás recuperar a este cliente.</i>,
            confirmButtonText: 'Eliminar',
            showCancelButton: true,
            reverseButtons: true,
            cancelButtonText: 'Regresar',
            icon: 'info'
        }).then(response => {
            if(response.isConfirmed) {
                handleDeleteClient( id );
                return MySwal.fire({
                    title: <p>Eliminado con éxito</p>,
                    html: <i>Has eliminado un cliente de tu lista.</i>,
                    icon: 'success',
                }).then(() => {
                    setEditable(false);
                    getClients();
                    setClientSelected('');
                })
            }
        });
    }

    const handleClientSearch = ( e ) => {
        const search = (e.target.value).toLowerCase();
        if(search === '') {
            setShowClients([...clientsState]);
        } else {
            let foundClients = clientsState.filter(client => (client.data().name).toLowerCase().includes(search));
            setShowClients([...foundClients]);
        }
    }

	return {
        clientSelected,
        clients: clientsState,
        editable,
        formik,
        formikEdit,
        loading,
        savedData,
        showClients,
        uploadImage,
        getClients,
        handleClientSearch,
        removeClient,
        setClientSelected,
        setEditable,
        setUploadImage,
    }
}
