
const image = 'https://firebasestorage.googleapis.com/v0/b/testingrefaccionariaogando.appspot.com/o/placeholders%2Fa.png?alt=media&token=380df832-e633-42a4-8e45-34627c98664b';

export const usersDB = [
    {
        id: 1,
        address: 'Durango, Dgo, México',
        email: 'contacto@avluetsoftware.com',
        image: image,
        name: 'Avluet Software',
        phoneNumber: '6181169096',
        position: 'Administrador',
    },
    {
        id: 2,
        address: 'Durango, Dgo, México',
        email: 'contacto@avluetsoftware.com',
        image: image,
        name: 'Avluet Ventas',
        phoneNumber: '6181169096',
        position: 'Ventas',
    },
    {
        id: 3,
        address: 'Durango, Dgo, México',
        email: 'contacto@avluetsoftware.com',
        image: image,
        name: 'Avluet Almacen',
        phoneNumber: '6181169096',
        position: 'Almacen',
    },
];