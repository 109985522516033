import React from 'react'

import { BrowserRouter, Link, Route, Routes } from 'react-router-dom'
import { SideNavigation } from '../../Components/SideNavigationComponents/SideNavigation/SideNavigation'
import { ClientsScreen } from '../../Screens/ClientsScreen/ClientsScreen'
import { HistoryScreen } from '../../Screens/HistoryScreen/HistoryScreen'
import { HomeScreen } from '../../Screens/HomeScreen/HomeScreen'
import { InventarioScreen } from '../../Screens/InventarioScreen/InventarioScreen'
import { PageNotFoundScreen } from '../../Screens/PageNotFoundScreen/PageNotFoundScreen'
import { ProfileScreen } from '../../Screens/ProfileScreen/ProfileScreen'
import { UsuariosScreen } from '../../Screens/UsuariosScreen/UsuariosScreen'
import { VentasScreen } from '../../Screens/VentasScreen/VentasScreen'
import { AccountingScreen } from '../../Screens/AccountingScreen/AccountingScreen'
import { SupportScreen } from '../../Screens/SupportScreen/SupportScreen'
import { CarModelsScreen } from '../../Screens/CarModelsScreen/CarModelsScreen'
import { ProductContext } from '../../Context/ProductContext'
import { PaymentScreen } from '../../Screens/PaymentScreen/PaymentScreen'

import { useCars } from '../../hooks/useCars'
import { useCart } from '../../hooks/useCart'
import { useSupplier } from '../../hooks/useSupplier'
import { useProducts } from '../../hooks/useProducts'
import { SuppliersScreen } from '../../Screens/SuppliersScreen/SuppliersScreen'
import { IncomeStatisticsScreen } from '../../Screens/IncomeStatisticsScreen/IncomeStatisticsScreen'
import { MobileNavigationLinks } from '../../Components/SideNavigationComponents/MobileNavigationLinks/MobileNavigationLinks'
import { CancelPurchases } from '../../Screens/CancelPurschases/CancelPurchases'

export const AppRoutes = ({ userType }) => {

	const { cars, showCars, getCars, setShowCars, } = useCars()

	const { 
		addSpecialProduct,
		cart, 
        cartTotal,
        addToCart,
        addOnePiece,
        cancelCart,
        cleanCart,
        deleteCartProduct,
        deleteOnePiece,
		setAddSpecialProduct,
		setCart,
	} = useCart();

	const {
		allSuppliers,
		loadSuppliers,
		loadSupplierData
	} = useSupplier();

	const {
		aplications,
        filter,
        formik,
        firestoreSaved,
		searchTerm,
        showProducts,
        allProducts,
        addNewAplication,
        deleteAplication,
        deleteProduct,
		getProductByCode,
		getProductsByFamily,
		getProductsByModel,
        loadProducts,
        setFilter,
        setFirestoreSaved,
        searchProduct,
        updateProduct,
	} = useProducts();

	/*  Object creation for value.  */

    const carsFunctions = {
        cars, showCars, getCars, setShowCars,
    }

	const cartFunctions = { 
		addSpecialProduct,
		cart, 
        cartTotal,
        addToCart,
        addOnePiece,
        cancelCart,
        cleanCart,
        deleteCartProduct,
        deleteOnePiece,
		setAddSpecialProduct,
		setCart,
	}

	const supplierFunctions = {
		allSuppliers,
		loadSuppliers, 
		loadSupplierData
	}

	const productsFunctions = {
		aplications,
        filter,
        formik,
        firestoreSaved,
        showProducts,
		searchTerm,
        allProducts,
        updateProduct,
        addNewAplication,
        searchProduct,
        deleteAplication,
        deleteProduct,
		getProductByCode,
		getProductsByFamily,
		getProductsByModel,
        loadProducts,
        setFirestoreSaved,
        setFilter,
	}

	const value = {
        ...carsFunctions,
		...cartFunctions, 
		...supplierFunctions,
		...productsFunctions,
	}

	return (
		<ProductContext.Provider value = { value }>

			<BrowserRouter>

				<div className = ' app-container-background '>

					<div className = ' d-lg-none '>
						<nav className = ' navbar navbar-expand-lg navbar-light bg-light px-3 '>
							<a className="navbar-brand" href="#">Punto de Venta</a>
							<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
								<span className="navbar-toggler-icon"></span>
							</button>
							<div className="collapse navbar-collapse" id="navbarNavAltMarkup">
								<div className="navbar-nav">
									<MobileNavigationLinks />
								</div>
							</div>
						</nav>
					</div>

					<div className={` row min-vh-100 `}>

						<div className={` col-2 fixed-top `}>
							<SideNavigation />
						</div>
						<div className={` col-12 col-lg-10 offset-lg-2 `}>
							{
								(userType === 'Administrador') &&

								<Routes>
									<Route path="/" element={<HomeScreen />} />
									<Route path="/ventas" element={<VentasScreen />} />
									<Route path='/payment' element={<PaymentScreen/>} />
									<Route path="/inventario" element={<InventarioScreen />} />
									<Route path="/carros" element={<CarModelsScreen />} />
									<Route path="/usuarios" element={<UsuariosScreen />} />
									<Route path="/historial" element = {<HistoryScreen />} />
									<Route path="/contabilidad" element = {<AccountingScreen />} />
									<Route path="/proveedores" element={<SuppliersScreen />} />
									<Route path="/ingresos" element={<IncomeStatisticsScreen  />} />
									<Route path="/clientes" element={<ClientsScreen />} />
									<Route path="/perfil" element = {<ProfileScreen />} />
									<Route path="/soporte" element={<SupportScreen />} />
									<Route path="/prueba" element={<CancelPurchases />} />
									<Route path = '*' element = { <PageNotFoundScreen /> } />
								</Routes>

							}
							{	
								(userType === 'Ventas') &&

								<Routes>
									<Route path="/" element={<VentasScreen />} />
									<Route path="/ventas" element={<VentasScreen />} />
									<Route path='/payment' element={<PaymentScreen/>} />
									<Route path="/inventario" element={<InventarioScreen />} />
									<Route path="/soporte" element={<SupportScreen />} />
									<Route path="/perfil" element={<ProfileScreen />} />
									<Route path = '*' element = { <PageNotFoundScreen /> } />
								</Routes>

							}
							{	
								(userType === 'Almacen') &&

								<Routes>
									<Route path="/" element={<InventarioScreen />} />
									<Route path="/inventario" element={<InventarioScreen />} />
									<Route path="/carros" element={<CarModelsScreen />} />
									<Route path="/soporte" element={<SupportScreen />} />
									<Route path="/perfil" element={<ProfileScreen />} />
									<Route path = '*' element = { <PageNotFoundScreen /> } />
								</Routes>

							}

						</div>

					</div>
				</div>
			</BrowserRouter>
		</ProductContext.Provider>
	)
}
