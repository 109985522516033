/* 
 *   Function that revieces a family with text, and 
 *   returns an array with the groups which has inside.
 */


export const getGroupsFromFamily = ( family ) => {
    let groups = [];    

    switch ( family ) {
        case 'Varios': 
            return [ '001', '014', '064', '072', '085', '086', '090', '099', '101', '108' ];
            break;
        case 'Aceite': 
            return [ '002' ];
            break;
        case 'Acumulador': 
            return [ '003' ];
            break;
        case 'Eléctrico': 
            return [ '005', '020', '025', '038', '031', '032', '034', '079', '084', '087', '091', '095', '098', '111' ];
            break;
        case 'Suspensión y Dirección': 
            return [ '006', '013', '016', '017', '024', '028', '029', '043', '044', '045', '058', '063', '065', '068', '075', '081', '089', '092', '096', '104', '105', '109', '110', '041', '069' ];
            break;
        case 'Motor': 
            return [ '007', '012', '036', '010', '015', '018', '019', '021', '027', '035', '046', '048', '049', '050', '051', '052', '053', '054', '056', '057', '066', '071', '076', '077', '078', '080', '082', '088', '093', '094', '107' ];
            break;
        case 'Frenos': 
            return [ '011', '026', '039', '083', '097', '073', '074' ];
            break;
        case 'Enfriamiento': 
            return [ '022', '047', '059', '100', '102', '103' ];
            break;
        case 'Clutch / Embrague / Transmisión': 
            return [ '023', '037', '009', '040', '055', '067', '033', '042', '070' ];
            break;
        case 'Servicio / Afinación': 
            return [ '060', '061', '062', '030', '106' ];
            break;

        default: 
            return [];
    }
}