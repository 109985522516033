import React, { useState, useEffect, useRef } from 'react'
import firebase from 'firebase/compat/app';
import { useReactToPrint } from 'react-to-print';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { useAuth } from './useAuth';
import { getProductById } from '../helpers/db/ProductsDB/getProductById';
import { useSalesSlice } from './redux/useSalesSlice';

export const useSaleDetail = ( selectedSale, setSelectedSale, users, clients, printTicketAction ) => {

    const { userType } = useAuth();
    const { handleCancelSale } = useSalesSlice();

    const MySwal = withReactContent(Swal)
    const {
        client,
        date,
        discount,
        invoiceRequired,
        paymentType,
        products,
        purchaseType,
        total,
        user,
    } = selectedSale;

    const [ userName, setUserName ] = useState( user || 'Vendedor no encontrado');
    const [ clientName, setClientName ] = useState('');
    const [ saleProducts, setSaleProducts ] = useState([]);
    const [ showProducts, setShowProducts ] = useState(false);
    
    const [ printTicket, setPrintTicket ] = useState(false);
    const [ ticketPaymentData, setTicketPaymentData ] = useState({});
    const [ ticketType, setTicketType ] = useState('');
    const [ showPrintButtons, setShowPrintButtons ] = useState(false);

    let componentRef = useRef();

    useEffect(() => getUserName(), [ ]);
    useEffect(() => getClientName(), [ clients ]);

    useEffect(() => {
        if( !printTicket ) return;

        printTicketAction( selectedSale, clientName, userName ).then(res => {
            setTicketPaymentData( res );
            setPrintTicket(false);
        }).catch((e) => console.log('Hubo un error imprimiendo tu ticket: ', e));
    }, [ printTicket ]);
    
    useEffect(() => {
        if(!showProducts) return;
        getProducts();
    },[ showProducts ]);

    useEffect(() => {
        if( ticketPaymentData.products !== undefined ) {
            setShowPrintButtons(true);
        }
    }, [ ticketPaymentData ]);

    useEffect(() => {
        if(ticketType === '') return;
        handleTicketPrint();
    }, [ ticketType ]);

    const handleTicketPrint = useReactToPrint({
        content: () => componentRef.current,
    })

    const generateTime = () => {
        const baseHours = new Date( date ).getHours();
        const hours = ( baseHours < 10) ? `0${ baseHours }` : `${ baseHours }`;
        const baseMinutes = new Date( date ).getMinutes();
        const minutes = ( baseMinutes < 10 ) ? `0${ baseMinutes }` : `${ baseMinutes }`;

        return `${ hours }:${ minutes }`
    }

    const [ time, setTime ] = useState(generateTime());

    const getClientName = () => {
        setClientName( client );
    }

    const getUserName = () => {
        setUserName( user || 'Vendedor no encontrado' );
    }

    const getProducts = () => {
        return true;
    }

    const cancelPurchase = () => {

        MySwal.fire({
            title: <strong>¿Seguro que deseas cancelar la compra?</strong>,
            html: <i>Ya no podrás consultarla y los productos regresarán a tu inventario.</i>,
            confirmButtonText: 'Cancelar',
            showCancelButton: true,
            reverseButtons: true,
            cancelButtonText: 'Regresar',
            icon: 'info'
        }).then(response => {
            if(response.isConfirmed) {
                handleCancelSale( selectedSale.folio );
                setSelectedSale('');
            }
        })

    }

    const cancelPurchaseQuery = () => {
        return new Promise((resolve, reject) => {
            const saleProducts = selectedSale.products;
            saleProducts.forEach(async product => {
                console.log(`ID: ${ product.product }, cantidad: ${ product.quantity }`);
                if( !product.product.includes('generic') ) {
                    firebase.firestore().collection('inventario').doc(product.product).get().then(async res => {
                        if( res !== undefined ) {
                            let existencia = res.existencia;
                            await res.ref.update({ existencia: Number(existencia + product.quantity) }).then(async () => {
                                console.log('Existencia recuperada');
                            })
                        } else {
                            return;
                        }
                    }).catch(e => reject(new Error(e)));   
                }
            })
            cancelPurchaseModifyStatistics( selectedSale ).then(() => {
                resolve('Finished');
            }).catch(e => reject(new Error(e)));
            cancelSellerStatistics( selectedSale ).then(() => {
                resolve('SellerStatistics gone.')
            }).catch(e => reject(new Error(e)));
        })
    }

    const statisticsCancelProcess = ( res, day, year, month, resolve, reject ) => {
        if( res.exists ) {
            let monthSales = [...res.data];
            let daySales = monthSales.filter(sale => sale.day === day);
            let daySalesIndex = monthSales.findIndex(sale => sale.day === day);
            if( daySales.length > 0) {
                let sales = daySales[0].sales;
                let total = daySales[0].total;
                const { total: saleTotal, invoiceRequired, paymentType } = selectedSale;
                monthSales[daySalesIndex] = { 
                    day: day, 
                    sales: Number( sales - 1 ), 
                    total: Number(total - saleTotal),
                    totalCash: ( purchaseType === 'cash') ? daySales[0].totalCash - saleTotal : daySales[0].totalCash, 
                    salesCash: ( purchaseType === 'cash') ? daySales[0].salesCash - 1 : daySales[0].salesCash, 
                    totalCredit: ( purchaseType !== 'cash') ? daySales[0].totalCredit - saleTotal : daySales[0].totalCredit, 
                    salesCredit: ( purchaseType !== 'cash') ? daySales[0].salesCredit - 1 : daySales[0].salesCredit,
                    salesNoInvoiceCash: (!invoiceRequired && paymentType === 'cash') ? daySales[0].salesNoInvoiceCash - saleTotal : daySales[0].salesNoInvoiceCash,
                    salesNoInvoiceCard: (!invoiceRequired && paymentType === 'card') ? daySales[0].salesNoInvoiceCard - saleTotal : daySales[0].salesNoInvoiceCard,
                    salesInvoiceCash: (invoiceRequired && paymentType === 'cash') ? daySales[0].salesInvoiceCash - saleTotal : daySales[0].salesInvoiceCash,
                    salesInvoiceCard: (invoiceRequired && paymentType === 'card') ? daySales[0].salesInvoiceCard - saleTotal : daySales[0].salesInvoiceCard,
                }

                res.ref.update({ data: [...monthSales] }).then(() => {
                    resolve('Finished');
                }).catch(err => reject(new Error(err)));
            }
        }
    }

    const cancelPurchaseModifyStatistics = ( selectedSale ) => {
        return new Promise(async (resolve, reject) => {
            let saleDate = new Date( selectedSale.date.seconds * 1000 );
            const year = saleDate.getFullYear();
            const month =  (Number(saleDate.getMonth()) + 1 );
            const day = saleDate.getDate();

            if( selectedSale.creditPayed ) {
                await firebase.firestore().collection('saleStatistics').doc(`${ year }-${ month }`).get().then(res => {
                    statisticsCancelProcess(res, day, year, month, resolve, reject );
                }).catch(e => reject(new Error(e)));
                await firebase.firestore().collection('completedSaleStatistics').doc(`${ year }-${ month }`).get().then(res => {
                    statisticsCancelProcess(res, day, year, month, resolve, reject );
                }).catch(e => reject(new Error(e)));
            }

        })
    }

    const cancelSellerStatistics = ( selectedSale ) => {
        return new Promise(async (resolve, reject) => {
            let saleDate = new Date( selectedSale.date.seconds * 1000 );
            const year = saleDate.getFullYear();
            const month =  (Number(saleDate.getMonth()) + 1 );
            const day = saleDate.getDate();
            await firebase.firestore().collection('usuarios').doc(user).collection('sales').doc(`${ year }-${ month }`).get().then(res => {
                if(res.exists) {
                    let monthSales = [...res.data];
                    let daySales = monthSales.filter(sale => sale.day === day);
                    let daySalesIndex = monthSales.findIndex(sale => sale.day === day);
                    if( daySales.length > 0) {
                        let doneSales = daySales[0].doneSales;
                        let total = daySales[0].total;
                        let salesArray = daySales[0].sales;
                        let deleteSaleIndex = salesArray.findIndex(sale => sale === selectedSale.id)
                        salesArray.splice(deleteSaleIndex, 1)
                        monthSales[daySalesIndex] = { 
                            day: day, 
                            doneSales: Number( doneSales - 1 ), 
                            total: Number(total - selectedSale.total),
                            sales: salesArray
                        }
                        res.ref.update({ data: [...monthSales] }).then(() => {
                            resolve('Se han eliminado las estadísticas del vendedor')
                        }).catch(e => reject(new Error(e)));
                    }
                }
            }).catch(e => reject(new Error(e)))
        })
    }

    const states = {
        client, 
        clientName, 
        componentRef,
        date,
        discount,
        invoiceRequired,
        paymentType,
        purchaseType,
        printTicket,
        products,
        saleProducts, 
        showPrintButtons,
        showProducts, 
        ticketPaymentData,
        ticketType,
        time,
        total,
        userName,
        userType,
        user,
    }

    const stateUpdaters = {
        cancelPurchase,
        setPrintTicket,
        setShowProducts,
        setTicketType,
    }

    return [states, stateUpdaters];

}
