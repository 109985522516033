/* 
 *   Return the object for days without salesStatistics.
 */

export const generateNewStats = ( total, purchaseType, invoiceRequired, paymentType ) => {
    console.log('Generando nueva venta');
    const today = new Date().getDate();
    return {
        day: today, 
        totalCash: (purchaseType === 'cash') ? total : 0, 
        salesCash: (purchaseType === 'cash') ? 1 : 0, 
        totalCredit: (purchaseType !== 'cash') ? total : 0, 
        salesCredit: (purchaseType !== 'cash') ? 1 : 0,
        total: total,
        sales: 1,
        salesNoInvoiceCash: (!invoiceRequired && paymentType === 'cash') ? total : 0,
        salesNoInvoiceCard: (!invoiceRequired && paymentType === 'card') ? total : 0,
        salesInvoiceCash: (invoiceRequired && paymentType === 'cash') ? total : 0,
        salesInvoiceCard: (invoiceRequired && paymentType === 'card') ? total : 0,
    }
}