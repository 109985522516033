import React, { useState } from 'react'
import { Modal } from '../../Modal/Modal';
import { DetalleProductoVenta } from '../DetalleProductoVenta/DetalleProductoVenta';
import { formatMoney } from './../../../helpers/formatMoney';

// import './SalesProductCard.css';

export const SalesProductCard = ({ addToCart, product }) => {

    const { existencia, nombre, pieza, precio_contado, precio_credito, image } = product
    const [ openDetailModal, setOpenDetailModal ] = useState(false);

    return (
        <div className = 'payment_container'>
            <div>
                <div className = ' payment_product-info w-100 d-flex justify-content-end '>
                    <ion-icon 
                        onClick = { () => setOpenDetailModal( true ) }
                        name = "ellipsis-horizontal-circle-outline">
                    </ion-icon>
                </div>
                <div className=' d-flex justify-content-center mb-2 '>
                    <img
                        className=' payment_image mb-2 '
                        src={image}
                    />
                </div>
                <p className=' text_bold-label '>{pieza}</p>
                <p className=' text_data '>{nombre}</p>
                <p
                    className=' text_instructions '
                >
                    Disponibles {existencia}
                </p>
            </div>
            <div className = ' d-flex align-center-center mt-1 '>
                <div className = ' flex-grow-1 d-flex align-items-center '>
                    <p className=' payment_product-price '>
                        {formatMoney(precio_contado)} - {formatMoney(precio_credito)}
                    </p>
                </div>
                <div className = ' payment_product-add ' onClick = { addToCart }> 
                    <ion-icon name="add-outline"></ion-icon>
                </div>
            </div>
            
            
            { !!openDetailModal && (
                <Modal>
                    <DetalleProductoVenta
                        closeModal = { () => setOpenDetailModal(false) }
                        producto = { product }
                        venta = { true }
                    />
                </Modal>
            )}
        </div>
    )

}
