import { createSlice } from "@reduxjs/toolkit";
import { invoicesDB } from "../../db/invoicesDB";

export const invoicesSlice = createSlice({
    name: 'invoices',
    initialState: {
        invoices: [ ...invoicesDB ],
    },
    reducers: {
        onInvoiceStatusChange: ( state, { payload }) => {
            state.invoices = payload;
        }
    },
})

export const { onInvoiceStatusChange } = invoicesSlice.actions;