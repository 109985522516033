import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import { NotificacionItem } from '../NotificationItem/NotificacionItem';
import { getProductsNotifications } from '../../../helpers/db/ProductsDB/getProductsNotifications';
import { getProductMoresNotifications } from '../../../helpers/db/ProductsDB/getProductsMoreNotifications';

export const Notifications = React.memo(() => {

    const [ lastDocument, setLastDocument ] = useState([]);
    const [ notifications, setNotifications ] = useState([]);
    const [ seeMore, setSeeMore ] = useState(true);

    useEffect(() => {

        let unsubscribe = true;
        getProductsNotifications(5, 8).then(response => {
            if(unsubscribe) {
                setFirstNotifications(response)
            }
        });
        return () => unsubscribe = false;

    }, []);

    const setFirstNotifications = ( notifications ) => {
        notifications.forEach( notification => {
            setNotifications(state => [...state, notification])
        })
        setLastDocument(notifications.docs[notifications.length - 1]);
    }

    const getSeeMoreNotifications = ( ) => {
        getProductMoresNotifications( 5, 8, lastDocument )
            .then(res => {
                let tempNotifications = [];
                res.docs.forEach(product => {
                    tempNotifications.push(product);
                })
                setNotifications(state => [ ...state, ...tempNotifications ]);
                setLastDocument(res.docs[res.docs.length - 1]);
                if(res.docs.length < 2) {
                    setSeeMore(false);
                }
            }).catch(e => {
                setSeeMore(false)
            })

    }

    return (
        <div className = ' notification_container '>
            <div className = ' d-flex '>
                <div className = ' notification_section-title-notification position-relative pe-2 '>
                    <p>
                        Notificaciones 
                    </p>
                    <div>
                        <span className = ' badge background-purple ' >{ notifications.length }</span>

                    </div>
                </div>

            </div>
            <div>
                <div className = ' mt-3 '>
                    {(notifications.length === 0) && (
                        <div className = ' notification_no-notif '>
                            <ion-icon name="checkmark-circle-outline"></ion-icon>
                            <h4>¡Enhorabuena! No hay notificaciones.</h4>
                        </div>
                    )}
                    {(notifications.length > 0) && (
                        notifications.map(notification => (
                            <NotificacionItem
                                key = { notification.id }
                                notification = { notification }
                            />
                        ))
                    )}
                    {(notifications.length > 0 && !!seeMore) && (
                        <div className = ' text-center '>
                            <p 
                                className = ' btn purple-button '
                                onClick = {() => getSeeMoreNotifications( ) }
                            >Cargar Más</p>
                        </div>
                    )}
                    {(notifications.length > 0 && !seeMore) && (
                        <div className = ' text-center '>
                            <p>Ya no hay más notificaciones disponibles.</p>
                        </div>
                    )}
                </div>
            </div>

        </div>
    );

});